import React, {  useEffect, useState, useCallback  } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { DEFAULTTYPEOFDOCUMENT, ENDPOINT } from "constants/variables";

import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'; 
import MenuItem  from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import FormSearchStores from "pages/forms/editOrNewFormDI/FormSearchStores";
import FormSearchStoresAddresses from "./FormSearchStoresAddress";
import FormSearchStoreVehicles from "./FormSearchVehicles"
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const initialDocumentIdToSelect = [{
    "id": "",
    "documentId": "",
    "tradename": ""
}];

const FormCreateOrEditDestTras = ({formForm,setFormForm,showModalFormSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormForm,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataForms']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const dest_tras_view_name = useSelector((state) => state.formatDIUser.dest_tras_view_name); 
    const dest_tras_view_contactname = useSelector((state) => state.formatDIUser.dest_tras_view_contactname); 
    const dest_tras_view_address = useSelector((state) => state.formatDIUser.dest_tras_view_address); 
    const dest_tras_view_country = useSelector((state) => state.formatDIUser.dest_tras_view_country); 
    const dest_tras_view_community = useSelector((state) => state.formatDIUser.dest_tras_view_community); 
    const dest_tras_view_phone = useSelector((state) => state.formatDIUser.dest_tras_view_phone); 
    const dest_tras_view_email = useSelector((state) => state.formatDIUser.dest_tras_view_email); 
    const dest_tras_view_nima = useSelector((state) => state.formatDIUser.dest_tras_view_nima); 
    const dest_tras_view_cnae = useSelector((state) => state.formatDIUser.dest_tras_view_cnae); 
    const dest_tras_view_inscription = useSelector((state) => state.formatDIUser.dest_tras_view_inscription); 
    const dest_tras_view_opt = useSelector((state) => state.formatDIUser.dest_tras_view_opt); 
    const dest_tras_view_licenseplate = useSelector((state) => state.formatDIUser.dest_tras_view_licenseplate); 
    const dest_tras_view_economicactivity = useSelector((state) => state.formatDIUser.dest_tras_view_economicactivity); 

    const dest_tras_handle_documnettype= useSelector((state) => state.formatDIUser.dest_tras_handle_documnettype); 
    const dest_tras_handle_documentid= useSelector((state) => state.formatDIUser.dest_tras_handle_documentid); 
    const dest_tras_handle_tradename= useSelector((state) => state.formatDIUser.dest_tras_handle_tradename); 
    const dest_tras_handle_name= useSelector((state) => state.formatDIUser.dest_tras_handle_name); 
    const dest_tras_handle_contactname= useSelector((state) => state.formatDIUser.dest_tras_handle_contactname); 
    const dest_tras_handle_address= useSelector((state) => state.formatDIUser.dest_tras_handle_address); 
    const dest_tras_handle_phone= useSelector((state) => state.formatDIUser.dest_tras_handle_phone); 
    const dest_tras_handle_email= useSelector((state) => state.formatDIUser.dest_tras_handle_email); 
    const dest_tras_handle_nima= useSelector((state) => state.formatDIUser.dest_tras_handle_nima); 
    const dest_tras_handle_cnae= useSelector((state) => state.formatDIUser.dest_tras_handle_cnae); 
    const dest_tras_handle_inscription= useSelector((state) => state.formatDIUser.dest_tras_handle_inscription); 
    const dest_tras_handle_opt= useSelector((state) => state.formatDIUser.dest_tras_handle_opt); 
    const dest_tras_handle_licenseplate= useSelector((state) => state.formatDIUser.dest_tras_handle_licenseplate); 
    const dest_tras_handle_economicactivity= useSelector((state) => state.formatDIUser.dest_tras_handle_economicactivity); 
    
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false); 

    const [provinceToSelect_Dest_Tras,setProvinceToSelect_Dest_Tras]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Dest_Tras,setPostalCodeToSelect_Dest_Tras]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const [documentIdDestToSelect,setDocumentIdDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredDocumentIdDest, setFilteredDocumentIdDest] = useState([]); // Opciones filtradas
    const [highlightedIndexDocumentIdDest, setHighlightedIndexDocumentIdDest] = useState(-1); // Índice de la opción resaltada 
    const [tradenameDestToSelect,setTradenameDestToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredTradenamedDest, setFilteredTradenamedDest] = useState([]); // Opciones filtradas
    const [highlightedIndexTradenamedDest, setHighlightedIndexTradenamedDest] = useState(-1); // Índice de la opción resaltada 
        
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchDocumentIdDest = (event) => {
        const searchDocumentIdDest = event.target.value;
        setFormForm(formForm => ({
        ...formForm,
        dest_tras_documentid: searchDocumentIdDest,
        })); 
        if (searchDocumentIdDest.trim() === "") {
        setFilteredDocumentIdDest([]);
        setHighlightedIndexDocumentIdDest(-1);
        return;
        } 
        const filtered = documentIdDestToSelect.filter((store) => {
        if (!store || !store.documentId) return false;
        const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
        // Comparar después de eliminar puntos y guiones de ambos
        const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        return normalizedDocumentId.includes(searchNormailzedDocumentId);
        });
        setFilteredDocumentIdDest(filtered);
        setHighlightedIndexDocumentIdDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectDocumentIdDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormForm((formForm) => ({
        ...formForm,
        dest_tras_documentid: store.documentId || "",
        }));
        setFilteredDocumentIdDest([]);
        setHighlightedIndexDocumentIdDest(-1);
    }; 
    const handleKeyDownDocumentIdDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredDocumentIdDest.length === 0 && formForm.dest_tras_documentid.trim() === "") {
            setFilteredDocumentIdDest(documentIdDestToSelect);
            setHighlightedIndexDocumentIdDest(0);
        } else {
            setHighlightedIndexDocumentIdDest((prevIndex) => {
            const nextIndex = prevIndex < filteredDocumentIdDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexDocumentIdDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdDest >= 0) {
        if (highlightedIndexDocumentIdDest >= 0 && highlightedIndexDocumentIdDest < filteredDocumentIdDest.length) {
            handleSelectDocumentIdDest(filteredDocumentIdDest[highlightedIndexDocumentIdDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = documentIdDestToSelect.some((store) => {
            if (!store || !store.documentId) return false; 
            const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
            const normalizedInputId = formForm.dest_tras_documentid.toUpperCase();
            return normalizedStoreId === normalizedInputId;
        }); 
        if (!isValid) {
            setFormForm((formForm) => ({
            ...formForm,
            dest_tras_documentid: "",
            }));
        } 
        setFilteredTradenamedDest([]);
        setHighlightedIndexDocumentIdDest(-1);
        }
    };
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchTradenameDest = (event) => {
        const searchTradenameDest = event.target.value.toUpperCase();
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_tradename: searchTradenameDest,
        })); 
        if (searchTradenameDest.trim() === "") {
            setFilteredTradenamedDest([]);
            setHighlightedIndexTradenamedDest(-1);
            return;
        } 
        const filtered = tradenameDestToSelect.filter((store) => {
        if (!store || !store.tradename) return false;
            // Comparar después de eliminar puntos y guiones de ambos
            const normalizedTradeName = store.tradename.toUpperCase();
            return normalizedTradeName.includes(searchTradenameDest);
        });
        setFilteredTradenamedDest(filtered);
        setHighlightedIndexTradenamedDest(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectTradeNameDest = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormForm((formForm) => ({
        ...formForm,
        dest_tras_tradename: store.tradename || "",
        dest_tras_documentid: store.documentId || formForm.dest_tras_documentid,
        
        }));
        setFilteredTradenamedDest([]);
        setHighlightedIndexTradenamedDest(-1);
    }; 
    const handleKeyDownTradenameDest = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredTradenamedDest.length === 0 && formForm.dest_tras_tradename.trim() === "") {
            setFilteredTradenamedDest(tradenameDestToSelect);
            setHighlightedIndexTradenamedDest(0);
        } else {
            setHighlightedIndexTradenamedDest((prevIndex) => {
            const nextIndex = prevIndex < filteredTradenamedDest.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexTradenamedDest((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedDest >= 0) {
        if (highlightedIndexTradenamedDest >= 0 && highlightedIndexTradenamedDest < filteredTradenamedDest.length) {
            handleSelectTradeNameDest(filteredTradenamedDest[highlightedIndexTradenamedDest]);
        }
        } else if (event.key === "Escape") {
        const isValid = tradenameDestToSelect.some((store) => {
            if (!store || !store.tradename) return false; 
            const normalizedTradeName = store.tradename.toUpperCase();
            const normalizedInputTradeName = formForm.dest_tras_tradename.toUpperCase();
            return normalizedTradeName === normalizedInputTradeName;
        }); 
        if (!isValid) {
            setFormForm((formForm) => ({
            ...formForm,
            dest_tras_tradename: "",
            }));
        } 
        setFilteredTradenamedDest([]);
        setHighlightedIndexTradenamedDest(-1);
        }
    };

    // Función para desplazar el contenedor
    const scrollToElement = (index) => {
        const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
        const item = dropdown?.children[index];
        if (item) {
        item.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
        }
    };

    const handleBlur = useCallback((event) => {
        if (!event || !event.currentTarget) return;
        // Asegúrate de que no se cierra si haces clic en un elemento de la lista
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setFilteredDocumentIdDest([]);
            setHighlightedIndexDocumentIdDest(-1);
            setFilteredTradenamedDest([]);
            setHighlightedIndexTradenamedDest(-1); 
        }
    }, []);
    
    const handleChangeSelectDocumentType_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Dest_Tras = (e) => {
        handleChangeSelectDocumentType_Dest_Tras(e);
    };
    
    const handleChangeSelectRoadType_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Dest_Tras = (e) => {
        handleChangeSelectRoadType_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectCountry_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_country_id: event.target.value,
            dest_tras_province_id:Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Dest_Tras = (e) => {
        handleChangeSelectCountry_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectProvince_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_province_id :event.target.value,
            dest_tras_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Dest_Tras = (e) => {
        handleChangeSelectProvince_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const handleChangeSelectPostalCode_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Dest_Tras = (e) => {
        handleChangeSelectPostalCode_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeSelectCommunity_Dest_Tras = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            dest_tras_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Dest_Tras = (e) => {
        handleChangeSelectCommunity_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };

    const handleChangeDataEditForm_Dest_Tras = (e) => {
        const { name, value } = e.target;
        setFormForm(formForm => ({
          ...formForm,
          [name]: value,
        }));
    };
    const handleBlurDataEditForm_Dest_Tras = (e) => {
        handleChangeDataEditForm_Dest_Tras(e);
        setErrorsForm(validateFormForm());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

    const searchFormVehicles = (event) => {
        setShowModalSearchVehicles(true);
    };

    const deleteDateDestTras = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            dest_tras_store_id:0,
            dest_tras_documnettype_id: "",
            dest_tras_documentid:"",
            dest_tras_tradename: "",
            dest_tras_name: "",
            dest_tras_contactname: "",
            dest_tras_roadtype_id: 0,
            dest_tras_street: "",
            dest_tras_country_id: Number(DEFAULTCOUNTRY),
            dest_tras_province_id: Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id: "",
            dest_tras_community_id: Number(DEFAULTCOMMUNITY),
            dest_tras_phone: "",
            dest_tras_email: "",
            dest_tras_nima: "",
            dest_tras_cnae: "",
            dest_tras_inscription: "",
            dest_tras_opt: 0,
            dest_tras_licenseplate: "",
            dest_tras_economicactivity:""
        }));
        setErrorsForm(validateFormForm());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            dest_tras_store_id:0,
            dest_tras_documnettype_id: "",
            dest_tras_documentid:"",
            dest_tras_tradename: "",
            dest_tras_name: "",
            dest_tras_contactname: "",
            dest_tras_roadtype_id: "",
            dest_tras_street: "",
            dest_tras_country_id: "",
            dest_tras_province_id: "",
            dest_tras_postalcode_id: "",
            dest_tras_community_id: "",
            dest_tras_phone: "",
            dest_tras_email: "",
            dest_tras_nima: "",
            dest_tras_cnae: "",
            dest_tras_inscription: "",
            dest_tras_opt: "",
            dest_tras_licenseplate: "",
            dest_tras_economicactivity:""
        }));
    };

    const deleteDataSteetDestTras = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            dest_tras_roadtype_id: 0,
            dest_tras_street: "",
            dest_tras_country_id: Number(DEFAULTCOUNTRY),
            dest_tras_province_id: Number(DEFAULTPROVINCE),
            dest_tras_postalcode_id: "",
            dest_tras_community_id: Number(DEFAULTCOMMUNITY),
            dest_tras_nima: "", 
            dest_tras_inscription: "",
            dest_tras_opt: 0,
        }));
        setErrorsForm(validateFormForm());
    };

    const deleteDataVehicleDestTras = (event) => {
        setFormForm(formForm => ({
          ...formForm,
          dest_tras_licenseplate: ""
        }));
        setErrorsForm(validateFormForm());
    };

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`form/getexiststorebynumberdocument/`,token,formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, ""));
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formForm.dest_tras_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get5(ENDPOINT,`form/getformstorebynumberdocument`,token,formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, ""),4);
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                        setFormForm(formForm => ({
                            ...formForm,
                            dest_tras_store_id: getData.store_id,
                            dest_tras_documnettype_id: getData.documnettype_id,
                            dest_tras_tradename: getData.tradename,
                            dest_tras_name: getData.name,
                            dest_tras_contactname: getData.contacperson,
                            dest_tras_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            dest_tras_street: getData.street,
                            dest_tras_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            dest_tras_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            dest_tras_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            dest_tras_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id,
                            dest_tras_phone: getData.phone,
                            dest_tras_email: getData.email,
                            dest_tras_nima: getData.nima_dest_tras,
                            dest_tras_cnae: getData.cnae,
                            dest_tras_inscription: getData.pygr_dest_tras,
                            dest_tras_opt: getData.opt_dest_tras,
                            dest_tras_licenseplate: getData.licenseplate,
                            dest_tras_economicactivity:getData.economicactivity
                        }));
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formForm.dest_tras_documentid && formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [t,token,dispatch,expanded,formForm.dest_tras_documentid,errorsForm.dest_tras_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormForm,formForm.dest_tras_store_id]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formForm.dest_tras_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formForm.dest_tras_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Dest_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [t,token,dispatch,i18n,showModalFormSettings,formForm.dest_tras_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formForm.dest_tras_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formForm.dest_tras_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formForm.dest_tras_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Dest_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [t,token,dispatch,i18n,showModalFormSettings,formForm.dest_tras_country_id,formForm.dest_tras_province_id,expanded]);
 
    useEffect(() => {
        let isMounted = true; 
        const fetchDataDocumentIdDest = async () => {
            try {
            const getData = await helpHttp().get2(ENDPOINT,`form/getlistdocumentidformdi/`,token,
                (formForm.dest_tras_documentid!==undefined && formForm.dest_tras_documentid!==null && formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
            if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setDocumentIdDestToSelect(getData); 
            }
            } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };   
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formForm.dest_tras_documentid && formForm.dest_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataDocumentIdDest();
            }
        } else{
            setDocumentIdDestToSelect(initialDocumentIdToSelect);
        } 
        return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,formForm.dest_tras_documentid,expanded]);
    
    useEffect(() => {
        let isMounted = true; 
        const fetchDataTradenameDest = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`form/getlisttradenameformdi/`,token,
                    (formForm.dest_tras_tradename!==undefined && formForm.dest_tras_tradename!==null && formForm.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formForm.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    setTradenameDestToSelect(getData);
                }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };  
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4'){
            if (formForm.dest_tras_tradename && formForm.dest_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataTradenameDest();
            }
        } else{
            setTradenameDestToSelect(initialDocumentIdToSelect);
        }  
        return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,formForm.dest_tras_tradename,expanded]);

    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel4') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="dest_tras_documnettype_id"
                    name="dest_tras_documnettype_id"
                    value={ (formForm.dest_tras_documnettype_id === undefined || formForm.dest_tras_documnettype_id ===null || formForm.dest_tras_documnettype_id ===""
                        || formForm.dest_tras_documnettype_id ==="0" || formForm.dest_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formForm.dest_tras_documnettype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Dest_Tras}
                    onChange={handleChangeSelectDocumentType_Dest_Tras}
                    disabled={!dest_tras_handle_documnettype}
                    helperText={errorsForm.dest_tras_documnettype_id!=null && errorsForm.dest_tras_documnettype_id!==""?t(errorsForm.dest_tras_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formForm.dest_tras_documnettype_id === undefined || formForm.dest_tras_documnettype_id ===null || formForm.dest_tras_documnettype_id ===""
                            || formForm.dest_tras_documnettype_id ==="0" || formForm.dest_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formForm.dest_tras_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="dest_tras_documentid" 
                        name="dest_tras_documentid"
                        value={formForm.dest_tras_documentid}
                        onChange={handleSearchDocumentIdDest}
                        onKeyDown={handleKeyDownDocumentIdDest}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.dest_tras_documentid!=null && errorsForm.dest_tras_documentid!==""?t(errorsForm.dest_tras_documentid):t('PlaceHolder_FORM_CIF')}
                        placeholder={t('PlaceHolder_FORM_CIF')}
                        variant="standard"
                        inputProps={{ style: {width: i18n.language==='es'?245:285,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        disabled={!dest_tras_handle_documentid}
                    />
                    {/* Desplegable dinámico */}
                    {filteredDocumentIdDest.length > 0 && (
                        <div
                            className="dropdown-class"
                            style={{
                            position: "absolute",
                            left:'0px',
                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                            borderRadius: "4px",
                            marginTop: "4px",
                            maxHeight: "200px",
                            overflowY: "desto",
                            width: "350%",
                            fontSize: '18px',
                            backgroundColor: "#f8f9fa", // Fondo gris claro
                            zIndex: 1000,
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                        >
                            {filteredDocumentIdDest.map((store, index) => (
                            <MenuItem
                                key={store.id}
                                onClick={() => handleSelectDocumentIdDest(store)}
                                style={{
                                    cursor: "pointer",
                                    padding: "8px",
                                    borderBottom: "1px solid #eee",
                                    fontSize: '18px',
                                    backgroundColor:
                                    index === highlightedIndexDocumentIdDest ? "#4e73df" : "transparent",
                                    color: index === highlightedIndexDocumentIdDest ? "white" : "black",
                                }}
                            >
                                {store.documentId} - {store.tradename}
                            </MenuItem>
                            ))}
                        </div>
                    )}
                </span> 
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateDestTras}>
                    <DeleteIcon  style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormStore}>
                        <SearchIcon  style={{fontSize: 28}}/>
                    </IconButton>
                }
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_TRADENAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="dest_tras_tradename" 
                        name="dest_tras_tradename"
                        value={formForm.dest_tras_tradename}
                        onChange={handleSearchTradenameDest}
                        onKeyDown={handleKeyDownTradenameDest}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.dest_tras_tradename!=null && errorsForm.dest_tras_tradename!==""?t(errorsForm.dest_tras_tradename):t('PlaceHolder_FORM_TRADENAME')}
                        placeholder={t('PlaceHolder_FORM_TRADENAME')}
                        variant="standard"
                        inputProps={{ style: {width: i18n.language==='es'?720:696,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        disabled={!dest_tras_handle_tradename}
                    />
                    {/* Desplegable dinámico */}
                    {filteredTradenamedDest.length > 0 && (
                            <div
                                className="dropdown-class"
                                style={{
                                position: "absolute",
                                left:'0px',
                                border: "2px solid #4e73df", // Borde azul (#4e73df)
                                borderRadius: "4px",
                                marginTop: "4px",
                                maxHeight: "200px",
                                overflowY: "desto",
                                width: "150%",
                                fontSize: '18px',
                                backgroundColor: "#f8f9fa", // Fondo gris claro
                                zIndex: 1000,
                                }}
                                onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                            >
                                {filteredTradenamedDest.map((store, index) => (
                                <MenuItem
                                    key={store.id}
                                    onClick={() => handleSelectTradeNameDest(store)}
                                    style={{
                                        cursor: "pointer",
                                        padding: "8px",
                                        borderBottom: "1px solid #eee",
                                        fontSize: '18px',
                                        backgroundColor:
                                        index === highlightedIndexTradenamedDest ? "#4e73df" : "transparent",
                                        color: index === highlightedIndexTradenamedDest ? "white" : "black",
                                    }}
                                >
                                    {store.tradename} - {store.documentId}
                                </MenuItem>
                                ))}
                            </div>
                        )}
                    </span> 
                </div>
            </div>
            {dest_tras_view_name &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NAME')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_name" 
                        name="dest_tras_name"
                        value={formForm.dest_tras_name}
                        inputProps={{ style: {width: i18n.language==='es'?674:719,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.dest_tras_name!=null && errorsForm.dest_tras_name!==""?t(errorsForm.dest_tras_name):t('PlaceHolder_FORM_NAME')}
                        placeholder={t('PlaceHolder_FORM_NAME')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Dest_Tras}
                        onChange={handleChangeDataEditForm_Dest_Tras}
                        disabled={!dest_tras_handle_name}
                    />
                    </div>
                </div> 
            }
            {dest_tras_view_contactname &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CONTACT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_contactname" 
                        name="dest_tras_contactname"
                        value={formForm.dest_tras_contactname}
                        inputProps={{ style: {width: i18n.language==='es'?657:702,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.dest_tras_contactname!=null && errorsForm.dest_tras_contactname!==""?t(errorsForm.dest_tras_contactname):t('PlaceHolder_FORM_CONTACT')}
                        placeholder={t('PlaceHolder_FORM_CONTACT')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Dest_Tras}
                        onChange={handleChangeDataEditForm_Dest_Tras}
                        disabled={!dest_tras_handle_contactname}
                    />
                    </div>
                </div> 
            }
            {dest_tras_view_address &&
                <>
                    <div className="row p-2"> 
                        <div className="form-group">
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_RoadType')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="dest_tras_roadtype"
                            name="dest_tras_roadtype"
                            value={ (formForm.dest_tras_roadtype_id === undefined || formForm.dest_tras_roadtype_id ===null || formForm.dest_tras_roadtype_id ===""
                                || formForm.dest_tras_roadtype_id ==="0" || formForm.dest_tras_roadtype_id ===0)?"":formForm.dest_tras_roadtype_id}
                            inputProps={{ style: {width: i18n.language==='es'?100:100,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            variant="outlined"
                            onBlur={handleBlurSelectRoadType_Dest_Tras}
                            onChange={handleChangeSelectRoadType_Dest_Tras}
                            disabled={!dest_tras_handle_address}
                            helperText={errorsForm.dest_tras_roadtype_id!=null && errorsForm.dest_tras_roadtype_id!==""?t(errorsForm.dest_tras_roadtype_id):""}
                            select
                            SelectProps={{
                                native: true,
                                value: (formForm.dest_tras_roadtype_id === undefined || formForm.dest_tras_roadtype_id ===null || formForm.dest_tras_roadtype_id ===""
                                    || formForm.dest_tras_roadtype_id ==="0" || formForm.dest_tras_roadtype_id ===0)?"": formForm.dest_tras_roadtype_id
                            }}>
                            <option key={0} value=""/>
                            {roadTypesToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                            ))}
                        </TextField>  
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Address')} :</label>
                        &nbsp;&nbsp;&nbsp;&nbsp; 
                        <TextField
                            id="dest_tras_street" 
                            name="dest_tras_street"
                            value={formForm.dest_tras_street}
                            inputProps={{ style: {width: i18n.language==='es'?361:374,  fontSize: '18px', }, maxLength: 250 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsForm.dest_tras_street!=null && errorsForm.dest_tras_street!==""?t(errorsForm.dest_tras_street):t('PlaceHolder_FORM_Address')}
                            placeholder={t('PlaceHolder_FORM_Address')}
                            variant="standard"
                            onBlur={handleBlurDataEditForm_Dest_Tras}
                            onChange={handleChangeDataEditForm_Dest_Tras}
                            disabled={!dest_tras_handle_address}
                        />
                        </div>
                        &nbsp;&nbsp;
                        <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataSteetDestTras}>
                            <DeleteIcon style={{fontSize: 28}}/>
                        </IconButton>
                        &nbsp;&nbsp;
                        {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&& formForm.dest_tras_documentid!==undefined && formForm.dest_tras_documentid!=="") ?
                            <IconButton  size="small"  style={{
                                color:
                                (formForm.dest_tras_store_id===undefined
                                || formForm.dest_tras_store_id===""
                                || formForm.dest_tras_store_id==="0"
                                || formForm.dest_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                                disabled={formForm.dest_tras_store_id===undefined
                                        || formForm.dest_tras_store_id===""
                                        || formForm.dest_tras_store_id==="0"
                                        || formForm.dest_tras_store_id===0 } onClick={searchFormStoreAddress}>
                            <SearchIcon style={{fontSize: 28}}/>
                            </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                                <SearchIcon style={{fontSize: 28}}/>
                            </IconButton>
                        }
                    </div>
 
                    {(dest_tras_view_country || dest_tras_view_community) &&
                        <div className="row p-2"> 
                            <div className="form-group">
                                {dest_tras_view_country &&
                                    <>
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Country')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                            id="dest_tras_country_id"
                                            name="dest_tras_country_id"
                                            value={ (formForm.dest_tras_country_id === undefined || formForm.dest_tras_country_id ===null || formForm.dest_tras_country_id ===""
                                                    || formForm.dest_tras_country_id ==="0" || formForm.dest_tras_country_id ===0)?Number(DEFAULTCOUNTRY):formForm.dest_tras_country_id}
                                            inputProps={{ style: {width: i18n.language==='es'?235:235,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                            variant="outlined"
                                            onBlur={handleBlurSelectCountry_Dest_Tras}
                                            onChange={handleChangeSelectCountry_Dest_Tras}
                                            disabled={!dest_tras_handle_address}
                                            helperText={errorsForm.dest_tras_country_id!=null && errorsForm.dest_tras_country_id!==""?t(errorsForm.dest_tras_country_id):""}
                                            select
                                            SelectProps={{
                                                native: true,
                                                value: (formForm.dest_tras_country_id === undefined || formForm.dest_tras_country_id ===null || formForm.dest_tras_country_id ===""
                                                    || formForm.dest_tras_country_id ==="0" || formForm.dest_tras_country_id ===0)?Number(DEFAULTCOUNTRY): formForm.dest_tras_country_id
                                            }}>
                                            {countryToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                            ))}
                                        </TextField>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </>
                                }
                                {dest_tras_view_community &&
                                <>
                                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Community')} :</label>
                                    &nbsp;&nbsp;&nbsp;&nbsp; 
                                    <TextField
                                        id="dest_tras_community_id"
                                        name="dest_tras_community_id"
                                        value={ (formForm.dest_tras_community_id === undefined || formForm.dest_tras_community_id ===null || formForm.dest_tras_community_id ===""
                                            || formForm.dest_tras_community_id ==="0" || formForm.dest_tras_community_id ===0)?Number(DEFAULTCOMMUNITY):formForm.dest_tras_community_id}
                                        inputProps={{ style: {width: i18n.language==='es'?294:268,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        variant="outlined"
                                        onBlur={handleBlurSelectCommunity_Dest_Tras}
                                        onChange={handleChangeSelectCommunity_Dest_Tras}
                                        disabled={!dest_tras_handle_address}
                                        helperText={errorsForm.dest_tras_community_id!=null  && errorsForm.dest_tras_community_id!==""?t(errorsForm.dest_tras_community_id):""}
                                        select
                                        SelectProps={{
                                            native: true,
                                            value: (formForm.dest_tras_community_id === undefined || formForm.dest_tras_community_id ===null || formForm.dest_tras_community_id ===""
                                                || formForm.dest_tras_community_id ==="0" || formForm.dest_tras_community_id ===0)?Number(DEFAULTCOMMUNITY): formForm.dest_tras_community_id
                                        }}>
                                        {communityToSelect.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {i18n.language==="es"?option.namees:option.nameen}
                                            </option>
                                        ))}
                                    </TextField>  
                                </>
                            } 
                            </div>
                        </div>
                    }
                    <div className="row p-2"> 
                        <div className="form-group">
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Province')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_province_id"
                                name="dest_tras_province_id"
                                value={ (formForm.dest_tras_province_id === undefined || formForm.dest_tras_province_id ===null || formForm.dest_tras_province_id ===""
                                    || formForm.dest_tras_province_id ==="0" || formForm.dest_tras_province_id ===0)?Number(DEFAULTPROVINCE):formForm.dest_tras_province_id}
                                inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurSelectProvince_Dest_Tras}
                                onChange={handleChangeSelectProvince_Dest_Tras}
                                disabled={!dest_tras_handle_address}
                                helperText={errorsForm.dest_tras_province_id!=null && errorsForm.dest_tras_province_id!==""?t(errorsForm.dest_tras_province_id):""}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formForm.dest_tras_province_id === undefined || formForm.dest_tras_province_id ===null || formForm.dest_tras_province_id ===""
                                        || formForm.dest_tras_province_id ==="0" || formForm.dest_tras_province_id ===0)?Number(DEFAULTPROVINCE): formForm.dest_tras_province_id
                                }}>
                                <option key={0} value=""/>
                                {provinceToSelect_Dest_Tras.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                                ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_PostalCode')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_postalcode_id"
                                name="dest_tras_postalcode_id"
                                value={ (formForm.dest_tras_postalcode_id === undefined || formForm.dest_tras_postalcode_id ===null || formForm.dest_tras_postalcode_id ===""
                                    || formForm.dest_tras_postalcode_id ==="0" || formForm.dest_tras_postalcode_id ===0)?"":formForm.dest_tras_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?284:302,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurSelectPostalCode_Dest_Tras}
                                onChange={handleChangeSelectPostalCode_Dest_Tras}
                                disabled={!dest_tras_handle_address}
                                helperText={errorsForm.dest_tras_postalcode_id!=null && errorsForm.dest_tras_postalcode_id!==""?t(errorsForm.dest_tras_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formForm.dest_tras_postalcode_id === undefined || formForm.dest_tras_postalcode_id ===null || formForm.dest_tras_postalcode_id ===""
                                    || formForm.dest_tras_postalcode_id ==="0" || formForm.dest_tras_postalcode_id ===0)?"": formForm.dest_tras_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect_Dest_Tras.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                            </TextField>
                        </div>
                    </div>
                </>
            }    
            { (dest_tras_view_phone || dest_tras_view_email || dest_tras_view_nima) &&
                <div className="row p-2"> 
                    <div className="form-group">
                        {dest_tras_view_phone &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Phone')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="dest_tras_phone" 
                                    name="dest_tras_phone"
                                    value={formForm.dest_tras_phone}
                                    inputProps={{ style: {width: i18n.language==='es'?180:180,  fontSize: '18px', }, maxLength: 30 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.dest_tras_phone!=null && errorsForm.dest_tras_phone!==""?t(errorsForm.dest_tras_phone):t('PlaceHolder_FORM_Phone')}
                                    placeholder={t('PlaceHolder_FORM_Phone')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Dest_Tras}
                                    onChange={handleChangeDataEditForm_Dest_Tras}
                                    disabled={!dest_tras_handle_phone}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {dest_tras_view_email &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Email')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="dest_tras_email" 
                                    name="dest_tras_email"
                                    value={formForm.dest_tras_email}
                                    inputProps={{ style: {width:  (!dest_tras_view_phone || !dest_tras_view_nima)?(i18n.language==='es'?380:394):(i18n.language==='es'?181:197) ,  fontSize: '18px'}, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.dest_tras_email!=null && errorsForm.dest_tras_email!==""?t(errorsForm.dest_tras_email):t('PlaceHolder_FORM_Email')}
                                    placeholder={t('PlaceHolder_FORM_Email')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Dest_Tras}
                                    onChange={handleChangeDataEditForm_Dest_Tras}
                                    disabled={!dest_tras_handle_email}
                                />
                                {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                            </>
                        }
                        {dest_tras_view_nima &&
                            <>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NIMA')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                    id="dest_tras_nima" 
                                    name="dest_tras_nima"
                                    value={formForm.dest_tras_nima}
                                    inputProps={{ style: {width: (!dest_tras_view_phone || !dest_tras_view_email)?180:180,  fontSize: '18px'}, maxLength: 50 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={errorsForm.dest_tras_nima!=null && errorsForm.dest_tras_nima!==""?t(errorsForm.dest_tras_nima):t('PlaceHolder_FORM_NIMA')}
                                    placeholder={t('PlaceHolder_FORM_NIMA')}
                                    variant="standard"
                                    onBlur={handleBlurDataEditForm_Dest_Tras}
                                    onChange={handleChangeDataEditForm_Dest_Tras}
                                    disabled={!dest_tras_handle_nima}
                                />
                            </>
                        }
                    </div>
                </div>  
            }
            { (dest_tras_view_inscription || dest_tras_view_opt) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {dest_tras_view_inscription &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_INSCRIPTION')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_inscription" 
                                name="dest_tras_inscription"
                                value={formForm.dest_tras_inscription}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !dest_tras_view_opt?350:250,  fontSize: '18px'}, maxLength: 50 }}
                                label={errorsForm.dest_tras_inscription!=null && errorsForm.dest_tras_inscription!==""?t(errorsForm.dest_tras_inscription):t('PlaceHolder_FORM_INSCRIPTION')}
                                placeholder={t('PlaceHolder_FORM_INSCRIPTION')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Dest_Tras}
                                onChange={handleChangeDataEditForm_Dest_Tras}
                                disabled={!dest_tras_handle_inscription}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {dest_tras_view_opt &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_OPT')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_opt"
                                name="dest_tras_opt"
                                value={ (formForm.dest_tras_opt === undefined || formForm.dest_tras_opt ===null || formForm.dest_tras_opt ===""
                                    || formForm.dest_tras_opt ==="0" || formForm.dest_tras_opt ===0)?0:formForm.dest_tras_opt}
                                inputProps={{ style: {width: i18n.language==='es'?170:147,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                                onBlur={handleBlurDataEditForm_Dest_Tras}
                                onChange={handleChangeDataEditForm_Dest_Tras} 
                                helperText={errorsForm.dest_tras_opt!=null?t(errorsForm.dest_tras_opt):""}
                                disabled={!dest_tras_handle_opt}
                                select
                                SelectProps={{
                                    native: true,
                                    value: (formForm.dest_tras_opt === undefined || formForm.dest_tras_opt ===null || formForm.dest_tras_opt ===""
                                        || formForm.dest_tras_opt ==="0" || formForm.dest_tras_opt ===0)?0: formForm.dest_tras_opt
                                }}>
                                <option key={0} value=""/>
                                {optTypeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.code} - {option.namees}
                                </option>
                                ))}
                            </TextField>   
                        </>
                    }
                    </div>
                </div> 
            }

            { (dest_tras_view_cnae || dest_tras_view_licenseplate) &&
                <div className="row p-2"> 
                    <div className="form-group">
                    {dest_tras_view_cnae &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CNAE')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_cnae" 
                                name="dest_tras_cnae"
                                value={formForm.dest_tras_cnae}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !dest_tras_view_licenseplate?355:250,  fontSize: '18px'}, maxLength: 100 }}
                                label={errorsForm.dest_tras_cnae!=null && errorsForm.dest_tras_cnae!==""?t(errorsForm.dest_tras_cnae):t('PlaceHolder_FORM_CNAE')}
                                placeholder={t('PlaceHolder_FORM_CNAE')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Dest_Tras}
                                onChange={handleChangeDataEditForm_Dest_Tras}
                                disabled={!dest_tras_handle_cnae}
                            />
                            {i18n.language==="es"?<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>:<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </>
                    }
                    {dest_tras_view_licenseplate &&
                        <>
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Vehicles')} :</label>
                            &nbsp;&nbsp;&nbsp;&nbsp; 
                            <TextField
                                id="dest_tras_licenseplate" 
                                name="dest_tras_licenseplate"
                                value={formForm.dest_tras_licenseplate}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                inputProps={{ style: {width: !dest_tras_view_cnae?313:(i18n.language==='es'?313:324),  fontSize: '18px'}, maxLength: 100 }}
                                label={errorsForm.dest_tras_licenseplate!=null && errorsForm.dest_tras_licenseplate!==""?t(errorsForm.dest_tras_licenseplate):t('PlaceHolder_FORM_Vehicles')}
                                placeholder={t('PlaceHolder_FORM_Vehicles')}
                                variant="standard"
                                onBlur={handleBlurDataEditForm_Dest_Tras}
                                onChange={handleChangeDataEditForm_Dest_Tras}
                                disabled={!dest_tras_handle_licenseplate}
                            />
                             &nbsp;&nbsp;
                            <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataVehicleDestTras}>
                                <DeleteIcon style={{fontSize: 28}}/>
                            </IconButton>
                            &nbsp;&nbsp;
                            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                                <IconButton  size="small"  style={{
                                            color:
                                            (formForm.dest_tras_store_id===undefined
                                            || formForm.dest_tras_store_id===""
                                            || formForm.dest_tras_store_id==="0"
                                            || formForm.dest_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                                            disabled={formForm.dest_tras_store_id===undefined
                                                    || formForm.dest_tras_store_id===""
                                                    || formForm.dest_tras_store_id==="0"
                                                    || formForm.dest_tras_store_id===0 } onClick={searchFormVehicles}>
                                    <SearchIcon style={{fontSize: 28}}/>
                                </IconButton>
                            }

                        </>
                    }
                    </div>
                </div> 
            }
            {dest_tras_view_economicactivity &&
                <div className="row p-2"> 
                    <div className="form-group"> 
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Economicactivity')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="dest_tras_economicactivity" 
                        name="dest_tras_economicactivity"
                        value={formForm.dest_tras_economicactivity}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        inputProps={{ style: {width: i18n.language==='es'?659:685,  fontSize: '18px'}, maxLength: 100 }}
                        label={errorsForm.dest_tras_economicactivity!=null && errorsForm.dest_tras_economicactivity!==""?t(errorsForm.dest_tras_economicactivity):t('PlaceHolder_FORM_Economicactivity')}
                        placeholder={t('PlaceHolder_FORM_Economicactivity')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Dest_Tras}
                        onChange={handleChangeDataEditForm_Dest_Tras}
                        disabled={!dest_tras_handle_economicactivity}
                    />
                    </div>
                </div> 
            }
            <FormSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Dest_Tras"}
                setFormForm={setFormForm}
            />
            <FormSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Dest_Tras"}
                setFormForm={setFormForm}
                titleTable={formForm.dest_tras_tradename}
                store_id={formForm.dest_tras_store_id}
                priority={4}
            />

            <FormSearchStoreVehicles
                showModalSearchVehicles={showModalSearchVehicles}
                setShowModalSearchVehicles={setShowModalSearchVehicles}
                selectStores={"Dest_Tras"}
                setFormForm={setFormForm}
                titleTable={formForm.dest_tras_tradename}
                store_id={formForm.dest_tras_store_id}
            />

        </> 
    )
}

FormCreateOrEditDestTras.propTypes = {
    formForm: PropTypes.object.isRequired,
    setFormForm: PropTypes.func.isRequired,
    showModalFormSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormForm: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default FormCreateOrEditDestTras;

