import React, {  useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom'; 
import { ENDPOINT } from "constants/variables"; 
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';  
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";    
import MenuItem  from "@mui/material/MenuItem"; 
import SendIcon from '@mui/icons-material/Send'; 
import Textarea from '@mui/joy/Textarea';
import { TextField } from '@mui/material';
import ReportTemplateFirst from './ReportTemplateFirst';
import ReportTemplateSecond from './ReportTemplateSecond';
import PropTypes from 'prop-types';  

const initialStateFromDISendEmail = {
  id: 0,
  ownformdi_id:0,
  defaultStore_id: 0,
  useraccount_id: 0, 
  defaultformatnumber: 1,
  isFinished: true,
  isScanned: false,
  numberDI_sheet: "", 
  date_sheet: "",
 
  opt_tras_documnettype: "",
  opt_tras_documentid: "",
  opt_tras_name: "",
  opt_tras_contactname: "", 
  opt_tras_cnae: "",
  opt_tras_nima: "",
  opt_tras_country: "",
  opt_tras_province: "",
  opt_tras_locality: "",
  opt_tras_community: "",
  opt_tras_phone: "",
  opt_tras_licenseplate: "",
  opt_tras_economicactivity: "",
  opt_tras_tradename: "",
  opt_tras_street: "",
  opt_tras_postalcode: "",
  opt_tras_email: "",
  opt_tras_inscription: "",
  opt_tras_opt: "",
  
  ori_tras_documnettype: "",
  ori_tras_documentid: "",
  ori_tras_name: "",
  ori_tras_contactname: "", 
  ori_tras_cnae: "",
  ori_tras_nima: "",
  ori_tras_country: "",
  ori_tras_province: "",
  ori_tras_locality: "",
  ori_tras_community: "",
  ori_tras_phone: "",
  ori_tras_licenseplate: "",
  ori_tras_economicactivity: "",
  ori_tras_tradename: "",
  ori_tras_street: "",
  ori_tras_postalcode: "",
  ori_tras_email: "",
  ori_tras_inscription: "",
  ori_tras_opt: "",

  aut_tras_documnettype: "",
  aut_tras_documentid: "",
  aut_tras_name: "",
  aut_tras_contactname: "", 
  aut_tras_cnae: "",
  aut_tras_nima: "",
  aut_tras_country: "",
  aut_tras_province: "",
  aut_tras_locality: "",
  aut_tras_community: "",
  aut_tras_phone: "",
  aut_tras_licenseplate: "",
  aut_tras_economicactivity: "",
  aut_tras_tradename: "",
  aut_tras_street: "",
  aut_tras_postalcode: "",
  aut_tras_email: "",
  aut_tras_inscription: "",
  aut_tras_opt: "",
 
  dest_tras_documnettype: "",
  dest_tras_documentid: "",
  dest_tras_name: "",
  dest_tras_contactname: "", 
  dest_tras_cnae: "",
  dest_tras_nima: "",
  dest_tras_country: "",
  dest_tras_province: "",
  dest_tras_locality: "",
  dest_tras_community: "",
  dest_tras_phone: "",
  dest_tras_licenseplate: "",
  dest_tras_economicactivity: "",
  dest_tras_tradename: "",
  dest_tras_street: "",
  dest_tras_postalcode: "",
  dest_tras_email: "",
  dest_tras_inscription: "",
  dest_tras_opt: "",
  
  aut_dest_documnettype: "",
  aut_dest_documentid: "",
  aut_dest_name: "",
  aut_dest_contactname: "", 
  aut_dest_cnae: "",
  aut_dest_nima: "",
  aut_dest_country: "",
  aut_dest_province: "",
  aut_dest_locality: "",
  aut_dest_community: "",
  aut_dest_phone: "",
  aut_dest_licenseplate: "",
  aut_dest_economicactivity: "",
  aut_dest_tradename: "",
  aut_dest_street: "",
  aut_dest_postalcode: "",
  aut_dest_email: "",
  aut_dest_inscription: "",
  aut_dest_opt: "",
  
  rel_trans_documnettype: "",
  rel_trans_documentid: "",
  rel_trans_name: "",
  rel_trans_contactname: "", 
  rel_trans_cnae: "",
  rel_trans_nima: "",
  rel_trans_country: "",
  rel_trans_province: "",
  rel_trans_locality: "",
  rel_trans_community: "",
  rel_trans_phone: "",
  rel_trans_licenseplate: "",
  rel_trans_economicactivity: "",
  rel_trans_tradename: "",
  rel_trans_street: "",
  rel_trans_postalcode: "",
  rel_trans_email: "",
  rel_trans_inscription: "",
  rel_trans_opt: "",
 
  resp_prod_documnettype: "",
  resp_prod_documentid: "",
  resp_prod_name: "",
  resp_prod_contactname: "", 
  resp_prod_cnae: "",
  resp_prod_nima: "",
  resp_prod_country: "",
  resp_prod_province: "",
  resp_prod_locality: "",
  resp_prod_community: "",
  resp_prod_phone: "",
  resp_prod_licenseplate: "",
  resp_prod_economicactivity: "",
  resp_prod_tradename: "",
  resp_prod_street: "",
  resp_prod_postalcode: "",
  resp_prod_email: "",
  resp_prod_inscription: "",
  resp_prod_opt: "",

  codeLER: "",
  codeLER_code: "",
  codeLER_name: "",
  description: "",
  description_code: "",
  description_name: "",
  treatmenttypeorigin_code: "",
  treatmenttypeorigin_name: "",
  treatmenttypedestine_code: "",
  treatmenttypedestine_name: "",
  processtypepeorigin_code: "",
  processtypepeorigin_name: "",
  processtypedestine_code: "",
  processtypedestine_name: "",
  dangerousness_name: "",
  charac_danger: "",
  amount: "",
  deliver_date: "",
  delivery_quantity: "",
  date_accepted: "",
  accept: "",
  date_refund: "",
  reason_refund: "",
  action_refund: "",
  observations1: "",
  observations2: "" ,
  
  signature_opt_tras:"",
  signature_dest_tras:"",
  signature_refund:"" 
}; 

const initialStateEMAIL = {
  "id":"",
  "defaultStore_id":0, 
  "useraccount_id":0,
  "store_EmailTo":"",
  "subjectEmail":"",
  "contentEmail":"",
  "filetosend":[] 
}; 

const initialEmailToSelect = [{
  "id": "",
  "email": "" 
}];

const FormDISendEmail = ({id, showModalFormDISendEmail,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);   
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const footeremail = useSelector((state) => state.loginUser.defaultticketsformat.footeremail);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [dataForm, setDataForm] = useState(initialStateFromDISendEmail);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [formEMAIL, setFormEMAIL] = useState(initialStateEMAIL);
  const [errorsEMAIL, setErrorsEMAIL] = useState({});
  const [pdfBlob, setPdfBlob] = useState(null); 
  const [emailToSelect,setEmailToSelect]= useState(initialEmailToSelect);  
  const [filteredEmail, setFilteredEmail] = useState([]); // Opciones filtradas
  const [highlightedIndexEmail, setHighlightedIndexEmail] = useState(-1); // Índice de la opción resaltada 
  const [pdfUrl, setPdfUrl] = useState(""); // Estado para actualizar el PDF

  const PdfViewer = useMemo(() => {
    return pdfUrl?
      <iframe
        src={pdfUrl} 
        title="PDF Preview"
        style={{ width: '100%', height: '100%', border: 'none' }}
      /> 
    :pdfBlob ? (
      <iframe
        src={URL.createObjectURL(pdfBlob)}
        title="PDF Preview"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    ) : null;
  }, [pdfBlob,pdfUrl]);

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalFormDISendEmail) {
        setDataForm(dataForm => ({
          ...dataForm,
          linespurchasedelivery: [],
        }));
        setDataForm(initialStateFromDISendEmail);
        dispatch(setLoading(false));  
        setPdfBlob(null); 
        setFormEMAIL(initialStateEMAIL);
        setErrorsEMAIL({});
        setEmailToSelect(initialEmailToSelect);
        setFilteredEmail([]);
        setHighlightedIndexEmail(-1); 
        setPdfUrl("");
        navigate(typeOfList==="1"?"/formdi":"/formdiended");
      }
    },
    [
      setDataForm,  
      setPdfBlob,    
      showModalFormDISendEmail,
      setEmailToSelect,
      setFilteredEmail,
      setHighlightedIndexEmail,
      setPdfUrl,
      typeOfList,
      dispatch,
      navigate]
  );
  
  const handleCloseSendEmailPurchaseSignature = () => {
    setDataForm(dataForm => ({
      ...dataForm,
      linespurchasedelivery: [],
    }));
    setDataForm(initialStateFromDISendEmail);
    dispatch(setLoading(false));
    setPdfBlob(null);   
    setFormEMAIL(initialStateEMAIL);
    setErrorsEMAIL({});
    setEmailToSelect(initialEmailToSelect);
    setFilteredEmail([]);
    setPdfUrl("");
    setHighlightedIndexEmail(-1); 
    navigate(typeOfList==="1"?"/formdi":"/formdiended"); 
	};

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchEmail = (event) => {
    const searchEmail = event.target.value.toLowerCase();
    setFormEMAIL(formEMAIL => ({
      ...formEMAIL,
      store_EmailTo: searchEmail,
    })); 
    if (searchEmail.trim() === "") {
      setFilteredEmail([]);
      setHighlightedIndexEmail(-1);
      return;
    }  
    const filtered = emailToSelect.filter((store) => {
      if (!store || !store.email) return false;
      const searchNormailzedStore = searchEmail.toLowerCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedEmail = store.email.toLowerCase().replace(/[.-]/g, "");
      return normalizedEmail.includes(searchNormailzedStore);
    }); 
    setFilteredEmail(filtered);
    setHighlightedIndexEmail(0);
  };

  // Manejo de la selección de una tienda
  const handleSelectEmail = (email) => {
    if (!email) return; // Validación adicional para evitar errores
    setFormEMAIL((formEMAIL) => ({
      ...formEMAIL,
      store_EmailTo: email.email || ""
      
    }));
    setFilteredEmail([]);
    setHighlightedIndexEmail(-1);
  }; 

  const handleKeyDownEmail = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredEmail.length === 0 && formEMAIL.store_EmailTo.trim() === "") {
        setFilteredEmail(emailToSelect);
        setHighlightedIndexEmail(0);
      } else {
        setHighlightedIndexEmail((prevIndex) => {
          const nextIndex = prevIndex < filteredEmail.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexEmail((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexEmail >= 0) {
      if (highlightedIndexEmail >= 0 && highlightedIndexEmail < filteredEmail.length) {
        handleSelectEmail(filteredEmail[highlightedIndexEmail]);
      }
    } else if (event.key === "Escape") {
      const isValid = emailToSelect.some((email) => {
        if (!email || !email.workplace) return false; 
        const normalizedEmail = email.email.toLowerCase();
        const normalizedInputEmail = formEMAIL.store_EmailTo.toLowerCase();
        return normalizedEmail === normalizedInputEmail;
      }); 
      if (!isValid) {
        setFormEMAIL((formEMAIL) => ({
          ...formEMAIL,
          store_EmailTo: "",
        }));
      } 
      setFilteredEmail([]);
      setHighlightedIndexEmail(-1);
    }
  };

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  
   const handleBlur = useCallback((event) => {
       if (!event || !event.currentTarget) return;
       // Asegúrate de que no se cierra si haces clic en un elemento de la lista
       if (!event.currentTarget.contains(event.relatedTarget)) {
        setFilteredEmail([]);
        setHighlightedIndexEmail(-1);
      }
    }, []);
    

  const handleBlurDataEditEmail = (e) => {
    handleChangeDataEditEmail(e);
    setErrorsEMAIL(validateFormEmail());
  };

  const handleChangeDataEditEmail = (e) => {
    const { name, value } = e.target;
    setFormEMAIL(formEMAIL => ({
      ...formEMAIL,
      [name]: value,
    })); 
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); // ✅ Convierte el Blob a Base64
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // ✅ Remueve "data:application/pdf;base64,"
      reader.onerror = reject;
    });
  };

  const validateFormEmail = () => {
    let errorsEMAIL = {};
    //let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formEMAIL.store_EmailTo || formEMAIL.store_EmailTo==="") {
      errorsEMAIL.store_EmailTo = 'Text_FieldSendEmailToRequired'; 
    } else {
      if (regexInvalidCharacters.test(formEMAIL.store_EmailTo)) {
        errorsEMAIL.store_EmailTo = 'Text_TextNoValid';
      }
    }  

    if (regexInvalidCharacters.test(formEMAIL.subjectEmail)) {
      errorsEMAIL.subjectEmail = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formEMAIL.contentEmail)) {
      errorsEMAIL.contentEmail = 'Text_TextNoValid';
    }  
    return errorsEMAIL;
  };

  const handleSubmitSendEmail =  async ()=>{
    const errors = validateFormEmail();
    setErrorsEMAIL(errors); 
    if (
      Object.keys(errors).length === 0 &&
      formEMAIL &&
      formEMAIL.store_EmailTo &&
      formEMAIL.store_EmailTo !== "" &&
      (pdfBlob || pdfUrl) 
    ) {  
      dispatch(setLoading(true));
      if (pdfBlob){
         // ✅ Convertir Blob a Base64 sin detener la ejecución
        blobToBase64(pdfBlob)
        .then((base64File) => {
          const formData = {
            id: id,
            defaultStore_id: defaultStore.id,
            useraccount_id: useraccount_id,
            store_EmailTo: formEMAIL.store_EmailTo,
            subjectEmail: formEMAIL.subjectEmail,
            contentEmail: formEMAIL.contentEmail,
            filetosend: base64File, 
            fileName: (dataForm.numberDI_sheet!==undefined && dataForm.numberDI_sheet!==""  && dataForm.numberDI_sheet!==0)?`FORMDI_${dataForm.numberDI_sheet}.pdf`:`FORMDI_${id.toString().padStart(8, '0')}.pdf`,
            fileType: "application/pdf",
          }; 
          // ✅ Llamada asíncrona sin bloquear el flujo 
          fetchData(formData);
        })
        .catch((error) => {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }); 
      }else{
        if (pdfUrl) {
          fetch(pdfUrl)
            .then(response => response.blob()) // Convertir la URL en un Blob
            .then(blob => blobToBase64(blob)) // Convertir el Blob en Base64
            .then((base64File) => {
              const formData = {
                id: id,
                defaultStore_id: defaultStore.id,
                useraccount_id: useraccount_id,
                store_EmailTo: formEMAIL.store_EmailTo,
                subjectEmail: formEMAIL.subjectEmail,
                contentEmail: formEMAIL.contentEmail,
                filetosend: base64File,
                fileName: (dataForm.numberDI_sheet !== undefined && dataForm.numberDI_sheet !== "" && dataForm.numberDI_sheet !== 0) 
                  ? `FORMDI_${dataForm.numberDI_sheet}.pdf` 
                  : `FORMDI_${id.toString().padStart(8, '0')}.pdf`,
                fileType: "application/pdf",
              };
              // ✅ Llamada asíncrona sin bloquear el flujo
              fetchData(formData);
            })
            .catch((error) => {
              dispatch(setLoading(false));
              dispatch(warningErrorAction(t('Text_IncorrectData22')));
            });
        }
        
      }
     
      const fetchData = async (formData) => {
        try {
          const getData = await helpHttp().postemail(ENDPOINT, `api/sendemailwithattachment`, token,formData);
            if (getData.status === "OK") {
                dispatch(setLoading(false));
                dispatch(successErrorAction(t('Text_SaveData')));
              } else { 
                dispatch(setLoading(false));
                dispatch(warningErrorAction(t(getData.cause))); 
              }
            } catch (error) {
              dispatch(setLoading(false));
              dispatch(warningErrorAction(t('Text_IncorrectData')));
            } finally { 
              dispatch(setLoading(false));
              handleCloseSendEmailPurchaseSignature();
            }
        return () => { };
      };
      //fetchData();
    } else { 
      if (errorsEMAIL.store_EmailTo) {
        dispatch(warningErrorAction(t(errorsEMAIL.store_EmailTo)));
      } else if (errorsEMAIL.subjectEmail) {
        dispatch(warningErrorAction(t(errorsEMAIL.subjectEmail)));
      } else if (errorsEMAIL.contentEmail) {
        dispatch(warningErrorAction(t(errorsEMAIL.contentEmail)));
      } else if (!pdfBlob && !pdfUrl) {
        dispatch(warningErrorAction(t('Text_FieldSendEmailToRequired')));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };  
 useEffect(() => {
    let isMounted = true; 
    const fetchDataEmail = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`api/getlistemails/`,token,
          (formEMAIL.store_EmailTo!==undefined && formEMAIL.store_EmailTo!==null && formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim()!=="")?formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setEmailToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalFormDISendEmail && formEMAIL.store_EmailTo && formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim()!=="") { 
      fetchDataEmail();
    }else{
      setEmailToSelect(initialEmailToSelect);
    }
    return () => { isMounted = false };
  }, [t,token,dispatch,i18n,showModalFormDISendEmail,formEMAIL.store_EmailTo]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (pdfBlob) { 
      const formatted_id= (dataForm.numberDI_sheet!==undefined && dataForm.numberDI_sheet!==""  && dataForm.numberDI_sheet!==0)?`FORMDI_${dataForm.numberDI_sheet}.pdf`:`FORMDI_${id.toString().padStart(8, '0')}.pdf`;

      const file = new File([pdfBlob], formatted_id, { type: "application/pdf" });
      setFormEMAIL((formEMAIL) => ({
        ...formEMAIL,
        filetosend: file // Se almacena directamente como un único archivo
      }));
    }
  }, [pdfBlob,id,dataForm.numberDI_sheet]);    
  
  useEffect(() => {
    let isMounted = true;
    const fetchFileForm = async (fileName) => {
       try {
        const getData = await helpHttp().getfilesupload(ENDPOINT, `pdftos3uploader/downloadfroms3`, token,fileName);
        if (!getData) { 
          setPdfUrl("");
          dispatch(warningErrorAction(t('Text_ConnectionFailed'))); 
        } else{ 
          if (getData && getData.size>0){
            const url = URL.createObjectURL(getData);
            setPdfUrl(url); // Guardar la URL del PDF para mostrarlo en <iframe>  
          } 
        }
      } catch (error) {
        setPdfUrl("");
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    const fetchDataFormDI = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get8(ENDPOINT, `form/getpdfformdibyid`, token, id, defaulttypesdi);
        if (getData.err) {
          setDataForm(initialStateFromDISendEmail); 
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataForm(getData);
          setFormEMAIL(formEMAIL => ({
            ...formEMAIL,
            defaultStore_id:defaultStore.id,
            useraccount_id:useraccount_id,
            store_EmailTo: [...new Set(
              [getData.ori_tras_email, getData.opt_tras_email]
                .filter(email => email && email.trim() !== "")
                .map(email => email.trim().toLowerCase()) // opcional: para evitar duplicados por mayúsculas/minúsculas
            )].join(";"),
            subjectEmail:"LE REMITO EL FORMULARIO D.I. Nº: "+getData.numberDI_sheet,
            contentEmail:footeremail
          })); 
        
          if (getData.isScanned){
            setPdfBlob(null)
            let fileName = "DI_"+getData.numberDI_sheet+ ".pdf"; 
            fetchFileForm(fileName); 
          } 
          dispatch(setLoading(false));
        }  
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
    if (id!=="0"){
      if (!dataForm.id || dataForm.id==="" || dataForm.id===0){
        fetchDataFormDI(); 
        dispatch(setLoading(false));
      }
    }else{
      setDataForm(initialStateFromDISendEmail);
      setDataForm(dataForm => ({
        ...dataForm,
        linespurchasedelivery:[]
      }));
      setFormEMAIL(formEMAIL => ({
        ...formEMAIL,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        contentEmail:footeremail
      })); 
      dispatch(setLoading(false));
    }
    return () => { isMounted = false };
  }, [dispatch,id,t,token,pdfBlob,dataForm.id,defaultStore.id,useraccount_id,footeremail,defaulttypesdi]);  

  return (
    <>
      {showModalFormDISendEmail ? ( 
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '1200px'}}>
            <form onSubmit={handleCloseSendEmailPurchaseSignature}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h2NewStyle mb-2">
                              {(isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <i className="fas fa-regular fa-envelope text-primary"></i>)} 
                                &nbsp;&nbsp;{t('Text_NumberForm_EMAIL')+': '+dataForm.numberDI_sheet}
                            </h1>
                          <div>
                            {isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <>
                                  <button type="button" className="buttonFinishPurchase" onClick={handleSubmitSendEmail} >{t('Button_SEND')}&nbsp;&nbsp;&nbsp;&nbsp;<SendIcon sx={{ fontSize: 28, color: "white" }} /></button><> </>
                                  <button type="button" className="buttonBack" onClick={handleCloseSendEmailPurchaseSignature}>{t('Button_Cancel')}</button>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_EmailsTo')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="store_EmailTo"
                                    name="store_EmailTo"
                                    value={formEMAIL.store_EmailTo}
                                    onChange={handleSearchEmail}
                                    onKeyDown={handleKeyDownEmail}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsEMAIL.store_EmailTo!=null?t(errorsEMAIL.store_EmailTo):t('PlaceHolder_SENDEMAIL_EmailsTo')}
                                    placeholder={t('PlaceHolder_SENDEMAIL_EmailsTo')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?846:971,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredEmail.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredEmail.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectEmail(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexEmail ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexEmail ? "white" : "black",
                                            }}
                                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                        >
                                          {store.email}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span>   
                              </div>
                              <div className="form-group">
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_Subject')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="subjectEmail" 
                                  name="subjectEmail"
                                  value={ formEMAIL.subjectEmail!== null ? formEMAIL.subjectEmail:""}
                                  inputProps={{ style: {width:  i18n.language==="es"?1035:1031,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                  label={errorsEMAIL.subjectEmail!=null?t(errorsEMAIL.subjectEmail):t('PlaceHolder_SENDEMAIL_Subject')}
                                  placeholder={t('PlaceHolder_SENDEMAIL_Subject')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditEmail}
                                  onChange={handleChangeDataEditEmail}>
                                </TextField>
                              </div> 
                              <div className="form-group">  
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_Content')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <Textarea 
                                  id="contentEmail" 
                                  name="contentEmail"
                                  value={formEMAIL.contentEmail}
                                  label={errorsEMAIL.contentEmail!=null?t(errorsEMAIL.contentEmail):t('PlaceHolder_SENDEMAIL_Content')}
                                  placeholder={t('PlaceHolder_SENDEMAIL_Content')}
                                  sx={{width: i18n.language==="es"?1120:1120,  fontSize: '18px'} }
                                  variant="standard"
                                  onBlur={handleBlurDataEditEmail}
                                  onChange={handleChangeDataEditEmail}
                                  maxRows={8}
                                />  
                              </div> 
                            </div>   
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 mb-4">
                    <div className="rowpdf">
                      <div className="cardpdf border-info shadow">
                        <div className="cardpdf-body">
                          <div className="rowpdf no-gutters align-items-center">
                            <div className="formgrouppdf" style={{ height: '100%' }}>
                              {PdfViewer}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <LoadingNews/>
                } 
              </form>
            </Box>
            {!pdfUrl && (defaulttypesdi===1?
              <ReportTemplateFirst dataForm={dataForm}  setPdfBlob={setPdfBlob}/>:
              <ReportTemplateSecond dataForm={dataForm} setPdfBlob={setPdfBlob}/>)
            } 
            <br/>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

FormDISendEmail.propTypes = {
  id: PropTypes.string.isRequired,
  showModalFormDISendEmail: PropTypes.bool.isRequired
};

export default FormDISendEmail;