import React, {  useEffect,useCallback,useState } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next" 
import { DropzoneAreaBase } from 'material-ui-dropzone'; 
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box';  
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container'; 
import Typography from '@mui/material/Typography'; 
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import makeStyles from '@mui/styles/makeStyles'; 
import "css/generalSettings.css";  
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import PropTypes from 'prop-types'; 

const useStyles = makeStyles(() => ({
  root: {
    border: '2px dashed #3f51b5',
    backgroundColor: 'white',
    padding: '5px',
    borderRadius: '8px',
    textAlign: 'center',
    height: '100%', // Asegúrate de que el contenedor padre tenga altura
    width: '100%',
    fill: '#4e73df',
    color: '#4e73df',
    fontSize: '16px',
    display: 'flex',  // Flexbox para asegurar el ajuste
    flexDirection: 'column',
  },
  icon: {
    backgroundColor: 'white',
    fill: '#4e73df',
    color: '#4e73df',
    fontSize: '16px'
  },
  text: {
    color: '#4e73df',
    fontSize: '16px',
    padding: '0px',
  } 
}));
 
const FormatDISettings = ({ openCreateFormatDI,setOpenCreateFormatDI,rowEdit,setRowEdit,titlees,titleen}) => {
  const { t, i18n } = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const [data, setData] = useState(rowEdit);
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [filesDestTras, setFilesDestTras] = useState([]);
   
  const classes = useStyles();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openCreateFormatDI) {
        setFiles([]);
        setFilesDestTras([]);
        setData(rowEdit);
        setOpenCreateFormatDI(false);
      }
    },
    [setData, 
      setFiles, 
      setFilesDestTras,
      rowEdit, 
      openCreateFormatDI, 
      setOpenCreateFormatDI]
  );

  const closeModalFormatDI = () => { 
    setFiles([]);
    setFilesDestTras([]);
    setData(rowEdit); 
    setOpenCreateFormatDI(false);
  };
   
   
  const handleAddSignature = (newFiles) => {
    setFiles(newFiles); 
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        signature_default: newFiles[0].data
      }
    }));
  };

  const handleAddSignatureDestTras = (newFiles) => {
    setFilesDestTras(newFiles); 
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        signature_default_des_tras: newFiles[0].data
      }
    }));
  };

  const handleDeleteSignatureDestTras = () => {
    setFilesDestTras([]);
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        signature_default_des_tras: ""
      }
    }));
  };

  const handleDeleteSignature = () => {
    setFiles([]);
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        signature_default: ""
      }
    }));
  };
 
  const handlePreviewIcon = (fileObject) => { 
    return ( 
      <div className="m-0 row justify-content-center ">
        <Box sx={{ bgcolor: '#00000', width: 150, height: 150 }}>
            <img src={fileObject.data} alt={fileObject.file.name} style={{width: '100%', height:'100%'}}/>   
        </Box>
      </div>
    ); 
  };
 
  const toggleDrawerFormatDI = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      closeModalFormatDI();
      return;
    }
  };

  useEffect(() => {
     
    if (openCreateFormatDI) {
      setData(rowEdit);
      if (rowEdit.formatDIDTO!==undefined && rowEdit.formatDIDTO.signature_default!==undefined && rowEdit.formatDIDTO.signature_default!==""){
        const existArrayFileToUpload=[];
        const imageDataUrl = rowEdit.formatDIDTO.signature_default
        let result = new File([imageDataUrl.arrayBuffer], "signature")
        existArrayFileToUpload.push(      
          {
            "data":rowEdit.formatDIDTO.signature_default,
            "file":result
          }
        )
        setFiles(existArrayFileToUpload);
      }
      if (rowEdit.formatDIDTO!==undefined && rowEdit.formatDIDTO.signature_default_des_tras!==undefined && rowEdit.formatDIDTO.signature_default_des_tras!==""){
        const existFileToUpload=[];
        const imageUrl = rowEdit.formatDIDTO.signature_default_des_tras
        let result = new File([imageUrl.arrayBuffer], "signatureDestTras")
        existFileToUpload.push(      
          {
            "data":rowEdit.formatDIDTO.signature_default_des_tras,
            "file":result
          }
        )
        setFilesDestTras(existFileToUpload);
      }
      
    } 
  }, [t,i18n,dispatch,token,rowEdit, openCreateFormatDI]);

  const handleChangeEnabledDefaultOptions = (e) => {
    const { name } = e.target;
    let newValue = !data.formatDIDTO[name];
    setData(data => ({
      ...data,
      formatDIDTO: {
        ...data.formatDIDTO,
        [name]: newValue
      }
    }));
  };
 
  const handleSubmitFormatDI = () => {
     setRowEdit(rowEdit => ({
      ...rowEdit,
      formatDIDTO:{
        id : data.id,
        typesdi_id : data.typesdi_id,
      
        number_dt_view:data.number_dt_view,
        code_description_view:data.code_description_view,
        code_treatmenttype_origin_view:data.code_treatmenttype_origin_view,
        code_treatmenttype_destine_view:data.code_treatmenttype_destine_view,
        code_processtype_origin_view:data.code_processtype_origin_view,
        code_processtype_destine_view:data.code_processtype_destine_view,
        code_dangerousness_view:data.code_dangerousness_view,
        charac_danger_view:data.charac_danger_view,
        date_refund_view:data.date_refund_view,
        reason_refund_view:data.reason_refund_view,
        action_refund_view:data.action_refund_view,
        number_dt_handle:data.number_dt_handle,
        code_description_handle:data.code_description_handle,
        code_treatmenttype_origin_handle:data.code_treatmenttype_origin_handle,
        code_treatmenttype_destine_handle:data.code_treatmenttype_destine_handle,
        code_processtype_origin_handle:data.code_processtype_origin_handle,
        code_processtype_destine_handle:data.code_processtype_destine_handle,
        code_dangerousness_handle:data.code_dangerousness_handle,
        charac_danger_handle:data.charac_danger_handle,
        date_refund_handle:data.date_refund_handle,
        reason_refund_handle:data.reason_refund_handle,
        action_refund_handle:data.action_refund_handle,
      
        share_form_view:data.share_form_view,
        share_form_handle:data.share_form_handle,
        share_form_opt_tras:data.share_form_opt_tras,
        share_form_ori_tras:data.share_form_ori_tras,
        share_form_aut_tras:data.share_form_aut_tras,
        share_form_dest_tras:data.share_form_dest_tras,
        share_form_aut_dest:data.share_form_aut_dest,
        share_form_rel_trans:data.share_form_rel_trans,
        share_form_resp_prod:data.share_form_resp_prod,

        sendbyemail_form_view:data.sendbyemail_form_view,
        sendbyemail_form_handle:data.sendbyemail_form_handle,
        sendbyemail_form_opt_tras:data.sendbyemail_form_opt_tras,
        sendbyemail_form_ori_tras:data.sendbyemail_form_ori_tras,
        sendbyemail_form_aut_tras:data.sendbyemail_form_aut_tras,
        sendbyemail_form_dest_tras:data.sendbyemail_form_dest_tras,
        sendbyemail_form_aut_dest:data.sendbyemail_form_aut_dest,
        sendbyemail_form_rel_trans:data.sendbyemail_form_rel_trans,
        sendbyemail_form_resp_prod:data.sendbyemail_form_resp_prod,
        signature_default:data.signature_default,
        signature_default_des_tras:data.signature_default_des_tras
        
      },
      
    })); 
    const fetchData = async () => {
      try {
        const getData = await helpHttp().put(ENDPOINT, `typesdi/updateformatdi`, token, data);
        if (getData.status === "OK") {
          dispatch(successErrorAction(t('Text_SaveData')));
        } else {
          dispatch(warningErrorAction(t(getData.cause)));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ConnectionFailied')));
      } 
    };

    if (openCreateFormatDI) {
      fetchData();
      closeModalFormatDI();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]); 


  return (
    <>
      {openCreateFormatDI && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerFormatDI(false)}
            onOpen={toggleDrawerFormatDI(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                    {t('Text_FORMAT_DI_TITTLE')}
                  </h1>
                  <div>
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_TypesDi_Create'))?
                      <div>
                        <button type="button" className="buttonCreate" onClick={handleSubmitFormatDI}>{t('Button_Update')}</button><> </>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>:
                      <div>
                        <button type="button" className="buttonBack" onClick={closeModalFormatDI}>{t('Button_Back')}</button>
                      </div>
                    }
                  </div>
                </div>
                <div id="myDIV">  
                  <h1 className="h1NewStyle mb-2">
                    {i18n.language==="es"?titlees:titleen}
                  </h1>  
                </div>  
                <br/>
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createformatdi" onSubmit={closeModalFormatDI}> 
                  <div className="card shadow mb-4">    
                    <div className="card-body">
                      <div className="form-group">
                        <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 24, paddingLeft:2 }} id="tableTitle" component="div" variant="h6">
                          {t('Text_FORMAT_DI_GENERAL')}
                        </Typography>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <br/>
                        <div className="row  px-5">
                          <div className="col-4 ">
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORMAT_DI_GENERAL_SIGNATURE')} :</label>
                          </div>  
                          <div className="col-8 ">
                            <DropzoneAreaBase
                              initialFiles={files}
                              fileObjects={files}
                              acceptedFiles={['image/*']}
                              maxFileSize={20000000}
                              filesLimit={1}
                              onAdd={handleAddSignature}
                              onDelete={handleDeleteSignature}
                              showAlerts={false}
                              getPreviewIcon={handlePreviewIcon}
                              dropzoneText={t('Text_SIGNED_Select_Files')}
                              showPreviews={false}
                              showPreviewsInDropzone={true}
                              previewText="Selected files"
                              classes={{
                                root: classes.root,
                                icon: classes.icon,
                                text: classes.text,
                              }}
                            /> 
                          </div>  
                        </div> 
                        <br/>
                        <div className="row  px-5">
                          <div className="col-4 ">
                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORMAT_DI_GENERAL_SIGNATURE_DEST_TRAS')} :</label>
                          </div>  
                          <div className="col-8 ">
                            <DropzoneAreaBase
                              initialFiles={filesDestTras}
                              fileObjects={filesDestTras}
                              acceptedFiles={['image/*']}
                              maxFileSize={20000000}
                              filesLimit={1}
                              onAdd={handleAddSignatureDestTras}
                              onDelete={handleDeleteSignatureDestTras}
                              showAlerts={false}
                              getPreviewIcon={handlePreviewIcon}
                              dropzoneText={t('Text_SIGNED_Select_Files')}
                              showPreviews={false}
                              showPreviewsInDropzone={true}
                              previewText="Selected files"
                              classes={{
                                root: classes.root,
                                icon: classes.icon,
                                text: classes.text,
                              }}
                            /> 
                          </div>  
                        </div> 
                      </div> 

                      <div className="form-group">
                        <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 24, paddingLeft:2 }} id="tableTitle" component="div" variant="h6">
                          {t('Text_FORMAT_DI_OPTIONS_GEN')}
                        </Typography>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="container text-left px-2">
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_NUMBERDT')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                id="number_dt_view"
                                name="number_dt_view"
                                checked={data.formatDIDTO.number_dt_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_NUMBERDT')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="number_dt_handle"
                                name="number_dt_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.number_dt_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_DESCRIPTION')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_description_view"
                                name="code_description_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_description_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_DESCRIPTION')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_description_handle"
                                name="code_description_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_description_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_TREATMENTYPE_ORIGIN')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_treatmenttype_origin_view"
                                name="code_treatmenttype_origin_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_treatmenttype_origin_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_TREATMENTYPE_ORIGIN')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_treatmenttype_origin_handle"
                                name="code_treatmenttype_origin_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_treatmenttype_origin_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div> 
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_TREATMENTYPE_DESTINE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_treatmenttype_destine_view"
                                name="code_treatmenttype_destine_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_treatmenttype_destine_view} 
                                onChange={handleChangeEnabledDefaultOptions}
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }} 
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_TREATMENTYPE_DESTINE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_treatmenttype_destine_handle"
                                name="code_treatmenttype_destine_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_treatmenttype_destine_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_PROCESSTYPE_ORIGIN')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_processtype_origin_view"
                                name="code_processtype_origin_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_processtype_origin_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_PROCESSTYPE_ORIGIN')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_processtype_origin_handle"
                                name="code_processtype_origin_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_processtype_origin_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_PROCESSTYPE_DESTINE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_processtype_destine_view"
                                name="code_processtype_destine_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_processtype_destine_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_PROCESSTYPE_DESTINE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_processtype_destine_handle"
                                name="code_processtype_destine_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_processtype_destine_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CODE_DANGEROUNESS')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_dangerousness_view"
                                name="code_dangerousness_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_dangerousness_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_CODE_DANGEROUNESS')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="code_dangerousness_handle"
                                name="code_dangerousness_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.code_dangerousness_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_CHARAC_DANGER')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="charac_danger_view"
                                name="charac_danger_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.charac_danger_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7" >
                                {t('Text_FORMAT_DI_HANDLE_CHARAC_DANGER')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="charac_danger_handle"
                                name="charac_danger_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.charac_danger_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_DATA_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="date_refund_view"
                                name="date_refund_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.date_refund_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_DATA_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="date_refund_handle"
                                name="date_refund_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.date_refund_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_REASON_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="reason_refund_view"
                                name="reason_refund_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.reason_refund_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_REASON_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="reason_refund_handle"
                                name="reason_refund_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.reason_refund_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_VIEW_ACTION_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="action_refund_view"
                                name="action_refund_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.action_refund_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_HANDLE_ACTION_REFUND')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="action_refund_handle"
                                name="action_refund_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.action_refund_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                          {t('Text_FORMAT_DI_OPTIONS_SHARE')}
                        </Typography>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="container text-left px-2">
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SHARE_VIEW')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_view"
                                name="share_form_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SHARE_HANDLE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_handle"
                                name="share_form_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_OPT_TRAS')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                id="share_form_opt_tras"
                                name="share_form_opt_tras"
                                checked={data.formatDIDTO.share_form_opt_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_ORI_TRAS_PROD')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_ori_tras"
                                name="share_form_ori_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_ori_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_ORI_TRAS_EMPR')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_aut_tras"
                                name="share_form_aut_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_aut_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_DEST_TRAS_PROD')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_dest_tras"
                                name="share_form_dest_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_dest_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_DEST_TRAS_EMPR')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_aut_dest"
                                name="share_form_aut_dest"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_aut_dest} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_Carrier')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_rel_trans"
                                name="share_form_rel_trans"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_rel_trans} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SRAP')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="share_form_resp_prod"
                                name="share_form_resp_prod"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.share_form_resp_prod} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <Typography sx={{ flex: '1 1 100%', color: "#4e73df", fontSize: 24, paddingLeft:2 }} id="tableTitle" component="div" variant="h6">
                          {t('Text_FORMAT_DI_OPTIONS_SENDEMAIL')}
                        </Typography>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="container text-left px-2">
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SENDEMAIL_VIEW')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_view"
                                name="sendbyemail_form_view"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_view} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SENDEMAIL_HANDLE')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_handle"
                                name="sendbyemail_form_handle"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_handle} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_OPT_TRAS')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                id="sendbyemail_form_opt_tras"
                                name="sendbyemail_form_opt_tras"
                                checked={data.formatDIDTO.sendbyemail_form_opt_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                            </div>
                            <div className="col-1">
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_ORI_TRAS_PROD')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_ori_tras"
                                name="sendbyemail_form_ori_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_ori_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_ORI_TRAS_EMPR')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_aut_tras"
                                name="sendbyemail_form_aut_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_aut_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div> 
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_DEST_TRAS_PROD')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_dest_tras"
                                name="sendbyemail_form_dest_tras"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_dest_tras} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_DEST_TRAS_EMPR')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_aut_dest"
                                name="sendbyemail_form_aut_dest"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_aut_dest} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                          <div className="row  px-5">
                            <div className="col-4 ">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_Carrier')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_rel_trans"
                                name="sendbyemail_form_rel_trans"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_rel_trans} 
                                onChange={handleChangeEnabledDefaultOptions} 
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }}
                              />
                            </div>
                            <div className="col-4">
                              <Typography sx={{ color: "#4e73df", fontSize: 18, paddingLeft:2 }} id="tableTitle" component="div" variant="h7">
                                {t('Text_FORMAT_DI_SRAP')}
                              </Typography> 
                            </div>
                            <div className="col-1">
                              <Checkbox  
                                id="sendbyemail_form_resp_prod"
                                name="sendbyemail_form_resp_prod"
                                style={{color:"#4e73df", fontSize: 18, paddingLeft:2}} 
                                checked={data.formatDIDTO.sendbyemail_form_resp_prod} 
                                onChange={handleChangeEnabledDefaultOptions}
                                sx={{
                                  '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                }} 
                              />
                            </div>
                            <div className="col-2">
                            </div>
                          </div>
                        </div>
                      </div>   
                    </div> 
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>    
        </div>
      } 
    </>
  )
}

FormatDISettings.propTypes = {
  openCreateFormatDI: PropTypes.bool.isRequired,
  setOpenCreateFormatDI: PropTypes.func.isRequired,
  rowEdit: PropTypes.object.isRequired,
  setRowEdit: PropTypes.func.isRequired,
  titlees: PropTypes.string.isRequired,
  titleen: PropTypes.string.isRequired
};

export default FormatDISettings;