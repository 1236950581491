import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom';  
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';  
import TextField from '@mui/material/TextField';
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";       
import PropTypes from 'prop-types';  
import TableSortingNormalized from "pages/stores/storesNormalized/SortingTablesStoreNormalized";
  
const initialStateNormalized = [{
  "id":"",
  "indexrow":0, 
  "store_id":0,
  "store_tradename":"",
  "normalizedworkplace":"",
  "newnormalizedworkplace":"",
  "enablednormalizedworkplace":false,
  "error":false
}];  
 
const infoData = {
  "id":"",
  "name":"", 
};  

const StoreNormalized = ({id, showModalStoreNormalized}) => {
  const {t} = useTranslation(['listDataStores']);
  const token = useSelector((state) => state.loginUser.token);   
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const dispatch = useDispatch();
  const navigate = useNavigate();  
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [formInfoData, setFormInfoData] = useState(infoData);
  const [formNormalized, setFormNormalized] = useState(initialStateNormalized);
  const [inputTextStoreNormalized, setInputTextStoreNormalized] = useState("");  
   
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalStoreNormalized) {
        dispatch(setLoading(false));   
        setFormNormalized(initialStateNormalized);
        setFormInfoData(infoData);
        setInputTextStoreNormalized(""); 
        navigate("/Store");
      }
    },
    [ 
      showModalStoreNormalized, 
      setFormNormalized,
      setFormInfoData,
      setInputTextStoreNormalized, 
      dispatch,
      navigate]
  );
  
  const handleCloseNormalizedPurchase = () => {
    dispatch(setLoading(false)); 
    setFormNormalized(initialStateNormalized);
    setFormInfoData(infoData);
    setInputTextStoreNormalized(""); 
    navigate("/Store"); 
	}; 

  const filteredDataPurchaseNormalized = formNormalized.filter((el) => {
    if (inputTextStoreNormalized === '') { 
      return el;
    }
    else {
      let dataTranslate = 
      String(el.normalizedworkplace || '') ;
      let result = dataTranslate.toLowerCase().includes(inputTextStoreNormalized.toLowerCase());
      return result;
    } 
  }) 

  let inputHandlerPurchaseNormalized  = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextStoreNormalized(lowerCase);
};
 
  const handleSubmitNormalizedWorkPlace =  async ()=>{
    const regexInvalidCharacters = /[\\!$%&|<>?{}~"']/;
    const hasEmptyValues = formNormalized.some(row => !row.newnormalizedworkplace.trim());
    const hasInvalidCharacters = formNormalized.some(row => regexInvalidCharacters.test(row.newnormalizedworkplace));
    if (!hasEmptyValues && !hasInvalidCharacters && formNormalized && formNormalized.length > 0) {
      handleCloseNormalizedPurchase();
      const fetchData = async () => {
        let isMounted = true; 
        try {
          const getData = await helpHttp().post(ENDPOINT, `store/savestorenormalizedworkplace`, token, formNormalized) 
          if (getData.status === "OK") { 
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData();
    } else { 
      if (hasEmptyValues) {
        dispatch(warningErrorAction(t('Text_Error_New_Normalizedworkplace')));
      } else if (hasInvalidCharacters) {
        dispatch(warningErrorAction(t('Text_TextNoValid')));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };  
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;

    const fetchDataPurchaseInfoData= async () => {
      dispatch(setLoading(true));
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`store/getstorenormalizedinfodata/`,token,id);
        if (getData.err) {
          setFormInfoData(infoData); 
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormInfoData(getData); 
          dispatch(setLoading(false));
        }  
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    const fetchDataPurchaseNormalized = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`store/getstorenormalizedlist/`,token,formInfoData.id);
        if (getData.err) {
          setFormNormalized(initialStateNormalized); 
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) { 
          setFormNormalized(getData); 
          dispatch(setLoading(false));
        }  
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    if (id!=="0"){
      if (formInfoData.id===""){ 
        fetchDataPurchaseInfoData(); 
        dispatch(setLoading(false)); 
      }        
    }else{
      setFormInfoData(infoData);
      dispatch(setLoading(false));
    }
    if (formInfoData.id && formInfoData.id!=="" && formInfoData.id!=="0" && formInfoData.id!==0){
      fetchDataPurchaseNormalized();
      dispatch(setLoading(false)); 
    } else{
      setFormNormalized(initialStateNormalized);
      dispatch(setLoading(false));
    }

    return () => { isMounted = false };
  }, [dispatch,id,t,token,formInfoData.id]);  
 
  return (
    <>
      {showModalStoreNormalized ? ( 
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '1600px'}}>
            <form onSubmit={handleCloseNormalizedPurchase}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h2NewStyle mb-2">
                              {(isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>: 
                                <i className="fas fa-solid fa-map-pin text-primary"></i>)} 
                                &nbsp;&nbsp;{t('Text_NORMALIZED')}
                            </h1>
                          <div>
                            {isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <>
                                  <button type="button" className="buttonNormalized" onClick={handleSubmitNormalizedWorkPlace} >{t('Button_NORMALIZED_WORKPLACE')}</button><> </>
                                  <button type="button" className="buttonBack" onClick={handleCloseNormalizedPurchase}>{t('Button_Cancel')}</button>
                                </>
                              }
                            </div>
                          
                          </div>
                          <h1 className="h2NewStyle mb-2">
                            {(isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                              "")} 
                              &nbsp;&nbsp;&nbsp;&nbsp;{formInfoData.name}
                          </h1> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h2NewStyle mb-2 text-justify"> 
                              {t('Text_INFO')}
                            </h1> 
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextStoreNormalized}
                  inputProps={{ style: {fontSize: '18px'} }}
                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                  placeholder={t('Text_PurchaseDelivery_Search_Normalized')} 
                  onChange={inputHandlerPurchaseNormalized }
                  fullWidth
                  label={t('Text_PurchaseDelivery_Search_Normalized')}>
                </TextField>
              </div>
              <br/>
              {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <TableSortingNormalized
                                  dataNormalized={filteredDataPurchaseNormalized}
                                  formNormalized={formNormalized}
                                  setFormNormalized={setFormNormalized}
                                  LIMITPAGESIZE={LIMITPAGESIZE}
                                  >
                                </TableSortingNormalized>
                              </div> 
                            </div>   
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div> 
                </>:
                  <LoadingNews/>
                } 
              </form>
            </Box>
           
            <br/>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

StoreNormalized.propTypes = {
  id: PropTypes.string.isRequired,
  showModalStoreNormalized: PropTypes.bool.isRequired
};

export default StoreNormalized;