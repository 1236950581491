import React, {useState }from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next" 
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom'; 
import { useParams } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom'; 
import { ToastContainer } from 'react-toastify'; 
import { TodayIs } from 'components/utils/TodayIs';
import ImportScript from 'components/utils/ImportScript';
import Footer from "pages/main/Footer";
import Alerts from 'pages/alerts/Alerts';
import Messages from 'pages/messages/Messages';
 
import FormDISendEmail from "pages/forms/formDISendEmail/FormDISendEmailEdit"
 
const HomeFormDISendEmail = (typeList) => {
    const {t,i18n} = useTranslation(['listDataForms']);
    const {id} = useParams();
    const enabled = useSelector((state) => state.loginUser.enabled);
    const roles = useSelector((state) => state.loginUser.roles);  
    const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
    const [showModalGeneralSettings, setShowModalGeneralSettings] = useState(false);
    const [indexShowSettings,setIndexShowSettings]=useState(0); 
    const [showModalFormDISendEmail, setShowModalFormDISendEmail] = useState(true); 
   
    const [typeOfList] = useState(typeList);

    const scriptUrl = '/js/sb-admin-2.min.js'; 
    const navigate = useNavigate();  
 
    const handleCloseFormDISendEmail = () => {
        setIndexShowSettings(0);
        setIndexShowSettings(indexShowSettings); 
        setShowModalFormDISendEmail(false);
        setShowModalGeneralSettings(showModalGeneralSettings);
        navigate(typeOfList==="1"?"/formdi":`/formdiended`);
        return () => {};
    };
    
    function changeSelectedLanguage(){
        i18n.language==="es"?i18n.changeLanguage("en"):i18n.changeLanguage("es");        
    };
             
    return (
    <>
        <ImportScript resourceUrl={scriptUrl} />
        <ToastContainer/>
        {enabled===false ? <Navigate to="/home"></Navigate>:
        <div id="page-top">

            {/* Page Wrapper */}
            <div id="wrapper">

                {/* Sidebar */}
                <ul className="navbar-nav bg-gradient-primary toggled sidebar sidebar-dark accordion" id="accordionSidebar">

                    {/* Sidebar - Brand */}
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/home">
                        <div className="sidebar-brand-icon rotate-n-15">
                            <DashboardIcon/>
                        </div>
                        <div className="sidebar-brand-text mx-3">{t('Text_Title')}</div>
                    </Link>

                    {/* Divider */}
                    <hr className="sidebar-divider my-0"/>

                    {/* Nav Item - Dashboard */}
                    <li className="nav-item active">
                        <div className="nav-link">
                            <i className="fas fa-fw fa-tachometer-alt"></i>
                            <span>{t('Menu_Main')}</span></div>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider"/>


                    {/* Heading */}
                    {/* Nav Item - Admin Collapse Menu */}
                    {roles[0].listmenus.find((key)=>(key.name==="Menu_Settings"))&&
                        <li className="nav-item">
                            <Link className="nav-link collapsed" to={typeOfList.typeList==="1"?`/formdisendemail/${id}`:`/formdisendemailended/${id}`} data-toggle="collapse" data-target="#collapseAdmin"
                                aria-expanded="true" aria-controls="collapseAdmin">
                                <SettingsIcon/>
                                <span>{t('Menu_Settings')}</span>
                            </Link>
                            <div id="collapseAdmin" className="collapse" aria-labelledby="headingAdmin"
                                data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <h6 className="collapse-header">{t('Custom_Menu_Settings')}</h6>
                                    <Link className="collapse-item" to={typeOfList.typeList==="1"?`/formdisendemail/${id}`:`/formdisendemailended/${id}`} onClick={changeSelectedLanguage}>{i18n.language==="es"?t('SubMenu_Settings_ChangeToEnglish'):t('SubMenu_Settings_ChangeToSpanish')}</Link>
                                </div>
                            </div>
                        </li>
                    }
                    {/* Nav Item - Charts */}                  
                    <li className="nav-item">
                        <Link className="nav-link" to={`/home`} onClick={handleCloseFormDISendEmail}>
                            <LogoutIcon/>
                            <span>{t('Button_Back')}</span>
                        </Link>
                    </li>

                    {/* Divider */}
                    <hr className="sidebar-divider d-none d-md-block"/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

          
                </ul>
                {/* End of Sidebar */}

                {/* Content Wrapper */}
                <div id="content-wrapper" className="d-flex flex-column">

                {/* Main Content */}
                <div id="content">

                    {/* Topbar  */}
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                        {/* Sidebar Toggle (Topbar)  */}
                        <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                            <i className="fa fa-bars"></i>
                        </button>
                        
                        {/* Topbar Navbar */}
                        <ul className="navbar-nav ml-auto">
                            <div className="topbar-divider d-none d-sm-block"></div>
                                <div className="text-center">
                                    <br/>
                                    {t('Text_StoreSelected')}&nbsp;{ defaultStore.tradename===null ? t('Text_StoreEmpty'):defaultStore.tradename }
                                </div>      
                                
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - Alerts */}
                            <Alerts/>
                            {/* Nav Item - Messages */}
                            <Messages/>
                            <div className="topbar-divider d-none d-sm-block"></div>
                            {/* Nav Item - User Information */}
                            <li className="nav-item dropdown no-arrow mx-1">
                                <br/>
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                                    <TodayIs/>  
                                </span>
                            </li>
                        </ul>
                    </nav>
                    {/* End of Topbar */}

                    {/* Show Modals */}
                    <FormDISendEmail 
                        id={id}
                        showModalFormDISendEmail={showModalFormDISendEmail}
                        typeOfList={typeOfList.typeList}
                    />
                </div>
            {/* End of Main Content */}

                {/* Footer */}
                    <Footer/>
                {/* End of Footer */}

            </div>
            {/* End of Content Wrapper */}

          </div>
          {/* End of Page Wrapper */}

      </div>}
      {/* End of Body */}

    </>
  )
};

export default HomeFormDISendEmail;
