import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 

const ReportTemplateFirst = ({ dataForm, initialStateForm, setDataForm, setViewPDF }) => {
  const { t } = useTranslation(['listDataForms']);
 
  useEffect(() => {
    const generatePDF = () => {
      const doc = new jsPDF({
        format: 'a4',
        unit: 'pt',
      });
 
      // Title
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text('DOCUMENTO DE IDENTIFICACIÓN DE RESIDUOS SIN NOTIFICACIÓN PREVIA', 300, 40, 'center');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text('(Artículo 6.1 y Anexo III del RD 553/2020, de 2 de junio, por lo que se regula el traslado de residuos en el interior del territorio del', 300, 50, 'center');
      doc.text('Estado. BOE. nº 171 del 19/07/2020)', 300, 60, 'center');
      
       // MAIN
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 70, 515, 13, 'FD');  
      doc.rect(200, 72, 175, 9, 'FD');  
      doc.rect(484, 72, 68, 9, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Documento de Identificación nº:', 45, 80, 'left');
      doc.text('Fecha inicio traslado:', 380, 80, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.numberDI_sheet}`, 201, 80, 'left');
      doc.text(`${dataForm.date_sheet}`, 486, 80, 'left');
    
      // OPT TRAS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 88, 515, 12, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN RELATIVA AL OPERADOR DEL TRASLADO', 45, 98, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 100, 515, 54, 'FD');  
      doc.rect(100, 102, 100, 9, 'FD');
      doc.rect(100, 112, 115, 9, 'FD');
      doc.rect(100, 122, 390, 9, 'FD');
      doc.rect(100, 132, 135, 9, 'FD');
      doc.rect(100, 142, 95, 9, 'FD');
      doc.rect(289, 102, 263, 9, 'FD');
      doc.rect(289, 112, 108, 9, 'FD');
      doc.rect(289, 132, 155, 9, 'FD');
      doc.rect(289, 142, 155, 9, 'FD');
      doc.rect(516, 112, 36, 9, 'FD');
      doc.rect(516, 122, 36, 9, 'FD'); 
      doc.rect(487, 132, 65, 19, 'FD'); 
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 110, 'left');
      doc.text(`NIMA:`, 45, 120, 'left');
      doc.text(`Dirección:`, 45, 130, 'left');
      doc.text(`Municipio:`, 45, 140, 'left');
      doc.text(`Teléfono:`, 45, 150, 'left');
      doc.text(`Razón social:`, 288, 110, 'right');
      doc.text(`Nº Inscripción:`, 288, 120, 'right');
      doc.text(`Provincia:`, 288, 140, 'right');
      doc.text(`Correo electrónico:`, 288, 150, 'right');
      doc.text(`Tipo Operador Traslado:`, 515, 120, 'right');
      doc.text(`C.P.:`, 515, 130, 'right');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);
 
      // Define position and size (x, y, width, height)
      if (dataForm.signature_opt_tras!==undefined && dataForm.signature_opt_tras!==""){
        doc.addImage(`${dataForm.signature_opt_tras}`, 'PNG', 510, 135, 20, 15);
      } 
      doc.text(`Firma`, 450, 138, 'left');
      doc.text(`operador`, 450, 144, 'left');
      doc.text(`traslado:`, 450, 150, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.opt_tras_documentid}`, 102, 110, 'left');
      doc.text(`${dataForm.opt_tras_tradename}`, 290, 110,'left');
      doc.text(`${dataForm.opt_tras_nima}`, 102, 120, 'left');
      doc.text(`${dataForm.opt_tras_inscription}`, 290, 120, 'left');
      doc.text(`${dataForm.opt_tras_opt}`, 517, 120, 'left');
      doc.text(`${dataForm.opt_tras_street}`, 102, 130, 'left');
      doc.text(`${dataForm.opt_tras_postalcode}`, 517, 130, 'left');
      doc.text(`${dataForm.opt_tras_locality}`, 102, 140, 'left');
      doc.text(`${dataForm.opt_tras_province}`, 291, 140, 'left');
      doc.text(`${dataForm.opt_tras_phone}`, 102, 150, 'left');
      doc.text(`${dataForm.opt_tras_email}`, 291, 150, 'left');

      // ORI TRAS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 156, 515, 12, 'FD');  
      doc.setFillColor('#ECECEC');
      doc.rect(40, 168, 515, 12, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN RELATIVA AL ORIGEN DEL TRASLADO', 45, 166, 'left');
      doc.text('Información del centro productor o poseedor de residuos o de la instalación origen del traslado', 45, 178, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 180, 515, 54, 'FD');  
      doc.rect(100, 182, 100, 9, 'FD');
      doc.rect(289, 182, 263, 9, 'FD');
      doc.rect(100, 192, 115, 9, 'FD');
      doc.rect(516, 192, 36, 9, 'FD');
      doc.rect(289, 192, 120, 9, 'FD');
      doc.rect(100, 202, 390, 9, 'FD');
      doc.rect(516, 202, 36, 9, 'FD'); 
      doc.rect(100, 212, 135, 9, 'FD');
      doc.rect(289, 212, 263, 9, 'FD');
      doc.rect(100, 222, 95, 9, 'FD');
      doc.rect(289, 222, 263, 9, 'FD'); 
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 190, 'left');
      doc.text(`Razón social:`, 288, 190, 'right');
      doc.text(`NIMA:`, 45,200, 'left');
      doc.text(`Nº Inscripción:`, 288, 200, 'right');
      doc.text(`Tipo Centro Traslado:`, 515, 200, 'right');
      doc.text(`Dirección:`, 45, 210, 'left');
      doc.text(`C.P.:`, 515, 210, 'right');
      doc.text(`Municipio:`, 45, 220, 'left');
      doc.text(`Provincia:`, 288, 220, 'right');
      doc.text(`Teléfono:`, 45, 230, 'left');
      doc.text(`Correo electrónico:`, 288, 230, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.ori_tras_documentid}`, 102, 190, 'left');
      doc.text(`${dataForm.ori_tras_tradename}`, 290, 190,'left');
      doc.text(`${dataForm.ori_tras_nima}`, 102, 200, 'left');
      doc.text(`${dataForm.ori_tras_inscription}`, 290, 200, 'left');
      doc.text(`${dataForm.ori_tras_opt}`, 517, 200, 'left');
      doc.text(`${dataForm.ori_tras_street}`, 102, 210, 'left');
      doc.text(`${dataForm.ori_tras_postalcode}`, 517, 210, 'left');
      doc.text(`${dataForm.ori_tras_locality}`, 102, 220, 'left');
      doc.text(`${dataForm.ori_tras_province}`, 291, 220, 'left');
      doc.text(`${dataForm.ori_tras_phone}`, 102, 230, 'left');
      doc.text(`${dataForm.ori_tras_email}`, 291, 230, 'left');
     
      // AUT TRAS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#ECECEC');
      doc.rect(40, 234, 515, 24, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Información de la empresa autorizada para realizar operaciones de tratamiento de residuos, incluido el ', 45, 244, 'left');
      doc.text('almacenamiento, en caso de que el origen del traslado sea una instalación de tratamiento de residuos', 45, 256, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 258, 515, 54, 'FD');  
      doc.rect(100, 260, 100, 9, 'FD');
      doc.rect(289, 260, 263, 9, 'FD');
      doc.rect(100, 270, 115, 9, 'FD');
      doc.rect(289, 270, 263, 9, 'FD');
      doc.rect(100, 280, 390, 9, 'FD');
      doc.rect(516, 280, 36, 9, 'FD'); 
      doc.rect(100, 290, 135, 9, 'FD');
      doc.rect(289, 290, 263, 9, 'FD');
      doc.rect(100, 300, 95, 9, 'FD');
      doc.rect(289, 300, 263, 9, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 268, 'left');
      doc.text(`Razón social:`, 288, 268, 'right');
      doc.text(`NIMA:`, 45,278, 'left');
      doc.text(`Nº Inscripción:`, 288, 278, 'right');
      doc.text(`Dirección:`, 45, 288, 'left');
      doc.text(`C.P.:`, 515, 288, 'right');
      doc.text(`Municipio:`, 45, 298, 'left');
      doc.text(`Provincia:`, 288, 298, 'right');
      doc.text(`Teléfono:`, 45, 308, 'left');
      doc.text(`Correo electrónico:`, 288, 308, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.aut_tras_documentid}`, 102, 268, 'left');
      doc.text(`${dataForm.aut_tras_tradename}`, 290, 268,'left');
      doc.text(`${dataForm.aut_tras_nima}`, 102, 278, 'left');
      doc.text(`${dataForm.aut_tras_inscription}`, 290, 278, 'left'); 
      doc.text(`${dataForm.aut_tras_street}`, 102, 288, 'left');
      doc.text(`${dataForm.aut_tras_postalcode}`, 517, 288, 'left');
      doc.text(`${dataForm.aut_tras_locality}`, 102, 298, 'left');
      doc.text(`${dataForm.aut_tras_province}`, 291, 298, 'left');
      doc.text(`${dataForm.aut_tras_phone}`, 102, 308, 'left');
      doc.text(`${dataForm.aut_tras_email}`, 291, 308, 'left');
   
      // DEST TRAS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 314, 515, 12, 'FD');
      doc.setFillColor('#ECECEC');
      doc.rect(40, 326, 515, 12, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN RELATIVA AL DESTINO DEL TRASLADO', 45, 324, 'left');
      doc.text('Información de la instalación de destino', 45, 336, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 338, 515, 54, 'FD');  
      doc.rect(100, 340, 100, 9, 'FD');
      doc.rect(289, 340, 263, 9, 'FD');
      doc.rect(100, 350, 115, 9, 'FD');
      doc.rect(289, 350, 263, 9, 'FD');
      doc.rect(100, 360, 390, 9, 'FD');
      doc.rect(516, 360, 36, 9, 'FD'); 
      doc.rect(100, 370, 135, 9, 'FD');
      doc.rect(289, 370, 263, 9, 'FD');
      doc.rect(100, 380, 95, 9, 'FD');
      doc.rect(289, 380, 263, 9, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 348, 'left');
      doc.text(`Razón social:`, 288, 348, 'right');
      doc.text(`NIMA:`, 45,358, 'left');
      doc.text(`Nº Inscripción:`, 288, 358, 'right');
      doc.text(`Dirección:`, 45, 368, 'left');
      doc.text(`C.P.:`, 515, 368, 'right');
      doc.text(`Municipio:`, 45, 378, 'left');
      doc.text(`Provincia:`, 288, 378, 'right');
      doc.text(`Teléfono:`, 45, 388, 'left');
      doc.text(`Correo electrónico:`, 288, 388, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.dest_tras_documentid}`, 102, 348, 'left');
      doc.text(`${dataForm.dest_tras_tradename}`, 290, 348,'left');
      doc.text(`${dataForm.dest_tras_nima}`, 102, 358, 'left');
      doc.text(`${dataForm.dest_tras_inscription}`, 290, 358, 'left'); 
      doc.text(`${dataForm.dest_tras_street}`, 102, 368, 'left');
      doc.text(`${dataForm.dest_tras_postalcode}`, 517, 368, 'left');
      doc.text(`${dataForm.dest_tras_locality}`, 102, 378, 'left');
      doc.text(`${dataForm.dest_tras_province}`, 291, 378, 'left');
      doc.text(`${dataForm.dest_tras_phone}`, 102, 388, 'left');
      doc.text(`${dataForm.dest_tras_email}`, 291, 388, 'left');

      // AUT DEST 
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#ECECEC');
      doc.rect(40, 392, 515, 24, 'FD');  
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('Información de la empresa autorizada para realizar operaciones de tratamiento de residuos, incluido el ', 45, 402, 'left');
      doc.text('almacenamiento, en la instalación d destino', 45, 414, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 416, 515, 54, 'FD');  
      doc.rect(100, 418, 100, 9, 'FD');
      doc.rect(289, 418, 263, 9, 'FD');
      doc.rect(100, 428, 115, 9, 'FD');
      doc.rect(289, 428, 263, 9, 'FD');
      doc.rect(100, 438, 390, 9, 'FD');
      doc.rect(516, 438, 36, 9, 'FD'); 
      doc.rect(100, 448, 135, 9, 'FD');
      doc.rect(289, 448, 263, 9, 'FD');
      doc.rect(100, 458, 95, 9, 'FD');
      doc.rect(289, 458, 263, 9, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 426, 'left');
      doc.text(`Razón social:`, 288, 426, 'right');
      doc.text(`NIMA:`, 45,436, 'left');
      doc.text(`Nº Inscripción:`, 288, 436, 'right');
      doc.text(`Dirección:`, 45, 446, 'left');
      doc.text(`C.P.:`, 515, 446, 'right');
      doc.text(`Municipio:`, 45, 456, 'left');
      doc.text(`Provincia:`, 288, 456, 'right');
      doc.text(`Teléfono:`, 45, 466, 'left');
      doc.text(`Correo electrónico:`, 288, 466, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.aut_dest_documentid}`, 102, 426, 'left');
      doc.text(`${dataForm.aut_dest_tradename}`, 290, 426,'left');
      doc.text(`${dataForm.aut_dest_nima}`, 102, 436, 'left');
      doc.text(`${dataForm.aut_dest_inscription}`, 290, 436, 'left'); 
      doc.text(`${dataForm.aut_dest_street}`, 102, 446, 'left');
      doc.text(`${dataForm.aut_dest_postalcode}`, 517, 446, 'left');
      doc.text(`${dataForm.aut_dest_locality}`, 102, 456, 'left');
      doc.text(`${dataForm.aut_dest_province}`, 291, 456, 'left');
      doc.text(`${dataForm.aut_dest_phone}`, 102, 466, 'left');
      doc.text(`${dataForm.aut_dest_email}`, 291, 466, 'left');

      // CHARAC
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 472, 515, 16, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN SOBRE EL RESIDUO QUE SE TRASLADA', 45, 484, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 488, 515, 44, 'FD');  
      doc.rect(204, 490, 52, 9, 'FD');
      doc.rect(204, 500, 52, 9, 'FD');
      doc.rect(204, 510, 52, 9, 'FD');
      doc.rect(204, 520, 52, 9, 'FD');

      doc.rect(384, 490, 168, 9, 'FD');
      doc.rect(384, 500, 168, 9, 'FD');
      
      doc.rect(364, 511, 35, 18, 'FD');
      doc.rect(517, 511, 35, 18, 'FD');

      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);

      doc.text(`Código LER/LER- extendido/LER_RAE:`, 45, 498, 'left');
      doc.text(`Código proceso - residuo en origen:`, 45, 508, 'left');
      doc.text(`Operación de Tratamiento destino (R/D):`, 45, 518, 'left');
      doc.text(`Cantidad (Kg. Netos)`, 45,528, 'left');


      doc.text(`Descripción del residuo:`, 260,498, 'left');
      doc.text(`Descripción proceso en origen:`, 260, 508, 'left');
      doc.text(`Código peligrosidad (HP):`, 260, 518, 'left');
      doc.text(`Código Operación proceso`, 405, 518, 'left');
      doc.text(`en destino R/D (4 cifras):`, 405, 528, 'left');

      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text(`${dataForm.codeLER_code}`, 206, 498, 'left');
      doc.text(`${dataForm.treatmenttypeorigin_code}`, 206, 508,'left');
      doc.text(`${dataForm.processtypedestine_code}`, 206, 518, 'left');
      doc.text(`${dataForm.amount}`, 206, 528, 'left');
      
      doc.text(`${dataForm.codeLER_name}`, 386, 498, 'left');
      doc.text(`${dataForm.treatmenttypeorigin_name}`, 386, 508, 'left');
      doc.text(`${dataForm.dangerousness_name}`, 370, 523, 'left');
      doc.text(`${dataForm.treatmenttypedestine_code}`, 523, 523, 'left');

      // SRAP
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 535, 515, 16, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);
      doc.text('SISTEMA DE RESPONSABILIDAD AMPLIADA DEL PRODUCTOR QUE, EN SU CASO, DECIDE LA INSTALACIÓN', 45, 546, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 549, 515, 54, 'FD');  
      doc.rect(100, 551, 100, 9, 'FD');
      doc.rect(289, 551, 263, 9, 'FD');
      doc.rect(100, 561, 115, 9, 'FD');
      doc.rect(289, 561, 263, 9, 'FD');
      doc.rect(100, 571, 390, 9, 'FD');
      doc.rect(516, 571, 36, 9, 'FD'); 
      doc.rect(100, 581, 135, 9, 'FD');
      doc.rect(289, 581, 263, 9, 'FD');
      doc.rect(100, 591, 95, 9, 'FD');
      doc.rect(289, 591, 263, 9, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 559, 'left');
      doc.text(`Razón social:`, 288, 559, 'right');
      doc.text(`NIMA:`, 45,569, 'left');
      doc.text(`Nº Inscripción:`, 288, 569, 'right');
      doc.text(`Dirección:`, 45, 579, 'left');
      doc.text(`C.P.:`, 515, 579, 'right');
      doc.text(`Municipio:`, 45, 589, 'left');
      doc.text(`Provincia:`, 288, 589, 'right');
      doc.text(`Teléfono:`, 45, 599, 'left');
      doc.text(`Correo electrónico:`, 288, 599, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.resp_prod_documentid}`, 102, 559, 'left');
      doc.text(`${dataForm.resp_prod_tradename}`, 290, 559,'left');
      doc.text(`${dataForm.resp_prod_nima}`, 102, 569, 'left');
      doc.text(`${dataForm.resp_prod_inscription}`, 290, 569, 'left'); 
      doc.text(`${dataForm.resp_prod_street}`, 102, 579, 'left');
      doc.text(`${dataForm.resp_prod_postalcode}`, 517, 579, 'left');
      doc.text(`${dataForm.resp_prod_locality}`, 102, 589, 'left');
      doc.text(`${dataForm.resp_prod_province}`, 291, 589, 'left');
      doc.text(`${dataForm.resp_prod_phone}`, 102, 599, 'left');
      doc.text(`${dataForm.resp_prod_email}`, 291, 599, 'left');

      // TRANS
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 606, 515, 16, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN RELATIVA AL TRANSPORTISTA', 45, 618, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 622, 515, 54, 'FD');  
      doc.rect(100, 624, 100, 9, 'FD');
      doc.rect(289, 624, 263, 9, 'FD');
      doc.rect(100, 634, 115, 9, 'FD');
      doc.rect(289, 634, 122, 9, 'FD');
      doc.rect(463, 634, 89, 9, 'FD');
      doc.rect(100, 644, 390, 9, 'FD');
      doc.rect(516, 644, 36, 9, 'FD'); 
      doc.rect(100, 654, 135, 9, 'FD');
      doc.rect(289, 654, 263, 9, 'FD');
      doc.rect(100, 664, 95, 9, 'FD');
      doc.rect(289, 664, 263, 9, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text(`CIF/NIF:`, 45, 632, 'left');
      doc.text(`Razón social:`, 288, 632, 'right');
      doc.text(`NIMA:`, 45,642, 'left');
      doc.text(`Nº Inscripción:`, 288, 642, 'right');
      doc.text(`Matrícula:`, 415, 642, 'left');
      doc.text(`Dirección:`, 45, 652, 'left');
      doc.text(`C.P.:`, 515, 652, 'right');
      doc.text(`Municipio:`, 45, 662, 'left');
      doc.text(`Provincia:`, 288, 662, 'right');
      doc.text(`Teléfono:`, 45, 672, 'left');
      doc.text(`Correo electrónico:`, 288, 672, 'right');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text(`${dataForm.rel_trans_documentid}`, 102, 632, 'left');
      doc.text(`${dataForm.rel_trans_tradename}`, 290, 632,'left');
      doc.text(`${dataForm.rel_trans_nima}`, 102, 642, 'left');
      doc.text(`${dataForm.rel_trans_inscription}`, 290, 642, 'left'); 
      doc.text(`${dataForm.rel_trans_licenseplate}`, 464, 642, 'left'); 
      doc.text(`${dataForm.rel_trans_street}`, 102, 652, 'left');
      doc.text(`${dataForm.rel_trans_postalcode}`, 517, 652, 'left');
      doc.text(`${dataForm.rel_trans_locality}`, 102, 662, 'left');
      doc.text(`${dataForm.rel_trans_province}`, 291, 662, 'left');
      doc.text(`${dataForm.rel_trans_phone}`, 102, 672, 'left');
      doc.text(`${dataForm.rel_trans_email}`, 291, 672, 'left');

      // DATA ACCEPT
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);   
      doc.setFillColor('#DDDDDD');
      doc.rect(40, 679, 515, 16, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(10);
      doc.text('INFORMACIÓN SOBRE LA ACEPTACIÓN DEL RESIDUO', 45, 691, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.setDrawColor('gray');
      doc.setLineWidth(0.5);   
      doc.setFillColor('white');
      doc.rect(40, 695, 515, 91, 'FD');  
      doc.rect(152, 698, 50, 9, 'FD');
      doc.rect(326, 698, 60, 9, 'FD');

      doc.rect(430, 698, 123, 9, 'FD');

      doc.rect(152, 708, 50, 9, 'FD');
      doc.rect(326, 708, 227, 9, 'FD');
      doc.rect(152, 718, 94, 9, 'FD');
      doc.rect(352, 718, 50, 9, 'FD');
      doc.rect(152, 733, 140, 50, 'FD');
      doc.rect(412, 733, 140, 50, 'FD');
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(8);
      doc.text(`Fecha entrega:`, 45, 705, 'left');
      doc.text(`kg. netos recibidos: `, 248,705, 'left');
      doc.text(`Estado: ${t(dataForm.accept)}`, 400, 705, 'left');

      doc.text(`Fecha aceptación/rechazo:`, 45, 715, 'left');
      doc.text(`Motivo del rechazo:`, 250,715, 'left');
      doc.text(`Acción en caso de rechazo:`, 45,725, 'left');
      doc.text(`Fecha devolución/reenvío:`, 250, 725, 'left');
      doc.text(`Firma del gestor de la`, 45, 740, 'left');
      doc.text(`instalación de destino`, 45, 750, 'left');
      doc.text(`recepción del residuo`, 45, 760, 'left');
      doc.text(`Firma del gestor de la`, 300, 740, 'left');
      doc.text(`instalación de destino`, 300, 750, 'left');
      doc.text(`aceptación/rechazo residuo`, 300, 760, 'left');
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text(`${dataForm.deliver_date}`, 154, 705, 'left');
      doc.text(`${dataForm.date_accepted}`, 154, 715, 'left');
      doc.text(`${dataForm.action_refund}`, 154, 725, 'left');
      doc.text(`${dataForm.delivery_quantity}`, 328, 705, 'left');
      doc.text(`${dataForm.reason_refund}`, 328, 715, 'left');
      doc.text(`${dataForm.date_refund}`, 354, 725, 'left');
      // Define position and size (x, y, width, height)
      if (dataForm.signature_dest_tras!==undefined && dataForm.signature_dest_tras!==""){
        doc.addImage(`${dataForm.signature_dest_tras}`, 'PNG', 200, 737, 40, 40);
      } 
      // Define position and size (x, y, width, height)
      if (dataForm.signature_refund!==undefined && dataForm.signature_refund!==""){
        doc.addImage(`${dataForm.signature_refund}`, 'PNG', 470, 737, 40, 40);
      } 

    // Open PDF in a new tab
    const pdfBlob = doc.output('blob');
    const url = URL.createObjectURL(pdfBlob);
    window.open(url, '_blank');
    setViewPDF(false);
    setDataForm(initialStateForm); 

  };

  if (dataForm) {
    generatePDF();
  }
}, [dataForm, t,setViewPDF, setDataForm, initialStateForm]);
  return null; // This component does not render anything in the DOM
};

ReportTemplateFirst.propTypes = {
  dataForm: PropTypes.object.isRequired, 
  setDataForm: PropTypes.func.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ReportTemplateFirst;
