import React from "react";
import {useTranslation} from "react-i18next"
import coverImage from "components/photos/MontanaArena.webp"; // Importa tu imagen de fondo
import "./notFound.css";

const NotFound = () => {

  const {t} = useTranslation(['loginText']);

  return (
    <div className="cover-container">
       <img className="background-image" src={coverImage} alt="Background" />
        <h1>
        {t('Text_404')}
        </h1>    
    </div>
  );
};

export default NotFound;
