import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { warningErrorAction } from "actions/ErrorActions";  
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import the jsPDF autoTable plugin
import PropTypes from 'prop-types'; 
import CE from "components/photos/tickets/ce.png";

const ReportTikets = ({ dataTickets, setDataTickets, initialStateTickets, setViewPDF  }) => {
  const { t, i18n } = useTranslation(['listDataForms']);
  const headerPrinter = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinter);
  const dispatch = useDispatch();

  // Función para formatear el número
  const formatNumberWithKg = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' Kg';
  };

  const formatNumberNoWeight = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
  };

  useEffect(() => {
    const generatePDF = () => {
      // Comprobar si hay datos válidos
      if (!dataTickets || !dataTickets.linestickets || dataTickets.linestickets.length === 0) {
        return;
      } 
      // Inicializar jsPDF
      let offset=0;
      if (dataTickets.linestickets!==undefined && dataTickets.linestickets.length>0) {
        offset+=(dataTickets.linestickets.length-1)*22;
      }  
      if (dataTickets.linesticketsWithOutWeight!==undefined && dataTickets.linesticketsWithOutWeight.length>0) {
        offset+=(dataTickets.linesticketsWithOutWeight.length-1)*22; 
      }  
    
      const doc = new jsPDF({
        orientation: 'p', 
        unit: 'mm', 
        format: [80, 120+offset] 
      });

      try { 
        // Añadir logo si está disponible 
        doc.addImage(CE, 'PNG', 32, 10, 15, 10);
        // Añadir encabezado
        let yOffset = 27;
        doc.setFont('helvetica', 'normal');
        doc.setFontSize(8); 
        if (headerPrinter) {
          headerPrinter.split('\n').forEach((line) => {
            doc.text(line, 40, yOffset, { align: 'center' });
            yOffset += 4;
          });
        }
        yOffset += 8;
        // Datos del ticket
        doc.setFont('helvetica', 'bold');
        doc.setFontSize(7);
        doc.text("NºTicket", 5, yOffset, 'left');
        doc.text("EMPRESA", 5, yOffset + 4, 'left');
        doc.text("Transportista", 5, yOffset + 8, 'left');
        doc.text("Vehículo", 5, yOffset + 12, 'left');
        doc.text("GRNP", 5, yOffset + 16, 'left');
        doc.text("Obra", 5, yOffset + 20, 'left');

        doc.setFont('helvetica', 'normal');
        doc.setFontSize(7);
        doc.text(dataTickets.numberticket?.toString() || '', 28, yOffset, 'left');
        let dateStartTicket = dataTickets.start_date_ticket
          ? new Date(dataTickets.start_date_ticket).toLocaleDateString(i18n.language === "es" ? "es-ES" : "en-US")
          : '';
        doc.text(dateStartTicket, 50, yOffset, 'left');
        doc.text(dataTickets.ori_tras_tradename?.substr(0, 33) || '', 28, yOffset + 4, 'left');
        doc.text(dataTickets.rel_trans_tradename?.substr(0, 33) || '', 28, yOffset + 8, 'left');
        doc.text(dataTickets.vehiclestore_licenseplate?.substr(0, 33) || '', 28, yOffset + 12, 'left');
        doc.text(dataTickets.rel_trans_grnp?.substr(0, 33) || '', 28, yOffset + 16, 'left');
        doc.text(dataTickets.ori_tras_street?.substr(0, 33) || '', 28, yOffset + 20, 'left');
        
        yOffset += 24;

        // Configurar formato de las tablas y tickets
        dataTickets.linestickets.forEach((line, index) => {
         
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(7);
          doc.text("Código", 5, yOffset + 4, 'left');
          doc.text("Material L.E.R.", 5, yOffset + 8, 'left');
          doc.text("1a.pesada", 5, yOffset + 12, 'left');
          doc.text("2a.pesada", 5, yOffset + 16, 'left');
          doc.text("NETO", 5, yOffset + 20, 'left');
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(7);
          doc.text(line.article_code?.substr(0, 33) || '', 28, yOffset + 4, 'left');
          doc.text((i18n.language === "es" ? line.article_namees : line.article_nameen)?.substr(0, 33) || '', 28, yOffset + 8, 'left');
         
          let dateStartLineTicket = line.start_date_ticket
            ? new Date(line.start_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US", {timeStyle: "short"})
            : '';
          doc.text(dateStartLineTicket, 28, yOffset + 12, 'left'); 
          let dateEndLineTicket = line.end_date_ticket
           ? new Date(line.end_date_ticket).toLocaleTimeString(i18n.language === "es" ? "es-ES" : "en-US",{timeStyle: "short"})
            : ''; 
          doc.text(dateEndLineTicket, 28, yOffset + 16, 'left');
          let formattedFirstWeight = formatNumberWithKg(line.firstweight);
          let formattedSecondWeight = formatNumberWithKg(line.secondweight);
          let formattedNetoWeight = formatNumberWithKg(Math.abs(line.firstweight - line.secondweight));
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(7);
          doc.text(formattedFirstWeight, 70, yOffset + 12, 'right');
          doc.text(formattedSecondWeight, 70, yOffset + 16, 'right');
          doc.text(formattedNetoWeight, 70, yOffset + 20, 'right');
          yOffset += 22;
        });

        if (dataTickets.linesticketsWithOutWeight!==undefined && dataTickets.linesticketsWithOutWeight.length>0) {
          dataTickets.linesticketsWithOutWeight.forEach((line, index) => {
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(7);
            doc.text("Código", 5, yOffset + 4, 'left');
            doc.text("Descripción", 5, yOffset + 8, 'left');
            doc.text("Cantidad", 5, yOffset + 12, 'left');
            doc.setFont('helvetica', 'normal');
            doc.setFontSize(7);
            doc.text(line.article_code?.substr(0, 33) || '', 28, yOffset + 4, 'left');
            doc.text((i18n.language === "es" ? line.article_namees : line.article_nameen)?.substr(0, 33) || '', 28, yOffset + 8, 'left');
            let formattedFirstWeight = formatNumberNoWeight(line.firstweight);
            doc.text(formattedFirstWeight, 28, yOffset + 12, 'left'); 
            yOffset+=16;
          });
        }

        // Open PDF in a new tab
        const pdfBlob = doc.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, '_blank');
        setViewPDF(false);
        setDataTickets(initialStateTickets);

      } catch (error) {
         dispatch(warningErrorAction(t('Text_Form_PDF_NOT_FOUND')));   
      } 
    };

    if (dataTickets) {
      generatePDF();
    }
  }, [dataTickets, t, i18n, dispatch, setViewPDF , setDataTickets, initialStateTickets, headerPrinter]);

  return null; // Este componente no renderiza nada en el DOM
};

ReportTikets.propTypes = {
  dataTickets: PropTypes.object.isRequired,
  setDataTickets: PropTypes.func.isRequired,
  setViewPDF : PropTypes.func.isRequired
};

export default ReportTikets;
