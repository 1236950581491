import React, {useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LIMIT_PAGE_SIZE_SLIDE, ENDPOINT } from "constants/variables";
import { setFilters, resetFilters} from 'reducers/filtersPurchaseDeliverySlice';
import { resetPagination } from 'reducers/paginationPurchaseDeliverySlice';
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem  from "@mui/material/MenuItem"; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { NumericFormat } from 'react-number-format'; 
import PropTypes from 'prop-types';  
import TableSortingPurchaseDelivery from "pages/purchaseDelivery/purchaseDeliveryFilterList/SortingTablesPurchaseDelivery";
import PurchaseDeliveryPDF from "pages/purchaseDelivery/generatePDF/PurchaseDeliveryPDF";
import exportToExcel from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportToExcel"
import exportToPDF from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportToPDF"
import exportToVicasoft from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportToVicasoft"
import exportToArid from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportToArid"
import exportToNonArid from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportToNoArid" 
import exportListToPDF from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliveryExportListToPDFs"
 
const initialState = {
  idFrom: "",
  idTo: "",
  defaultStoreIdFrom: "",
  defaultStoreIdTo: "",
  useraccountIdFrom: "",
  useraccountIdTo: "",
  purchasenoteFrom: "",
  purchasenoteTo: "",
  datepurchaseFrom: null,
  datepurchaseTo: null,
  vehiclestore_licenseplateFrom: "",
  vehiclestore_licenseplateTo: "",
  ori_tras_workplaceFrom: "",
  ori_tras_workplaceTo: "",
  rel_trans_store_idFrom: "", 
  rel_trans_store_idTo: "", 
  ori_tras_store_idFrom: "", 
  ori_tras_store_idTo: "",  
  numberDIFROM: "",
  numberDITO: "",
  amountFROM:0,
  amountTO:0,
  paymentFromES: "",
  paymentToES: "",
  paymentFromEN: "",
  paymentToEN: "",
};

 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": ""
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];
 
const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const initialWorkPlaceToSelect = [{
  "id": "",
  "workplace": "" 
}];

const initialStatePurchseDelivery = [{
  "id" : "",
  "defaultStore_id": 0,
  "tradename": "",
  "useraccount_id": 0,
  "username": "",
  "defaultformatnumber": 1,
  "isPending": true,
  "isFinished" : false,
  "purchasenote":0, 
  "ownnumberpurchase":"", 
  "numberticket":0, 
  "vehiclestore_licenseplate" : "",
  "workplace": "",
  "datepurchase" : null, 
  "rel_trans_tradename" : "",
  "ori_tras_tradename" : "",
  "totalpurchase" : 0 ,
  "enabled":"",
  "formDIAssociates": "",
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": "" 

}];

const PurchaseDeliveryFilter = ({ showModalPurchasesDeliverySettings,setShowModalPurchasesDeliverySettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_CREATE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_FORMDI = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const orderpurchaseended = useSelector((state) => state.loginUser.defaultticketsformat.orderpurchaseended); 
  const orderpurchasenotended = useSelector((state) => state.loginUser.defaultticketsformat.orderpurchasenotended); 
  const headerPrinterPurchase = useSelector((state) => state.loginUser.defaultticketsformat.headerPrinterPurchase); 
  const [dataPurchaseDelivery, setDataPurchaseDelivery] = useState(initialStatePurchseDelivery);
  const [formPurchaseDelivery, setFormPurchaseDelivery] = useState(initialStatePurchseDelivery[0]);
  const filtersPurchaseDelivery = useSelector((state) => state.filtersPurchaseDelivery);
  const [filterPurchase,setFilterPurchase]=useState(filtersPurchaseDelivery);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegPurchaseDelivery,setNumTotRegPurchaseDelivery]= useState(0);
  const [errorsFiltersPurchaseDelivery, setErrorsFiltersPurchaseDelivery] = useState({});
  const [inputTextPurchaseDelivery, setInputTextPurchaseDelivery] = useState("");  
  const [openDeleteRowPurchaseDelivery,setOpenDeleteRowPurchaseDelivery]= useState(false); 
  const [openEnabledRowPurchaseDelivery,setOpenEnabledRowPurchaseDelivery]= useState(false); 
  const [openCreateBlankPurchaseDelivery,setOpenCreateBlankPurchaseDelivery]= useState(false); 
  const [openEditRowPurchaseDelivery,setOpenEditRowPurchaseDelivery]= useState(false);
  const [openEditFilterPurchaseDelivery,setOpenEditFilterPurchaseDelivery]= useState(false);
  const [rowEditPurchaseDelivery,setRowEditPurchaseDelivery]= useState(initialStatePurchseDelivery[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorPurchaseDelivery, setIsLoadingErrorPurchaseDelivery] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [idCreatePurchase,setIdCreatePurchase]=useState(DELETE_CODE_CREATE);
  const [viewPDF,setViewPDF]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetPurchaseDelivery, setActiveOffsetPurchaseDelivery]= useState(false);
  const [currencyOffsetPurchaseDelivery, setCurrencyOffsetPurchaseDelivery] = useState(0);
  const [rangeOffsetPurchaseDelivery, setRangeOffsetPurchaseDelivery] = useState([{}]);
  const [tradenameToSelect,setTradenameToSelect]= useState(initialStoresToSelect); 
  const [trasnporterToSelect,setTrasnporterToSelect]= useState(initialStoresToSelect); 
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [workplaceToSelect,setWorkplaceToSelect]= useState(initialWorkPlaceToSelect); 
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 
  const [refeshData,setRefeshData]=useState(false);
  const [filteredStoreFrom, setFilteredStoreFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreFrom, setHighlightedIndexStoreFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredStoreTo, setFilteredStoreTo] = useState([]); // Opciones filtradas
  const [highlightedIndexStoreTo, setHighlightedIndexStoreTo] = useState(-1); // Índice de la opción resaltada
  const [filteredTransFrom, setFilteredTransFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexTransFrom, setHighlightedIndexTransFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredTransTo, setFilteredTransTo] = useState([]); // Opciones filtradas
  const [highlightedIndexTransTo, setHighlightedIndexTransTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleFrom, setFilteredVehicleFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleFrom, setHighlightedIndexVehicleFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredVehicleTo, setFilteredVehicleTo] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicleTo, setHighlightedIndexVehicleTo] = useState(-1); // Índice de la opción resaltada 
  const [filteredWorkPlaceFrom, setFilteredWorkPlaceFrom] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlaceFrom, setHighlightedIndexWorkPlaceFrom] = useState(-1); // Índice de la opción resaltada 
  const [filteredWorkPlaceTo, setFilteredWorkPlaceTo] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlaceTo, setHighlightedIndexWorkPlaceTo] = useState(-1); // Índice de la opción resaltada 

  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesDeliverySettings) {
        setDataPurchaseDelivery(initialStatePurchseDelivery);
        setFormPurchaseDelivery(initialStatePurchseDelivery[0]); 
        setNumTotRegFilter(0);
        setNumTotRegPurchaseDelivery(0);
        setErrorsFiltersPurchaseDelivery({});
        setInputTextPurchaseDelivery(""); 
        setOpenDeleteRowPurchaseDelivery(false); 
        setOpenEnabledRowPurchaseDelivery(false); 
        setOpenCreateBlankPurchaseDelivery(false);
        setOpenEditRowPurchaseDelivery(false);
        setOpenEditFilterPurchaseDelivery(false);
        setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
        setIsLoadingErrorPurchaseDelivery(false);
        setIdCodeDelete(DELETE_CODE);
        setIdCreatePurchase(DELETE_CODE_CREATE);
        setViewPDF(false);
        setRefeshData(false);
        setActiveOffsetPurchaseDelivery(false);
        setCurrencyOffsetPurchaseDelivery(0);
        setRangeOffsetPurchaseDelivery([{}]); 
        setShowModalPurchasesDeliverySettings(false);
        setTrasnporterToSelect(initialStoresToSelect);
        setTradenameToSelect(initialStoresToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect); 
        setVehiclesToSelect(initialVehicleToSelect);
        setWorkplaceToSelect(initialWorkPlaceToSelect); 
        setFilteredStoreFrom([]);
        setHighlightedIndexStoreFrom(-1); 
        setFilteredStoreTo([]);  
        setHighlightedIndexStoreTo(-1);  
        setFilteredTransFrom([]);
        setHighlightedIndexTransFrom(-1);  
        setFilteredTransTo([]);
        setHighlightedIndexTransTo(-1);  
        setFilteredVehicleFrom([]);
        setHighlightedIndexVehicleFrom(-1);  
        setFilteredVehicleTo([]);
        setHighlightedIndexVehicleTo(-1);   
        setFilteredWorkPlaceFrom([]);
        setHighlightedIndexWorkPlaceFrom(-1);  
        setFilteredWorkPlaceTo([]);  
        setHighlightedIndexWorkPlaceTo(-1);
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFilterPurchase(filtersPurchaseDelivery);
        navigate("/home");
        return () => {};
      }
    },
    [ setDataPurchaseDelivery,
      setFormPurchaseDelivery, 
      setNumTotRegFilter,
      setFilterPurchase,
      filtersPurchaseDelivery,
      setNumTotRegPurchaseDelivery,
      setErrorsFiltersPurchaseDelivery,
      setInputTextPurchaseDelivery, 
      setOpenDeleteRowPurchaseDelivery, 
      setOpenEnabledRowPurchaseDelivery,
      setOpenCreateBlankPurchaseDelivery,
      setOpenEditRowPurchaseDelivery,
      setOpenEditFilterPurchaseDelivery,
      setRowEditPurchaseDelivery, 
      setIsLoadingErrorPurchaseDelivery,
      setIdCodeDelete,
      setIdCreatePurchase,
      setViewPDF,
      setRefeshData,
      setActiveOffsetPurchaseDelivery,
      setCurrencyOffsetPurchaseDelivery,
      setRangeOffsetPurchaseDelivery, 
      setTrasnporterToSelect,
      setTradenameToSelect,
      setVehiclesToSelect,
      setWorkplaceToSelect,
      setPaymenetTypeSelect, 
      setFilteredStoreFrom,
      setHighlightedIndexStoreFrom, 
      setFilteredStoreTo,
      setHighlightedIndexStoreTo, 
      setFilteredTransFrom,
      setHighlightedIndexTransFrom, 
      setFilteredTransTo,
      setHighlightedIndexTransTo,  
      setFilteredVehicleFrom,
      setHighlightedIndexVehicleFrom, 
      setFilteredVehicleTo,
      setHighlightedIndexVehicleTo,   
      setFilteredWorkPlaceFrom,
      setHighlightedIndexWorkPlaceFrom, 
      setFilteredWorkPlaceTo,
      setHighlightedIndexWorkPlaceTo,   
      setShowModalPurchasesDeliverySettings,
      showModalPurchasesDeliverySettings, 
      navigate,
      dispatch,
      DELETE_CODE,
      DELETE_CODE_CREATE]
  );

  const closeModalPurchaseDelivery = () => {    
    setDataPurchaseDelivery(initialStatePurchseDelivery);
    setFormPurchaseDelivery(initialStatePurchseDelivery[0]); 
    setNumTotRegFilter(0);
    setNumTotRegPurchaseDelivery(0);
    setErrorsFiltersPurchaseDelivery({});
    setInputTextPurchaseDelivery(""); 
    setOpenDeleteRowPurchaseDelivery(false); 
    setOpenEnabledRowPurchaseDelivery(false); 
    setOpenCreateBlankPurchaseDelivery(false);
    setOpenEditRowPurchaseDelivery(false);
    setOpenEditFilterPurchaseDelivery(false);
    setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
    setIsLoadingErrorPurchaseDelivery(false);
    setIdCodeDelete(DELETE_CODE);
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setViewPDF(false);
    setRefeshData(false);
    setActiveOffsetPurchaseDelivery(false);
    setCurrencyOffsetPurchaseDelivery(0);
    setRangeOffsetPurchaseDelivery([{}]); 
    setTrasnporterToSelect(initialStoresToSelect);
    setTradenameToSelect(initialStoresToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect);
    setVehiclesToSelect(initialVehicleToSelect);
    setWorkplaceToSelect(initialWorkPlaceToSelect); 
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1); 
    setFilteredStoreTo([]);  
    setHighlightedIndexStoreTo(-1);  
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);  
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);  
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);  
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);   
    setFilteredWorkPlaceFrom([]);
    setHighlightedIndexWorkPlaceFrom(-1);  
    setFilteredWorkPlaceTo([]);
    setHighlightedIndexWorkPlaceTo(-1);
    setShowModalPurchasesDeliverySettings(false);
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters()); 
    setFilterPurchase(filtersPurchaseDelivery);
    navigate("/home");
    return () => {};
  };

  const handleFinishSelectedPurchaseDelivery = (e) => {
    let isMounted = true;
    if (e.key !== 'Escape') {
      const fetchnumTotRegPurchaseDelivery = async () => { 
        try {
          const getData  = await helpHttp().get2(ENDPOINT,`purchasedelivery/getinformationpurchasedelivery/`,token,(typeOfList==="1" || typeOfList==="3")?false:true);
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              }  
            } 
          } else if (isMounted) {
            setNumTotRegPurchaseDelivery(getData); 
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          dispatch(setLoading(false)); 
        }
      };

      const fetchnumTotRegFilter = async () => { 
        try {
          const getData = await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,filtersPurchaseDelivery);
          if (getData.err || getData.message === "Load failed") {
            if (isMounted) {
              if (getData.message==="Load failed"){
                dispatch(warningErrorAction(t('Text_ConnectionFailied')));
              }else {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              }  
            }
          } else if (isMounted) {
            setNumTotRegFilter(getData); 
            fetchDataPurchaseDelivery(); 
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      };  
      const fetchDataPurchaseDelivery = async () => { 
        dispatch(setLoading(true));
        try {
          const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,numTotRegFilter<=LIMIT_PAGE_SIZE_SLIDE?numTotRegFilter:LIMIT_PAGE_SIZE_SLIDE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
          if (getData.err) {
            setDataPurchaseDelivery(initialStatePurchseDelivery);
             dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else {
            setDataPurchaseDelivery(getData); 
            dispatch(setLoading(false));
            if (numTotRegFilter>LIMIT_PAGE_SIZE_SLIDE){
              fetchDataSecondPurchaseDelivery();
            } 
          } 
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_IncorrectData')));  
        }
      }; 
      const fetchDataSecondPurchaseDelivery = async () => {
        try {
          const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
          if (getData.err) { 
            setDataPurchaseDelivery(initialStatePurchseDelivery);
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else {
            setDataPurchaseDelivery(getData);  
          } 
        } catch (error) { 
          dispatch(warningErrorAction(t('Text_IncorrectData')));
        }
      }; 
      const fetchData = async () => {
        dispatch(setLoading(true));
        try {
          // Filtrar los datos según las condiciones
          const dataToSend = filteredDataPurchaseDelivery.filter(item =>
            item.isPending === true ||  item.isFinished === true
          );
          const getData = (typeOfList==="1" || typeOfList==="3")
          ? await helpHttp().put(ENDPOINT, `purchasedelivery/finishpurchasedelivery`, token, dataToSend)
          :await helpHttp().put(ENDPOINT, `purchasedelivery/returnpurchasedelivery`, token, dataToSend);
          if (getData.status === "OK") {
            if (isMounted) {
              dispatch(successErrorAction((typeOfList==="1" || typeOfList==="3")?t('Text_Finished'):t('Text_Pending'))); 
              handleCloseDataEditPurchaseDelivery();
              fetchnumTotRegPurchaseDelivery();
              fetchnumTotRegFilter();
              fetchDataPurchaseDelivery();
              dispatch(setLoading(false)); 
            }
          } else {
            if (isMounted) {
              dispatch(warningErrorAction(t(getData.cause)));
              dispatch(setLoading(false)); 
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));
          dispatch(setLoading(false));
        }
      }   
      fetchData();
    } 
    dispatch(setLoading(false));
    handleCloseDataEditPurchaseDelivery();
  };
  
  const openCreateOrEditDataPurchaseDelivery = () => {
    setInputTextPurchaseDelivery(""); 
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
    navigate(typeOfList==="1"?"/purchasedeliverydetail/0":typeOfList==="2"?"/purchasedeliverydetailended/0":`/purchasedeliverydetailbegin/0`);
  };
 
  const openEditFiltersPurchaseDelivery = () => {
      setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };

  const handleSubmitFilterEditPurchaseDelivery = () => {
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
    if (Object.keys(errorsFiltersPurchaseDelivery).length === 0) { 
      setErrorsFiltersPurchaseDelivery({}); 
      dispatch(resetPagination());
      dispatch(setFilters(filterPurchase)); 
      setOpenEditFilterPurchaseDelivery(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersPurchaseDelivery = () => {
    setErrorsFiltersPurchaseDelivery({});
    dispatch(resetPagination());
    dispatch(resetFilters()); 
    setFilterPurchase(initialState);
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };

  const handleCancelFiltersPurchaseDelivery = () => {
    setErrorsFiltersPurchaseDelivery({});
    setOpenEditFilterPurchaseDelivery(!openEditFilterPurchaseDelivery);
  };
 
  const handleChangeSelectDatePurchaseDelivery = (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      datepurchaseFrom: newDate
    }));
  };

  const handleBlurSelectDatePurchaseDeliveryFrom = (e) => {
    handleChangeSelectDatePurchaseDelivery(e);
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };

  const handleBlurAmountFrom = (value) => {
    handleChangeAmountFrom(value);
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };

  const handleChangeAmountFrom = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" Tn","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      amountFROM: newValue
    }));
  };

  const handleBlurAmountTo = (value) => {
    handleChangeAmountTo(value);
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };

  const handleChangeAmountTo = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" Tn","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      amountTO: newValue
    }));
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 100000000) return true;
    return false;
  };

  const handleChangeSelectDatePurchaseDeliveryTo = (event) => {
    const newDate = event ? new Date(event) : null;
    if (newDate && isNaN(newDate.getTime())) { 
      return; // Salir si la fecha es inválida
    }
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      datepurchaseTo: newDate
    }));
  };

  const handleBlurSelectDatePurchaseDeliveryTo = (e) => {
    handleChangeSelectDatePurchaseDeliveryTo(e);
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
  };

  const handleChangeFilterEditPurchaseDelivery = (e) => {
    const { name, value } = e.target;
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      [name]: value
    })); 
  }; 

  const handleBlurFilterEditPurchaseDelivery = (e) => {
    setErrorsFiltersPurchaseDelivery(validateFormFilterPurchaseDelivery());
    handleChangeFilterEditPurchaseDelivery(e);
  };

  const toggleDrawerFilterEditPurchaseDelivery = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditPurchaseDelivery();
    }
  };

  const handleExport = () => { 
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getregsiterbook`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToExcel(getData, "RegistrosAlbraranes"); 
        }
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataRegisterBook();
    } 
  };

  const handleExportVicasoft = () => {
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getregsiterbook`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToVicasoft(getData, "RegistrosVicasoft"); 
        }
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataRegisterBook();
    } 
  };

  const handleExportPDF = () => {
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getregsiterbook`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToPDF(getData, "RegistroAlbraranes", roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_View_TOTAL'))?true:false); 
        }
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataRegisterBook();
    } 
  };

  const handleExportSelectedPDF = () => {
    const fetchDataSelectedPDF= async () => {
      dispatch(setLoading(true));
      try {
        // Filtrar los datos según las condiciones
        const dataToSend = filteredDataPurchaseDelivery.filter(item =>
            item.isPending === true ||  item.isFinished === true
        );
        const getData = await helpHttp().post2(ENDPOINT, `purchasedelivery/finishpurchasedeliverytopdfs`, token, (typeOfList==="1" || typeOfList==="3")?false:true,dataToSend);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else {
          // Asegúrate de que la respuesta tiene la estructura esperada
          const groupedData = getData;
          if (groupedData) {
            // Iterar sobre los grupos
            for (const [fileName, groupData] of Object.entries(groupedData)) {
              // Generar y descargar el PDF para cada grupo
              const pdfBlob = exportListToPDF(groupData, headerPrinterPurchase);
              const url = URL.createObjectURL(pdfBlob);
              const link = document.createElement("a");
              link.href = url;
              link.download = fileName.replace(/[.\-/\\|]/g, ""); // Usa el nombre formateado como nombre del archivo
              link.click();
              URL.revokeObjectURL(url);
              // Retraso entre descargas
              await new Promise((resolve) => setTimeout(resolve, 100));
            }
          }  
        }
        setRefeshData(true);
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataSelectedPDF();
    } 
  };

  const handleExportArid = () => {
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try { 
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getregsiterbookarid`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToArid(getData, "RegistrosAridos"); 
        }
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataRegisterBook();
    } 
  };

  const handleExportNONArid = () => {
    const fetchDataRegisterBook = async () => {
      dispatch(setLoading(true));
      try { 
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getregsiterbooknonarid`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (getData && getData.length>0) {
          exportToNonArid(getData, "RegistrosResiduos"); 
        }
        dispatch(setLoading(false)); 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (dataPurchaseDelivery && dataPurchaseDelivery.length>0){ 
      fetchDataRegisterBook();
    } 
  }; 
  
  const validateFormFilterPurchaseDelivery = () => {

    let errorsFiltersPurchaseDelivery = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filterPurchase.idFrom!=="" & !regexNumber.test(filterPurchase.idFrom)) {
      errorsFiltersPurchaseDelivery.idFrom = 'Text_TextNoNumber';
    }
    if (filterPurchase.idTo!=="" & !regexNumber.test(filterPurchase.idTo)) {
      errorsFiltersPurchaseDelivery.idTo = 'Text_TextNoNumber';
    }
    if (filterPurchase.idFrom!=="" & !errorsFiltersPurchaseDelivery.idFrom!==undefined){
      if (filterPurchase.idTo!=="" & !errorsFiltersPurchaseDelivery.idTo!==undefined){
        if (parseInt(filterPurchase.idTo)<parseInt(filterPurchase.idFrom)){
          errorsFiltersPurchaseDelivery.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filterPurchase.numberDIFROM!=="" & !regexNumber.test(filterPurchase.numberDIFROM)) {
      errorsFiltersPurchaseDelivery.numberDIFROM = 'Text_TextNoNumber';
    }
    if (filterPurchase.numberDITO!=="" & !regexNumber.test(filterPurchase.numberDITO)) {
      errorsFiltersPurchaseDelivery.numberDITO = 'Text_TextNoNumber';
    }
    if (filterPurchase.numberDIFROM!=="" & !errorsFiltersPurchaseDelivery.numberDIFROM!==undefined){
      if (filterPurchase.numberDITO!=="" & !errorsFiltersPurchaseDelivery.numberDITO!==undefined){
        if (parseInt(filterPurchase.numberDITO)<parseInt(filterPurchase.numberDIFROM)){
          errorsFiltersPurchaseDelivery.numberDITO = 'Text_TextGreater_FormDI';
        }
      }
    }

    if (filterPurchase.amountTO!==undefined && filterPurchase.amountTO!=="" && filterPurchase.amountTO>0){
      if (parseFloat(filterPurchase.amountTO)<parseFloat(filterPurchase.amountFROM)){
        errorsFiltersPurchaseDelivery.amountTO = 'Text_TextGreater_AMOUNT';
      } 
    }
    
    
    if (regexInvalidCharacters.test(filterPurchase.datepurchaseFrom)) {
      errorsFiltersPurchaseDelivery.datepurchaseFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.datepurchaseTo)) {
      errorsFiltersPurchaseDelivery.datepurchaseTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filterPurchase.vehiclestore_licenseplateFrom)) {
      errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.vehiclestore_licenseplateTo)) {
      errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(filterPurchase.ori_tras_workplaceFrom)) {
      errorsFiltersPurchaseDelivery.ori_tras_workplaceFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filterPurchase.ori_tras_workplaceTo)) {
      errorsFiltersPurchaseDelivery.ori_tras_workplaceTo = 'Text_TextNoValid';
    }
    
    if (filterPurchase.vehiclestore_licenseplateTo!=="" & filterPurchase.vehiclestore_licenseplateTo<filterPurchase.vehiclestore_licenseplateFrom){
      errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo = 'Text_TextGreater_vehicles';
    }

    if (filterPurchase.ori_tras_workplaceTo!=="" & filterPurchase.ori_tras_workplaceTo<filterPurchase.ori_tras_workplaceFrom){
      errorsFiltersPurchaseDelivery.ori_tras_workplaceTo = 'Text_TextGreater_workplace';
    } 
    return errorsFiltersPurchaseDelivery;
  }  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchStoreFrom = (event) => {
    const searchStoreFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idFrom: searchStoreFrom,
    }));
    if (searchStoreFrom.trim() === "") {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchStoreFrom) ||
        normalizedDocumentId.includes(searchStoreFrom)
      );
    });
    setFilteredStoreFrom(filtered);
    setHighlightedIndexStoreFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectStoreFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idFrom: store.tradename || "",
      ori_tras_store_idTo: store.tradename || filterPurchase.ori_tras_store_idTo,
      ori_tras_workplaceFrom: "",
      ori_tras_workplaceTo: "",
    }));
    setFilteredStoreFrom([]);
    setHighlightedIndexStoreFrom(-1);
  }; 
  const handleKeyDownStoreFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredStoreFrom.length === 0 && filterPurchase.ori_tras_store_idFrom.trim() === "") {
        setFilteredStoreFrom(tradenameToSelect);
        setHighlightedIndexStoreFrom(0);
      } else {
        setHighlightedIndexStoreFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredStoreFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexStoreFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexStoreFrom >= 0) {
      if (highlightedIndexStoreFrom >= 0 && highlightedIndexStoreFrom < filteredStoreFrom.length) {
        handleSelectStoreFrom(filteredStoreFrom[highlightedIndexStoreFrom]);
      }
    } else if (event.key === "Escape" ) {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.ori_tras_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_store_idFrom: ""
        }));
      }
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1);
    }
  };
 // Manejo del texto de búsqueda y filtrado dinámico
   const handleSearchStoreTo = (event) => {
     const searchStoreTo = event.target.value.toUpperCase();
     setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idTo: searchStoreTo,
      ori_tras_workplaceFrom:"",
      ori_tras_workplaceTo:"" 
    }));
    if (searchStoreTo.trim() === "") {
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
      return;
    } 
     const filtered = tradenameToSelect
     .filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom))
     .filter((store) => {
       if (!store || !store.tradename) return false;
       // Comparar después de eliminar puntos y guiones de ambos
       const normalizedTradeName = store.tradename.toUpperCase();
       const normalizedDocumentId = store.documentid.toUpperCase();
       return (
         normalizedTradeName.includes(searchStoreTo) ||
         normalizedDocumentId.includes(searchStoreTo)
       );
     });
     setFilteredStoreTo(filtered);
     setHighlightedIndexStoreTo(0);
   };
   // Manejo de la selección de una tienda
   const handleSelectStoreTo = (store) => {
     if (!store) return; // Validación adicional para evitar errores
     setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_store_idTo:  store.tradename || ""
    }));
    setFilteredStoreTo([]);
    setHighlightedIndexStoreTo(-1);
   }; 
   const handleKeyDownStoreTo = (event) => {
     if (event.key === "ArrowDown") {
       if (filteredStoreTo.length === 0 && filterPurchase.ori_tras_store_idTo.trim() === "") {
         const filtered = tradenameToSelect.filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom));
         setFilteredStoreTo(filtered);
         setHighlightedIndexStoreTo(0);
       } else {
         setHighlightedIndexStoreTo((prevIndex) => {
           const nextIndex = prevIndex < filteredStoreTo.length - 1 ? prevIndex + 1 : prevIndex;
           scrollToElement(nextIndex);
           return nextIndex;
         });
       }
     } else if (event.key === "ArrowUp") {
       setHighlightedIndexStoreTo((prevIndex) => {
         const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
         scrollToElement(prevIndexValid);
         return prevIndexValid;
       });
     } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexStoreTo >= 0) {
       if (highlightedIndexStoreTo >= 0 && highlightedIndexStoreTo < filteredStoreTo.length) {
         handleSelectStoreTo(filteredStoreTo[highlightedIndexStoreTo]);
       }
     } else if (event.key === "Escape") {
       const isValid = tradenameToSelect
       .filter(item=>(item.tradename>=filterPurchase.ori_tras_store_idFrom))
       .some((store) => {
        if (!store || !store.tradename) return false; 
          const normalizedTradeName = store.tradename.toUpperCase();
          const normalizedInputTradeName = filterPurchase.ori_tras_store_idTo.toUpperCase();
          return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_store_idTo:  ""
        }));
      }
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1);
     }
   };    
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTransFrom = (event) => {
    const searchTransFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idFrom:  searchTransFrom
    })); 
    if (searchTransFrom.trim() === "") {
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
      return;
    } 
    const filtered = trasnporterToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransFrom) ||
        normalizedDocumentId.includes(searchTransFrom)
      );
    });
    setFilteredTransFrom(filtered);
    setHighlightedIndexTransFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idFrom: store.tradename || "",
      rel_trans_store_idTo: store.tradename || filterPurchase.rel_trans_store_idTo,
    })); 
    setFilteredTransFrom([]);
    setHighlightedIndexTransFrom(-1);
  }; 
  const handleKeyDownTransFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransFrom.length === 0 && filterPurchase.rel_trans_store_idFrom.trim() === "") {
        setFilteredTransFrom(trasnporterToSelect);
        setHighlightedIndexTransFrom(0);
      } else {
        setHighlightedIndexTransFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredTransFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTransFrom >= 0) {
      if (highlightedIndexTransFrom >= 0 && highlightedIndexTransFrom < filteredTransFrom.length) {
        handleSelectTransFrom(filteredTransFrom[highlightedIndexTransFrom]);
      }
    } else if (event.key === "Escape") {
      const isValid = trasnporterToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.rel_trans_store_idFrom.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          rel_trans_store_idFrom: "" 
        })); 
      }
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1);
    }
  }; 
  const handleSearchTransTo = (event) => {
    const searchTransTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idTo:searchTransTo,
    })); 
    if (searchTransTo.trim() === "") {
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
      return;
    } 
    const filtered = trasnporterToSelect
    .filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom))
    .filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      const normalizedDocumentId = store.documentid.toUpperCase();
      return (
        normalizedTradeName.includes(searchTransTo) ||
        normalizedDocumentId.includes(searchTransTo)
      );
    });
    setFilteredTransTo(filtered);
    setHighlightedIndexTransTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTransTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      rel_trans_store_idTo:store.tradename || ""
    })); 
    setFilteredTransTo([]);
    setHighlightedIndexTransTo(-1);
  }; 
  const handleKeyDownTransTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTransTo.length === 0 && filterPurchase.rel_trans_store_idTo.trim() === "") {
        const filtered = trasnporterToSelect.filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom));
        setFilteredTransTo(filtered);
        setHighlightedIndexTransTo(0);
      } else {
        setHighlightedIndexTransTo((prevIndex) => {
          const nextIndex = prevIndex < filteredTransTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTransTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTransTo >= 0) {
      if (highlightedIndexTransTo >= 0 && highlightedIndexTransTo < filteredTransTo.length) {
        handleSelectTransTo(filteredTransTo[highlightedIndexTransTo]);
      }
    } else if (event.key === "Escape") {
      const isValid = trasnporterToSelect
      .filter(item=>(item.tradename>=filterPurchase.rel_trans_store_idFrom))
      .some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = filterPurchase.rel_trans_store_idTo.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          rel_trans_store_idTo: ""
        }));
      }
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1);
    }
  };  
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicleFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateFrom: searchVehicleFrom,
    }));
    if (searchVehicleFrom.trim() === "") {
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      return;
    } 
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleFrom.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return ( normalizedVehicle.includes(searchNormailzedVehicled) );
    });
    setFilteredVehicleFrom(filtered);
    setHighlightedIndexVehicleFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateFrom: vehicle.vehiclestore_licenseplate || "",
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || filterPurchase.vehiclestore_licenseplateTo,
    }));
    setFilteredVehicleFrom([]);
    setHighlightedIndexVehicleFrom(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleFrom.length === 0 && filterPurchase.vehiclestore_licenseplateFrom.trim() === "") {
        setFilteredVehicleFrom(vehiclesToSelect);
        setHighlightedIndexVehicleFrom(0);
      } else {
        setHighlightedIndexVehicleFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexVehicleFrom >= 0) {
      if (highlightedIndexVehicleFrom >= 0 && highlightedIndexVehicleFrom < filteredVehicleFrom.length) {
        handleSelectVehicleFrom(filteredVehicleFrom[highlightedIndexVehicleFrom]);
      }
    } else if (event.key === "Escape") {
      const isValid = vehiclesToSelect.some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterPurchase.vehiclestore_licenseplateFrom.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          vehiclestore_licenseplateFrom: ""
        }));
      }
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
    }
  };
  const handleSearchVehicleTo = (event) => {
    const searchVehicleTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateTo:searchVehicleTo,
    }));
    if (searchVehicleTo.trim() === "") {
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      return;
    } 
    const filtered = vehiclesToSelect
    .filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom))
    .filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const searchNormailzedVehicled = searchVehicleTo.toUpperCase().replace(/[.-]/g, "");
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, ""); 
      return (normalizedVehicle.includes(searchNormailzedVehicled));
    });
    setFilteredVehicleTo(filtered);
    setHighlightedIndexVehicleTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleTo = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      vehiclestore_licenseplateTo: vehicle.vehiclestore_licenseplate || ""
    })); 
    setFilteredVehicleTo([]);
    setHighlightedIndexVehicleTo(-1);
  }; 
  const handleKeyDownVehicleTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicleTo.length === 0 && filterPurchase.vehiclestore_licenseplateTo.trim() === "") {
        const filtered = vehiclesToSelect.filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom));
        setFilteredVehicleTo(filtered);
        setHighlightedIndexVehicleTo(0);
      } else {
        setHighlightedIndexVehicleTo((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicleTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicleTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexVehicleTo >= 0) {
      if (highlightedIndexVehicleTo >= 0 && highlightedIndexVehicleTo < filteredVehicleTo.length) {
        handleSelectVehicleTo(filteredVehicleTo[highlightedIndexVehicleTo]);
      }
    } else if (event.key === "Escape" ) {
      const isValid = vehiclesToSelect
      .filter(item=>(item.vehiclestore_licenseplate>=filterPurchase.vehiclestore_licenseplateFrom))
      .some((vehicle) => {
        if (!vehicle || !vehicle.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = filterPurchase.vehiclestore_licenseplateTo.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
           vehiclestore_licenseplateTo: ""
        }));   
      }
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchWorkPlaceFrom = (event) => {
    const searchWorkPlaceFrom = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceFrom:  searchWorkPlaceFrom
    })); 
    if (searchWorkPlaceFrom.trim() === "") {
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
      return;
    } 
    const filtered = workplaceToSelect.filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase(); 
      return (normalizedWorkPlace.includes(searchWorkPlaceFrom));
    });
    setFilteredWorkPlaceFrom(filtered);
    setHighlightedIndexWorkPlaceFrom(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlaceFrom = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceFrom: store.workplace || "",
      ori_tras_workplaceTo: store.workplace || filterPurchase.ori_tras_workplaceTo,
    })); 
    setFilteredWorkPlaceFrom([]);
    setHighlightedIndexWorkPlaceFrom(-1);
  }; 
  const handleKeyDownWorkPlaceFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlaceFrom.length === 0 && filterPurchase.ori_tras_workplaceFrom.trim() === "") {
        setFilteredWorkPlaceFrom(workplaceToSelect);
        setHighlightedIndexWorkPlaceFrom(0);
      } else {
        setHighlightedIndexWorkPlaceFrom((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlaceFrom.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlaceFrom((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexWorkPlaceFrom >= 0) {
      if (highlightedIndexWorkPlaceFrom >= 0 && highlightedIndexWorkPlaceFrom < filteredWorkPlaceFrom.length) {
        handleSelectWorkPlaceFrom(filteredWorkPlaceFrom[highlightedIndexWorkPlaceFrom]);
      }
    } else if (event.key === "Escape") {
      const isValid = workplaceToSelect.some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase();
        const normalizedInputWorkPlace = filterPurchase.ori_tras_workplaceFrom.toUpperCase();
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
          ori_tras_workplaceFrom: "" 
        })); 
      }
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
    }
  }; 
  const handleSearchWorkPlaceTo = (event) => {
    const searchWorkPlaceTo = event.target.value.toUpperCase();
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceTo:searchWorkPlaceTo,
    })); 
    if (searchWorkPlaceTo.trim() === "") {
      setFilteredWorkPlaceTo([]);
      setHighlightedIndexWorkPlaceTo(-1);
      return;
    } 
    const filtered = workplaceToSelect
    .filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom))
    .filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase(); 
      return ( normalizedWorkPlace.includes(searchWorkPlaceTo));
    });
    setFilteredWorkPlaceTo(filtered);
    setHighlightedIndexWorkPlaceTo(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlaceTo = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFilterPurchase(filterPurchase => ({
      ...filterPurchase,
      ori_tras_workplaceTo: store.workplace || ""
    })); 
    setFilteredWorkPlaceTo([]);
    setHighlightedIndexWorkPlaceTo(-1);
  }; 
  const handleKeyDownWorkPlaceTo = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlaceTo.length === 0 && filterPurchase.ori_tras_workplaceTo.trim() === "") {
        const filtered = workplaceToSelect.filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom));
        setFilteredWorkPlaceTo(filtered);
        setHighlightedIndexWorkPlaceTo(0);
      } else {
        setHighlightedIndexWorkPlaceTo((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlaceTo.length - 1 ? prevIndex + 1 : prevIndex;
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlaceTo((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexWorkPlaceTo >= 0) {
      if (highlightedIndexWorkPlaceTo >= 0 && highlightedIndexWorkPlaceTo < filteredWorkPlaceTo.length) {
        handleSelectWorkPlaceTo(filteredWorkPlaceTo[highlightedIndexWorkPlaceTo]);
      }
    } else if (event.key === "Escape" ) {
      const isValid = workplaceToSelect
      .filter(item=>(item.workplace>=filterPurchase.ori_tras_workplaceFrom))
      .some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase();
        const normalizedInputWorkPlace = filterPurchase.ori_tras_workplaceTo.toUpperCase();
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setFilterPurchase(filterPurchase => ({
          ...filterPurchase,
           ori_tras_workplaceTo: ""
        })); 
      }
      setFilteredWorkPlaceTo([]);
      setHighlightedIndexWorkPlaceTo(-1);
    }
  }; 
    
  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const handleBlur = useCallback((event) => {
    if (!event || !event.currentTarget) return;
    // Asegúrate de que no se cierra si haces clic en un elemento de la lista
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredStoreFrom([]);
      setHighlightedIndexStoreFrom(-1); 
      setFilteredStoreTo([]);
      setHighlightedIndexStoreTo(-1); 
      setFilteredTransFrom([]);
      setHighlightedIndexTransFrom(-1); 
      setFilteredTransTo([]);
      setHighlightedIndexTransTo(-1); 
      setFilteredVehicleFrom([]);
      setHighlightedIndexVehicleFrom(-1);
      setFilteredVehicleTo([]);
      setHighlightedIndexVehicleTo(-1);
      setFilteredWorkPlaceFrom([]);
      setHighlightedIndexWorkPlaceFrom(-1);
      setHighlightedIndexWorkPlaceTo(-1);
      setHighlightedIndexWorkPlaceTo(-1);
    }
  }, []);

  const filteredDataPurchaseDelivery = dataPurchaseDelivery.filter((el) => {
    if (inputTextPurchaseDelivery === '') { 
      return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.purchasenote || '') +
        String(el.vehiclestore_licenseplate || '') +
        String(el.rel_trans_tradename || '') +
        String(el.ori_tras_tradename || '') +
        String(el.workplace || '') +
        String(el.totalpurchase ? parseFloat(el.totalpurchase).toFixed(2) : '');
      let result = dataTranslate.toLowerCase().includes(inputTextPurchaseDelivery.toLowerCase());
      return result;
    } 
  }) 
  
  const openCreateNewBlankPurchaseDelivery = () => { 
    setOpenCreateBlankPurchaseDelivery(true);
  };

  const handleCancelCreateBlankPurchaseDelivery = () => {
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setOpenCreateBlankPurchaseDelivery(false);
  };

  const handleChangeCreateBlankPurchaseDelivery = (e) => {
    const value  = e.target.value;
    setIdCreatePurchase(value);
  };

  const handleSubmitCreateBlankPurchaseDelivery = (e) => {
    if (idCreatePurchase !== DELETE_CODE_CREATE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      const fetchData = async () => {
        dispatch(setLoading(true));
        try {
          const getData = await helpHttp().put4(ENDPOINT,`purchasedelivery/saveblankpurchasedelivery`,token,defaultStore.id,useraccount_id);
          if (getData.status === "OK") {
            if (isMounted) {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_SaveData_Blank')));
              setRefeshData(true);
              dispatch(setLoading(false));
              handleCloseDataEditPurchaseDelivery();
            } 
          } else {
            if (isMounted) {
              dispatch(setLoading(false));
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        } 
      };
      fetchData(); 
    } else {
      dispatch(setLoading(false));
      dispatch(warningErrorAction(t('Text_InvalidId')));
    }
    setIdCreatePurchase(DELETE_CODE_CREATE);
    setIsLoadingErrorPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery(); 
  };

  let inputHandlerPurchaseDelivery  = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextPurchaseDelivery(lowerCase);
  };

  const handleChangeDeleteRowPurchaseDelivery = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleChangeEnableRowPurchaseDelivery = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelEnabledRowPurchaseDelivery = () => {
    setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
    setIsLoadingErrorPurchaseDelivery(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
    setOpenEnabledRowPurchaseDelivery(false);
  };

  const handleCancelDeleteRowPurchaseDelivery = () => {
    setRowEditPurchaseDelivery(initialStatePurchseDelivery[0]);
    setIsLoadingErrorPurchaseDelivery(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
    setOpenEnabledRowPurchaseDelivery(false);
  };
 
  const handleCloseEnabledRowPurchaseDelivery = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditPurchaseDelivery.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`purchasedelivery/deleteresetpurchasedelivery  `,token,rowEditPurchaseDelivery);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                setRefeshData(true);
                dispatch(setLoading(false));
                handleCloseDataEditPurchaseDelivery();
              }
            } else {
              if (isMounted) {
                dispatch(setLoading(false));
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }   
        };
        fetchData();
      
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
    setOpenEnabledRowPurchaseDelivery(false);
    setIsLoadingErrorPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery(); 
  };
 
  const handleCloseDeleteRowPurchaseDelivery = (e) => {
    if (idCodeDelete !== DELETE_CODE_FORMDI) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditPurchaseDelivery.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`purchasedelivery/deletepurchasedelivery  `,token,rowEditPurchaseDelivery);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
                setRefeshData(true);
                dispatch(setLoading(false));
                handleCloseDataEditPurchaseDelivery();
              }
            } else {
              if (isMounted) {
                dispatch(setLoading(false));
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }   
        };
        fetchData(); 
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowPurchaseDelivery(false);
    setOpenEnabledRowPurchaseDelivery(false);
    setIsLoadingErrorPurchaseDelivery(false);
    handleCloseDataEditPurchaseDelivery(); 
  };
 
  const handleCloseDataEditPurchaseDelivery = () => { 
    setOpenEditRowPurchaseDelivery(false);
    setRowEditPurchaseDelivery(openEditRowPurchaseDelivery?rowEditPurchaseDelivery:initialStatePurchseDelivery[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorPurchaseDelivery(false);
    toggleDrawerFilterEditPurchaseDelivery(false);  
	}; 
  
  useEffect(() => {
    if (numTotRegFilter > Number(LIMITPAGESIZE)) {
      setActiveOffsetPurchaseDelivery(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetPurchaseDelivery(newRangeOffset);
    } else {
      setCurrencyOffsetPurchaseDelivery(0);
      setActiveOffsetPurchaseDelivery(false);
      setRangeOffsetPurchaseDelivery([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    if (showModalPurchasesDeliverySettings){
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        id : rowEditPurchaseDelivery.id!==null?rowEditPurchaseDelivery.id:"",
        defaultStore_id : rowEditPurchaseDelivery.defaultStore_id!==null?rowEditPurchaseDelivery.defaultStore_id:0,
        tradename :rowEditPurchaseDelivery.tradename!==null?rowEditPurchaseDelivery.tradename:"",
        useraccount_id :rowEditPurchaseDelivery.useraccount_id!==null?rowEditPurchaseDelivery.useraccount_id:0,
        username :rowEditPurchaseDelivery.username!==null?rowEditPurchaseDelivery.username:"",
        defaultformatnumber :rowEditPurchaseDelivery.defaultformatnumber!==null?rowEditPurchaseDelivery.defaultformatnumber:0,
        purchasenote :rowEditPurchaseDelivery.purchasenote!==null?rowEditPurchaseDelivery.purchasenote:"",
        vehiclestore_licenseplate :rowEditPurchaseDelivery.vehiclestore_licenseplate!==null?rowEditPurchaseDelivery.vehiclestore_licenseplate:"",
        workplace :rowEditPurchaseDelivery.workplace!==null?rowEditPurchaseDelivery.workplace:"",
        datepurchase :rowEditPurchaseDelivery.datepurchase!==null?rowEditPurchaseDelivery.datepurchase:"",
        rel_trans_tradename :rowEditPurchaseDelivery.rel_trans_tradename!==null?rowEditPurchaseDelivery.rel_trans_tradename:"",
        ori_tras_tradename :rowEditPurchaseDelivery.ori_tras_tradename!==null?rowEditPurchaseDelivery.ori_tras_tradename:"",
        totalpurchase :rowEditPurchaseDelivery.totalpurchase!==null?rowEditPurchaseDelivery.totalpurchase:0,
        payment_id:rowEditPurchaseDelivery.payment_id!==null?rowEditPurchaseDelivery.payment_id:0,
        payment_code:rowEditPurchaseDelivery.payment_code!==null?rowEditPurchaseDelivery.payment_code:"",
        payment_namees:rowEditPurchaseDelivery.payment_namees!==null?rowEditPurchaseDelivery.payment_namees:"",
        payment_nameen:rowEditPurchaseDelivery.payment_nameen!==null?rowEditPurchaseDelivery.payment_nameen:"",
      }));
    }else{
      setFormPurchaseDelivery(initialStatePurchseDelivery[0]);
    }
  },[rowEditPurchaseDelivery,showModalPurchasesDeliverySettings]);
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true; 
    const fetchDataTransporter = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasedelivery/getlisttransporterpurchasedelivery/`, token,(typeOfList==="1" || typeOfList==="3")?false:true);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setTrasnporterToSelect(getData);
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataPayment = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setPaymenetTypeSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataTradename = async () => {
      try {
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) { 
          setTradenameToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   

    if (showModalPurchasesDeliverySettings ) { 
      fetchDataTransporter(); 
      fetchDataTradename();
      fetchDataPayment(); 
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,roles,filtersPurchaseDelivery,openEditFilterPurchaseDelivery,showModalPurchasesDeliverySettings,defaultStore.id,useraccount_id,typeOfList]);
   
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlistvehiclespurchasedelivery`,token,
          filterPurchase.rel_trans_store_idFrom!==undefined && filterPurchase.rel_trans_store_idFrom!==null && filterPurchase.rel_trans_store_idFrom.replace(/[.\-/\\|]/g, "").trim()!==""?filterPurchase.rel_trans_store_idFrom.replace(/[.\-/\\|]/g, ""):"-1",
          filterPurchase.rel_trans_store_idTo!==undefined && filterPurchase.rel_trans_store_idTo!==null && filterPurchase.rel_trans_store_idTo.replace(/[.\-/\\|]/g, "").trim()!==""?filterPurchase.rel_trans_store_idTo.replace(/[.\-/\\|]/g, ""):"-1",
          (typeOfList==="1" || typeOfList==="3")?false:true); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setVehiclesToSelect(getData); 
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  

    if (showModalPurchasesDeliverySettings ) { 
      fetchDataVehicles();
    }
    return () => { isMounted = false };
  }, [t,token,dispatch,i18n,roles,filterPurchase,openEditFilterPurchaseDelivery,showModalPurchasesDeliverySettings,typeOfList]);
   
  useEffect(() => {
    let isMounted = true; 
    const fetchDataVehicles = async () => {
      try {
        const getData = await helpHttp().get7(ENDPOINT,`purchasedelivery/getlistworkplacepurchasedelivery`,token,
          filterPurchase.ori_tras_store_idFrom!==undefined && filterPurchase.ori_tras_store_idFrom!==null && filterPurchase.ori_tras_store_idFrom.replace(/[.\-/\\|]/g, "").trim()!==""?filterPurchase.ori_tras_store_idFrom.replace(/[.\-/\\|]/g, ""):"-1",
          filterPurchase.ori_tras_store_idTo!==undefined && filterPurchase.ori_tras_store_idTo!==null && filterPurchase.ori_tras_store_idTo.replace(/[.\-/\\|]/g, "").trim()!==""?filterPurchase.ori_tras_store_idTo.replace(/[.\-/\\|]/g, ""):"-1",
          (typeOfList==="1" || typeOfList==="3")?false:true); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setWorkplaceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };   
    if (showModalPurchasesDeliverySettings ) { 
      fetchDataVehicles();
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,roles,filterPurchase,openEditFilterPurchaseDelivery,showModalPurchasesDeliverySettings,typeOfList]);
   
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegPurchaseDelivery = async () => { 
      try {
        const getData  = await helpHttp().get2(ENDPOINT,`purchasedelivery/getinformationpurchasedelivery/`,token,(typeOfList==="1" || typeOfList==="3")?false:true);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }   
          }
        } else if (isMounted) {
          setNumTotRegPurchaseDelivery(getData); 
        } 
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = await helpHttp().post4(ENDPOINT,`purchasedelivery/getcountfilterpurchasedelivery`,token,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,filtersPurchaseDelivery);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);  
          fetchDataPurchaseDelivery(); 
        } 
      } catch (error) {
        dispatch(setLoading(false)); 
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      } 
    }; 
    
    const fetchDataPurchaseDelivery = async () => {
      try {
        dispatch(setLoading(false));
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,numTotRegFilter<=LIMIT_PAGE_SIZE_SLIDE?numTotRegFilter:LIMIT_PAGE_SIZE_SLIDE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) {
          dispatch(setLoading(false));
          setDataPurchaseDelivery(initialStatePurchseDelivery);
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else {
          setDataPurchaseDelivery(getData); 
          dispatch(setLoading(false));
          if (numTotRegFilter>LIMIT_PAGE_SIZE_SLIDE){
            fetchDataSecondPurchaseDelivery();
          } 
        } 
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    };

    const fetchDataSecondPurchaseDelivery = async () => {
      try {
        const getData = await helpHttp().post10(ENDPOINT,`purchasedelivery/getpurchasedelivery`,token,currencyOffsetPurchaseDelivery,LIMITPAGESIZE,"-1","-1",(typeOfList==="1" || typeOfList==="3")?false:true,orderpurchaseended,orderpurchasenotended,filtersPurchaseDelivery);
        if (getData.err) { 
          setDataPurchaseDelivery(initialStatePurchseDelivery);
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseDelivery(getData);  
        } 
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    };
  
    if (showModalPurchasesDeliverySettings || refeshData){ 
      fetchnumTotRegPurchaseDelivery();
      fetchnumTotRegFilter();   
      setRefeshData(false); 
    }    
    return () => { isMounted = false; };
  }, [t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,numTotRegFilter,filtersPurchaseDelivery,showModalPurchasesDeliverySettings,orderpurchaseended,orderpurchasenotended,currencyOffsetPurchaseDelivery,defaultStore.id,useraccount_id ,openDeleteRowPurchaseDelivery,setOpenDeleteRowPurchaseDelivery,setOpenEditRowPurchaseDelivery,openEditRowPurchaseDelivery,rowEditPurchaseDelivery,setRowEditPurchaseDelivery,defaultStore,typeOfList,refeshData ]);
 
   return (
    <>
       {showModalPurchasesDeliverySettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={(typeOfList==="1" || typeOfList==="3")?"hANewStyle mb-2":"hBNewStyle mb-2"}>
              {isLoadingErrorPurchaseDelivery?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowPurchaseDelivery?"warning":"primary"}/>:<i className="fas fa-highlighter text-warning"></i>} {(typeOfList==="1" || typeOfList==="3")?t('Text_PurchaseDelivery_Table'):t('Text_PurchaseDeliveryEnded_Table')}</h1>
              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Create')) ?
                <div>
                  {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?
                    <div>
                      <button type="button" className="buttonCreate" onClick={openCreateOrEditDataPurchaseDelivery}>{t('Button_Create')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={openCreateNewBlankPurchaseDelivery}>{t('Button_Create_BLANK')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={handleFinishSelectedPurchaseDelivery}>{(typeOfList==="1" || typeOfList==="3")?t('Button_Finish'):t('Button_Pending')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>:
                    <div>
                      <button type="button" className="buttonCreate" onClick={openCreateOrEditDataPurchaseDelivery}>{t('Button_Create')}</button><> </>
                      <button type="button" className="buttonFinishPurchase" onClick={openCreateNewBlankPurchaseDelivery}>{t('Button_Create_BLANK')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>
                  }
                </div>:
                <div>
                  {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_Ended'))?
                    <div>
                      <button type="button" className="buttonFinishPurchase" onClick={handleFinishSelectedPurchaseDelivery}>{(typeOfList==="1" || typeOfList==="3")?t('Button_Finish'):t('Button_Pending')}</button><> </>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>:
                    <div>
                      <button type="button" className="buttonBack" onClick={closeModalPurchaseDelivery}>{t('Button_Back')}</button><> </>
                    </div>
                  } 
                </div>
              }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              value={inputTextPurchaseDelivery}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_PurchaseDelivery_Search')} 
              onChange={inputHandlerPurchaseDelivery }
              fullWidth
              label={t('Text_PurchaseDelivery_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyle mb-2">
            {t('Text_Filter')}&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersPurchaseDelivery }><i className="fas fa-solid fa-circle" style={{color:( 
                      filtersPurchaseDelivery.idFrom!=="" 
                      || filtersPurchaseDelivery.idTo!==""
                      || filtersPurchaseDelivery.defaultStoreIdFrom!==""
                      || filtersPurchaseDelivery.defaultStoreIdTo!==""
                      || filtersPurchaseDelivery.useraccountIdFrom!=="" 
                      || filtersPurchaseDelivery.useraccountIdTo!==""
                      || filtersPurchaseDelivery.purchasenoteFrom!=="" 
                      || filtersPurchaseDelivery.purchasenoteTo!=="" 
                      || filtersPurchaseDelivery.datepurchaseFrom!==null
                      || filtersPurchaseDelivery.datepurchaseTo!==null 
                      || filtersPurchaseDelivery.numberDIFROM!=="" 
                      || filtersPurchaseDelivery.numberDITO!=="" 
                      || filtersPurchaseDelivery.amountFROM!==0
                      || filtersPurchaseDelivery.amountTO!==0
                      || filtersPurchaseDelivery.vehiclestore_licenseplateFrom
                      || filtersPurchaseDelivery.vehiclestore_licenseplateTo
                      || filtersPurchaseDelivery.rel_trans_store_idFrom
                      || filtersPurchaseDelivery.rel_trans_store_idTo!=="" 
                      || filtersPurchaseDelivery.ori_tras_store_idFrom!==""
                      || filtersPurchaseDelivery.ori_tras_store_idTo!==""
                      || filtersPurchaseDelivery.ori_tras_workplaceFrom!==""
                      || filtersPurchaseDelivery.ori_tras_workplaceTo!==""                      
                      || filtersPurchaseDelivery.paymentFromES!==""
                      || filtersPurchaseDelivery.paymentToES!==""
                      || filtersPurchaseDelivery.paymentFromEN!==""
                      || filtersPurchaseDelivery.paymentToEN!==""
                      )?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Button_Apply_Filters')}</button><> </>
                <button type="button" className="buttonPDF" onClick={handleExportPDF}>{t('Button_PDF')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Handle_Excel"))&&
                  <>
                    <button type="button" className="buttonExcel" onClick={handleExport}>{t('Button_Excel')}</button><> </> 
                  </>
                }
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Handle_Vicasoft"))&&
                  <>
                    <button type="button" className="buttonVicasoft" onClick={handleExportVicasoft}>{t('Button_VICASOFT')}</button><> </> 
                  </>
                }
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Handle_PDFs"))&&
                  <>
                    <button type="button" className="buttonPDF" onClick={handleExportSelectedPDF}>{t('Button_PDF_SELECT')}</button><> </> 
                  </>
                }
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Handle_Arid"))&&
                  <>
                    <button type="button" className="buttonExcel" onClick={handleExportArid}>{t('Button_ARID')}</button><> </> 
                  </>
                }
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Purchases_Handle_No_Arid"))&&
                  <>
                    <button type="button" className="buttonExcel" onClick={handleExportNONArid}>{t('Button_NON_ARID')}</button><> </> 
                  </>
                }
            </h1>
          </div>
          <br/>

          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingPurchaseDelivery
                    dataPurchaseDelivery={filteredDataPurchaseDelivery}
                    setDataPurchaseDelivery={setDataPurchaseDelivery}
                    setFormPurchaseDelivery={setFormPurchaseDelivery}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={(typeOfList==="1" || typeOfList==="3")?t('Text_PurchaseDelivery_Table'):t('Text_PurchaseDeliveryEnded_Table')}
                    setOpenEnabledRowPurchaseDelivery={setOpenEnabledRowPurchaseDelivery} 
                    setOpenDeleteRowPurchaseDelivery={setOpenDeleteRowPurchaseDelivery} 
                    setRowEditPurchaseDelivery={setRowEditPurchaseDelivery}
                    showFinishButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Ended'))?true:false}
                    showEditButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Edit'))?true:false}
                    showPrintButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Print'))?true:false}
                    showDeleteButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Delete'))?true:false}
                    showEnabledButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Enable'))?true:false}
                    showTotalPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_View_TOTAL'))?true:false}
                    showEmailButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Handle_EMAILs'))?true:false} 
                    showNormalizedButtonPurchaseDelivery={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Purchases_Normalized_WorkPlace'))?true:false} 
                    numTotRegPurchaseDelivery={numTotRegPurchaseDelivery}
                    activeOffsetPurchaseDelivery={activeOffsetPurchaseDelivery}
                    rangeOffsetPurchaseDelivery={rangeOffsetPurchaseDelivery}
                    currencyOffsetPurchaseDelivery={currencyOffsetPurchaseDelivery}
                    setCurrencyOffsetPurchaseDelivery={setCurrencyOffsetPurchaseDelivery}
                    setViewPDF={setViewPDF} 
                    typeOfList={typeOfList}
                    formPurchaseDelivery={formPurchaseDelivery}
                    >
                  </TableSortingPurchaseDelivery>
                </div>             
              </div>
              
              {openEditFilterPurchaseDelivery &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditPurchaseDelivery(false)}
                 onOpen={toggleDrawerFilterEditPurchaseDelivery(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilterpurchasedelivery" onSubmit={handleCancelFiltersPurchaseDelivery}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idFrom" 
                                name="idFrom" 
                                value={filterPurchase.idFrom}
                                label={errorsFiltersPurchaseDelivery.idFrom!=null?t(errorsFiltersPurchaseDelivery.idFrom):t('Text_Filter_Code_From')}
                                placeholder={t('PlaceHolder_Filter_Code_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery} 
                              /> 
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {0}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="idTo" 
                                name="idTo" 
                                value={filterPurchase.idTo}
                                label={errorsFiltersPurchaseDelivery.idTo!=null?t(errorsFiltersPurchaseDelivery.idTo):t('Text_Filter_Code_To')}
                                placeholder={t('PlaceHolder_Filter_Code_to')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={handleBlurFilterEditPurchaseDelivery}
                                onChange={handleChangeFilterEditPurchaseDelivery} 
                              />  
                          </div> 
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseFrom" 
                                  name="datepurchaseFrom"
                                  value={ filterPurchase.datepurchaseFrom!== null ? new Date(filterPurchase.datepurchaseFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseDelivery.datepurchaseFrom!=null?t(errorsFiltersPurchaseDelivery.datepurchaseFrom):t('Text_Filter_DatePurchase_From')}
                                  onBlur={handleBlurSelectDatePurchaseDeliveryFrom}
                                  onChange={handleChangeSelectDatePurchaseDelivery}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="datepurchaseTo" 
                                  name="datepurchaseTo"
                                  minDate={filterPurchase.datepurchaseFrom!== null ? new Date(filterPurchase.datepurchaseFrom): null}
                                  value={ filterPurchase.datepurchaseTo!== null ? new Date(filterPurchase.datepurchaseTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersPurchaseDelivery.datepurchaseTo!=null?t(errorsFiltersPurchaseDelivery.datepurchaseTo):t('Text_Filter_DatePurchase_To')}
                                  onBlur={handleBlurSelectDatePurchaseDeliveryTo}
                                  onChange={handleChangeSelectDatePurchaseDeliveryTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>         
                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_store_idFrom"
                                name="ori_tras_store_idFrom"
                                value={filterPurchase.ori_tras_store_idFrom}
                                onChange={handleSearchStoreFrom}
                                onKeyDown={handleKeyDownStoreFrom}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_store_idFrom!=null?t(errorsFiltersPurchaseDelivery.ori_tras_store_idFrom):t('Text_Filter_TradeName_From')}
                                placeholder={t('PlaceHolder_Filter_tradename_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined" 
                              />
                              {/* Desplegable dinámico */}
                              {filteredStoreFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredStoreFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectStoreFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexStoreFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexStoreFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.documentid} - {store.tradename}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_store_idTo"
                                name="ori_tras_store_idTo"
                                value={filterPurchase.ori_tras_store_idTo}
                                onChange={handleSearchStoreTo}
                                onKeyDown={handleKeyDownStoreTo}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_store_idTo!=null?t(errorsFiltersPurchaseDelivery.ori_tras_store_idTo):t('Text_Filter_TradeName_To')}
                                placeholder={t('PlaceHolder_Filter_tradename_to')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              />
                              {/* Desplegable dinámico */}
                              {filteredStoreTo.length > 0 && ( 
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredStoreTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectStoreTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexStoreTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexStoreTo ? "white" : "black",
                                        }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                  ))}
                                </div>
                            )}    
                            </span>
                          </div> 

                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_workplaceFrom"
                                name="ori_tras_workplaceFrom"
                                value={filterPurchase.ori_tras_workplaceFrom}
                                onChange={handleSearchWorkPlaceFrom}
                                onKeyDown={handleKeyDownWorkPlaceFrom}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_workplaceFrom!=null?t(errorsFiltersPurchaseDelivery.ori_tras_workplaceFrom):t('Text_Filter_WorkPlace_From')}
                                placeholder={t('PlaceHolder_Filter_WorkPlace_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined" 
                              />
                              {/* Desplegable dinámico */}
                              {filteredWorkPlaceFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredWorkPlaceFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectWorkPlaceFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexWorkPlaceFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexWorkPlaceFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.workplace}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="ori_tras_workplaceTo"
                                name="ori_tras_workplaceTo"
                                value={filterPurchase.ori_tras_workplaceTo}
                                onChange={handleSearchWorkPlaceTo}
                                onKeyDown={handleKeyDownWorkPlaceTo}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.ori_tras_workplaceTo!=null?t(errorsFiltersPurchaseDelivery.ori_tras_workplaceTo):t('Text_Filter_WorkPlace_To')}
                                placeholder={t('PlaceHolder_Filter_WorkPlace_To')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                              />
                              {/* Desplegable dinámico */}
                              {filteredWorkPlaceTo.length > 0 && ( 
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredWorkPlaceTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectWorkPlaceTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexWorkPlaceTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexWorkPlaceTo ? "white" : "black",
                                        }}
                                      >
                                        {store.workplace}
                                      </MenuItem>
                                  ))}
                                </div>
                            )}    
                            </span>
                          </div> 

                          <div className="form-group">
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                            {/* Campo de texto para escribir y filtrar */}
                              <TextField
                                id="rel_trans_store_idFrom"
                                name="rel_trans_store_idFrom"
                                value={filterPurchase.rel_trans_store_idFrom}
                                onChange={handleSearchTransFrom}
                                onKeyDown={handleKeyDownTransFrom}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.rel_trans_store_idFrom!=null?t(errorsFiltersPurchaseDelivery.rel_trans_store_idFrom):t('Text_Filter_Trans_From')}
                                placeholder={t('PlaceHolder_Filter_Trans_From')}
                                variant="outlined"
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              />
                              {/* Desplegable dinámico */}
                              {filteredTransFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredTransFrom.map((store, index) => (
                                    <MenuItem
                                        key={store.id}
                                        onClick={() => handleSelectTransFrom(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexTransFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexTransFrom ? "white" : "black",
                                        }}
                                    >
                                      {store.documentid} - {store.tradename}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="rel_trans_store_idTo"
                                  name="rel_trans_store_idTo"
                                  value={filterPurchase.rel_trans_store_idTo}
                                  onChange={handleSearchTransTo}
                                  onKeyDown={handleKeyDownTransTo}
                                  onBlur={(event) => {
                                    // Solo cerrar si el foco se pierde fuera del desplegable
                                    if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                      handleBlur();
                                    }
                                  }}
                                  label={errorsFiltersPurchaseDelivery.rel_trans_store_idTo!=null?t(errorsFiltersPurchaseDelivery.rel_trans_store_idTo):t('Text_Filter_Trans_To')}
                                  placeholder={t('PlaceHolder_Filter_Trans_To')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredTransTo.length > 0 && (
                                  <div
                                    className="dropdown-class"
                                    style={{
                                      position: "absolute",
                                      left:'0px',
                                      border: "2px solid #4e73df", // Borde azul (#4e73df)
                                      borderRadius: "4px",
                                      marginTop: "4px",
                                      maxHeight: "200px",
                                      overflowY: "auto",
                                      width: "100%",
                                      fontSize: '18px',
                                      backgroundColor: "#f8f9fa", // Fondo gris claro
                                      zIndex: 1000,
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                  >
                                  {filteredTransTo.map((store, index) => (
                                    <MenuItem
                                      key={store.id}
                                        onClick={() => handleSelectTransTo(store)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexTransTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexTransTo ? "white" : "black",
                                        }}
                                      >
                                        {store.documentid} - {store.tradename}
                                      </MenuItem>
                                  ))}
                                </div>
                              )}
                            </span>  
                          </div> 

                          <div className="form-group">
                            {/* Campo de texto para escribir y filtrar */}
                            <span style={{ position: "relative" }}  onBlur={handleBlur}>
                              <TextField
                                id="vehiclestore_licenseplateFrom"
                                name="vehiclestore_licenseplateFrom"
                                value={filterPurchase.vehiclestore_licenseplateFrom}
                                onChange={handleSearchVehicleFrom}
                                onKeyDown={handleKeyDownVehicleFrom}
                                onBlur={(event) => {
                                  // Solo cerrar si el foco se pierde fuera del desplegable
                                  if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                    handleBlur();
                                  }
                                }}
                                label={errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom!=null?t(errorsFiltersPurchaseDelivery.vehiclestore_licenseplateFrom):t('Text_Filter_LicensePlate_From')}
                                placeholder={t('PlaceHolder_Filter_LicensePlate_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                variant="outlined"
                              />
                              {/* Desplegable dinámico */}
                              {filteredVehicleFrom.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredVehicleFrom.map((vehicle, index) => (
                                    <MenuItem
                                        key={vehicle.id}
                                        onClick={() => handleSelectVehicleFrom(vehicle)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexVehicleFrom ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexVehicleFrom ? "white" : "black",
                                        }}
                                    >
                                      {vehicle.vehiclestore_licenseplate}
                                    </MenuItem>
                                  ))}
                                </div>
                              )}
                              </span>  
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                              <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                <TextField
                                  id="vehiclestore_licenseplateTo"
                                  name="vehiclestore_licenseplateTo"
                                  value={filterPurchase.vehiclestore_licenseplateTo}
                                  onChange={handleSearchVehicleTo}
                                  onKeyDown={handleKeyDownVehicleTo}
                                  onBlur={(event) => {
                                    // Solo cerrar si el foco se pierde fuera del desplegable
                                    if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                      handleBlur();
                                    }
                                  }}
                                  label={errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo!=null?t(errorsFiltersPurchaseDelivery.vehiclestore_licenseplateTo):t('Text_Filter_LicensePlate_To')}
                                  placeholder={t('PlaceHolder_Filter_LicensePlate_To')}
                                  inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  variant="outlined"
                                />
                                {/* Desplegable dinámico */}
                                {filteredVehicleTo.length > 0 && (
                                <div
                                  className="dropdown-class"
                                  style={{
                                    position: "absolute",
                                    left:'0px',
                                    border: "2px solid #4e73df", // Borde azul (#4e73df)
                                    borderRadius: "4px",
                                    marginTop: "4px",
                                    maxHeight: "200px",
                                    overflowY: "auto",
                                    width: "100%",
                                    fontSize: '18px',
                                    backgroundColor: "#f8f9fa", // Fondo gris claro
                                    zIndex: 1000,
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                >
                                  {filteredVehicleTo.map((vehicle, index) => (
                                    <MenuItem
                                      key={vehicle.id}
                                        onClick={() => handleSelectVehicleTo(vehicle)}
                                        style={{
                                            cursor: "pointer",
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                            fontSize: '18px',
                                            backgroundColor:
                                            index === highlightedIndexVehicleTo ? "#4e73df" : "transparent",
                                            color: index === highlightedIndexVehicleTo ? "white" : "black",
                                        }}
                                      >
                                        {vehicle.vehiclestore_licenseplate}
                                      </MenuItem>
                                  ))}
                                </div>
                              )}    
                            </span>  
                          </div>   
                          <div className="form-group">
                              <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="amountFROM" 
                                name="amountFROM" 
                                value={filterPurchase.amountFROM}
                                label={errorsFiltersPurchaseDelivery.amountFROM!=null?t(errorsFiltersPurchaseDelivery.amountFROM):t('Text_Filter_amount_From')}
                                placeholder={t('PlaceHolderFilter_amount_From')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={(e) => handleBlurAmountFrom(e.target.value)}
                                onChange={(e) => handleChangeAmountFrom(e.target.value)}
                              /> 
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <NumericFormat 
                                thousandSeparator={''} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap}
                                id="amountTO" 
                                name="amountTO" 
                                value={filterPurchase.amountTO}
                                label={errorsFiltersPurchaseDelivery.amountTO!=null?t(errorsFiltersPurchaseDelivery.amountTO):t('Text_Filter_amount_To')}
                                placeholder={t('PlaceHolder_Filter_amount_To')}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 20 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                customInput={TextField}
                                onBlur={(e) => handleBlurAmountTo(e.target.value)}
                                onChange={(e) => handleChangeAmountTo(e.target.value)}
                              />  
                          </div> 

                          <div className="form-group">
                            <TextField
                              id={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                              name={i18n.language==="es"?"paymentFromES":"paymentFromEN"}
                              value={i18n.language==="es"?filterPurchase.paymentFromES:filterPurchase.paymentFromEN}
                              inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={i18n.language==="es"?errorsFiltersPurchaseDelivery.paymentFromES!=null?t(errorsFiltersPurchaseDelivery.paymentFromES):t('Text_Filter_payment_From'):errorsFiltersPurchaseDelivery.paymentFromEN!=null?t(errorsFiltersPurchaseDelivery.paymentFromEN):t('Text_Filter_payment_From')}
                              placeholder={t('PlaceHolder_Filter_payment_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseDelivery}
                              onChange={handleChangeFilterEditPurchaseDelivery}
                              select
                              SelectProps={{
                              native: true,
                              }}>
                              <option key={0} value=""/>
                              {paymenetTypeSelect.map((option) => (
                              <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                            </TextField>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                            <TextField
                              id={i18n.language==="es"?"paymentToES":"paymentToEN"}
                              name={i18n.language==="es"?"paymentToES":"paymentToEN"}
                              value={i18n.language==="es"?filterPurchase.paymentToES:filterPurchase.paymentToEN}
                              inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={i18n.language==="es"?errorsFiltersPurchaseDelivery.paymentToES!=null?t(errorsFiltersPurchaseDelivery.paymentToES):t('Text_Filter_payment_To'):errorsFiltersPurchaseDelivery.paymentToEN!=null?t(errorsFiltersPurchaseDelivery.paymentToEN):t('Text_Filter_payment_To')}
                              placeholder={t('PlaceHolder_Filter_payment_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditPurchaseDelivery}
                              onChange={handleChangeFilterEditPurchaseDelivery}
                              select
                              SelectProps={{
                              native: true,
                              }}>
                              <option key={0} value=""/>
                              {paymenetTypeSelect.filter(item=>(i18n.language==="es"?item.namees>=filterPurchase.paymentFromES:item.nameen>=filterPurchase.paymentFromEN)).map(option => (
                              <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                  {i18n.language==="es"?option.namees:option.nameen}
                              </option>
                              ))}
                              </TextField>
                          </div>  
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditPurchaseDelivery}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersPurchaseDelivery}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
              <PurchaseDeliveryPDF 
                key={rowEditPurchaseDelivery.id} 
                rowEditPurchaseDelivery={rowEditPurchaseDelivery} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
              <Dialog open={openDeleteRowPurchaseDelivery} onClose={handleCancelDeleteRowPurchaseDelivery}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditPurchaseDelivery.purchasenote} - {rowEditPurchaseDelivery.ori_tras_tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowPurchaseDelivery}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowPurchaseDelivery}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowPurchaseDelivery}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 


              <Dialog open={openEnabledRowPurchaseDelivery} onClose={handleCancelEnabledRowPurchaseDelivery}>
                <DialogTitle style={{color:"#e74a3b"}}>{rowEditPurchaseDelivery.enabled?t('Text_Disabled'):t('Text_Enabled')} {rowEditPurchaseDelivery.purchasenote} - {rowEditPurchaseDelivery.ori_tras_tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line7')}<br/>
                      {t('Text_Line8')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeEnableRowPurchaseDelivery}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelEnabledRowPurchaseDelivery}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseEnabledRowPurchaseDelivery}>{t('Button_ENABLED')}</Button>
                </DialogActions>
              </Dialog> 

              <Dialog open={openCreateBlankPurchaseDelivery} onClose={handleCancelCreateBlankPurchaseDelivery}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Button_Create_BLANK')} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line5')}<br/>
                      {t('Text_Line6')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label_Create')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCreatePurchase}
                        onChange={handleChangeCreateBlankPurchaseDelivery}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelCreateBlankPurchaseDelivery}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleSubmitCreateBlankPurchaseDelivery}>{t('Button_Create')}</Button>
                </DialogActions>
              </Dialog> 

              {/* End Content Row */}
          </>:
            <LoadingNews/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

PurchaseDeliveryFilter.propTypes = {
  showModalPurchasesDeliverySettings: PropTypes.bool.isRequired,
  setShowModalPurchasesDeliverySettings: PropTypes.func.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default PurchaseDeliveryFilter;

