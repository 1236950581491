import React, {  useEffect, useState,useCallback } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"; 
import { ENDPOINT, DEFAULT_STORE, DEFAULTCOLOREMPLOYEE, DEFAULT_TYPESDI } from "constants/variables";
import Box from '@mui/material/Box'; 
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types'; 


const initialUserAccount = {
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_password": "",
  "useraccount_enabled": true,
  "useraccount_isAccountNonLocked": true,
  "useraccount_color_id": 0,
  "useraccount_color_codehex": "",
  "useraccount_store_id": 0,
  "useraccount_store_name": "",
  "useraccount_email": "",
  "useraccount_tyyesdi_id": 0,
  "useraccount_tyyesdi_namees": "",
  "useraccount_tyyesdi_nameen": "",
 "useraccount_ticketsformat_id": 1,
  "useraccount_ticketsformat_namees": "",
  "useraccount_ticketsformat_nameen": ""
};

const initialColorsToSelect = [{
  "id":"",
  "namees":"",
  "nameen":"",
  "codehex":""
}];

const initialStoreToSelect = [{
  "id":"",
  "deafaultStore":true,
  "name":"",
  "tradename":""
}];

const initialTypesToSelect = [{
  "id":0,
  "namees":"",
  "nameen":false
}];

const initialTicketsFormatToSelect = [{
  "id":0,
  "namees":"",
  "nameen":"",
  "limitsarticleperweight":""
}];


const EmployeeCreateOrEditUserAccount = ({openEditRowUserAccount,setOpenEditRowUserAccount,openEditNewUserAccount,setOpenEditNewUserAccount,formEmployee,setFormEmployee}) => {
  const {t,i18n} = useTranslation(['listDataEmployees']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const [ newUserAccount, setNewUserAccount ]= useState(initialUserAccount);
  const [ errorsUserAccount, setErrorsUserAccount ] = useState({});
  const [ showPassword, setShowPassword ] = useState(false);
  const [ newPassword, setNewPassword ] = useState(false);
  const [ colorsToSelect, setColorsToSelect ]= useState(initialColorsToSelect);
  const [ storesToSelect, setStoresToSelect ]= useState(initialStoreToSelect);
  const [typesDIToSelect,setTypesDIToSelect]= useState(initialTypesToSelect);
  const [ticketsFormatToSelect,setTicketsFormatToSelect]= useState(initialTicketsFormatToSelect);

  const [ foundUsername, setFoundUsername ]= useState(false);
  const [ foundAliasUSer, setFoundAliasUSer ]= useState(false);
  const [ foundEmail, setFoundEmail ]= useState(false);
  const dispatch = useDispatch();

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowUserAccount) {     
        setNewUserAccount(initialUserAccount);
        setErrorsUserAccount({});
        setShowPassword(false);
        setNewPassword(false);
        setColorsToSelect(initialColorsToSelect);
        setStoresToSelect(initialStoreToSelect);
        setTypesDIToSelect(initialTypesToSelect);
        setTicketsFormatToSelect(initialTicketsFormatToSelect);
        setFoundUsername(false);
        setFoundAliasUSer(false);
        setFoundEmail(false);
        setOpenEditRowUserAccount(false);
        setOpenEditNewUserAccount(false);
      }
    },[
      setNewUserAccount,
      setErrorsUserAccount,
      setShowPassword,
      setNewPassword,
      setColorsToSelect,
      setStoresToSelect,
      setTypesDIToSelect,
      setTicketsFormatToSelect,
      setFoundUsername,
      setFoundAliasUSer,
      setFoundEmail,
      openEditRowUserAccount,
      setOpenEditRowUserAccount,
      setOpenEditNewUserAccount]
  );

  const handleCloseNewUserAccount = () => {
    setNewUserAccount(initialUserAccount);
    setErrorsUserAccount({});
    setShowPassword(false);
    setNewPassword(false);
    setColorsToSelect(initialColorsToSelect);
    setStoresToSelect(initialStoreToSelect);
    setTypesDIToSelect(initialTypesToSelect);
    setTicketsFormatToSelect(initialTicketsFormatToSelect);
    setFoundUsername(false);
    setFoundAliasUSer(false);
    setFoundEmail(false);
    setOpenEditRowUserAccount(false);
    setOpenEditNewUserAccount(false);
  };

  const handleChangeNewUserAccount = (e) => {
    const { name, value } = e.target;
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      [name]: value,
    }));   
  };
  
  const handleBlurNewUserAccount = (e) => {
    handleChangeNewUserAccount(e);
    setErrorsUserAccount(validateFormUserAccount());
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleDeletePassword = () => {
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      useraccount_password: ""
    }));
    setNewPassword(true);
  };

  const handleisAccountNonLockedUserAccount = () => {
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      useraccount_isAccountNonLocked: !newUserAccount.useraccount_isAccountNonLocked
    }));
  };

  const handleBlurSelectColor = (e) => {
    handleChangeSelectColor(e);
    setErrorsUserAccount(validateFormUserAccount());
  };
  
  const handleChangeSelectColor = (event) => {
    let colorHex="#FFFFFF";
    if (event.target.value!==""){
      colorHex=colorsToSelect.find((key=>key.id===Number(event.target.value))).codehex;
    }

    setNewUserAccount(newUserAccount => ({
        ...newUserAccount,
        useraccount_color_id:event.target.value,
        useraccount_color_codehex:colorHex
    }));
  };
 
  const handleBlurSelectTypeDI = (e) => {
    handleChangeSelectTypeDI(e);
    setErrorsUserAccount(validateFormUserAccount());
  };

  const handleChangeSelectTypeDI = (event) => {
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      useraccount_tyyesdi_id:event.target.value
    }));
  };

  const handleChangeSelectWeighVehiclesFormat = (event) => {
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      useraccount_ticketsformat_id:event.target.value
    }));
  };

  const handleBlurSelectWeighVehiclesFormat = (e) => {
    handleChangeSelectWeighVehiclesFormat(e);
  };

  const handleBlurSelectStore = (event) => {
    handleChangeSelectStore(event);
    setErrorsUserAccount(validateFormUserAccount());
  };

  const handleChangeSelectStore = (event) => {
    setNewUserAccount(newUserAccount => ({
      ...newUserAccount,
      useraccount_store_id:event.target.value
    }));
  };

  const toggleDrawerNewUserAccount  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewUserAccount();
    }
  };

  const validateFormUserAccount = () => {
    let errorsUserAccount = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    if (!newUserAccount.useraccount_username || newUserAccount.useraccount_username==="") {
      errorsUserAccount.useraccount_username = 'Text_FieldUsernameRequired';
    } else { 
        if (regexInvalidCharacters.test(newUserAccount.useraccount_username)) {
          errorsUserAccount.useraccount_username = 'Text_TextNoValid';
        } else{

        if(foundUsername){
          errorsUserAccount.useraccount_username= 'Text_User_UserNameExist';
        }
      }
    }

    if (!newUserAccount.useraccount_aliasuser || newUserAccount.useraccount_aliasuser==="") {
      errorsUserAccount.useraccount_aliasuser = 'Text_FieldAliasuserRequired';
    } else { 
      if (regexInvalidCharacters.test(newUserAccount.useraccount_aliasuser)) {
        errorsUserAccount.useraccount_aliasuser = 'Text_TextNoValid';
      } else{
        if(foundAliasUSer){
          errorsUserAccount.useraccount_aliasuser= 'Text_User_AliasuserExist';
        }
      }  
    }

    if (!newUserAccount.useraccount_email || newUserAccount.useraccount_email==="") {
      errorsUserAccount.useraccount_email = 'Text_FieldEmailRequired';
    } else { 
      if (regexInvalidCharacters.test(newUserAccount.useraccount_email)) {
        errorsUserAccount.useraccount_email = 'Text_TextNoValid';
      } else{
        if(foundEmail){
          errorsUserAccount.useraccount_email= 'Text_User_EmailExist';
        }
      }  
    }
    if (newUserAccount.useraccount_color_id===undefined || newUserAccount.useraccount_color_id===null ||newUserAccount.useraccount_color_id==="" || newUserAccount.useraccount_color_id===0 || newUserAccount.useraccount_color_id==="0") {
      errorsUserAccount.useraccount_color_id = 'Text_FieldColorRequired';
    }

    if (newUserAccount.useraccount_tyyesdi_id===undefined || newUserAccount.useraccount_tyyesdi_id===null ||newUserAccount.useraccount_tyyesdi_id==="" || newUserAccount.useraccount_tyyesdi_id===0 || newUserAccount.useraccount_tyyesdi_id==="0") {
      errorsUserAccount.useraccount_tyyesdi_id = 'Text_FieldTypesDiRequired';
    }
    
    if (newUserAccount.useraccount_ticketsformat_id===undefined || newUserAccount.useraccount_ticketsformat_id===null ||newUserAccount.useraccount_ticketsformat_id==="" || newUserAccount.useraccount_ticketsformat_id===0 || newUserAccount.useraccount_ticketsformat_id==="0") {
      errorsUserAccount.useraccount_ticketsformat_id = 'Text_FieldTicketsFormatRequired';
    }

    if (newUserAccount.useraccount_store_id===undefined || newUserAccount.useraccount_store_id===null ||newUserAccount.useraccount_store_id==="" || newUserAccount.useraccount_store_id===0 || newUserAccount.useraccount_store_id==="0") {
      errorsUserAccount.useraccount_store_id = 'Text_FieldStoreRequired';
    }
  
  return errorsUserAccount;
  };

  const handleSubmitNewUserAccount = () => {  

    setErrorsUserAccount(validateFormUserAccount());
    let errorsCheckUserAccount=validateFormUserAccount();
    if ((Object.keys(errorsCheckUserAccount).length === 0) 
          && newUserAccount.useraccount_username!==''  
          && newUserAccount.useraccount_aliasuser!=='' 
          && newUserAccount.useraccount_color_id!==undefined && newUserAccount.useraccount_color_id!==null 
          && newUserAccount.useraccount_color_id!=='' && newUserAccount.useraccount_color_id!=='0' && newUserAccount.useraccount_color_id!==0 
          && newUserAccount.useraccount_tyyesdi_id!==undefined && newUserAccount.useraccount_tyyesdi_id!==null 
          && newUserAccount.useraccount_tyyesdi_id!=='' && newUserAccount.useraccount_tyyesdi_id!=='0' && newUserAccount.useraccount_tyyesdi_id!==0 
          && newUserAccount.useraccount_ticketsformat_id!=='' && newUserAccount.useraccount_ticketsformat_id!=='0' && newUserAccount.useraccount_ticketsformat_id!==0 
          && newUserAccount.useraccount_store_id!==undefined && newUserAccount.useraccount_store_id!==null 
          && newUserAccount.useraccount_store_id!=='' && newUserAccount.useraccount_store_id!=='0' && newUserAccount.useraccount_store_id!==0 
          && newUserAccount.useraccount_email!=='' ) {
            setFormEmployee(formEmployee => ({
          ...formEmployee,
          useraccount_id: newUserAccount.useraccount_id,
          useraccount_username: newUserAccount.useraccount_username,
          useraccount_aliasuser: newUserAccount.useraccount_aliasuser,
          useraccount_password: newUserAccount.useraccount_password,
          useraccount_enabled: newUserAccount.useraccount_enabled,
          useraccount_isAccountNonLocked: newUserAccount.useraccount_isAccountNonLocked,
          useraccount_color_id: newUserAccount.useraccount_color_id,
          useraccount_color_codehex: newUserAccount.useraccount_color_codehex,
          useraccount_store_id: newUserAccount.useraccount_store_id,
          useraccount_store_name: newUserAccount.useraccount_store_name,
          useraccount_tyyesdi_id: newUserAccount.useraccount_tyyesdi_id,
          useraccount_tyyesdi_namees: newUserAccount.useraccount_tyyesdi_namees,
          useraccount_tyyesdi_nameen: newUserAccount.useraccount_tyyesdi_nameen,
          useraccount_ticketsformat_id: newUserAccount.useraccount_ticketsformat_id,
          useraccount_ticketsformat_namees: newUserAccount.useraccount_ticketsformat_namees,
          useraccount_ticketsformat_nameen: newUserAccount.useraccount_ticketsformat_nameen,
          useraccount_email: newUserAccount.useraccount_email
      }));
      handleCloseNewUserAccount();
    } else {
      if (errorsCheckUserAccount.useraccount_username){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_username)));
      }
      if (errorsCheckUserAccount.useraccount_aliasuser){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_aliasuser)));
      }
      if (errorsCheckUserAccount.useraccount_color_id){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_color_id)));
      }
      if (errorsCheckUserAccount.useraccount_tyyesdi_id){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_tyyesdi_id)));
      }
      if (errorsCheckUserAccount.useraccount_ticketsformat_id){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_ticketsformat_id)));
      }
      if (errorsCheckUserAccount.useraccount_store_id){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_store_id)));
      }
      if (errorsCheckUserAccount.useraccount_email){
        dispatch(warningErrorAction(t(errorsCheckUserAccount.useraccount_email)));
      }
    }  
  };

  useEffect(() => {
    let isMounted = true;
    const fetchExistUsername = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`employee/getuseraccountemployeefindbyusername/`,token,newUserAccount.useraccount_username);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundUsername(getData!==newUserAccount.useraccount_id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (newUserAccount.useraccount_username!==""){
      fetchExistUsername();
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,newUserAccount.useraccount_id,newUserAccount.useraccount_username]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistAliasuser = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`employee/getiduseraccountemployeefindbyaliasuser/`,token,newUserAccount.useraccount_aliasuser);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundAliasUSer(getData!==newUserAccount.useraccount_id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (newUserAccount.useraccount_aliasuser!==""){
      fetchExistAliasuser();
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,newUserAccount.useraccount_id,newUserAccount.useraccount_aliasuser]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchExistEmail = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`employee/getiduseraccountemployeefindbyemail/`,token,newUserAccount.useraccount_email);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setFoundEmail(getData!==newUserAccount.useraccount_id && getData>0)
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    if (newUserAccount.useraccount_email!==""){
      fetchExistEmail();
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,newUserAccount.useraccount_id,newUserAccount.useraccount_email]);
 
  useEffect(() => {
    let isMounted = true;
      
    const fetchDataColor = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `color/colorsorderbynamees`, token)
        : await  helpHttp().get(ENDPOINT,`color/colorsorderbynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setColorsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTypeDI = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `typesdi/typesdisorderbynamees`, token)
        : await  helpHttp().get(ENDPOINT,`typesdi/typesdisorderbynameen`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTypesDIToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataTicketsFormat = async () => {
      try {
        const getData = i18n.language==="es"
        ? await helpHttp().get(ENDPOINT, `employee/ticketsformatorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `employee/ticketsformatorderbynameen`, token);
         if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setTicketsFormatToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`store/storesorderbyname`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if ((openEditNewUserAccount || openEditRowUserAccount)){
      fetchDataColor();
      fetchDataTypeDI();
      fetchDataTicketsFormat();
      fetchDataStore();
    }
    
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,roles,openEditNewUserAccount,openEditRowUserAccount]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (!openEditNewUserAccount){
      setNewUserAccount(newUserAccount => ({
        ...newUserAccount,
        useraccount_id:formEmployee.useraccount_id!==null?formEmployee.useraccount_id:0,
        useraccount_username:formEmployee.useraccount_username!==null?formEmployee.useraccount_username:"",
        useraccount_aliasuser:formEmployee.useraccount_aliasuser!==null?formEmployee.useraccount_aliasuser:"",
        useraccount_password:formEmployee.useraccount_password!==null?formEmployee.useraccount_password:"",
        useraccount_enabled:formEmployee.useraccount_enabled!==null?formEmployee.useraccount_enabled:true,
        useraccount_isAccountNonLocked:formEmployee.useraccount_isAccountNonLocked!==null?formEmployee.useraccount_isAccountNonLocked:true,
        useraccount_color_id:formEmployee.useraccount_color_id!==null?formEmployee.useraccount_color_id:Number(DEFAULTCOLOREMPLOYEE),
        useraccount_color_codehex:formEmployee.useraccount_color_codehex!==null?formEmployee.useraccount_color_codehex:"",
        useraccount_store_id:formEmployee.useraccount_store_id!==null?formEmployee.useraccount_store_id:Number(DEFAULT_STORE),
        useraccount_store_name:formEmployee.useraccount_store_name!==null?formEmployee.useraccount_store_name:"",
        useraccount_email:formEmployee.useraccount_email!==null?formEmployee.useraccount_email:"",
        useraccount_tyyesdi_id:formEmployee.useraccount_tyyesdi_id!==null?formEmployee.useraccount_tyyesdi_id:Number(DEFAULT_TYPESDI),
        useraccount_tyyesdi_namees:formEmployee.useraccount_tyyesdi_namees!==null?formEmployee.useraccount_tyyesdi_namees:"",
        useraccount_tyyesdi_nameen:formEmployee.useraccount_tyyesdi_nameen!==null?formEmployee.useraccount_tyyesdi_nameen:"",
         useraccount_ticketsformat_id:formEmployee.useraccount_ticketsformat_id!==null?formEmployee.useraccount_ticketsformat_id:1,
        useraccount_ticketsformat_namees:formEmployee.useraccount_ticketsformat_namees!==null?formEmployee.useraccount_ticketsformat_namees:"",
        useraccount_ticketsformat_nameen:formEmployee.useraccount_ticketsformat_nameen!==null?formEmployee.useraccount_ticketsformat_nameen:""
      }));
    } else{
      setNewUserAccount(initialUserAccount);
      setNewUserAccount(newUserAccount => ({
        ...newUserAccount,
        useraccount_store_id:Number(DEFAULT_STORE),
        useraccount_color_id:Number(DEFAULTCOLOREMPLOYEE),
        useraccount_tyyesdi_id:Number(DEFAULT_TYPESDI),
        useraccount_ticketsformat_id:1
      }));
    }
  },[openEditRowUserAccount,openEditNewUserAccount,formEmployee.useraccount_aliasuser,formEmployee.useraccount_color_codehex,formEmployee.useraccount_color_id,formEmployee.useraccount_email,
    formEmployee.useraccount_enabled,formEmployee.useraccount_id, formEmployee.useraccount_isAccountNonLocked, formEmployee.useraccount_password,formEmployee.useraccount_store_id,
    formEmployee.useraccount_store_name,formEmployee, formEmployee.useraccount_username ]);
 
  return(
    <>
      {openEditRowUserAccount && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewUserAccount(false)}
            onOpen={toggleDrawerNewUserAccount(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{!openEditNewUserAccount?t('Text_User_Edit'):t('Text_User_Create')}</h1>
                </div>
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createUser" onSubmit={handleSubmitNewUserAccount}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_Code')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_id" 
                        name="useraccount_id"
                        value={newUserAccount.useraccount_id}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 200, fontSize: '18px' }, maxLength: 15 }} 
                        label={t('Text_Code')}
                        placeholder={t('PlaceHolder_User_Code')}
                        variant="standard"
                        disabled/>
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_User')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_username" 
                        name="useraccount_username"
                        value={newUserAccount.useraccount_username}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 450, fontSize: '18px' }, maxLength: 100 }} 
                        label={!openEditNewUserAccount?t('PlaceHolder_User_Username_Not'):
                          (errorsUserAccount.useraccount_username!=null?t(errorsUserAccount.useraccount_username):t('PlaceHolder_User_Username'))}
                        placeholder={!openEditNewUserAccount?t('PlaceHolder_User_Username_Not'):t('PlaceHolder_User_Username')}
                        variant="standard"
                        onBlur={handleBlurNewUserAccount}
                        onChange={handleChangeNewUserAccount}
                        disabled={!openEditNewUserAccount}/>
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_AliasUser')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_aliasuser" 
                        name="useraccount_aliasuser"
                        value={newUserAccount.useraccount_aliasuser}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 450, fontSize: '18px' }, maxLength: 100 }} 
                        label={errorsUserAccount.useraccount_aliasuser!=null?t(errorsUserAccount.useraccount_aliasuser):t('PlaceHolder_User_AliasUser')}
                        placeholder={t('PlaceHolder_User_AliasUser')}
                        variant="standard"
                        onBlur={handleBlurNewUserAccount}
                        onChange={handleChangeNewUserAccount}/>
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_Password')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_password" 
                        name="useraccount_password"
                        type={showPassword ? 'text' : 'password'}
                        value={newUserAccount.useraccount_password}
                        label={errorsUserAccount.useraccount_password!=null?t(errorsUserAccount.useraccount_password):t('PlaceHolder_User_Password')}
                        placeholder={t('PlaceHolder_User_Password')}
                        variant="standard"
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        onBlur={handleBlurNewUserAccount}
                        onChange={handleChangeNewUserAccount}
                        disabled={!openEditNewUserAccount && ! newPassword}
                        InputProps={{
                          style: {width: 450 , fontSize: '18px'},
                          endAdornment: (
                            <InputAdornment position="end"
                            onClick={handleClickShowPassword}>
                              {showPassword?<VisibilityOff style={{ fontSize: 28}}/>:<Visibility style={{ fontSize: 28}}/>}
                            </InputAdornment>
                          ),
                          startAdornment: (
                            <InputAdornment position="start"
                            onClick={handleDeletePassword}>
                              <DeleteIcon style={{ fontSize: 28}}/>
                            </InputAdornment>
                          )
                        }}
                        />
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_Email')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_email" 
                        name="useraccount_email"
                        value={newUserAccount.useraccount_email}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 450, fontSize: '18px' }, maxLength: 100 }} 
                        label={errorsUserAccount.useraccount_email!=null?t(errorsUserAccount.useraccount_email):t('PlaceHolder_User_Email')}
                        placeholder={t('PlaceHolder_User_Email')}
                        variant="standard"
                        onBlur={handleBlurNewUserAccount}
                        onChange={handleChangeNewUserAccount}/>
                    </div>
                    
                    <div className="form-group">
                    < label className="text-primary" style={{ fontSize: 18}}>{t('Text_isAccountNonLocked')}</label>   
                      &nbsp;&nbsp;   
                      <Checkbox
                        id="useraccount_isAccountNonLocked" 
                        name="useraccount_isAccountNonLocked"
                        checked={newUserAccount.useraccount_isAccountNonLocked}
                        onChange={handleisAccountNonLockedUserAccount}
                        color="primary"
                        variant="standard"
                        sx={{
                          '& .MuiSvgIcon-root': { color:"#4e73df",fontSize: 28 }, // Ajusta el tamaño del ícono
                        }}
                      />
                    </div>
                   
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_User_Color')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_color_id"
                        name="useraccount_color_id"
                        value={ (newUserAccount.useraccount_color_id === undefined || newUserAccount.useraccount_color_id ===null || newUserAccount.useraccount_color_id ===""
                              || newUserAccount.useraccount_color_id ==="0" || newUserAccount.useraccount_color_id ===0)?Number(DEFAULTCOLOREMPLOYEE):newUserAccount.useraccount_color_id}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {color:newUserAccount.useraccount_color_codehex, width: 405, fontSize: '18px'}, maxLength: 100 }}
                        variant="outlined"
                        onBlur={handleBlurSelectColor}
                        onChange={handleChangeSelectColor}
                        helperText={errorsUserAccount.useraccount_color_id!=null?t(errorsUserAccount.useraccount_color_id):""}
                        select
                        SelectProps={{
                          native: true,
                          value: (newUserAccount.useraccount_color_id === undefined || newUserAccount.useraccount_color_id ===null || newUserAccount.useraccount_color_id ===""
                               || newUserAccount.useraccount_color_id ==="0" || newUserAccount.useraccount_color_id ===0)?Number(DEFAULTCOLOREMPLOYEE): newUserAccount.useraccount_color_id
                        }}>
                        {colorsToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                          {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                      </TextField>
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_User_Stores')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_store_id"
                        name="useraccount_store_id"
                        value={ (newUserAccount.useraccount_store_id === undefined || newUserAccount.useraccount_store_id ===null || newUserAccount.useraccount_store_id ===""
                              || newUserAccount.useraccount_store_id ==="0" || newUserAccount.useraccount_store_id ===0)?Number(DEFAULT_STORE):newUserAccount.useraccount_store_id}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 405, fontSize: '18px' }, maxLength: 100 }} 
                        variant="outlined"
                        onBlur={handleBlurSelectStore}
                        onChange={handleChangeSelectStore}
                        helperText={errorsUserAccount.useraccount_store_id!=null?t(errorsUserAccount.useraccount_store_id):""}
                        select
                        SelectProps={{
                          native: true,
                          value: (newUserAccount.useraccount_store_id === undefined || newUserAccount.useraccount_store_id ===null || newUserAccount.useraccount_store_id ===""
                               || newUserAccount.useraccount_store_id ==="0" || newUserAccount.useraccount_store_id ===0)?Number(DEFAULT_STORE): newUserAccount.useraccount_store_id
                        }}>
                        {storesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                              {option.name}
                        </option>
                        ))}
                      </TextField>
                    </div>


                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_User_TypesDI')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_tyyesdi_id"
                        name="useraccount_tyyesdi_id"
                        value={ (newUserAccount.useraccount_tyyesdi_id === undefined || newUserAccount.useraccount_tyyesdi_id ===null || newUserAccount.useraccount_tyyesdi_id ===""
                              || newUserAccount.useraccount_tyyesdi_id ==="0" || newUserAccount.useraccount_tyyesdi_id ===0)?Number(DEFAULT_TYPESDI):newUserAccount.useraccount_tyyesdi_id}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 405, fontSize: '18px' }, maxLength: 100 }}  
                        variant="outlined"
                        onBlur={handleBlurSelectTypeDI}
                        onChange={handleChangeSelectTypeDI}
                        helperText={errorsUserAccount.useraccount_tyyesdi_id!=null?t(errorsUserAccount.useraccount_tyyesdi_id):""}
                        select
                        SelectProps={{
                          native: true,
                          value: (newUserAccount.useraccount_tyyesdi_id === undefined || newUserAccount.useraccount_tyyesdi_id ===null || newUserAccount.useraccount_tyyesdi_id ===""
                               || newUserAccount.useraccount_tyyesdi_id ==="0" || newUserAccount.useraccount_tyyesdi_id ===0)?Number(DEFAULT_TYPESDI): newUserAccount.useraccount_tyyesdi_id
                        }}>
                        {typesDIToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                              {i18n.language==='es'?option.namees:option.nameen}
                        </option>
                        ))}
                      </TextField>
                    </div>
                    
                    <label className="text-primary" style={{ fontSize: 18}}>{t('Text_TicketsFormat')} :</label>
                    <div className="form-group">
                      <TextField
                        id="useraccount_ticketsformat_id"
                        name="useraccount_ticketsformat_id"
                        value={ (newUserAccount.useraccount_ticketsformat_id === undefined || newUserAccount.useraccount_ticketsformat_id ===null || newUserAccount.useraccount_ticketsformat_id ===""
                              || newUserAccount.useraccount_ticketsformat_id ==="0" || newUserAccount.useraccount_ticketsformat_id ===0)?1:newUserAccount.useraccount_ticketsformat_id}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        inputProps={{ style: {width: 405, fontSize: '18px' }, maxLength: 100 }} 
                        variant="outlined"
                        onBlur={handleBlurSelectWeighVehiclesFormat}
                        onChange={handleChangeSelectWeighVehiclesFormat}
                        helperText={errorsUserAccount.useraccount_ticketsformat_id!=null?t(errorsUserAccount.useraccount_ticketsformat_id):""}
                        select
                        SelectProps={{
                          native: true,
                          value: (newUserAccount.useraccount_ticketsformat_id === undefined || newUserAccount.useraccount_ticketsformat_id ===null || newUserAccount.useraccount_ticketsformat_id ===""
                               || newUserAccount.useraccount_ticketsformat_id ==="0" || newUserAccount.useraccount_ticketsformat_id ===0)?1: newUserAccount.useraccount_ticketsformat_id
                        }}>
                        {ticketsFormatToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                              {i18n.language==='es'?option.namees:option.nameen}
                        </option>
                        ))}
                      </TextField>
                    </div>
                    

                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitNewUserAccount}>{!openEditNewUserAccount?t('Button_Update'):t('Button_Create')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleCloseNewUserAccount}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

EmployeeCreateOrEditUserAccount.propTypes = {
  openEditRowUserAccount: PropTypes.bool.isRequired,
  setOpenEditRowUserAccount: PropTypes.func.isRequired,
  openEditNewUserAccount: PropTypes.bool.isRequired,
  setOpenEditNewUserAccount: PropTypes.func.isRequired,
  formEmployee: PropTypes.object.isRequired,
  setFormEmployee: PropTypes.func.isRequired
};

export default EmployeeCreateOrEditUserAccount;