import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportTemplateFirst from './ReportTemplateFirst';
import ReportTemplateSecond from './ReportTemplateSecond';
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types'; 

const initialStateForm = {
  id: 0,
  ownformdi_id:0,
  defaultStore_id: 0,
  useraccount_id: 0, 
  defaultformatnumber: 1,
  isFinished: true,
  isScanned: false,
  numberDI_sheet: "", 
  date_sheet: "",
 
  opt_tras_documnettype: "",
  opt_tras_documentid: "",
  opt_tras_name: "",
  opt_tras_contactname: "", 
  opt_tras_cnae: "",
  opt_tras_nima: "",
  opt_tras_country: "",
  opt_tras_province: "",
  opt_tras_locality: "",
  opt_tras_community: "",
  opt_tras_phone: "",
  opt_tras_licenseplate: "",
  opt_tras_economicactivity: "",
  opt_tras_tradename: "",
  opt_tras_street: "",
  opt_tras_postalcode: "",
  opt_tras_email: "",
  opt_tras_inscription: "",
  opt_tras_opt: "",
  
  ori_tras_documnettype: "",
  ori_tras_documentid: "",
  ori_tras_name: "",
  ori_tras_contactname: "", 
  ori_tras_cnae: "",
  ori_tras_nima: "",
  ori_tras_country: "",
  ori_tras_province: "",
  ori_tras_locality: "",
  ori_tras_community: "",
  ori_tras_phone: "",
  ori_tras_licenseplate: "",
  ori_tras_economicactivity: "",
  ori_tras_tradename: "",
  ori_tras_street: "",
  ori_tras_postalcode: "",
  ori_tras_email: "",
  ori_tras_inscription: "",
  ori_tras_opt: "",

  aut_tras_documnettype: "",
  aut_tras_documentid: "",
  aut_tras_name: "",
  aut_tras_contactname: "", 
  aut_tras_cnae: "",
  aut_tras_nima: "",
  aut_tras_country: "",
  aut_tras_province: "",
  aut_tras_locality: "",
  aut_tras_community: "",
  aut_tras_phone: "",
  aut_tras_licenseplate: "",
  aut_tras_economicactivity: "",
  aut_tras_tradename: "",
  aut_tras_street: "",
  aut_tras_postalcode: "",
  aut_tras_email: "",
  aut_tras_inscription: "",
  aut_tras_opt: "",
 
  dest_tras_documnettype: "",
  dest_tras_documentid: "",
  dest_tras_name: "",
  dest_tras_contactname: "", 
  dest_tras_cnae: "",
  dest_tras_nima: "",
  dest_tras_country: "",
  dest_tras_province: "",
  dest_tras_locality: "",
  dest_tras_community: "",
  dest_tras_phone: "",
  dest_tras_licenseplate: "",
  dest_tras_economicactivity: "",
  dest_tras_tradename: "",
  dest_tras_street: "",
  dest_tras_postalcode: "",
  dest_tras_email: "",
  dest_tras_inscription: "",
  dest_tras_opt: "",
  
  aut_dest_documnettype: "",
  aut_dest_documentid: "",
  aut_dest_name: "",
  aut_dest_contactname: "", 
  aut_dest_cnae: "",
  aut_dest_nima: "",
  aut_dest_country: "",
  aut_dest_province: "",
  aut_dest_locality: "",
  aut_dest_community: "",
  aut_dest_phone: "",
  aut_dest_licenseplate: "",
  aut_dest_economicactivity: "",
  aut_dest_tradename: "",
  aut_dest_street: "",
  aut_dest_postalcode: "",
  aut_dest_email: "",
  aut_dest_inscription: "",
  aut_dest_opt: "",
  
  rel_trans_documnettype: "",
  rel_trans_documentid: "",
  rel_trans_name: "",
  rel_trans_contactname: "", 
  rel_trans_cnae: "",
  rel_trans_nima: "",
  rel_trans_country: "",
  rel_trans_province: "",
  rel_trans_locality: "",
  rel_trans_community: "",
  rel_trans_phone: "",
  rel_trans_licenseplate: "",
  rel_trans_economicactivity: "",
  rel_trans_tradename: "",
  rel_trans_street: "",
  rel_trans_postalcode: "",
  rel_trans_email: "",
  rel_trans_inscription: "",
  rel_trans_opt: "",
 
  resp_prod_documnettype: "",
  resp_prod_documentid: "",
  resp_prod_name: "",
  resp_prod_contactname: "", 
  resp_prod_cnae: "",
  resp_prod_nima: "",
  resp_prod_country: "",
  resp_prod_province: "",
  resp_prod_locality: "",
  resp_prod_community: "",
  resp_prod_phone: "",
  resp_prod_licenseplate: "",
  resp_prod_economicactivity: "",
  resp_prod_tradename: "",
  resp_prod_street: "",
  resp_prod_postalcode: "",
  resp_prod_email: "",
  resp_prod_inscription: "",
  resp_prod_opt: "",

  codeLER: "",
  codeLER_code: "",
  codeLER_name: "",
  description: "",
  description_code: "",
  description_name: "",
  treatmenttypeorigin_code: "",
  treatmenttypeorigin_name: "",
  treatmenttypedestine_code: "",
  treatmenttypedestine_name: "",
  processtypepeorigin_code: "",
  processtypepeorigin_name: "",
  processtypedestine_code: "",
  processtypedestine_name: "",
  dangerousness_name: "",
  charac_danger: "",
  amount: "",
  deliver_date: "",
  delivery_quantity: "",
  date_accepted: "",
  accept: "",
  date_refund: "",
  reason_refund: "",
  action_refund: "",
  observations1: "",
  observations2: "" ,
  
  signature_opt_tras:"",
  signature_dest_tras:"",
  signature_refund:"" 
  
};


const FormDIPDF = ({ rowEditFormDI, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const [dataForm, setDataForm] = useState(initialStateForm);  
  const [pdfOpened, setPdfOpened] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    let isMounted = true;  
    const fetchFileForm = async (fileName) => {
      if (pdfOpened) return; // Evita abrir el PDF dos veces
      try {
        const getData = await helpHttp().getfilesupload(ENDPOINT, `pdftos3uploader/downloadfroms3`, token,fileName);
        if (!getData) { 
          dispatch(warningErrorAction(t('Text_ConnectionFailed'))); 
        } else{
          if (getData && getData.size>0 && isMounted){
             // Open PDF in a new tab  
              const pdfData = new Blob([getData], { type: "application/pdf" });
           
              // Espera un pequeño tiempo antes de abrir la ventana, para evitar ejecuciones dobles
              setTimeout(() => {
                window.open(URL.createObjectURL(pdfData), "_blank");
              }, 100);
              setViewPDF(false); 
              setPdfOpened(false)
          }
        
        }
      } catch (error) { 
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    const fetchDataForm = async () => {
      try {
        const getData = await helpHttp().get8(ENDPOINT, `form/getpdfformdibyid`, token, rowEditFormDI.id, defaulttypesdi);
        if (getData.err) {
          setDataForm(initialStateForm);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataForm(getData); 
       
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (
      rowEditFormDI?.id &&
      defaulttypesdi &&
      rowEditFormDI.id !== "0" &&
      rowEditFormDI.id !== "0" &&
      viewPDF
    ) {
      if (rowEditFormDI.isScanned){
        let fileName = rowEditFormDI.preiddoc_sheet || "";
        if (rowEditFormDI.sufiddoc_sheet) {
          fileName += rowEditFormDI.sufiddoc_sheet;
        } 
        fetchFileForm("DI_" + fileName + ".pdf");
      } else{
        fetchDataForm();
      }  
    }
 
    return () => {isMounted = false;};
  }, [defaulttypesdi,rowEditFormDI, dispatch, t, token,i18n.language,setViewPDF,pdfOpened,viewPDF]);  
 
  return (
    !rowEditFormDI.isScanned && 
      <div id="pdf-content" style={{ padding: 10 }}>
        {(viewPDF && dataForm.id!==undefined  && dataForm.id!==null  && dataForm.id!=="" && dataForm.id!=="0" && dataForm.id!==0 
                  && defaulttypesdi!==undefined  &&defaulttypesdi!==null  &&defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
                
        ) &&
        (defaulttypesdi===1?
          <ReportTemplateFirst dataForm={dataForm}  initialStateForm={initialStateForm} setDataForm={setDataForm} setViewPDF={setViewPDF}/>:
          <ReportTemplateSecond dataForm={dataForm}  initialStateForm={initialStateForm} setDataForm={setDataForm} setViewPDF={setViewPDF}/>)}
      </div>
       
  );
};

FormDIPDF.propTypes = {
  rowEditFormDI: PropTypes.object.isRequired,
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default FormDIPDF;
