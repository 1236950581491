import React, {useState, useEffect} from 'react' 
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';   
import Checkbox from '@mui/material/Checkbox'; 
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'; 
import Paper from '@mui/material/Paper';  
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';  
import Typography from '@mui/material/Typography'; 
import TableFooter from '@mui/material/TableFooter';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/material/styles/useTheme';   
import visuallyHidden from '@mui/utils/visuallyHidden'; 
 
import PropTypes from 'prop-types';

const headCellsNormalized = [
  {
    id: 'indexrow',
    numeric: false,
    disablePadding: false,
    label: 'Text_Row',
    canOrder:true,
  },
  {
    id: 'normalizedworkplace',
    numeric: false,
    disablePadding: false,
    label: 'Text_Normalizedworkplace',
    canOrder:true,
  },
  {
    id: 'newnormalizedworkplace',
    numeric: false,
    disablePadding: false,
    label: 'Text_New_Normalizedworkplace',
    canOrder:true,
  } ,
  {
    id: 'enablednormalizedworkplace',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enablednormalizedworkplace',
    canOrder:false,
  }

];

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
        return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, t } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {headCellsNormalized.map((headCell, indexhead) => (
          <TableCell
            key={indexhead}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.canOrder ?(
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>):
              <Typography style={{color:"#4e73df", fontSize: 16}} >  
                {t(headCell.label)}
              </Typography>}
          </TableCell>
        ))} 
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const {t} = useTranslation(['listDataPurchases']);

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label={t('Text_First_Page')}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label={t('Text_Previous_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Next_Page')}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label={t('Text_Last_Page')}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const TableSortingNormalized =(props)=>{
  const { dataNormalized, setFormNormalized, LIMITPAGESIZE} = props;
  const {t} = useTranslation(['listDataPurchases']);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [emptyRows, setEmptyRows] = useState(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataNormalized.length) : 0);
  const [countData,setCountData] = useState(dataNormalized.length);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  const regexInvalidCharacters = /[\\!$%&|<>?{}~"']/;

    useEffect(() => {
      if (dataNormalized.length !== countData || dataNormalized.length <= rowsPerPage) {
        setPage(0);
      }  
      setCountData(dataNormalized.length);
      setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataNormalized.length) : 0);
    }, [dataNormalized, rowsPerPage, page, countData]);
    
    useEffect(() => {
      if (countData > LIMITPAGESIZE) {
        setActiveOffset(true);
        const ranges = [
          {
            value: 1,
            label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
          },
        ];
        for (let i = Number(LIMITPAGESIZE); i < countData; i += Number(LIMITPAGESIZE)) {
          ranges.push({
            value: (i / Number(LIMITPAGESIZE)) + 1,
            label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
          });
        }
        setRangeOffset(ranges);
      } else {
        setCurrencyOffset(1);
        setActiveOffset(false);
        setRangeOffset([{}]);
      }
    }, [countData, LIMITPAGESIZE]);

  const handleChangeOffset = (event) => {
    setCurrencyOffset(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }; 

  const handleChangeEnabledNormalizedWorkPlace = (event, rowIndex) => {
    setFormNormalized(prevState =>
      prevState.map(row =>
        row.id === rowIndex.id
          ? { ...row, 
            enablednormalizedworkplace: !row.enablednormalizedworkplace}
          : row
      )
    ); 
  };
 
  const handleEditNewNormalizedWorkplace = (event, rowIndex) => {
    const newValue = event.target.value;
    setFormNormalized(prevState =>
      prevState.map(row =>
        row.id === rowIndex.id
          ? { ...row, 
              newnormalizedworkplace: newValue,
              error: !newValue.trim() || regexInvalidCharacters.test(newValue) }
          : row
      )
    );
  };
   
  const listItems= (
  stableSort(dataNormalized, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, keydataNormalized) => {
    return (
      <TableRow hover tabIndex={-1} key={keydataNormalized}>
         
        <TableCell align="left">
          <Typography style={{color:"#4e73df",fontSize: 16}} >
            {row.indexrow}
          </Typography>
        </TableCell> 
        <TableCell align="left">
        <Typography style={{color:"#4e73df" , fontSize: 16}} >
            {row.normalizedworkplace}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <TextField
            value={row.newnormalizedworkplace}
            error={row.error}
            helperText={
              !row.newnormalizedworkplace.trim()
                ? t('Text_Error_New_Normalizedworkplace') 
                : regexInvalidCharacters.test(row.newnormalizedworkplace)
                ? t('Text_TextNoValid')
                : ""
            }
            placeholder={t('Placeholder_New_Normalizedworkplace')}
            onChange={(event) => handleEditNewNormalizedWorkplace(event, row)}
            variant="standard"
            sx={{
              '& input': { width: 700, fontSize: 16 }
            }}
          /> 
        </TableCell> 
        <TableCell align="center">
          <Checkbox checked={row.enablednormalizedworkplace} 
            sx={{
              '& .MuiSvgIcon-root': { color: "#4e73df",fontSize: 28 }, // Ajusta el tamaño del ícono
            }} 
            onClick={(event)=>handleChangeEnabledNormalizedWorkPlace(event, row)}/>
        </TableCell> 
      </TableRow>
    );
  }));
  
  return(
    <>
      <Box sx={{ width: '100%' }}>      
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '1560px',width: '100%' }} aria-labelledby="tableTitle" size='small'>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} t={t} />
              <TableBody>    
                {listItems}
                {emptyRows > 0 && (
                <TableRow style={{ height: 33  * emptyRows}}>
                  <TableCell colSpan={4} />
                </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                {activeOffset && (
                    <TableCell colSpan={2}>
                      <Box sx={{ flexShrink: 0, p: 1}}>
                        <div className="form-group">
                          <TextField
                            id="currencyOffset"
                            name="currencyOffset"
                            value={currencyOffset}
                            inputProps={{ style: {maxLength: 100, color:"#4e73df",fontSize:14}}}
                            placeholder={t('Text_Range_Offset')}
                            variant="standard"
                            onChange={handleChangeOffset}
                            helperText={t('Text_Range_Offset')}
                            select
                            SelectProps={{
                              native: true,
                              value: currencyOffset
                            }}>
                            {rangeOffset.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                          ))}
                          </TextField>
                        </div>
                      </Box>  
                    </TableCell>
                  )}
                  <TablePagination
                    rowsPerPageOptions={[5, 10,25,50,100]}
                    colSpan={4}
                    count={countData}
                    rowsPerPage={rowsPerPage}
                    page={countData===0?0:page}
                    labelRowsPerPage={t('Text_Rows_Per_Page')}
                    slotProps={{
                      inputProps: {
                        'aria-label': t('Text_Rows_Per_Page'),
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
      </Box>
    </>
  )
}

TableSortingNormalized.propTypes = {
  dataNormalized: PropTypes.array.isRequired,
  setFormNormalized: PropTypes.func.isRequired 
};

export default TableSortingNormalized;