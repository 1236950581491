import { useState } from "react";  
import { useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import { warningErrorAction, successErrorAction } from 'actions/ErrorActions';
import { loadLoginAction, resetLoginAction } from "actions/LoginActions";
import { ENDPOINT } from "constants/variables";
import loginServiceEncrypted from './LoginService';

export const useForm = (initialForm, validateForm) => {
  const { t, i18n } = useTranslation(['loginText']);
  const [form, setForm] = useState(initialForm);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    handleChange(e);
    setErrors(validateForm(form));
  }; 
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validateForm(form));
    if ((Object.keys(errors).length === 0) && form.username !== '') {
      setLoading(true);
      try {
        const result = await loginServiceEncrypted(ENDPOINT, form.username, form.password);
        if (result.ok) {
          const obj = await result.json();
          if (obj === null) {
            dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            dispatch(resetLoginAction());
          } else if (!obj.isAccountNonLocked) {
            dispatch(warningErrorAction(t('Text_UserBlocked')));
            dispatch(resetLoginAction());
          } else if (!obj.enabled) {
            dispatch(warningErrorAction(t('Text_UserDisabled')));
            dispatch(resetLoginAction());
          } else {
            obj.language.code === "es" ? i18n.changeLanguage("es") : i18n.changeLanguage("en");
            dispatch(successErrorAction(t('Text_ValidCredentials')));
            dispatch(loadLoginAction(obj));
          }
        } else {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          dispatch(resetLoginAction());
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        setLoading(false);
      } finally {
        setLoading(false);
      }
    } else {
      dispatch(warningErrorAction(t('Text_IncorrectData')));
    }
  };

  return {
    form, 
    errors,
    loading,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};
