import React, { useEffect, useState } from "react";
import ImportScript from 'components/utils/ImportScript';
import Home from "pages/main/Home";
import "./loginForm.css"
import { warningErrorAction } from "actions/ErrorActions";  
import { useForm } from "service/UseForm"
import { useTranslation } from "react-i18next" 
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify'; 
import { helpHttp } from "service/HelpHttp";
import { ENDPOINT } from "constants/variables"; 
//import coverVideo from "components/media/coverVideo.mp4";
import coverImage from "components/photos/MontanaArena.webp"; // Importa tu imagen de fondo

import {
  loadFormatDIAction,
  resetFormatDIAction
} from "actions/FormatDIActions";

// Detectar si estamos en Electron
const isElectron = () => {
  return !!window.electron; // Nueva detección
};

// Obtener ipcRenderer solo si estamos en Electron
const ipcRenderer = isElectron() ? window.electron.ipcRenderer : null;

const validationsForm = (form) => {
  let errors = {};
  if (!form.username) {
    errors.username = 'Text_UsuerRequired';
  }
  return errors;
};

const Login = () => {

  const {t} = useTranslation(['loginText']);
  const [loadVariables,setLoadVariables]= useState(false); 
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles); 
  const enabled = useSelector((state) => state.loginUser.enabled);  
  const typesdi_id = useSelector((state) => state.loginUser.defaulttypesdi.id);  
  const [credentialsLoaded, setCredentialsLoaded] = useState(false);
  const scriptUrl = '/js/sb-admin-2.min.js';

  const dispatch = useDispatch();

  const initialForm = {
    username: "",
    password: ""
  };

  const {
    form, 
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationsForm);
  
  useEffect(() => {
    if (!loadVariables){  
      setLoadVariables(true);
    } 
  }, [dispatch,loadVariables]);
  
  // Cargar credenciales almacenadas
  useEffect(() => {
    const loadCredentials = async () => {
      if (!isElectron()) {
        return;
      } 
      try {
        const response = await ipcRenderer.invoke('get-credentials');
        if (response.status === 'success' && response.password) {
          form.username = response.username;
          form.password = response.password;
          handleSubmit({ preventDefault: () => {} }); // Simula evento vacío
          setCredentialsLoaded(true); // Marca como cargado
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_ValidCredentials')));  
      }
    };
  
    // Solo ejecuta si no se ha cargado antes
    if (!credentialsLoaded) {
      loadCredentials();
    }
  }, [credentialsLoaded,form,handleSubmit,dispatch,t]); // Dependencia controlada
  
  
  useEffect(() => {
    let isMounted = true;
      const fetchDataFormatDI = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`typesdi/getformatdibytypeip/`,"",typesdi_id);
          if (getData.err || getData.message === "Load failed") {
            dispatch(resetFormatDIAction());
          } else if (isMounted) {
            dispatch(loadFormatDIAction(getData)); 
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }  
      };
    
      if (enabled && typesdi_id!==undefined  && typesdi_id!==null  && typesdi_id!=="" ){
         fetchDataFormatDI();
      }  
    return () => { isMounted = false };
  }, [t,dispatch,loadVariables,token,enabled,typesdi_id]);
  
  useEffect(() => {
    if (!loadVariables){ 
      setLoadVariables(true);
    } 
  }, [dispatch,loadVariables]); 
  return (
    <>
    <ImportScript resourceUrl={scriptUrl} />
    <ToastContainer></ToastContainer>
    {(enabled===true && roles!==undefined && roles[0]!==undefined && roles[0].listmenus!==undefined ) ?
      <Home/> :  
      <div className="cover-container">
        <img className="background-image" src={coverImage} alt="Background" />
          
        <div className="row"> 
        <div className="col-lg-25">  
            <div className="card border-0">
              <div className="card-body">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h2 text-gray-900 mb-4">{t('Text_Welcome')}</h1>
                  </div>
                  <form className="user" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input type="text" className="form-control form-control-user"
                          aria-describedby="emailHelp"
                          name="username"
                          placeholder={t('Text_EnterUsername')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={form.username}
                          required
                          />
                          {errors.username ? 
                          <div className="small text-danger py-1 px-3">
                          {t(errors.username)}
                          </div>: <div className="small text-danger py-1 px-3"><br/></div>}
                      <input type="password" className="form-control form-control-user"
                          id="password" 
                          name="password"
                          placeholder={t('Text_EnterPassword')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          />
                    </div>
                    <div className="text-center">
                      <button  className="buttonBlue" onClick={handleSubmit}>
                        {t('Text_Login')}
                      </button>
                    </div>
                    <hr/>
                  </form> 
                </div>                    
              </div> 
            </div>
          </div>
        </div>
      </div>
    }
    </>
  );
};

export default Login;