import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportTikets from 'pages/ticketsDetails/generatePDF/ReportTickets'; 
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const initialStateTickets = {
  id : "",
  defaultStore_id:0, 
  tradename: "",
  useraccount_id:0,
  username: "",
  defaultformatnumber:1,
  isfinished:false, 
  numberticket:0, 
  purchasenote:0, 
  ownnumbertickes:0, 
  start_date_ticket: "",
  end_date_ticket: "", 
  vehiclestore_id: 0,
  vehiclestore_licenseplate: "",
  vehiclestore_tara: 0,
  vehiclestore_pma: 0,
  vehiclestore_checkpma: true,
  vehiclestore_defaultdriver: "",
  vehiclestore_store_id: 0,
  rel_trans_store_id: 0,
  rel_trans_documnettype_id: 0,
  rel_trans_documentid: "",
  rel_trans_documnettype_name: "",
  rel_trans_tradename: "",
  rel_trans_name: "",
  rel_trans_contacperson: "",
  rel_trans_roadtype_id: 0,
  rel_trans_street: "",
  rel_trans_country_id: 1,
  rel_trans_province_id: 30,
  rel_trans_postalcode_id: 0,
  rel_trans_grnp:"",
  ori_tras_store_id: 0,
  ori_tras_documnettype_id: 0,
  ori_tras_documnettype_name: "",
  ori_tras_documentid: "",
  ori_tras_tradename: "",
  ori_tras_name: "",
  ori_tras_contacperson: "",
  ori_tras_roadtype_id: 0,
  ori_tras_street: "",
  ori_tras_country_id: 1,
  ori_tras_province_id: 30,
  ori_tras_postalcode_id: 0,
  observations: "",
  totalstartweight: 0,
  totalendweight: 0,
  newweightreading:0,
  enabled:true,
  linestickets:[],
  linesticketsWithOutWeight:[]

};
 
const TicketsPDF = ({ rowEditTicketsDetails, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataTickets']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const [dataTickets, setDataTickets] = useState(initialStateTickets);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataTickets = async () => {
     
      try {
        const getData = await helpHttp().get2(ENDPOINT, `ticketsdetails/getticketbyid/`, token, rowEditTicketsDetails.id);
        if (getData.err) {
          setDataTickets(initialStateTickets);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataTickets(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditTicketsDetails!==undefined && rowEditTicketsDetails.id!==undefined  && rowEditTicketsDetails.id!==null  && rowEditTicketsDetails.id!=="" && rowEditTicketsDetails.id!=="0" && rowEditTicketsDetails.id!==0
      && defaulttypesdi!==undefined  && defaulttypesdi!==null  && defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
    ) {
      fetchDataTickets();
    }
    
    return () => {isMounted = false;};
  }, [defaulttypesdi, rowEditTicketsDetails,  dispatch, t, token,i18n.language]);  
 
  return (
    <div id="pdf-content" style={{ padding: 10 }}>
      {(viewPDF && dataTickets.id!==undefined  && dataTickets.id!==null  && dataTickets.id!=="" && dataTickets.id!=="0" && dataTickets.id!==0 
                && defaulttypesdi!==undefined  &&defaulttypesdi!==null  &&defaulttypesdi!=="" && defaulttypesdi!=="0" && defaulttypesdi!==0
      ) &&  <ReportTikets dataTickets={dataTickets}  initialStateTickets={initialStateTickets} setDataTickets={setDataTickets} setViewPDF={setViewPDF}/> }
    </div>
  );
};


TicketsPDF.propTypes = {
  rowEditTicketsDetails: PropTypes.object.isRequired,
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default TicketsPDF;
