import React, {  Suspense, useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { setFilters, resetFilters} from 'reducers/filtersEmployeesSlice';
import { resetPagination } from 'reducers/paginationEmployeesSlice';
import { useNavigate } from 'react-router-dom';
import { NON_ERASABLE_EMPLOYEE_ID, ENDPOINT } from "constants/variables";

import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField';
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import TableSortingEmployee from 'pages/employees/SortingTablesEmployee';
import PropTypes from 'prop-types';

const initialFiltersEmployee= {
  "idFrom":"",
  "idTo":"",
  "firstnameFrom":"",
  "firstnameTo":"",
  "lastname1From":"",
  "lastname1To":"",
  "lastname2From":"",
  "lastname2To":"",
  "enabled":true,
  "notenabled":true,
  "languageFromES":"",
  "languageToES":"",
  "languageFromEN":"",
  "languageToEN":"",
  "emailsFrom":"",
  "emailsTo":"",
  "phonesFrom":"",
  "phonesTo":"",
};

const initialLanguageToSelect = [{
  "id": "",
  "code":"",
  "namees": "",
  "nameen": ""
}];

const initialStateEmployee = [{
   
  "id" : "",
  "firstname" : "",
  "lastname1" : "",
  "lastname2" : "",
  "fullname" : "", 
  "createdate":"",
  "enabled" : true,
  "birthdate" : null,
  "comments" : "",
  "useraccount_id":0,
  "useraccount_username": "",
  "useraccount_aliasuser": "",
  "useraccount_email": "",
  "documnettype_id":0,
  "documnettype_name": "",
  "numberdocument" : "",
  "numbersecuritysocial" : "",
  "language_id":0,
  "language_code": "",
  "language_namees": "",
  "language_nameen": "", 
  "phones" : [
    {
      "id":"",
      "phone":"",
      "defaultphone":""
    }
  ],
  "emails" : [
    {
      "id":"",
      "email":"",
      "defaultemail":""
    }
  ]  
}];

            
const EmployeesFilter = ({showModalEmployeeSettings,setShowModalEmployeeSettings}) => {
  const {t,i18n} = useTranslation(['listDataEmployees']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_EMPLOYEE = useSelector((state) => state.formatDIUser.delete_code_employee); 
  const filtersEmployeesDetails = useSelector((state) => state.filtersEmployees);
  const [dataEmployee, setDataEmployee] = useState(initialStateEmployee);
  const [filtersEmployee, setFiltersEmployee]= useState(filtersEmployeesDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegEmployee,setNumTotRegEmployee]= useState(0);
  const [errorsFiltersEmployee, setErrorsFiltersEmployee] = useState({});
  const [inputTextEmployee, setInputTextEmployee] = useState("");
  const [openCreateDataEmployee,setOpenCreateDataEmployee]= useState(false);
  const [openDeleteRowEmployee,setOpenDeleteRowEmployee]= useState(false);
  const [openEditRowEmployee,setOpenEditRowEmployee]= useState(false);
  const [openEditFilterEmployee,setOpenEditFilterEmployee]= useState(false);
  const [rowEditEmployee,setRowEditEmployee]= useState(initialStateEmployee[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorEmployee, setIsLoadingErrorEmployee] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [activeOffsetEmployee, setActiveOffsetEmployee]= useState(false);
  const [currencyOffsetEmployee, setCurrencyOffsetEmployee] = useState(0);
  const [rangeOffsetEmployee, setRangeOffsetEmployee] = useState([{}]); 
  const [languagesToSelect,setLanguagesToSelect]= useState(initialLanguageToSelect);
  const navigate = useNavigate(); 
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalEmployeeSettings) {
        setDataEmployee(initialStateEmployee); 
        setNumTotRegFilter(0);
        setNumTotRegEmployee(0);
        setErrorsFiltersEmployee({});
        setInputTextEmployee("");
        setOpenCreateDataEmployee(false);
        setOpenDeleteRowEmployee(false);
        setOpenEditRowEmployee(false);
        setOpenEditFilterEmployee(false);
        setRowEditEmployee(initialStateEmployee[0]); 
        setIsLoadingErrorEmployee(false);
        setIdCodeDelete(DELETE_CODE);
        setActiveOffsetEmployee(false);
        setCurrencyOffsetEmployee(0);
        setRangeOffsetEmployee([{}]);
        setLanguagesToSelect(initialLanguageToSelect);
        setShowModalEmployeeSettings(false);
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFiltersEmployee(initialFiltersEmployee);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataEmployee,
      setFiltersEmployee,
      setNumTotRegFilter,
      setNumTotRegEmployee,
      setErrorsFiltersEmployee,
      setInputTextEmployee,
      setOpenCreateDataEmployee,
      setOpenDeleteRowEmployee,
      setOpenEditRowEmployee,
      setOpenEditFilterEmployee,
      setRowEditEmployee, 
      setIsLoadingErrorEmployee,
      setIdCodeDelete,
      setActiveOffsetEmployee,
      setCurrencyOffsetEmployee,
      setRangeOffsetEmployee,
      setLanguagesToSelect, 
      showModalEmployeeSettings,
      setShowModalEmployeeSettings, 
      DELETE_CODE,
      dispatch,
      navigate]
  );

  const closeModalEmployees = () => {    
    setDataEmployee(initialStateEmployee); 
    setNumTotRegFilter(0);
    setNumTotRegEmployee(0);
    setErrorsFiltersEmployee({});
    setInputTextEmployee("");
    setOpenCreateDataEmployee(false);
    setOpenDeleteRowEmployee(false);
    setOpenEditRowEmployee(false);
    setOpenEditFilterEmployee(false);
    setRowEditEmployee(initialStateEmployee[0]); 
    setIsLoadingErrorEmployee(false);
    setIdCodeDelete(DELETE_CODE);
    setActiveOffsetEmployee(false);
    setCurrencyOffsetEmployee(0);
    setRangeOffsetEmployee([{}]);
    setLanguagesToSelect(initialLanguageToSelect);
    setShowModalEmployeeSettings(false); 
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFiltersEmployee(initialFiltersEmployee); 
    navigate("/home");
    return () => {};
  };

  const openCreateOrEditDataEmployee = () => {
    setInputTextEmployee("");
    setOpenCreateDataEmployee(true);
    navigate("/employeedetail/0");
  };

  const openEditFiltersEmployee = () => { 
      setOpenEditFilterEmployee(!openEditFilterEmployee);
  };

  const handleSubmitFilterEditEmployee = () => {
    setErrorsFiltersEmployee(validateFormFilterEmployee());
    if (Object.keys(errorsFiltersEmployee).length === 0) {
      setErrorsFiltersEmployee({});   
      dispatch(resetPagination());
      dispatch(setFilters(filtersEmployee));  
      setOpenEditFilterEmployee(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersEmployee = () => {
    setErrorsFiltersEmployee({});
    dispatch(resetFilters());
    dispatch(resetPagination());
    setFiltersEmployee(initialFiltersEmployee);
    setOpenEditFilterEmployee(!openEditFilterEmployee);
  };

  const handleCancelFiltersEmployee = () => {
    setErrorsFiltersEmployee({}); 
    setOpenEditFilterEmployee(!openEditFilterEmployee);
  };   

  const handleChangeEnabledFilter = (e) => {
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      enabled: !filtersEmployee.enabled
    }));
  };

  const handleChangeFilterLanguages = (e) => {
    const { name, value } = e.target;
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      [name]: value
    }));
  };

  const handleBlurFilterLanguages = (e) => {
    setErrorsFiltersEmployee(validateFormFilterEmployee());
    handleChangeFilterLanguages(e);
  };  

  const handleChangeNotEnabledFilter = (e) => {
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      notenabled: !filtersEmployee.notenabled
    }));
  };

  const handleChangeFilterEditEmployee = (e) => {
    const { name, value } = e.target;
    setFiltersEmployee(filtersEmployee => ({
      ...filtersEmployee,
      [name]: value
    }));
  };

  const handleBlurFilterEditEmployee = (e) => {
    setErrorsFiltersEmployee(validateFormFilterEmployee());
    handleChangeFilterEditEmployee(e);
  };

  const toggleDrawerFilterEditEmployee = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditEmployee();
    }
  };

  const validateFormFilterEmployee = () => {

    let errorsFiltersEmployee = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersEmployee.idFrom!=="" & !regexNumber.test(filtersEmployee.idFrom)) {
      errorsFiltersEmployee.idFrom = 'Text_TextNoNumber';
    }
    if (filtersEmployee.idTo!=="" & !regexNumber.test(filtersEmployee.idTo)) {
      errorsFiltersEmployee.idTo = 'Text_TextNoNumber';
    }
    if (filtersEmployee.idFrom!=="" & !errorsFiltersEmployee.idFrom!==undefined){
      if (filtersEmployee.idTo!=="" & !errorsFiltersEmployee.idTo!==undefined){
        if (parseInt(filtersEmployee.idTo)<parseInt(filtersEmployee.idFrom)){
          errorsFiltersEmployee.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersEmployee.firstnameFrom)) {
      errorsFiltersEmployee.firstnameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.firstnameTo)) {
      errorsFiltersEmployee.firstnameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname1From)) {
      errorsFiltersEmployee.lastname1From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname1To)) {
      errorsFiltersEmployee.lastname1To = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(filtersEmployee.lastname2From)) {
      errorsFiltersEmployee.lastname2From = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.lastname2To)) {
      errorsFiltersEmployee.lastname2To = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersEmployee.emailsFrom)) {
      errorsFiltersEmployee.emailsFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.emailsTo)) {
      errorsFiltersEmployee.emailsTo = 'Text_TextNoValid';
    } 
  
    if (regexInvalidCharacters.test(filtersEmployee.phonesFrom)) {
      errorsFiltersEmployee.phonesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersEmployee.phonesTo)) {
      errorsFiltersEmployee.phonesTo = 'Text_TextNoValid';
    } 
  
    if (filtersEmployee.firstnameTo!=="" & filtersEmployee.firstnameTo<filtersEmployee.firstnameFrom){
      errorsFiltersEmployee.firstnameTo = 'Text_TextGreater_firstname';
    }

    if (filtersEmployee.lastname1To!=="" & filtersEmployee.lastname1To<filtersEmployee.lastname1From){
      errorsFiltersEmployee.lastname1To = 'Text_TextGreater_lastname1';
    }

    if (filtersEmployee.lastname2To!=="" & filtersEmployee.lastname2To<filtersEmployee.lastname2From){
      errorsFiltersEmployee.lastname2To = 'Text_TextGreater_lastname2';
    }

    if (filtersEmployee.emailsTo!=="" & filtersEmployee.emailsTo<filtersEmployee.emailsFrom){
      errorsFiltersEmployee.emailsTo = 'Text_TextGreater_emails';
    }

    if (filtersEmployee.phonesTo!=="" & filtersEmployee.phonesTo<filtersEmployee.phonesFrom){
      errorsFiltersEmployee.phonesTo = 'Text_TextGreater_phones';
    }
 
    if (filtersEmployee.languageToES!=="" & filtersEmployee.languageToES<filtersEmployee.languageFromES){
      filtersEmployee.languageToES="";
      errorsFiltersEmployee.languageToES = 'Text_TextGreater_language';
    }    
    if (filtersEmployee.languageToEN!=="" & filtersEmployee.languageToEN<filtersEmployee.languageFromEN){
        filtersEmployee.languageToEN="";
        errorsFiltersEmployee.languageToEN = 'Text_TextGreater_language';
    }
 
    return errorsFiltersEmployee;
  }  

  const filteredDataEmployee = dataEmployee.filter((el) => {
    if (inputTextEmployee === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.firstname || '') +
        String(el.lastname1 || '') +
        String(el.lastname2 || '') +
        String(el.fullname || '') +
        String(el.numberdocument || '') +
        String(el.numbersecuritysocial || '')
        +(el.emails!==undefined && el.emails!==null && el.emails.length>0?el.emails.map(current => {return (current.email )}):"")
        +(el.phones!==undefined && el.phones!==null && el.phones.length>0?el.phones.map(current => {return (current.phone )}):"");
      let result = dataTranslate.toLowerCase().includes(inputTextEmployee.toLowerCase());
      return result; 
    }
  })

  let inputHandlerEmployee = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextEmployee(lowerCase);
  };

  const handleChangeDeleteRowEmployee = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowEmployee = () => {
    setRowEditEmployee(initialStateEmployee[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorEmployee(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowEmployee(false);
  };
 
  const handleCloseDeleteRowEmployee = (e) => {
    if (idCodeDelete !== DELETE_CODE_EMPLOYEE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditEmployee.id !== '') {
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT, `employee/deleteemployee`, token, rowEditEmployee);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField')));
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }   
        };
        fetchData(); 
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowEmployee(false);
    setIsLoadingErrorEmployee(false);
    handleCloseDataEditEmployee();
    return () => { isMounted = false;};
  };
 

  const handleCloseDataEditEmployee = () => {
    setOpenCreateDataEmployee(false);
    setOpenEditRowEmployee(false);
    setRowEditEmployee(openEditRowEmployee?rowEditEmployee:initialStateEmployee[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorEmployee(false);
    toggleDrawerFilterEditEmployee(false);
	};

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    if (numTotRegEmployee > LIMITPAGESIZE) {
      setActiveOffsetEmployee(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegEmployee; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetEmployee(newRangeOffset);
    } else {
      setCurrencyOffsetEmployee(0);
      setActiveOffsetEmployee(false);
      setRangeOffsetEmployee([{}]);
    }
  }, [numTotRegEmployee, LIMITPAGESIZE]);

  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegEmployee = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT, `employee/getinformationtableemployee`, token);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegEmployee(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = await helpHttp().post(ENDPOINT, `employee/getcountfilteremployee`, token, filtersEmployeesDetails);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
 
    const fetchDataLanguage = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `language/languagesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `language/languagesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setLanguagesToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataEmployee = async () => {
      dispatch(setLoading(true));
      try { 
        const getData = await helpHttp().get3(ENDPOINT, `employee/employees`, token, currencyOffsetEmployee, LIMITPAGESIZE, filtersEmployeesDetails);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataEmployee(initialStateEmployee);
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
          }
        } else if (isMounted) {
          setDataEmployee(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    if (showModalEmployeeSettings){
      fetchnumTotRegEmployee();
      fetchnumTotRegFilter();
      fetchDataEmployee();
      fetchDataLanguage(); 
      return () => { isMounted = false; };
    }
  }, [t,token,dispatch,i18n.language,openCreateDataEmployee,openDeleteRowEmployee,setOpenDeleteRowEmployee,setOpenEditRowEmployee,openEditRowEmployee,rowEditEmployee,showModalEmployeeSettings,LIMITPAGESIZE,currencyOffsetEmployee,filtersEmployeesDetails]);

  return (
    <>
       {showModalEmployeeSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className="h1NewStyle mb-2">
            {isLoadingErrorEmployee?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowEmployee?"warning":"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_Employees_Table')}</h1>
            {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Employees_Create')) ?
              <div>
                <button type="button" className="buttonCreate" onClick={openCreateOrEditDataEmployee}>{t('Button_Create')}</button><> </>
                <button type="button" className="buttonBack" onClick={closeModalEmployees}>{t('Button_Back')}</button><> </>
              </div>:
              <div>
                <button type="button" className="buttonBack" onClick={closeModalEmployees}>{t('Button_Back')}</button><> </>
              </div>
            }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined"
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              value={inputTextEmployee}
              placeholder={t('Text_Employee_Search')} 
              onChange={inputHandlerEmployee}
              fullWidth
              label={t('Text_Employee_Search')}>
            </TextField>
          </div> 
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.idFrom!=="" || filtersEmployeesDetails.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.firstnameFrom!=="" || filtersEmployeesDetails.firstnameTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_firstname')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.lastname1From!=="" || filtersEmployeesDetails.lastname1To!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_lastname1')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.lastname2From!=="" || filtersEmployeesDetails.lastname2To!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_lastname2')}</button><> </> 
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.enabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Enabled')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(filtersEmployeesDetails.notenabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_disabled')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersEmployee}><i className="fas fa-solid fa-circle" style={{color:(
                      filtersEmployeesDetails.languageFromES!==""
                      || filtersEmployeesDetails.languageToES!==""
                      || filtersEmployeesDetails.languageFromEN!==""
                      || filtersEmployeesDetails.languageToEN!==""
                      || filtersEmployeesDetails.emailsFrom!==""
                      || filtersEmployeesDetails.emailsTo!==""
                      || filtersEmployeesDetails.phonesFrom!==""
                      || filtersEmployeesDetails.phonesTo!==""
                      )?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Others_Filters')}</button><> </> 
            </h1>
          </div>
          <br/> 
          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingEmployee
                    dataEmployee={filteredDataEmployee}
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_Employees_Table')}
                    setOpenDeleteRowEmployee={setOpenDeleteRowEmployee}
                    setRowEditEmployee={setRowEditEmployee}
                    showEditButtonEmployee={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Employees_Edit'))?true:false}
                    showDeleteButtonEmployee={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Employees_Delete'))?true:false}
                    numTotRegEmployee={numTotRegEmployee}
                    activeOffsetEmployee={activeOffsetEmployee}
                    rangeOffsetEmployee={rangeOffsetEmployee}
                    currencyOffsetEmployee={currencyOffsetEmployee}
                    setCurrencyOffsetEmployee={setCurrencyOffsetEmployee} 
                    minvalue={Number(NON_ERASABLE_EMPLOYEE_ID)}> 
                  </TableSortingEmployee>
                </div>             
              </div>
              {openEditFilterEmployee &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditEmployee(false)}
                 onOpen={toggleDrawerFilterEditEmployee(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfilteemployee" onSubmit={handleCancelFiltersEmployee}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                              id="idFrom" 
                              name="idFrom" 
                              value={filtersEmployee.idFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.idFrom!=null?t(errorsFiltersEmployee.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="idTo" 
                              name="idTo"
                              value={filtersEmployee.idTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.idTo!=null?t(errorsFiltersEmployee.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="firstnameFrom" 
                              name="firstnameFrom"
                              value={filtersEmployee.firstnameFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.firstnameFrom!=null?t(errorsFiltersEmployee.firstnameFrom):t('Text_Filter_firstname_From')}
                              placeholder={t('PlaceHolder_Filter_firstname_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="firstnameTo" 
                              name="firstnameTo"
                              value={filtersEmployee.firstnameTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.firstnameTo!=null?t(errorsFiltersEmployee.firstnameTo):t('Text_Filter_firstname_To')}
                              placeholder={t('PlaceHolder_Filter_firstname_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="lastname1From" 
                              name="lastname1From"
                              value={filtersEmployee.lastname1From}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.lastname1From!=null?t(errorsFiltersEmployee.lastname1From):t('Text_Filter_lastname1_From')}
                              placeholder={t('PlaceHolder_Filter_lastname1_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                             <TextField
                              id="lastname1To" 
                              name="lastname1To"
                              value={filtersEmployee.lastname1To}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.lastname1To!=null?t(errorsFiltersEmployee.lastname1To):t('Text_Filter_lastname1_To')}
                              placeholder={t('PlaceHolder_Filter_lastname1_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                           </div>
                           <div className="form-group">
                             <TextField
                              id="lastname2From" 
                              name="lastname2From"
                              value={filtersEmployee.lastname2From}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.lastname2From!=null?t(errorsFiltersEmployee.lastname2From):t('Text_Filter_lastname2_From')}
                              placeholder={t('PlaceHolder_Filter_lastname2_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                             <TextField
                              id="lastname2To" 
                              name="lastname2To"
                              value={filtersEmployee.lastname2To}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersEmployee.lastname2To!=null?t(errorsFiltersEmployee.lastname2To):t('Text_Filter_lastname2_To')}
                              placeholder={t('PlaceHolder_Filter_lastname2_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditEmployee}
                              onChange={handleChangeFilterEditEmployee}/>
                            </div>
                            <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                              <div className="form-group">
                                <TextField
                                  id={i18n.language==="es"?"languageFromES":"languageFromEN"}
                                  name={i18n.language==="es"?"languageFromES":"languageFromEN"}
                                  value={i18n.language==="es"?filtersEmployee.languageFromES:filtersEmployee.languageFromEN}
                                  inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={i18n.language==="es"?errorsFiltersEmployee.languageFromES!=null?t(errorsFiltersEmployee.languageFromES):t('Text_Filter_language_From'):errorsFiltersEmployee.languageFromEN!=null?t(errorsFiltersEmployee.languageFromEN):t('Text_Filter_language_From')}
                                  placeholder={t('PlaceHolder_Filter_language_From')}
                                  variant="outlined"
                                  onBlur={handleBlurFilterLanguages}
                                  onChange={handleChangeFilterLanguages}
                                  select
                                  SelectProps={{
                                  native: true,
                                  }}>
                                  <option key={0} value=""/>
                                  {languagesToSelect.map((option) => (
                                  <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                      {i18n.language==="es"?option.namees:option.nameen}
                                  </option>
                                  ))}
                                </TextField>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <TextField
                                    id={i18n.language==="es"?"languageToES":"languageToEN"}
                                    name={i18n.language==="es"?"languageToES":"languageToEN"}
                                    value={i18n.language==="es"?filtersEmployee.languageToES:filtersEmployee.languageToEN}
                                    inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                    label={i18n.language==="es"?errorsFiltersEmployee.languageToES!=null?t(errorsFiltersEmployee.languageToES):t('Text_Filter_language_To'):errorsFiltersEmployee.languageToEN!=null?t(errorsFiltersEmployee.languageToEN):t('Text_Filter_language_To')}
                                    placeholder={t('PlaceHolder_Filter_language_to')}
                                    variant="outlined"
                                    onBlur={handleBlurFilterLanguages}
                                    onChange={handleChangeFilterLanguages}
                                    select
                                    SelectProps={{
                                    native: true,
                                    }}>
                                    <option key={0} value=""/>
                                    {languagesToSelect.filter(item=>(i18n.language==="es"?item.namees>=filtersEmployee.languageFromES:item.nameen>=filtersEmployee.languageFromEN)).map(option => (
                                    <option key={option.id} value={i18n.language==="es"?option.namees:option.nameen}>
                                        {i18n.language==="es"?option.namees:option.nameen}
                                    </option>
                                    ))}
                                </TextField>
                              </div>
                            </Suspense>
                         
                            <div className="form-group">
                              <TextField
                                id="emailsFrom" 
                                name="emailsFrom"
                                value={filtersEmployee.emailsFrom}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errorsFiltersEmployee.emailsFrom!=null?t(errorsFiltersEmployee.emailsFrom):t('Text_Filter_emails_From')}
                                placeholder={t('PlaceHolder_Filter_emails_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditEmployee}
                                onChange={handleChangeFilterEditEmployee}/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <TextField
                                id="emailsTo" 
                                name="emailsTo"
                                value={filtersEmployee.emailsTo}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errorsFiltersEmployee.emailsTo!=null?t(errorsFiltersEmployee.emailsTo):t('Text_Filter_emails_To')}
                                placeholder={t('PlaceHolder_Filter_emails_to')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditEmployee}
                                onChange={handleChangeFilterEditEmployee}/>
                            </div>
                            <div className="form-group">
                                <TextField
                                id="phonesFrom" 
                                name="phonesFrom"
                                value={filtersEmployee.phonesFrom}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 30 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errorsFiltersEmployee.phonesFrom!=null?t(errorsFiltersEmployee.phonesFrom):t('Text_Filter_phones_From')}
                                placeholder={t('PlaceHolder_Filter_phones_From')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditEmployee}
                                onChange={handleChangeFilterEditEmployee}/>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <TextField
                                id="phonesTo" 
                                name="phonesTo"
                                value={filtersEmployee.phonesTo}
                                inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 30 }}
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                label={errorsFiltersEmployee.phonesTo!=null?t(errorsFiltersEmployee.phonesTo):t('Text_Filter_phones_To')}
                                placeholder={t('PlaceHolder_Filter_phones_to')}
                                variant="outlined"
                                onBlur={handleBlurFilterEditEmployee}
                                onChange={handleChangeFilterEditEmployee}/>
                                </div>
                            {i18n.language==="en"?
                             <>
                               <div className="form-group">
                                 <Checkbox
                                    id="enabled" 
                                    name="enabled"
                                    checked={filtersEmployee.enabled}
                                    onChange={handleChangeEnabledFilter}
                                    variant="standard"
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                    }}
                                  />
                                 &nbsp;&nbsp;    
                                 <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Habilty')}</label>  
                                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                 <Checkbox
                                   id="notenabled" 
                                   name="notenabled"
                                   checked={filtersEmployee.notenabled}
                                   onChange={handleChangeNotEnabledFilter}
                                   variant="standard"
                                    sx={{
                                      '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                    }}
                                  />
                                 &nbsp;&nbsp;    
                                 <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Not_Habilty')}</label>    
                               </div>
                             </>  
                           :
                             <>
                               <div className="form-group">  
                               <Checkbox
                                 id="enabled" 
                                 name="enabled"
                                 checked={filtersEmployee.enabled}
                                 onChange={handleChangeEnabledFilter}
                                 variant="standard"
                                  sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                  }}
                                />
                               &nbsp;&nbsp;    
                               <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Habilty')}</label>  
                               &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                               <Checkbox
                                 id="notenabled" 
                                 name="notenabled"
                                 checked={filtersEmployee.notenabled}
                                 onChange={handleChangeNotEnabledFilter}
                                 variant="standard"
                                  sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                                  }}
                                />
                               &nbsp;&nbsp;    
                               <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Not_Habilty')}</label>    
                             </div>
                           </>
                         }
                         </div>
                         <hr className="sidebar-divider d-none d-md-block"/>
                         <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                           <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditEmployee}>{t('Button_Apply')}</button><> </>
                           <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersEmployee}>{t('Button_Cancel_Filters')}</button>
                         </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
             </div>
              }  
              <Dialog open={openDeleteRowEmployee} onClose={handleCancelDeleteRowEmployee}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditEmployee.id}  - {rowEditEmployee.fullname} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowEmployee}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowEmployee}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowEmployee}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <LoadingNews/>
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

EmployeesFilter.propTypes = {
  showModalEmployeeSettings: PropTypes.bool.isRequired,
  setShowModalEmployeeSettings: PropTypes.func.isRequired 
};

export default EmployeesFilter;
