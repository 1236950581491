import React, {  useEffect, useState,useCallback } from 'react'; 
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import TextField from '@mui/material/TextField';
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";   
import PropTypes from 'prop-types'; 
 
const initialButtonsArticleToSelect = [{
  "button_id": 0,
  "indexrowbutton":"",
  "button_namees": "",
  "button_nameen": "",
  "button_article_id":"",
  "button_article_code": "",
  "button_article_namees": "",
  "button_article_nameen": "",
  "button_color": ""
}];

const TicketsCreateButtonArticle = ({openEditRowButtonsArticles,setOpenEditRowButtonsArticles,formFrom,setFormForm}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token);  
  const [newButtonArticle,setNewButtonArticle]= useState(initialButtonsArticleToSelect[0]); 
  const [buttonsToSelect,setButtonsToSelect]= useState(initialButtonsArticleToSelect);
  const [selectedButtonIds,setSelectedButtonIds] = useState([]); 
  const dispatch = useDispatch();
 
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && openEditRowButtonsArticles) {
        setNewButtonArticle(initialButtonsArticleToSelect[0]);
        setButtonsToSelect(initialButtonsArticleToSelect);
        setSelectedButtonIds([]);
        setOpenEditRowButtonsArticles(false); 
      }
    },[
      setNewButtonArticle,  
      setButtonsToSelect,
      setSelectedButtonIds,
      openEditRowButtonsArticles,
      setOpenEditRowButtonsArticles]
  );

  const handleCloseNewArticle = () => {
    setNewButtonArticle(initialButtonsArticleToSelect[0]);
    setButtonsToSelect(initialButtonsArticleToSelect);
    setSelectedButtonIds([]);
    setOpenEditRowButtonsArticles(false); 
  };

  const handleChangeNewArticle = (e) => {
    const { name, value } = e.target;
    setNewButtonArticle(newButtonArticle => ({
      ...newButtonArticle,
      [name]: value,
    }));   
  };
  
  const handleBlurNewArticle = (e) => {
    handleChangeNewArticle(e); 
  };
   

  const toggleDrawerNewArticle  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseNewArticle();
    }
  };
 
  const handleSubmitNewArticle = () => {   
    if (newButtonArticle.button_id!==0 ) {
      if (formFrom.articlesButtonsList.find((item) => {return (Number(item.button_id)=== Number(newButtonArticle.button_id) ) })){
        dispatch(warningErrorAction(t('Text_TicketsFormat_ButtonExist'))); 
      } else{
        let indexToPush=buttonsToSelect.findIndex(((item) => Number(item.button_id) ===  Number(newButtonArticle.button_id)));
        if ( indexToPush !== -1 ) {
          let buttonArticleToAdd={
            "button_id":buttonsToSelect[indexToPush].button_id ,
            "indexrowbutton":formFrom.articlesButtonsList.length>0?formFrom.articlesButtonsList[formFrom.articlesButtonsList.length-1].indexrowbutton+1:1,  
            "button_namees":buttonsToSelect[indexToPush].button_namees,
            "button_nameen":buttonsToSelect[indexToPush].button_nameen,
            "button_article_id":buttonsToSelect[indexToPush].button_article_id,
            "button_article_code":buttonsToSelect[indexToPush].button_article_code,
            "button_article_namees":buttonsToSelect[indexToPush].button_article_namees,
            "button_article_nameen":buttonsToSelect[indexToPush].button_article_nameen ,
            "button_color": buttonsToSelect[indexToPush].button_color
          }
          formFrom.articlesButtonsList.push(buttonArticleToAdd);
          setFormForm(formFrom => ({
            ...formFrom,
          }))
        }
       
        handleCloseNewArticle();
      }
    } else {
      dispatch(warningErrorAction(t('Text_TicketsFormat_Button_Required'))); 
    }  
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchDataButtons = async () => {
      try {
        const getData = i18n.language==="es"
            ?await helpHttp().get(ENDPOINT, `ticketsformat/getticketsformatbuttonorderbynamees`, token)
            :await helpHttp().get(ENDPOINT, `ticketsformat/getticketsformatbuttonorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setButtonsToSelect(getData);
          setSelectedButtonIds(formFrom.articlesButtonsList.map(button => button.button_id));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (openEditRowButtonsArticles ){
      fetchDataButtons(); 
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,openEditRowButtonsArticles,formFrom.articlesButtonsList]);

  return(
    <>
      {openEditRowButtonsArticles && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerNewArticle(false)}
            onOpen={toggleDrawerNewArticle(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '800px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle px-4 mb-2">{t('Text_ArticlesButton_Table_SELECT')}</h1>
                </div>  
                <hr className="sidebar-divider d-none d-md-block"/>
                <form className="createGroupSection" onSubmit={handleCloseNewArticle}>  
                  <div className="form-floating mb-3 px-4 primary">
                    <label className="text-primary">{t('Text_ArticlesButton_Table_SELECT')} :</label>
                    <div className="form-group">
                      <TextField
                        id="button_id"
                        name="button_id"
                        value={ (newButtonArticle.button_id === undefined || newButtonArticle.button_id ===null || newButtonArticle.button_id ===""
                              || newButtonArticle.button_id ==="0" || newButtonArticle.button_id ===0)?0:newButtonArticle.button_id}
                        inputProps={{ style: {width: 700,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleBlurNewArticle}
                        onChange={handleChangeNewArticle}
                        select
                        SelectProps={{
                            native: true,
                            value: (newButtonArticle.button_id === undefined || newButtonArticle.button_id ===null || newButtonArticle.button_id ===""
                                || newButtonArticle.button_id ==="0" || newButtonArticle.button_id ===0)?0: newButtonArticle.button_id
                            }}>
                            <option key={0} value=""/>
                            {buttonsToSelect
                              .filter(item => !selectedButtonIds.includes(item.button_id))
                              .map((option) => (
                                <option key={option.button_id} value={option.button_id}>
                                  {i18n.language === 'es'
                                    ? (option.button_namees + " - " + option.button_article_code + " - " + option.button_article_namees).substring(0, 90)
                                    : (option.button_nameen + " - " + option.button_article_code + " - " + option.button_article_nameen).substring(0, 90)}
                                </option>
                          ))}
                      </TextField>  
                        
                      
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                      <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitNewArticle}>{t('Button_Select')}</button><> </>
                      <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleCloseNewArticle}>{t('Button_Cancel')}</button>
                    </div>
                  </div>
                </form>
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

TicketsCreateButtonArticle.propTypes = {
  openEditRowButtonsArticles: PropTypes.bool.isRequired,
  setOpenEditRowButtonsArticles: PropTypes.func.isRequired,  
  formFrom: PropTypes.object.isRequired,
  setFormForm: PropTypes.func.isRequired, 
};

export default TicketsCreateButtonArticle;