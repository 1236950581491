import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportPurchseSignature from 'pages/purchaseSignature/generatePDF/ReportPurchaseSignature'; 
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const initialStatePurchaseSignature = {
  id : "",
  defaultStore_id:0, 
  tradename: "",
  useraccount_id:0,
  username: "",
  defaultformatnumber:1,
  isfinished:false, 
  purchasenote:0,
  numberticket:0, 
  ownnumberpurchase:"", 
  datepurchase: "",

  vehiclestore_id: 0,
  vehiclestore_licenseplate: "",
  vehiclestore_defaultdriver: "",
  vehiclestore_store_id: 0,
 
  rel_trans_store_id: 0,
  rel_trans_documnettype_id: 0,
  rel_trans_documentid: "",
  rel_trans_documnettype_name: "",
  rel_trans_tradename: "",
  rel_trans_name: "",
  rel_trans_contacperson: "",
  rel_trans_roadtype_id: 0,
  rel_trans_street: "",
  rel_trans_country_id: 1,
  rel_trans_province_id: 30,
  rel_trans_postalcode_id: 0,

  ori_tras_store_id: 0,
  ori_tras_documnettype_id: 0,
  ori_tras_documnettype_name: "",
  ori_tras_documentid: "",
  ori_tras_tradename: "",
  ori_tras_name: "",
  ori_tras_contacperson: "",
  ori_tras_roadtype_id: 0,
  ori_tras_street: "",
  ori_tras_country_id: 1,
  ori_tras_province_id: 30,
  ori_tras_postalcode_id: 0,

  observations: "",
  totalpurchasedelivery: 0,
  totaldiscountpurchasedelivery: 0,
  totaltaxespurchasedelivery:0,
  totalNetoPurchasedelivery:0,
  enabled:true,
  subownnumberpurchase:0,
  payment_id:0, 
  payment_code: "",
  payment_namees: "",
  payment_nameen: "", 
  
  totalWeigthNeto:0,
  signature_dispatched_by:"",
  signature_client:"",
  linespurchasedelivery:[]
};
 
const PurchaseSignaturePDF = ({ rowEditPurchaseSignature, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token); 
  const [dataPurchaseSignature, setDataPurchaseSignature] = useState(initialStatePurchaseSignature);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataTickets = async () => {
     
      try {
        const getData = await helpHttp().get2(ENDPOINT, `purchasesignature/getpurchasesignaturebyid/`, token, rowEditPurchaseSignature.id);
        if (getData.err) {
          setDataPurchaseSignature(initialStatePurchaseSignature);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataPurchaseSignature(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditPurchaseSignature!==undefined && rowEditPurchaseSignature.id!==undefined  && rowEditPurchaseSignature.id!==null  && rowEditPurchaseSignature.id!=="" && rowEditPurchaseSignature.id!=="0" && rowEditPurchaseSignature.id!==0
    ) {
      fetchDataTickets();
    }
    
    return () => {isMounted = false;};
  }, [rowEditPurchaseSignature, dispatch, t, token,i18n.language]);  
 
  return (
    <div id="pdf-content" style={{ padding: 10 }}>
      {(viewPDF && dataPurchaseSignature.id!==undefined  && dataPurchaseSignature.id!==null  && dataPurchaseSignature.id!=="" && dataPurchaseSignature.id!=="0" && dataPurchaseSignature.id!==0 
      ) &&  <ReportPurchseSignature dataPurchaseSignature={dataPurchaseSignature}  initialStatePurchaseSignature={initialStatePurchaseSignature} setDataPurchaseSignature={setDataPurchaseSignature} setViewPDF={setViewPDF}/> }
    </div>
  );
};


PurchaseSignaturePDF.propTypes = {
  rowEditPurchaseSignature: PropTypes.object.isRequired,
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default PurchaseSignaturePDF;
