import { AESUtil } from "components/utils/AESUTIL";

/**
 * Servicio de login
 * @param {string} ENDPOINT - El endpoint del servidor
 * @param {string} username - El nombre de usuario
 * @param {string} password - La contraseña
 * @returns {Promise<Response>} - La respuesta de la petición fetch
 */
export default function loginService(ENDPOINT, username, password) {
  return fetch(`${ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({ username, password })
  });
}

/**
 * Servicio de login con cifrado AES
 * @param {string} ENDPOINT - El endpoint del servidor
 * @param {string} username - El nombre de usuario
 * @param {string} password - La contraseña
 * @returns {Promise<Response>} - La respuesta de la petición fetch
 */
export function loginServiceEncrypted(ENDPOINT, username, password) {
  const encrypted = AESUtil.encrypt(`${username}//${password}`);
  return fetch(`${ENDPOINT}/auth/loginhead/${encrypted}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  });
}
 
export function getAllUserService(ENDPOINT, username, password) {
  return fetch(`${ENDPOINT}/auth/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': ''
    },
    body: JSON.stringify({ username, password })
  });
}
