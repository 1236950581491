import React, {  useEffect, useCallback, useState } from 'react';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux"; 
import { NON_ERASABLE_PROVINCE_ID, ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box';   
import Button from '@mui/material/Button'; 
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css"; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import TableSortingProvince from './SortingTablesProvince';
import  PostalCodeSettings from "./PostalCodeSettings";
import PropTypes from 'prop-types';

const initialFiltersProvince= {
  "idFrom":"",
  "idTo":"",
  "codeFrom":"",
  "codeTo":"",
  "nameesFrom":"",
  "nameesTo":"",
  "nameenFrom":"",
  "nameenTo":"",
  "codeHexFrom":"",
  "codeHexTo":"",
  "codeCotGroupFrom":"",
	"codeCotGroupTo":"",
  "code_iso2From":"",
  "code_iso2To":"",
  "code_iso3From":"",
  "code_iso3To":"",
  "extphoneFrom":"",
  "extphoneTo":"",
  "extinternetFrom":"",
  "extinternetTo":"",
  "isEnabled":true,
  "isNotEnabled":true
};

const initialStateProvince = [{
  "id" : "",
  "name" : "",
  "enabled":false,
  "numTotPostalCode":0,
  "postalcodes":[]
}];

const headCellsProvince = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Text_Code',
    canOrder:true,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Text_Name',
    canOrder:true,
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'Text_Enabled',
    canOrder:false,
  },
  {
    id: 'postalcodes',
    numeric: false,
    disablePadding: false,
    label: 'Text_Type_Postal_Code',
    canOrder:false,
  }
];

const ProvinceSettings = ({ openCreateProvince,setOpenCreateProvince,rowEditCountry}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const [dataProvince, setDataProvince] = useState(initialStateProvince);
  const [formProvince, setFormProvince] = useState(initialStateProvince[0]);
  const [filtersProvince, setFiltersProvince]= useState(initialFiltersProvince);
  const [applyFiltersProvince, setApplyFiltersProvince]= useState(initialFiltersProvince);
  const [numTotRegProvince,setNumTotRegProvince]= useState(0);
  const [numTotRegProvinceByCountry,setNumTotRegProvinceByCountry]= useState(0);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [errorsFiltersProvince, setErrorsFiltersProvince] = useState({});
  const [errorsProvince, setErrorsProvince] = useState({});
  const [inputTextProvince, setInputTextProvince] = useState("");
  const [openCreateDataProvince,setOpenCreateDataProvince]= useState(false);
  const [openDeleteRowProvince,setOpenDeleteRowProvince]= useState(false);
  const [openEditRowProvince,setOpenEditRowProvince]= useState(false);
  const [openEditFilterProvince,setOpenEditFilterProvince]= useState(false);
  const [rowEditProvince,setRowEditProvince]= useState(initialStateProvince[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorProvince, setIsLoadingErrorProvince] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const dispatch = useDispatch();
  const [openPostalCode,setOpenPotalCode]= useState(false);
  const [activeOffset, setActiveOffset]= useState(false);
  const [currencyOffset, setCurrencyOffset] = useState(1);
  const [rangeOffset, setRangeOffset] = useState([{}]);
  const [refreshData, setRefreshData]= useState(false);
  
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && openCreateProvince) {
        setDataProvince(initialStateProvince);
        setFormProvince(initialStateProvince[0]);
        setFiltersProvince(initialFiltersProvince);
        setApplyFiltersProvince(initialFiltersProvince);
        setNumTotRegProvince(0);
        setNumTotRegProvinceByCountry(0);
        setNumTotRegFilter(0);
        setErrorsFiltersProvince({});
        setErrorsProvince({});
        setInputTextProvince("");
        setOpenCreateDataProvince(false);
        setOpenDeleteRowProvince(false);
        setOpenEditRowProvince(false);
        setOpenEditFilterProvince(false);
        setRowEditProvince(initialStateProvince[0]);
        dispatch(setLoading(false));
        setIsLoadingErrorProvince(false);
        setIdCodeDelete(DELETE_CODE);
        setOpenPotalCode(false);
        setActiveOffset(false);
        setCurrencyOffset(0);
        setRangeOffset([{}]);
        setRefreshData(false); 
        setOpenCreateProvince(false); 
      }
    },
    [
      setDataProvince,
      setFormProvince,
      setFiltersProvince,
      setApplyFiltersProvince,
      setNumTotRegProvince,
      setNumTotRegProvinceByCountry,
      setNumTotRegFilter,
      setErrorsFiltersProvince,
      setErrorsProvince,
      setInputTextProvince,
      setOpenCreateDataProvince,
      setOpenDeleteRowProvince,
      setOpenEditRowProvince,
      setOpenEditFilterProvince,
      setRowEditProvince, 
      setIsLoadingErrorProvince,
      setIdCodeDelete,
      setOpenPotalCode,
      setActiveOffset,
      setCurrencyOffset,
      setRangeOffset,
      setRefreshData,
      openCreateProvince,
      setOpenCreateProvince,
      dispatch,
      DELETE_CODE
    ]
  );

  const closeModalProvinceSettings = () => {
    setDataProvince(initialStateProvince);
    setFormProvince(initialStateProvince[0]);
    setFiltersProvince(initialFiltersProvince);
    setApplyFiltersProvince(initialFiltersProvince);
    setNumTotRegProvince(0);
    setNumTotRegProvinceByCountry(0);
    setNumTotRegFilter(0);
    setErrorsFiltersProvince({});
    setErrorsProvince({});
    setInputTextProvince("");
    setOpenCreateDataProvince(false);
    setOpenDeleteRowProvince(false);
    setOpenEditRowProvince(false);
    setOpenEditFilterProvince(false);
    setRowEditProvince(initialStateProvince[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorProvince(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenPotalCode(false);
    setActiveOffset(false);
    setCurrencyOffset(0);
    setRangeOffset([{}]);
    setRefreshData(false); 
    setOpenCreateProvince(false); 
  };

  const openCreateOrEditDataProvince  = () => {
    setInputTextProvince("");
    setOpenCreateDataProvince(true);
  };

  const openEditFiltersProvince = () => {
    setFiltersProvince(applyFiltersProvince);
    setOpenEditFilterProvince(!openEditFilterProvince);
  };

  const handleSubmitFilterEditProvince = () => {
    setErrorsFiltersProvince(validateFormFilterProvince());
    if (Object.keys(errorsFiltersProvince).length === 0) {
      setApplyFiltersProvince(filtersProvince);
      setFiltersProvince(initialFiltersProvince);
      setErrorsFiltersProvince({});
      setOpenEditFilterProvince(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersProvince = () => {
    setErrorsFiltersProvince({});
    setFiltersProvince(initialFiltersProvince);
    setApplyFiltersProvince(initialFiltersProvince);
    setOpenEditFilterProvince(!openEditFilterProvince);
  };

  const handleCancelFiltersProvince = () => {
    setErrorsFiltersProvince({});
    setFiltersProvince(initialFiltersProvince);
    setOpenEditFilterProvince(!openEditFilterProvince);
  };

  const handleChangeEnabledFilterProvince = (e) => {
    setFiltersProvince(filtersProvince => ({
      ...filtersProvince,
      isEnabled: !filtersProvince.isEnabled
    }));
  };

  const handleChangeNotEnabledFilterProvince = (e) => {
    setFiltersProvince(filtersProvince => ({
      ...filtersProvince,
      isNotEnabled: !filtersProvince.isNotEnabled
    }));
  };

  const handleChangeFilterEditProvince = (e) => {
    const { name, value } = e.target;
    setFiltersProvince(filtersProvince => ({
      ...filtersProvince,
      [name]: value
    }));
  };

  const handleBlurFilterEditProvince = (e) => {
    setErrorsFiltersProvince(validateFormFilterProvince());
    handleChangeFilterEditProvince(e);
  };

  const toggleDrawerFilterEditProvince = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      setErrorsFiltersProvince({});
      setFiltersProvince(initialFiltersProvince);
      setOpenEditFilterProvince(!openEditFilterProvince);
    }
  };
  
  const validateFormFilterProvince = () => {

    let errorsFiltersProvince = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersProvince.idFrom!=="" & !regexNumber.test(filtersProvince.idFrom)) {
      errorsFiltersProvince.idFrom = 'Text_TextNoNumber';
    }
    if (filtersProvince.idTo!=="" & !regexNumber.test(filtersProvince.idTo)) {
      errorsFiltersProvince.idTo = 'Text_TextNoNumber';
    }
    if (filtersProvince.idFrom!=="" & !errorsFiltersProvince.idFrom!==undefined){
      if (filtersProvince.idTo!=="" & !errorsFiltersProvince.idTo!==undefined){
        if (parseInt(filtersProvince.idTo)<parseInt(filtersProvince.idFrom)){
          errorsFiltersProvince.idTo = 'Text_TextGreater_Id';
        }
      }
    }
    if (regexInvalidCharacters.test(filtersProvince.nameesFrom)) {
      errorsFiltersProvince.nameesFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersProvince.nameesTo)) {
      errorsFiltersProvince.nameesTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersProvince.nameenFrom)) {
      errorsFiltersProvince.nameenFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersProvince.nameenTo)) {
      errorsFiltersProvince.nameenTo = 'Text_TextNoValid';
    } 
    if (filtersProvince.nameesTo!=="" & filtersProvince.nameesTo<filtersProvince.nameesFrom){
      errorsFiltersProvince.nameesTo = 'Text_TextGreater_Namees';
    }
    if (filtersProvince.nameenTo!=="" & filtersProvince.nameenTo<filtersProvince.nameenFrom){
      errorsFiltersProvince.nameenTo = 'Text_TextGreater_Nameen';
    }
    return errorsFiltersProvince;
  }  

  const filteredDataProvince = dataProvince.filter((el) => {
    if (inputTextProvince === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +   
        String(el.name || '');
      let result = dataTranslate.toLowerCase().includes(inputTextProvince.toLowerCase());
      return result;
    }
  })

  let inputHandlerProvince = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextProvince(lowerCase);
  };

  const handleChangeDeleteRowProvince = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowProvince = () => {
    setErrorsProvince({});
    setRowEditProvince(initialStateProvince[0]);
    setFormProvince(initialStateProvince[0]);
    dispatch(setLoading(false)); 
    setIsLoadingErrorProvince(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowProvince(false);
  };

  const handleCloseDeleteRowProvince = (e) => {
    if (idCodeDelete !== DELETE_CODE) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
  
    if (e.key !== 'Escape') {
      if (rowEditProvince.id !== '') {
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT, `province/deleteprovince`, token, rowEditProvince);
            if (getData.status === "OK") {
              dispatch(successErrorAction(t('Text_ValidCode')));
              dispatch(successErrorAction(t('Text_DeleteField')));
              setRefreshData(true);
            } else {
              dispatch(warningErrorAction(t(getData.cause)));
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }  
        };
        fetchData();
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
  
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowProvince(false);
    handleCloseDataEditProvince();
  };
  
  const handleSubmitDataEditProvince = (e) => {
    setErrorsProvince(validateFormProvince());
    const errorsToCheckProvince = validateFormProvince();
  
    if (Object.keys(errorsToCheckProvince).length === 0 && formProvince.name !== '') {
      const fetchData = async () => { 
        try {
          const getData = openEditRowProvince
            ? await helpHttp().put2(ENDPOINT, `province/updateprovince`, token, rowEditCountry.id, formProvince)
            : await helpHttp().post2(ENDPOINT, `province/saveprovince`, token, rowEditCountry.id, formProvince);
  
          if (getData.status === "OK") {
            dispatch(successErrorAction(t('Text_SaveData')));
            setRefreshData(true);
            handleCloseDataEditProvince();
          } else {
            dispatch(warningErrorAction(t(getData.cause)));
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData')));  
        }
      };
      fetchData();
    } else {
      if (errorsToCheckProvince.name) {
        dispatch(warningErrorAction(t(errorsToCheckProvince.name)));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };
 
  const handleCloseDataEditProvince  = () => {
    setOpenCreateDataProvince(false);
    setOpenEditRowProvince(false);
    setErrorsProvince({});
    setRefreshData(false);
    setRowEditProvince(openEditRowProvince?rowEditProvince:initialStateProvince[0]);
    setFormProvince(initialStateProvince[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorProvince(false);
    toggleDrawerProvince(false);
	};

  const handleChangeDataEditProvince  = (e) => {
    const { name, value } = e.target;
    setFormProvince(formProvince => ({
      ...formProvince,
      [name]: value
    }));
  };

  const handleBlurDataEditProvince  = (e) => {
    handleChangeDataEditProvince(e);
    setErrorsProvince(validateFormProvince());
  };

  const handleChangeEnabledProvince = (e) => {
    setFormProvince(formProvince => ({
      ...formProvince,
      enabled: !formProvince.enabled
    }));
  };
  
  const toggleDrawerDataEditProvince  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditProvince();
    }  
  };

  const toggleDrawerProvince = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown' ) {
      closeModalProvinceSettings();
      return;
    }
  };

  const validateFormProvince = () => {
    let errorsProvince = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formProvince.name || formProvince.name==="") {
      errorsProvince.name = 'Text_FieldNameRequired';
    } else {
      if (regexInvalidCharacters.test(formProvince.name)) {
        errorsProvince.name = 'Text_TextNoValid';
      } else{
        if (openEditRowProvince){
          if (dataProvince.find((key=>(key.name.toLowerCase()===formProvince.name.toLowerCase() && key.id!==rowEditProvince.id)))) {
            errorsProvince.name = 'Text_Province_NameExist';
          }
        } else {
          if (dataProvince.find((key=>key.name.toLowerCase()===formProvince.name.toLowerCase()))) {
            errorsProvince.name = 'Text_Province_NameExist';
          }
        }     
      }  
    }
    if (!formProvince.enabled || formProvince.enabled==="") {
      formProvince.enabled=false;
    }
    return errorsProvince;
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (openEditRowProvince){
      setFormProvince(form => ({
        ...form,
        id : rowEditProvince.id!==null?rowEditProvince.id:"",
        name : rowEditProvince.name!==null?rowEditProvince.name:"",
        enabled :rowEditProvince.enabled!==null?rowEditProvince.enabled:false,
        numTotPostalCode :rowEditProvince.numTotPostalCode!==null?rowEditProvince.numTotPostalCode:0,
        postalcodes :rowEditProvince.postalcodes!==null?rowEditProvince.postalcodes:[]
      }));
    }else{
      setFormProvince(initialStateProvince[0]);
    }
  },[rowEditProvince,openEditRowProvince]);

  useEffect(() => {
    if (numTotRegFilter > LIMITPAGESIZE) {
      setActiveOffset(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegFilter; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffset(newRangeOffset);
    } else {
      setCurrencyOffset(0);
      setActiveOffset(false);
      setRangeOffset([{}]);
    }
  }, [numTotRegFilter, LIMITPAGESIZE]);

  useEffect(() => {
    let ignore = false;
  
    if (!rowEditCountry?.id) return;
  
    const fetchnumTotReg = async () => { 
      try {
        const getData = await helpHttp().get(ENDPOINT, `province/getinformationtableprovince`, token);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorProvince(true);
          setDataProvince(initialStateProvince);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegProvince(getData);
        }
      } catch (error) {
        setDataProvince(initialStateProvince);
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
  
    const fetchnumTotRegByCountry = async () => { 
      try {
        const getData = await helpHttp().get2(ENDPOINT, `province/getinformationtableprovincebycountry/`, token, rowEditCountry.id);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorProvince(true);
          setDataProvince(initialStateProvince);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegProvinceByCountry(getData);
        }
      } catch (error) {
        setDataProvince(initialStateProvince);
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
  
    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = await helpHttp().post2(ENDPOINT, `province/getcountfilterprovinces`, token, rowEditCountry.id, applyFiltersProvince);
        if (getData.err || getData.message === "Load failed") {
          setDataProvince(initialStateProvince);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        setDataProvince(initialStateProvince);
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
  
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await helpHttp().get4(ENDPOINT, `province/searchpostalcodesprovinces`, token, rowEditCountry.id, currencyOffset, LIMITPAGESIZE, applyFiltersProvince);
        if (getData.err || getData.message === "Load failed") {
          setIsLoadingErrorProvince(true);
          setDataProvince(initialStateProvince);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (!ignore) {
          setDataProvince(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        setDataProvince(initialStateProvince);
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
  
    if (openCreateProvince || refreshData) {
      fetchnumTotReg();
      fetchnumTotRegByCountry();
      fetchnumTotRegFilter();
      setRefreshData(false);
      fetchData();
      return () => { ignore = true };
    }
  }, [
     t, token, dispatch, refreshData, openCreateDataProvince, openDeleteRowProvince,
    openEditRowProvince, rowEditProvince, openCreateProvince, rowEditCountry, openPostalCode,
    LIMITPAGESIZE, applyFiltersProvince, currencyOffset
  ]);
  
  return (
    <div> 
      {openCreateProvince && 
       <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerProvince(false)}
            onOpen={toggleDrawerProvince(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1200px' }}>
              <br/>
              <div id="myDIV">
                <h1 className="h1NewStyle mb-2">
                {isLoadingErrorProvince?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowProvince?"warning":"primary"}/>:<i className="fas fa-solid fa-flag text-warning"></i>} {t('Text_Province_Config')}</h1>
                <div>
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Province_Create'))?
                <div>
                  <button type="button" className="buttonCreate" onClick={openCreateOrEditDataProvince}>{t('Button_Create')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalProvinceSettings}>{t('Button_Back')}</button>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalProvinceSettings}>{t('Button_Back')}</button>
                </div>}
                </div>
              </div>
              <div id="myDIV">  
                <h1 className="h1NewStyle mb-2">
                  {i18n.language==="es"?rowEditCountry.namees:rowEditCountry.nameen}
                </h1>  
              </div>  
              <br/>
              <div className="searchStyle">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  value={inputTextProvince}
                  inputProps={{ style: {fontSize: '18px'} }}
                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                  placeholder={t('Text_Province_Search')}
                  onChange={inputHandlerProvince}
                  fullWidth
                  label={t('Text_Province_Search')}>
                </TextField>
              </div>
              <br/>
              <div id="myDIV">
                <h1 className="h6NewStyleHigh mb-2">
                {t('Text_Filter')}&nbsp;&nbsp;
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersProvince}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersProvince.idFrom!=="" || applyFiltersProvince.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersProvince}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersProvince.nameesFrom!=="" || applyFiltersProvince.nameesTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Name')}</button><> </>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersProvince}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersProvince.isEnabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Enabled')}</button><> </>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersProvince}><i className="fas fa-solid fa-circle" style={{color:(applyFiltersProvince.isNotEnabled)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Not_Enabled')}</button><> </>
                  </h1>
              </div>
              <br/>  
              <hr className="sidebar-divider d-none d-md-block"/>
              <div className="card shadow mb-4">    
              <div className="card-body">
                <TableSortingProvince
                  dataProvince={filteredDataProvince}
                  headCellsProvince={headCellsProvince}
                  numTotRegFilter={numTotRegFilter}
                  titleTable={t('Text_Province_Table')}
                  setOpenCreateDataProvince={setOpenCreateDataProvince}
                  setOpenDeleteRowProvince={setOpenDeleteRowProvince}
                  setOpenEditRowProvince={setOpenEditRowProvince}
                  setRowEditProvince={setRowEditProvince}
                  showEditButtonProvince={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Province_Edit'))?true:false}
                  showDeleteButtonProvince={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Province_Delete'))?true:false}
                  showVisibilityButton={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_PostalCodes'))?true:false}
                  setOpenPotalCode={setOpenPotalCode}
                  numTotRegProvince={numTotRegProvince}
                  numTotRegProvinceByCountry={numTotRegProvinceByCountry}
                  activeOffset={activeOffset}
                  rangeOffset={rangeOffset}
                  currencyOffset={currencyOffset}
                  setCurrencyOffset={setCurrencyOffset}
                  minvalue={Number(NON_ERASABLE_PROVINCE_ID)}>
                </TableSortingProvince>
              </div>             
            </div>
            <PostalCodeSettings
              openPostalCode={openPostalCode} 
              setOpenPotalCode={setOpenPotalCode} 
              rowEditProvince={rowEditProvince} 
              setRowEditProvince={setRowEditProvince}
            />    
            </Box>
            </Container>
          </SwipeableDrawer>
          {openEditFilterProvince &&
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerFilterEditProvince(false)}
                onOpen={toggleDrawerFilterEditProvince(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                    </div>
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createfilter" onSubmit={handleCancelFiltersProvince}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Code_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idFrom" 
                            name="idFrom"
                            value={filtersProvince.idFrom}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 15 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                            label={errorsFiltersProvince.idFrom!=null?t(errorsFiltersProvince.idFrom):t('PlaceHolder_Filter_Code_From')}
                            placeholder={t('PlaceHolder_Filter_Code_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditProvince}
                            onChange={handleChangeFilterEditProvince}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Code_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="idTo" 
                            name="idTo"
                            value={filtersProvince.idTo}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 15 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsFiltersProvince.idTo!=null?t(errorsFiltersProvince.idTo):t('PlaceHolder_Filter_Code_to')}
                            placeholder={t('PlaceHolder_Filter_Code_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditProvince}
                            onChange={handleChangeFilterEditProvince}/>
                        </div>

                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Name_From')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesFrom" 
                            name="nameesFrom"
                            value={filtersProvince.nameesFrom}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsFiltersProvince.nameesFrom!=null?t(errorsFiltersProvince.nameesFrom):t('PlaceHolder_Filter_Name_From')}
                            placeholder={t('PlaceHolder_Filter_Name_From')}
                            variant="standard"
                            onBlur={handleBlurFilterEditProvince}
                            onChange={handleChangeFilterEditProvince}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Filter_Name_To')} :</label>
                        <div className="form-group">
                          <TextField
                            id="nameesTo" 
                            name="nameesTo"
                            value={filtersProvince.nameesTo}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsFiltersProvince.nameesTo!=null?t(errorsFiltersProvince.nameesTo):t('PlaceHolder_Filter_Name_to')}
                            placeholder={t('PlaceHolder_Filter_Name_to')}
                            variant="standard"
                            onBlur={handleBlurFilterEditProvince}
                            onChange={handleChangeFilterEditProvince}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isEnabled" 
                            name="isEnabled"
                            checked={filtersProvince.isEnabled}
                            onChange={handleChangeEnabledFilterProvince}
                            variant="standard"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                            }}
                          /> 
                        </div> 
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Not_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="isNotEnabled" 
                            name="isNotEnabled"
                            checked={filtersProvince.isNotEnabled}
                            onChange={handleChangeNotEnabledFilterProvince}
                            variant="standard"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                            }}
                          />
                        </div> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditProvince}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersProvince}>{t('Button_Cancel_Filters')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }  

          {(openCreateDataProvince || openEditRowProvince) && 
            <div>
              <SwipeableDrawer
                anchor='right'
                open={true}
                onClose={toggleDrawerDataEditProvince(false)}
                onOpen={toggleDrawerDataEditProvince(true)}>
                <Container >
                  <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '500px' }}>
                    <br/>
                    <div id="myDIV">
                      <h1 className="h1NewStyle px-4 mb-2">{openEditRowProvince?t('Text_Province_Edit'):t('Text_Province_Create')}</h1>
                    </div>  
                    <hr className="sidebar-divider d-none d-md-block"/>
                    <form className="createcountry" onSubmit={handleSubmitDataEditProvince}>  
                      <div className="form-floating mb-3 px-4 primary">
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Code')} :</label>
                        <div className="form-group">
                          <TextField
                            id="id" 
                            name="id"
                            value={formProvince.id}
                            inputProps={{ style: {width: 200,  fontSize: '18px', }, maxLength: 15 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={t('PlaceHolder_Province_Code')}
                            placeholder={t('PlaceHolder_Province_Code')}
                            variant="standard"
                            disabled/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Name')} :</label>
                        <div className="form-group">
                          <TextField
                            id="name" 
                            name="name"
                            value={formProvince.name}
                            inputProps={{ style: {width: 450,  fontSize: '18px', }, maxLength: 100 }}
                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                            label={errorsProvince.name!=null?t(errorsProvince.name):t('PlaceHolder_Province_Name')}
                            placeholder={t('PlaceHolder_Province_Name')}
                            variant="standard"
                            onBlur={handleBlurDataEditProvince}
                            onChange={handleChangeDataEditProvince}/>
                        </div>
                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_Enabled')} :</label>
                        <div className="form-group">
                          <Checkbox
                            id="enabled" 
                            name="enabled"
                            checked={formProvince.enabled}
                            onChange={handleChangeEnabledProvince}
                            variant="standard"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: 28 }, // Ajusta el tamaño del ícono
                            }}
                          />
                        </div>
                        <br/> 
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitDataEditProvince}>{openEditRowProvince?t('Button_Update'):t('Button_Create')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleCloseDataEditProvince}>{t('Button_Cancel')}</button>
                        </div>
                      </div>
                    </form>
                  </Box>
                </Container>
              </SwipeableDrawer>
            </div>
          }
          <Dialog open={openDeleteRowProvince} onClose={handleCancelDeleteRowProvince}>
            <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditProvince.id}  - {rowEditProvince.name} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="idCode"
                    label={t('Text_Label')}
                    type="text"
                    fullWidth
                    variant="standard"
                    error
                    defaultValue={idCodeDelete}
                    onChange={handleChangeDeleteRowProvince}/>
              </DialogContent>
            <DialogActions>
              <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowProvince}>{t('Button_Cancel')}</Button>
              <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowProvince}>{t('Button_Delete')}</Button>
            </DialogActions>
          </Dialog>
          </div>
      } 
    </div>
  )
}

ProvinceSettings.propTypes = {
  openCreateProvince: PropTypes.bool.isRequired,
  setOpenCreateProvince: PropTypes.func.isRequired,
  rowEditCountry: PropTypes.object.isRequired
};
  
export default ProvinceSettings;

