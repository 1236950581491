import React, {  useEffect, useState, useCallback, useRef } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" 
import { useNavigate } from 'react-router-dom'; 
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';  
import CardContent from '@mui/material/CardContent'; 
import CircularProgress from '@mui/material/CircularProgress'; 
import Grid  from '@mui/material/Grid';
import SignatureCanvas from 'react-signature-canvas';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LoadingNews } from "components/utils/LoadingNews"; 
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { es } from 'date-fns/locale';
import { helpHttp } from "service/HelpHttp"; 
import { NumericFormat } from 'react-number-format';
import { PDFDocument,rgb } from "pdf-lib"; 
import { setLoading } from 'reducers/loadingSlice';
import { setReloadRefreshData } from 'reducers/loadingSlice'; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css"; 
import signFrancisco from "components/photos/signs/Francisco.png"; 
import signGonzalo from "components/photos/signs/Gonzalo.png"; 
import signGuillermo from "components/photos/signs/Guillermo.png"; 
import signJorge from "components/photos/signs/Jorge.png"; 

const initialStateForm = {
  "id" : "",
  "ownformdi_id" : "", 
  "defaultStore_id":0, 
  "useraccount_id":0, 
  "defaultformatnumber":1,
  "isFinished":false,
  "isScanned":false,
  "hasbeenfinishedscanned":false,
  "numberDI_sheet" : "", 
  "number_dt":"",
  "date_sheet":"", 
  "amount" : 0,   
  "deliver_date":"",
  "delivery_quantity":0,
  "accept":"Text_NOT_REPORTING",
  "date_accepted":"",
  "observations":"",
  "date_refund":"",
  "reason_refund":"",
  "action_refund":"",
  "signature_refund":"" ,
  
  "typeofconvertpdf" : 0,
  "xcoord_amount" : 0,
  "ycoord_amount" : 0,
  "xcoord_deliver_date" : 0,
  "ycoord_deliver_date" : 0,
  "xcoord_delivery_quantity" : 0,
  "ycoord_delivery_quantity" : 0,
  "xcoord_date_accepted" : 0,
  "ycoord_date_accepted" : 0,
  "xcoord_accept" : 0,
  "ycoord_accept" : 0,
  "xcoord_date_refund" : 0,
  "ycoord_date_refund" : 0,
  "xcoord_reason_refund" : 0,
  "ycoord_reason_refund" : 0,
  "xcoord_action_refund" : 0,
  "ycoord_action_refund" : 0,
  "xcoord_observations" : 0,
  "ycoord_observations" : 0, 
  "xcoordsignaturerefund" : 0,
  "ycoordsignaturerefund" : 0,
  "pdfUrl":""
 
};

const textAccepted=[
  {"id":1,"name":"Text_NOT_REPORTING"},
  {"id":2,"name":"Text_FORM_IsAccept"},
  {"id":3,"name":"Text_FORM_IsNotAccept"}];

export const FormEdiFormDISignatureCreate = ({id, showModalFormDISignature,typeOfList}) => {
  const { t, i18n } = useTranslation(['listDataForms']);
  const token = useSelector((state) => state.loginUser.token);
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const reason_refund_view = useSelector((state) => state.formatDIUser.reason_refund_view);
  const action_refund_view = useSelector((state) => state.formatDIUser.action_refund_view);
  const date_refund_view = useSelector((state) => state.formatDIUser.date_refund_view);
  const date_refund_handle = useSelector((state) => state.formatDIUser.date_refund_handle);
  const reason_refund_handle = useSelector((state) => state.formatDIUser.reason_refund_handle);
  const action_refund_handle = useSelector((state) => state.formatDIUser.action_refund_handle);
  const isLoadingData = useSelector((state) => state.loading.isLoading);   
  const [dataFormDISignature, setDataFormDISignature] = useState(initialStateForm);
  const [errorsFormDISignature, setErrorsFormDISignature] = useState({});
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);

  const [enabledAmount, setEnabledAmount] = useState(true);
  const [enabledDeliver_date, setEnabledDeliver_date] = useState(true);
  const [enabledDelivery_quantity, setEnabledDelivery_quantity] = useState(true);
  const [enabledAccept, setEnabledAccept] = useState(true);
  const [enabledDate_accepted, setEnabledDate_accepted] = useState(true);
  const [enabledObservations, setEnabledObservations] = useState(true);
  const [enabledDate_refund, setEnabledDate_refund] = useState(true);
  const [enabledReason_refund, setEnabledReason_refund] = useState(true);
  const [enabledAction_refund, setEnabledAction_refund] = useState(true);
  const [enabledSignature_refund, setEnabledSignature_refund] = useState(true);

  const signDispatchedCanvas = useRef(null);
  const [pdfUrl, setPdfUrl] = useState(""); // Estado para actualizar el PDF
  const [originalPdfUrl, setOriginalPdfUrl] = useState("");  

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalFormDISignature) {
        setDataFormDISignature(initialStateForm);
        setErrorsFormDISignature({}); 
        setIsLoadingErrorData(false);
        setEnabledAmount(true);
        setEnabledDeliver_date(true);
        setEnabledDelivery_quantity(true);
        setEnabledAccept(true);
        setEnabledDate_accepted(true);
        setEnabledObservations(true);
        setEnabledDate_refund(true);
        setEnabledReason_refund(true);
        setEnabledAction_refund(true);
        setEnabledSignature_refund(true); 
        setPdfUrl("");
        setOriginalPdfUrl(""); 
        dispatch(setLoading(false));
        dispatch(setReloadRefreshData(true));
        if (signDispatchedCanvas.current) signDispatchedCanvas.current.clear();
        navigate(typeOfList==="1"?"/formdi":"/formdiended") ;
        return () => {};
      }
    },
    [
      showModalFormDISignature,
      setErrorsFormDISignature,
      setPdfUrl,
      setOriginalPdfUrl,
      setEnabledAmount,
      setEnabledDeliver_date,
      setEnabledDelivery_quantity,
      setEnabledAccept,
      setEnabledDate_accepted,
      setEnabledObservations,
      setEnabledDate_refund,
      setEnabledReason_refund,
      setEnabledAction_refund,
      setEnabledSignature_refund, 
      typeOfList,
      dispatch,
      navigate]
  );
  
  const handleCloseDataEditFormDISignature = () => {
    setDataFormDISignature(initialStateForm);
        setErrorsFormDISignature({}); 
        setIsLoadingErrorData(false);
        setEnabledAmount(true);
        setEnabledDeliver_date(true);
        setEnabledDelivery_quantity(true);
        setEnabledAccept(true);
        setEnabledDate_accepted(true);
        setEnabledObservations(true);
        setEnabledDate_refund(true);
        setEnabledReason_refund(true);
        setEnabledAction_refund(true);
        setEnabledSignature_refund(true); 
        setPdfUrl("");
        setOriginalPdfUrl(""); 
        dispatch(setLoading(false));
        dispatch(setReloadRefreshData(true));
        if (signDispatchedCanvas.current) signDispatchedCanvas.current.clear();
    navigate(typeOfList==="1"?"/formdi":"/formdiended") ;
    return () => {};
	};

  const handleCancelSignDispatched = () => {
    if (signDispatchedCanvas.current) signDispatchedCanvas.current.clear();
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      signature_refund: "",
      pdfUrl: ""
    }))
    handleCloseDataEditFormDISignature();
  };

  const handleRemoveSignature = () => {
    setPdfUrl(originalPdfUrl); // Restaurar el PDF original
    if (signDispatchedCanvas.current) signDispatchedCanvas.current.clear();
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      signature_refund: "",
      pdfUrl: ""
    })); 
  };
 
  const handleSetSignatureFrancisco = () => {
    if (signDispatchedCanvas.current) {
      const canvas = signDispatchedCanvas.current.getCanvas();
      const ctx = canvas.getContext("2d");

      // Crear una nueva imagen
      const img = new Image();
      img.src = signFrancisco; // Imagen importada

      img.onload = () => {
          // Limpiar el canvas antes de dibujar
          ctx.clearRect(0, 0,550, 240);
          
          // Dibujar la imagen de Jorge en el canvas
          ctx.drawImage(img, 0, 0, 550, 240);

          // Guardar la firma en el estado
          setDataFormDISignature(dataFormDISignature => ({
              ...dataFormDISignature,
              signature_refund: signDispatchedCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
          }));
      };
    } 
  };

  const handleSetSignatureGonzalo = () => {
    if (signDispatchedCanvas.current) {
      const canvas = signDispatchedCanvas.current.getCanvas();
      const ctx = canvas.getContext("2d");

      // Crear una nueva imagen
      const img = new Image();
      img.src = signGonzalo; // Imagen importada

      img.onload = () => {
          // Limpiar el canvas antes de dibujar
          ctx.clearRect(0, 0,550, 240);
          
          // Dibujar la imagen de Jorge en el canvas
          ctx.drawImage(img, 0, 0, 550, 240);

          // Guardar la firma en el estado
          setDataFormDISignature(dataFormDISignature => ({
              ...dataFormDISignature,
              signature_refund: signDispatchedCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
          }));
      };
    } 
  };
    
  const handleSetSignatureGuillermo = () => {
    if (signDispatchedCanvas.current) {
      const canvas = signDispatchedCanvas.current.getCanvas();
      const ctx = canvas.getContext("2d");

      // Crear una nueva imagen
      const img = new Image();
      img.src = signGuillermo; // Imagen importada

      img.onload = () => {
          // Limpiar el canvas antes de dibujar
          ctx.clearRect(0, 0,550, 240);
          
          // Dibujar la imagen de Jorge en el canvas
          ctx.drawImage(img, 0, 0, 550, 240);

          // Guardar la firma en el estado
          setDataFormDISignature(dataFormDISignature => ({
              ...dataFormDISignature,
              signature_refund: signDispatchedCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
          }));
      };
    } 
  };
    
  const handleSetSignatureJorge = () => {
    if (signDispatchedCanvas.current) {
        const canvas = signDispatchedCanvas.current.getCanvas();
        const ctx = canvas.getContext("2d");

        // Crear una nueva imagen
        const img = new Image();
        img.src = signJorge; // Imagen importada

        img.onload = () => {
            // Limpiar el canvas antes de dibujar
            ctx.clearRect(0, 0,550, 240);
            
            // Dibujar la imagen de Jorge en el canvas
            ctx.drawImage(img, 0, 0, 550, 240);

            // Guardar la firma en el estado
            setDataFormDISignature(dataFormDISignature => ({
                ...dataFormDISignature,
                signature_refund: signDispatchedCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
            }));
        };
    }
  };  
   
  // Función para formatear el número
  const formatNumberWithKg = (num) => {
    num = (num == null || num === "" || isNaN(Number(num))) ? 0 : num;
    return num.toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }) + ' Kg';
  };
 
  function getDateFormated(getDate) {
    const meses = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const hoy = (getDate !==null && getDate !=="")? new Date(getDate):new Date();
    const dia = hoy.getDate();
    const mes = meses[hoy.getMonth()];
    const año = hoy.getFullYear();
    return `${dia}/${mes}/${año}`;
  }

  const insertDateInPDF = async (pdfUrl, signatureCanvas, setPdfUrl) => {
  
    const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes); 
    const pages = pdfDoc.getPages();
    const firstPage = pages[0]; // Puedes cambiar la página si lo necesitas
    const secondPage = pages[1]; // Puedes cambiar la página si lo necesitas 
    try {
      if (enabledSignature_refund && signatureCanvas.current && signatureCanvas.current!==""){
        const signatureDataUrl = signatureCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        const signatureImageBytes = await fetch(signatureDataUrl).then(res => res.arrayBuffer());
        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
        if (dataFormDISignature.typeofconvertpdf===0 || dataFormDISignature.typeofconvertpdf===3){
          if (enabledSignature_refund){
            firstPage.drawImage(signatureImage, {
              x: 170, // Ajusta la posición X
              y: 30, // Ajusta la posición Y
              width: 70, // Ajusta el ancho de la firma
              height: 35  // Ajusta la altura de la firma
            });
          }
        } else{
          if (dataFormDISignature.typeofconvertpdf===2){
            if (enabledSignature_refund){
              secondPage.drawImage(signatureImage, {
                x: 100, // Ajusta la posición X
                y: 200, // Ajusta la posición Y
                width: 100, // Ajusta el ancho de la firma
                height: 50  // Ajusta la altura de la firma
              });
            }  
          } else{
            if (enabledSignature_refund){
              firstPage.drawImage(signatureImage, {
                x: 100, // Ajusta la posición X
                y: 50, // Ajusta la posición Y
                width: 100, // Ajusta el ancho de la firma
                height: 50  // Ajusta la altura de la firma
              });
            }
          }
        }    
      } 
     
      if (dataFormDISignature.typeofconvertpdf===2){
        if (enabledAmount){
          if (dataFormDISignature.amount && dataFormDISignature.ycoord_amount!==0) {
            let amount = formatNumberWithKg(dataFormDISignature.amount);
            secondPage.drawText(`${amount}`, {
                x: 165,  
                y: dataFormDISignature.ycoord_amount,   
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          }
        }  
        if (enabledDeliver_date){
          if (dataFormDISignature.deliver_date && dataFormDISignature.ycoord_deliver_date!==0) {
            let deliver_date = getDateFormated(dataFormDISignature.deliver_date);
            secondPage.drawText(`${deliver_date}`, {
              x: 165,  
              y: dataFormDISignature.ycoord_deliver_date,  // Posición Y en la página 166F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          } 
        }
        if (enabledDelivery_quantity){
          if (dataFormDISignature.delivery_quantity && dataFormDISignature.ycoord_delivery_quantity!==0) {
            let delivery_quantity = formatNumberWithKg(dataFormDISignature.delivery_quantity);
            secondPage.drawText(`${delivery_quantity}`, {
              x: 350,  
              y: dataFormDISignature.ycoord_delivery_quantity,  // Posición Y en la página 166F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          } 
        }  
        if (enabledDate_accepted && dataFormDISignature.date_accepted ){
          if (dataFormDISignature.date_accepted && dataFormDISignature.ycoord_date_accepted!==0) {
            let date_accepted = getDateFormated(dataFormDISignature.date_accepted) ;
            secondPage.drawText(`${date_accepted}`, {
              x: 170,  
              y: dataFormDISignature.ycoord_date_accepted,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledAction_refund){
          if (dataFormDISignature.action_refund  && dataFormDISignature.ycoord_action_refund!==0) {
            secondPage.drawText(`${dataFormDISignature.action_refund}`, {
              x: 170,  
              y: dataFormDISignature.ycoord_action_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }  
        if (enabledDate_refund){
          if (dataFormDISignature.date_refund  && dataFormDISignature.ycoord_date_refund!==0) {
            let date_refund = getDateFormated(dataFormDISignature.date_refund);
            secondPage.drawText(`${date_refund}`, {
              x: 170,  
              y: dataFormDISignature.ycoord_date_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledReason_refund){
          if (dataFormDISignature.reason_refund  && dataFormDISignature.ycoord_reason_refund!==0) {
            secondPage.drawText(`${dataFormDISignature.reason_refund}`, {
              x: 170,  
              y: dataFormDISignature.ycoord_reason_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledObservations){ 
          if (dataFormDISignature.observations  && dataFormDISignature.ycoord_observations!==0) {
            secondPage.drawText(`${dataFormDISignature.observations.substring(0, 120)}`, {
              x: 200,  
              y: dataFormDISignature.ycoord_observations,  // Posición Y en la página 134F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }  
        } 
      } else{
         if (enabledAmount){
          if (dataFormDISignature.amount && dataFormDISignature.ycoord_amount!==0) {
            let amount = formatNumberWithKg(dataFormDISignature.amount);
            firstPage.drawText(`${amount}`, {
                x: dataFormDISignature.xcoord_amount,  // Posición X en la página 322F
                y: dataFormDISignature.ycoord_amount,  // Posición Y en la página 425F
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledDeliver_date){
          if (dataFormDISignature.deliver_date && dataFormDISignature.ycoord_deliver_date!==0) {
            let deliver_date = getDateFormated(dataFormDISignature.deliver_date);
            firstPage.drawText(`${deliver_date}`, {
                x: dataFormDISignature.xcoord_deliver_date,  // Posición X en la página 180F
                y: dataFormDISignature.ycoord_deliver_date,  // Posición Y en la página 166F
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          } 
        }
        if (enabledDelivery_quantity){
          if (dataFormDISignature.delivery_quantity && dataFormDISignature.ycoord_delivery_quantity!==0) {
            let delivery_quantity = formatNumberWithKg(dataFormDISignature.delivery_quantity);
            firstPage.drawText(`${delivery_quantity}`, {
                x: dataFormDISignature.xcoord_delivery_quantity,  // Posición X en la página 425F
                y: dataFormDISignature.ycoord_delivery_quantity,  // Posición Y en la página 166F
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          } 
        }
        if (enabledAccept){
          if (dataFormDISignature.accept && dataFormDISignature.ycoord_accept!==0) {
              firstPage.drawText(`${t(dataFormDISignature.accept)}`, {
                x: dataFormDISignature.xcoord_accept,  // Posición X en la página 195F
                y: dataFormDISignature.ycoord_accept,  // Posición Y en la página 156F
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          } 
        }
        if (enabledDate_accepted){
          if (dataFormDISignature.date_accepted && dataFormDISignature.ycoord_date_accepted!==0) {
            let date_accepted = getDateFormated(dataFormDISignature.date_accepted);
            firstPage.drawText(`${date_accepted}`, {
                x: dataFormDISignature.xcoord_date_accepted,  // Posición X en la página 415F
                y: dataFormDISignature.ycoord_date_accepted,  // Posición Y en la página 156F
                size: 8, // Tamaño de la fuente
                color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledReason_refund){
          if (dataFormDISignature.reason_refund  && dataFormDISignature.ycoord_reason_refund!==0) {
            firstPage.drawText(`${dataFormDISignature.reason_refund}`, {
              x: dataFormDISignature.xcoord_reason_refund,
              y: dataFormDISignature.ycoord_reason_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }
      
        if (enabledAction_refund){
          if (dataFormDISignature.action_refund  && dataFormDISignature.ycoord_action_refund!==0) {
            firstPage.drawText(`${dataFormDISignature.action_refund}`, {
              x: dataFormDISignature.xcoord_action_refund,
              y: dataFormDISignature.ycoord_action_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }  
        if (enabledDate_refund){
          if (dataFormDISignature.date_refund  && dataFormDISignature.ycoord_date_refund!==0) {
            let date_refund = getDateFormated(dataFormDISignature.date_refund);
            firstPage.drawText(`${date_refund}`, {
              x: dataFormDISignature.xcoord_date_refund,
              y: dataFormDISignature.ycoord_date_refund,  // Posición Y en la página 156F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }
        if (enabledObservations){  
          if (dataFormDISignature.observations  && dataFormDISignature.ycoord_observations!==0) {
            firstPage.drawText(`${dataFormDISignature.observations.substring(0, 120)}`, {
              x: dataFormDISignature.xcoord_observations,  // Posición X en la página 130F
              y: dataFormDISignature.ycoord_observations,  // Posición Y en la página 134F
              size: 8, // Tamaño de la fuente
              color: rgb(0, 0, 0), // Color negro
            });
          }
        }

      }
      const pdfBytes = await pdfDoc.save();
      const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
      const newPdfUrl = URL.createObjectURL(pdfBlob);
      setPdfUrl(newPdfUrl); // Actualiza el `iframe`
      // Convertir el Blob en Base64
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result.split(',')[1]); // Tomamos solo el Base64
          reader.onerror = (error) => reject(error);
        });
      };
      const pdfBase64 = await convertBlobToBase64(pdfBlob);
      setDataFormDISignature(prevData => ({
        ...prevData,
        pdfUrl: pdfBase64 // Enviar el contenido en Base64 en lugar de una URL blob
      }));   
    } catch (error) { 
      dispatch(warningErrorAction(t('Text_IncorrectData'))); 
    }
  };
 
  const handleBlurDataEditFormAmount = (e) => {
    handleChangeDataEditFormAmount(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeDataEditFormAmount = (e) => {
    const { name, value } = e.target;
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" kg","")):0;
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      [name]: newValue,
    }));
  };

  const handleBlurDataEditForm = (e) => {
    handleChangeDataEditForm(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeDataEditForm = (e) => {
    const { name, value } = e.target;
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      [name]: value,
    }));
  };

  const handleBlurSelectAccepted = (e) => {
    handleChangeSelectAccepted(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeSelectAccepted = (event) => {
    setDataFormDISignature(dataFormDISignature => ({
        ...dataFormDISignature,
        accept : event.target.value 
    }));
  };

  const handleBlurSelectDateAccepted = (e) => {
    handleChangeSelectDateAccepted(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeSelectDateAccepted = (event) => {
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      date_accepted: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateDeliver = (e) => {
    handleChangeSelectDateDeliver(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeSelectDateDeliver = (event) => {
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      deliver_date: event!==null?new Date(event):null
    }));
  };

  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };

  const handleBlurSelectDateRefund = (e) => {
    handleChangeSelectDateRefund(e);
    setErrorsFormDISignature(validateFormDISiganture());
  };

  const handleChangeSelectDateRefund = (event) => {
    setDataFormDISignature(dataFormDISignature => ({
      ...dataFormDISignature,
      date_refund: event!==null?new Date(event):null
    }));
  };
    
  const validateFormDISiganture = () => {
    let errorsFormDISignature = {};
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
     
        
    if (regexInvalidCharacters.test(dataFormDISignature.reason_refund)) {
      errorsFormDISignature.reason_refund = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(dataFormDISignature.action_refund)) {
      errorsFormDISignature.action_refund = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(dataFormDISignature.observations)) {
      errorsFormDISignature.observations = 'Text_TextNoValid';
    }
    return errorsFormDISignature;
  };
  

   const handleSubmitDataEditFormSignature = () => {
    const errors = validateFormDISiganture();
    setErrorsFormDISignature(errors);
    if (Object.keys(errors).length === 0 && dataFormDISignature.pdfUrl!=="") {       
      if (signDispatchedCanvas.current) {
        const dataURL = signDispatchedCanvas.current.getTrimmedCanvas().toDataURL("image/png");
        setDataFormDISignature(dataFormDISignature => ({
          ...dataFormDISignature,
          signature_refund: dataURL 
        }));
      } 
      handleCloseDataEditFormDISignature(); 
      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData =  await helpHttp().put(ENDPOINT, `form/updateformdisignature`, token, dataFormDISignature);
          if (getData.status === "OK") { 
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData(); 

    } else { 
      if (errorsFormDISignature.reason_refund) {
        dispatch(warningErrorAction(t(errorsFormDISignature.reason_refund)));
      } else if (errorsFormDISignature.action_refund) {
        dispatch(warningErrorAction(t(errorsFormDISignature.action_refund)));
      } else if (errorsFormDISignature.observations) {
        dispatch(warningErrorAction(t(errorsFormDISignature.observations)));
      } else if (dataFormDISignature.pdfUrl==="") {
        dispatch(warningErrorAction(t('Text_NOT_INSERTED')));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      } 
    }
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchFileForm = async (fileName) => {
      try {
        const getData = await helpHttp().getfilesupload(ENDPOINT, `pdftos3uploader/downloadfroms3`, token,fileName);
        if (!getData) {
          setPdfUrl("");
          setOriginalPdfUrl("");
          dispatch(warningErrorAction(t('Text_ConnectionFailed'))); 
        } else{
          if (getData && getData.size>0){
            const url = URL.createObjectURL(getData);
            setPdfUrl(url); // Guardar la URL del PDF para mostrarlo en <iframe>
            setOriginalPdfUrl(url); 
          }
        
        }
      } catch (error) {
        setPdfUrl("");
        setOriginalPdfUrl("");
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    const fetchDataForm = async () => {
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`form/getformdisignaturebyid/`,token,id);
        if (getData.err) {
          setDataFormDISignature(initialStateForm);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataFormDISignature(getData);
          if (getData.amount!==0){
            setEnabledAmount(false);
          }
          if (getData.deliver_date===null || getData.deliver_date===""){
            setEnabledDeliver_date(true);
          }else{
            setEnabledDeliver_date(false);
          }
          if (getData.delivery_quantity!==0){
            setEnabledDelivery_quantity(false);
          }
          if (getData.accept!==""){
            setEnabledAccept(false);
          }
          if (getData.date_accepted===null || getData.date_accepted===""){
            setEnabledDate_accepted(true);
          }else{
            setEnabledDate_accepted(false);
          }
          if (getData.observations!==""){
            setEnabledObservations(false);
          }
          if (getData.date_refund===null || getData.date_refund===""){
            setEnabledDate_refund(true);
          }else{
            setEnabledDate_refund(false);
          }
          if (getData.reason_refund!==""){
            setEnabledReason_refund(false);
          }
          if (getData.action_refund!==""){
            setEnabledAction_refund(false);
          }
          if (getData.signature_refund!==""){
            setEnabledSignature_refund(false); 
          } 
          if (getData && getData.numberDI_sheet && getData.numberDI_sheet!==""){
            fetchFileForm("DI_"+getData.numberDI_sheet+".pdf"); 
          }
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    }; 
    if (id!=="0"){
      if (dataFormDISignature.id==="" || dataFormDISignature.id===0){ 
        fetchDataForm();
      } 
    }else{
      setDataFormDISignature(initialStateForm);  
    }
    return () => { isMounted = false };
  }, [dispatch,id,t,token,dataFormDISignature.id,defaulttypesdi,showModalFormDISignature]);  
 
   return (
    <>
      {showModalFormDISignature ? ( 
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '1840px'}}>
            <form className="createform" onSubmit={handleCloseDataEditFormDISignature}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h9NewStyle mb-2">
                                {isLoadingErrorData?
                                <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                (isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                &nbsp;&nbsp;{t('Text_FORM_NUM_DI')+': '+dataFormDISignature.numberDI_sheet}
                            </h1>
                            <div>
                            {isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <>
                                  <button type="button" className="buttonCreate" disabled={ pdfUrl===""} onClick={handleSubmitDataEditFormSignature}>
                                    {t("Button_Update")}
                                  </button>
                                  <> </>
                                  <button type="button" className="buttonBack" onClick={handleCloseDataEditFormDISignature}>
                                    {t("Button_Cancel")}
                                  </button>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoadingData ?
                <>   
                  <div className="row">
                    <div className="col-xl-12 mb-4"> 
                      <div className="card border-info shadow">
                        <div className="card-body">
                          <div className="row justify-content-center">
                         
                            {/* Firma digital */}
                            <div className="col-6 mb-2">
                              <div className="card border-info shadow">
                                <div className="card-body">
                                  <div className="row no-gutters align-items-center">
                                    <div className="row p-0"> 
                                      <div className="form-group">
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_AMOUNT')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <NumericFormat 
                                          thousandSeparator={','} 
                                          decimalSeparator={'.'} 
                                          decimalScale= {2}
                                          fixedDecimalScale= {true}
                                          allowNegative= {false}
                                          isAllowed={withValueCap}
                                          id="amount" 
                                          name="amount"
                                          suffix={" kg"}
                                          value={dataFormDISignature.amount}
                                          label={errorsFormDISignature.amount!=null?t(errorsFormDISignature.amount):t('Text_FORM_AMOUNT')}
                                          placeholder={t('PlaceHolder_FORM_AMOUNT')}
                                          inputProps={{ style: {width: i18n.language==='es'?200:200} }}
                                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                          customInput={TextField}
                                          onBlur={handleBlurDataEditFormAmount}
                                          onChange={handleChangeDataEditFormAmount}
                                          disabled={!enabledAmount || pdfUrl==="" }
                                        />
                                      </div>
                                    </div>
                                    <div className="row p-2"> 
                                      <div className="form-group">   
                                        <h6 className="h7NewStyle mb-2">{t('Text_FORM_ACCEPTED')}</h6>
                                        {/* Divider */}
                                        <hr className="sidebar-divider d-none d-md-block"/>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Deliver_date')} :</label>
                                          &nbsp;&nbsp;&nbsp;&nbsp; 
                                          <DesktopDatePicker
                                            id="deliver_date" 
                                            name="deliver_date"
                                            value={ dataFormDISignature.deliver_date!== null ? new Date(dataFormDISignature.deliver_date): null}
                                            label={errorsFormDISignature.deliver_date!=null?t(errorsFormDISignature.deliver_date):t('Text_FORM_Deliver_date')}
                                            format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                            onBlur={handleBlurSelectDateDeliver}
                                            onChange={handleChangeSelectDateDeliver}
                                            disabled={!enabledDeliver_date || pdfUrl==="" }
                                            slotProps={{
                                              textField: {
                                                placeholder: i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                                sx: {
                                                  maxLength: 200,
                                                  width: i18n.language==="es"?200:250,
                                                  fontSize: '18px',  
                                                },
                                                InputProps: {
                                                  style: {
                                                    fontSize: '18px',
                                                  }
                                                },
                                                InputLabelProps:{ 
                                                  style: {
                                                    fontSize: '18px', 
                                                    background: '#FFFFFF'
                                                  }
                                                },
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Delivery_quantity')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <NumericFormat 
                                          thousandSeparator={','} 
                                          decimalSeparator={'.'} 
                                          decimalScale= {2}
                                          fixedDecimalScale= {true}
                                          allowNegative= {false}
                                          isAllowed={withValueCap}
                                          id="delivery_quantity" 
                                          name="delivery_quantity"
                                          value={dataFormDISignature.delivery_quantity}
                                          label={errorsFormDISignature.delivery_quantity!=null?t(errorsFormDISignature.delivery_quantity):t('Text_FORM_Delivery_quantity')}
                                          placeholder={t('Text_FORM_Delivery_quantity')}
                                          inputProps={{ style: {width: i18n.language==='es'?222:268} }}
                                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                          customInput={TextField}
                                          onBlur={handleBlurDataEditFormAmount}
                                          onChange={handleChangeDataEditFormAmount}
                                          disabled={!enabledDelivery_quantity  || pdfUrl==="" }
                                        />
                                      </div>
                                    </div> 
                                    <div className="row p-2"> 
                                      <div className="form-group">   
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Accept')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="accept"
                                          name="accept"
                                          value={ (dataFormDISignature.accept === undefined || dataFormDISignature.accept ===null || dataFormDISignature.accept ==="")?t("Text_NOT_REPORTING"):dataFormDISignature.accept}
                                          inputProps={{ style: {width: i18n.language==='es'?180:207} }}
                                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                          variant="outlined"
                                          onBlur={handleBlurSelectAccepted}
                                          onChange={handleChangeSelectAccepted}
                                          disabled={!enabledAccept }
                                          helperText={errorsFormDISignature.accept!=null?t(errorsFormDISignature.accept):""}
                                          select
                                          SelectProps={{
                                              native: true,
                                              value: (dataFormDISignature.accept === undefined || dataFormDISignature.accept ===null || dataFormDISignature.accept ==="")?t("Text_NOT_REPORTING"): dataFormDISignature.accept
                                          }}>
                                          {textAccepted.map((option) => (
                                              <option key={option.id} value={option.name}>
                                                  {t(option.name)}
                                              </option>
                                          ))}
                                        </TextField>  
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Date_Accepted')} :</label>
                                          &nbsp;&nbsp;&nbsp;&nbsp; 
                                          <DesktopDatePicker
                                            id="date_accepted" 
                                            name="date_accepted"
                                            value={ dataFormDISignature.date_accepted!== null ? new Date(dataFormDISignature.date_accepted): null}
                                            format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                            label={errorsFormDISignature.date_accepted!=null?t(errorsFormDISignature.date_accepted):t('Text_FORM_Date_Accepted')}
                                            onBlur={handleBlurSelectDateAccepted}
                                            onChange={handleChangeSelectDateAccepted}
                                            disabled={!enabledDate_accepted || pdfUrl==="" }
                                            slotProps={{
                                              textField: {
                                                placeholder: i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                                sx: {
                                                  maxLength: 200,
                                                  width: 200,
                                                  fontSize: '18px',  
                                                },
                                                InputProps: {
                                                  style: {
                                                    fontSize: '18px',
                                                  }
                                                },
                                                InputLabelProps:{ 
                                                  style: {
                                                    fontSize: '18px', 
                                                    background: '#FFFFFF'
                                                  }
                                                },
                                              },
                                            }}
                                          />
                                        </LocalizationProvider>
                                      </div>
                                    </div> 
                                    {(reason_refund_view && dataFormDISignature.typeofconvertpdf!==1) && 
                                      <div className="row p-2"> 
                                        <div className="form-group">
                                          <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Reason_Refund')} :</label>
                                          &nbsp;&nbsp;&nbsp;&nbsp; 
                                          <TextField
                                            id="reason_refund" 
                                            name="reason_refund"
                                            value={dataFormDISignature.reason_refund}
                                            inputProps={{ style: {width: i18n.language==='es'?696:683}, maxLength: 100  }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                            label={errorsFormDISignature.reason_refund!=null?t(errorsFormDISignature.reason_refund):t('PlaceHolder_FORM_Reason_Refund')}
                                            placeholder={t('PlaceHolder_FORM_Reason_Refund')}
                                            variant="standard"
                                            onBlur={handleBlurDataEditForm}
                                            onChange={handleChangeDataEditForm}
                                            disabled={!reason_refund_handle || !enabledDate_refund || pdfUrl==="" }/>
                                        </div>
                                      </div> 
                                    } 
                                    {((action_refund_view || date_refund_view) && dataFormDISignature.typeofconvertpdf!==1) && 
                                      <div className="row p-2">  
                                        {action_refund_view && 
                                          <div className="form-group">
                                            <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Action_Refund')} :</label>
                                            &nbsp;&nbsp;&nbsp;&nbsp; 
                                            <TextField
                                              id="action_refund" 
                                              name="action_refund"
                                              value={dataFormDISignature.action_refund}
                                              inputProps={{ style: {width: i18n.language==='es'?190:248}, maxLength: 100  }}
                                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                              label={errorsFormDISignature.action_refund!=null?t(errorsFormDISignature.action_refund):t('PlaceHolder_FORM_Action_Refund')}
                                              placeholder={t('PlaceHolder_FORM_Action_Refund')}
                                              variant="standard"
                                              onBlur={handleBlurDataEditForm}
                                              onChange={handleChangeDataEditForm} 
                                              disabled={!action_refund_handle || !enabledReason_refund || pdfUrl==="" }
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                          </div> 
                                        }
                                        {date_refund_view && 
                                          <div className="form-group"> 
                                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Refund')} :</label>
                                              &nbsp;&nbsp;&nbsp;&nbsp; 
                                              <DesktopDatePicker
                                                id="date_refund" 
                                                name="date_refund"
                                                value={ dataFormDISignature.date_refund!== null ? new Date(dataFormDISignature.date_refund): null}
                                                format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                                label={errorsFormDISignature.date_refund!=null?t(errorsFormDISignature.date_refund):t('Text_FORM_Refund')}
                                                onBlur={handleBlurSelectDateRefund}
                                                onChange={handleChangeSelectDateRefund} 
                                                disabled={!date_refund_handle || !enabledAction_refund || pdfUrl==="" }
                                                slotProps={{
                                                  textField: {
                                                    placeholder: i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                                    sx: {
                                                      maxLength: 200,
                                                      width: i18n.language==="es"?200:200,
                                                      fontSize: '18px',  
                                                    },
                                                    InputProps: {
                                                      style: {
                                                        fontSize: '18px',
                                                      }
                                                    },
                                                    InputLabelProps:{ 
                                                      style: {
                                                        fontSize: '18px', 
                                                        background: '#FFFFFF'
                                                      }
                                                    },
                                                  },
                                                }}
                                              />
                                            </LocalizationProvider>
                                          </div> 
                                        }
                                      </div> 
                                    }  
                                    <div className="row p-2"> 
                                      <div className="form-group">   
                                        <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Observations')} :</label>
                                        &nbsp;&nbsp;&nbsp;&nbsp; 
                                        <TextField
                                          id="observations" 
                                          name="observations"
                                          value={dataFormDISignature.observations}
                                          inputProps={{ style: {width: i18n.language==='es'?705:719}, maxLength: 250  }}
                                          InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                          label={errorsFormDISignature.observations!=null?t(errorsFormDISignature.observations):t('PlaceHolder_FORM_Observations')}
                                          placeholder={t('PlaceHolder_FORM_Observations')}
                                          variant="standard"
                                          onBlur={handleBlurDataEditForm}
                                          onChange={handleChangeDataEditForm}
                                          disabled={!enabledObservations || pdfUrl==="" }
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                    {(enabledSignature_refund  || pdfUrl!=="") && 
                                      <>
                                        <div className="col-8 mb-2"> 
                                            <div style={{ border: "2px solid #4e73df", width: 550, height: 240 }}>
                                              <SignatureCanvas
                                                penColor="black"
                                                canvasProps={{
                                                  style: {
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%",
                                                    backgroundColor: "transparent",
                                                  },
                                                }}
                                                ref={signDispatchedCanvas}
                                              />
                                            </div> 
                                          </div>
                                          <div className="col-4 mb-2">
                                            <Grid item xs={2} sm={3} md={2.1} key={2}>
                                              <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                                <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                  <ButtonGroup
                                                    orientation="vertical"
                                                    aria-label="Vertical button group"
                                                    variant="contained"
                                                    sx={{ gap: 2 }}
                                                  >
                                                    <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureFrancisco} >
                                                      {t("Text_SIGN_FRANCISCO")}
                                                    </Button>
                                                    <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureGonzalo}>
                                                      {t("Text_SIGN_GONZALO")}
                                                    </Button>
                                                    <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureGuillermo}>
                                                      {t("Text_SIGN_GUILLLERMO")}
                                                    </Button>
                                                    <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureJorge}>
                                                      {t("Text_SIGN_JORGE")}
                                                    </Button>
                                                  </ButtonGroup>
                                                </CardContent>
                                              </Card>
                                            </Grid>
                                          </div> 
                                        </>
                                      }
                                      <hr className="sidebar-divider d-none d-md-block" />
                                      <div className="btn-group w-100 d-grid d-md-flex justify-content-md-end" role="group">
                                        <button className="btn btn-secondary btn-lg custom-btn" type="button" onClick={handleCancelSignDispatched}>
                                          {t("Button_Cancel_Sign")}
                                        </button>
                                        <button className="btn btn-outline-secondary btn-lg custom-btn" disabled={ pdfUrl===""} type="button" onClick={handleRemoveSignature}>
                                          {t("Button_Delete_Data")}
                                        </button>
                                        <button className="btn btn-primary btn-lg custom-btn" type="button" disabled={ pdfUrl===""} onClick={() => insertDateInPDF(pdfUrl, signDispatchedCanvas, setPdfUrl)}>
                                          {t("Button_Insert_Data")}
                                        </button> 
                                      </div> 
                                    </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                  

                            {/* Vista previa PDF */}
                            <div className="col-xl-6 mb-4">
                              <div className="rowpdf">
                                <div className="cardpdf border-info shadow">
                                  <div className="cardpdf-body">
                                    <div className="rowpdf no-gutters align-items-center">
                                      <div className="formgroupsignature" style={{ height: "100%" }}>
                                        {pdfUrl ? (
                                          <iframe 
                                            src={pdfUrl} 
                                            title="PDF FORM DI" 
                                            style={{ width: '100%', height: '100%', border: 'none' }} 
                                          />
                                        ) : (
                                          <div style={{ textAlign: 'center', padding: '20px' }}>
                                            {t("Text_Form_PDF_NOT_FOUND")}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </>:
                  <LoadingNews/>
              }
            </form>
          </Box> 
        </div>
      //* End Page Content *//
    ) :  null }
  </>
  )    
}