import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next" ;
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';  
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp"; 
import TableSortingShippingStoreAddresses from 'pages/shipping/editOrNewShipping/SortingTablesShippingAddress'; 
import PropTypes from 'prop-types'; 
 
const initialStateStoreAddresses = [{
  "id":0,
  "roadtype_id":0,
  "roadtype_namees":"", 
  "roadtype_nameen":"",
  "street":"",
  "country_id":0,
  "country_namees":"",
  "country_nameen":"",
  "province_id":0,
  "province_name":"",
  "community_id":0,
  "community_namees":"",
  "community_nameen":"",
  "postalcode_id":0,
  "postalcode_postalcodename":""
}];
        

const ShippingSearchStoresAddresses = ({ showModalSearchStoresAddress, setShowModalSearchStoresAddress,formShipping,setFormShipping,selectStores}) => {
  const {t,i18n} = useTranslation(['listDataShipping']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const [dataStoreAddresses, setDataStoreAddresses] = useState(initialStateStoreAddresses);
  const [numTotRegStoreAddresses,setNumTotRegStoreAddresses]= useState(0); 
  const dispatch = useDispatch(); 
   
  const keyPress = useCallback(
    (e) => {
     
      if (e.key === 'Escape' && showModalSearchStoresAddress) {
        setDataStoreAddresses(initialStateStoreAddresses);
        setNumTotRegStoreAddresses(0);
        setShowModalSearchStoresAddress(false);
      }
    },
    [ setDataStoreAddresses,
      setNumTotRegStoreAddresses,
      showModalSearchStoresAddress,
      setShowModalSearchStoresAddress
    ]
  );

  const handleCloseDataEditStoreAdresses = () => {
    setDataStoreAddresses(initialStateStoreAddresses);
    setNumTotRegStoreAddresses(0);
    setShowModalSearchStoresAddress(false);
	};

  const toggleDrawerSearchFormStoreAddresses  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditStoreAdresses();
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
       
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegStoreAdresses = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT, `shipping/getinformationtablestoreaddress/`, token,selectStores==='Rel_Trans'?formShipping.rel_trans_store_id:selectStores==='Opt_Tras'?formShipping.opt_tras_store_id:formShipping.dest_tras_store_id);
        if (getData.err) {
          setDataStoreAddresses(initialStateStoreAddresses);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegStoreAddresses(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
  
    const fetchDataStoreAdresses = async () => {
      try {
        const getData = await helpHttp().get8(ENDPOINT,`shipping/getaddressesstoresbystoreid`,token,selectStores==='Rel_Trans'?formShipping.rel_trans_store_id:selectStores==='Opt_Tras'?formShipping.opt_tras_store_id:formShipping.dest_tras_store_id,selectStores==='Rel_Trans'?6:selectStores==='Opt_Tras'?1:4);
        if (getData.err || getData.message === "Load failed") {
          setDataStoreAddresses(initialStateStoreAddresses);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataStoreAddresses(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    
    if (showModalSearchStoresAddress){
      fetchnumTotRegStoreAdresses(); 
      fetchDataStoreAdresses();
    }
    return () => { isMounted = false };
  }, [t,i18n,token,dispatch,LIMITPAGESIZE,showModalSearchStoresAddress,formShipping.dest_tras_store_id,formShipping.opt_tras_store_id,formShipping.rel_trans_store_id,selectStores]);
 
  return(
    <>
      {showModalSearchStoresAddress && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormStoreAddresses(false)}
            onOpen={toggleDrawerSearchFormStoreAddresses(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff', width: '800px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2"> {t('Text_TICKETS_Stores_Address_Search')}</h1>
                  <div>
                    <button type="button" className="buttonCreate" onClick={handleCloseDataEditStoreAdresses}>{t('Button_Exit')}</button><> </>
                  </div> 
                </div>  
                <br/>
                
                <div className="card shadow mb-4">    
                  <div className="card-body">
                    <TableSortingShippingStoreAddresses
                      dataStoreAddresses={dataStoreAddresses}
                      numTotRegStoreAddresses={numTotRegStoreAddresses}
                      titleTable={selectStores==='Rel_Trans'?formShipping.rel_trans_tradename:selectStores==='Opt_Tras'?formShipping.opt_tras_tradename:formShipping.dest_tras_tradename}
                      setFormShipping={setFormShipping}
                      handleCloseDataEditStoreAdresses={handleCloseDataEditStoreAdresses}
                      selectStores={selectStores}
                    />
                  </div>             
                </div>
                {/* End Content Row */}                
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

ShippingSearchStoresAddresses.propTypes = {
  showModalSearchStoresAddress : PropTypes.bool.isRequired,
  setShowModalSearchStoresAddress : PropTypes.func.isRequired,
  setFormShipping : PropTypes.func.isRequired,
  selectStores: PropTypes.string.isRequired
};

export default ShippingSearchStoresAddresses;
