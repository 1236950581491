import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { DEFAULTTYPEOFDOCUMENT, ENDPOINT } from "constants/variables";

import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem  from "@mui/material/MenuItem";
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';  
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import ContractSearchStores from "pages/contracts/editOrNewContract/ContractSearchStores";
import ContractSearchStoresAddresses from "pages/contracts/editOrNewContract/ContractSearchStoresAddress";
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "",
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const initialDocumentIdToSelect = [{
    "id": "",
    "documentId": "",
    "tradename": ""
}];

const ContractCreateOrEditOptTras = ({formContract,setFormContract,showModalContractSettings,setIsLoadingErrorData,errorsForm,setErrorsForm,validateFormContract,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataContracts']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
    const [priority, setPriority] = useState(1);   
    const [tetxtSearch, setTetxtSearch] = useState("Opt_Tras");   
    const [provinceToSelect_Opt_Tras,setProvinceToSelect_Opt_Tras]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Opt_Tras,setPostalCodeToSelect_Opt_Tras]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 
    
    const [documentIdOptToSelect,setDocumentIdOptToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredDocumentIdOpt, setFilteredDocumentIdOpt] = useState([]); // Opciones filtradas
    const [highlightedIndexDocumentIdOpt, setHighlightedIndexDocumentIdOpt] = useState(-1); // Índice de la opción resaltada 
    const [tradenameOptToSelect,setTradenameOptToSelect]= useState(initialDocumentIdToSelect);  
    const [filteredTradenamedOpt, setFilteredTradenamedOpt] = useState([]); // Opciones filtradas
    const [highlightedIndexTradenamedOpt, setHighlightedIndexTradenamedOpt] = useState(-1); // Índice de la opción resaltada 
      
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchDocumentIdOpt = (event) => {
        const searchDocumentIdOpt = event.target.value;
        setFormContract(formContract => ({
        ...formContract,
        opt_tras_documentid: searchDocumentIdOpt,
        })); 
        if (searchDocumentIdOpt.trim() === "") {
        setFilteredDocumentIdOpt([]);
        setHighlightedIndexDocumentIdOpt(-1);
        return;
        } 
        const filtered = documentIdOptToSelect.filter((store) => {
        if (!store || !store.documentId) return false;
        const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
        // Comparar después de eliminar puntos y guiones de ambos
        const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        return normalizedDocumentId.includes(searchNormailzedDocumentId);
        });
        setFilteredDocumentIdOpt(filtered);
        setHighlightedIndexDocumentIdOpt(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectDocumentIdOpt = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        opt_tras_documentid: store.documentId || "",
        }));
        setFilteredDocumentIdOpt([]);
        setHighlightedIndexDocumentIdOpt(-1);
    }; 
    const handleKeyDownDocumentIdOpt = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredDocumentIdOpt.length === 0 && formContract.opt_tras_documentid.trim() === "") {
            setFilteredDocumentIdOpt(documentIdOptToSelect);
            setHighlightedIndexDocumentIdOpt(0);
        } else {
            setHighlightedIndexDocumentIdOpt((prevIndex) => {
            const nextIndex = prevIndex < filteredDocumentIdOpt.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexDocumentIdOpt((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdOpt >= 0) {
        if (highlightedIndexDocumentIdOpt >= 0 && highlightedIndexDocumentIdOpt < filteredDocumentIdOpt.length) {
            handleSelectDocumentIdOpt(filteredDocumentIdOpt[highlightedIndexDocumentIdOpt]);
        }
        } else if (event.key === "Escape") {
        const isValid = documentIdOptToSelect.some((store) => {
            if (!store || !store.documentId) return false; 
            const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
            const normalizedInputId = formContract.opt_tras_documentid.toUpperCase();
            return normalizedStoreId === normalizedInputId;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            opt_tras_documentid: "",
            }));
        } 
        setFilteredTradenamedOpt([]);
        setHighlightedIndexDocumentIdOpt(-1);
        }
    };
    // Manejo del texto de búsqueda y filtrado dinámico
    const handleSearchTradenameOpt = (event) => {
        const searchTradenameOpt = event.target.value.toUpperCase();
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_tradename: searchTradenameOpt,
        })); 
        if (searchTradenameOpt.trim() === "") {
            setFilteredTradenamedOpt([]);
            setHighlightedIndexTradenamedOpt(-1);
            return;
        } 
        const filtered = tradenameOptToSelect.filter((store) => {
        if (!store || !store.tradename) return false;
            // Comparar después de eliminar puntos y guiones de ambos
            const normalizedTradeName = store.tradename.toUpperCase();
            return normalizedTradeName.includes(searchTradenameOpt);
        });
        setFilteredTradenamedOpt(filtered);
        setHighlightedIndexTradenamedOpt(0);
    };
    // Manejo de la selección de una tienda
    const handleSelectTradeNameOpt = (store) => {
        if (!store) return; // Validación adicional para evitar errores
        setFormContract((formContract) => ({
        ...formContract,
        opt_tras_tradename: store.tradename || "",
        opt_tras_documentid: store.documentId || formContract.opt_tras_documentid,
        
        }));
        setFilteredTradenamedOpt([]);
        setHighlightedIndexTradenamedOpt(-1);
    }; 
    const handleKeyDownTradenameOpt = (event) => {
        if (event.key === "ArrowDown") {
        if (filteredTradenamedOpt.length === 0 && formContract.opt_tras_tradename.trim() === "") {
            setFilteredTradenamedOpt(tradenameOptToSelect);
            setHighlightedIndexTradenamedOpt(0);
        } else {
            setHighlightedIndexTradenamedOpt((prevIndex) => {
            const nextIndex = prevIndex < filteredTradenamedOpt.length - 1 ? prevIndex + 1 : prevIndex;
            // Desplaza al siguiente elemento
            scrollToElement(nextIndex);
            return nextIndex;
            });
        }
        } else if (event.key === "ArrowUp") {
        setHighlightedIndexTradenamedOpt((prevIndex) => {
            const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
            // Desplaza al elemento anterior
            scrollToElement(prevIndexValid);
            return prevIndexValid;
        });
        } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedOpt >= 0) {
        if (highlightedIndexTradenamedOpt >= 0 && highlightedIndexTradenamedOpt < filteredTradenamedOpt.length) {
            handleSelectTradeNameOpt(filteredTradenamedOpt[highlightedIndexTradenamedOpt]);
        }
        } else if (event.key === "Escape") {
        const isValid = tradenameOptToSelect.some((store) => {
            if (!store || !store.tradename) return false; 
            const normalizedTradeName = store.tradename.toUpperCase();
            const normalizedInputTradeName = formContract.opt_tras_tradename.toUpperCase();
            return normalizedTradeName === normalizedInputTradeName;
        }); 
        if (!isValid) {
            setFormContract((formContract) => ({
            ...formContract,
            opt_tras_tradename: "",
            }));
        } 
        setFilteredTradenamedOpt([]);
        setHighlightedIndexTradenamedOpt(-1);
        }
    };

    // Función para desplazar el contenedor
    const scrollToElement = (index) => {
        const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
        const item = dropdown?.children[index];
        if (item) {
        item.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
        });
        }
    };

    const handleBlur = useCallback((event) => {
        if (!event || !event.currentTarget) return;
        // Asegúrate de que no se cierra si haces clic en un elemento de la lista
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setFilteredDocumentIdOpt([]);
          setHighlightedIndexDocumentIdOpt(-1);
          setFilteredTradenamedOpt([]);
          setHighlightedIndexTradenamedOpt(-1); 
        }
    }, []);

    const handleChangeSelectDocumentType_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Opt_Tras = (e) => {
        handleChangeSelectDocumentType_Opt_Tras(e);
    };
    
    const handleChangeSelectRoadType_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_roadtype_id : event.target.value 
        }));
    };

    const handleBlurSelectRoadType_Fiscal_Opt_Tras = (e) => {
        handleChangeSelectRoadTypeFiscal_Opt_Tras(e);
    };
    
    const handleChangeSelectRoadTypeFiscal_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_roadtype_id_fiscal : event.target.value 
        }));
    };

    const handleBlurSelectRoadType_Opt_Tras = (e) => {
        handleChangeSelectRoadType_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectCountry_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_country_id: event.target.value,
            opt_tras_province_id:Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Opt_Tras = (e) => {
        handleChangeSelectCountry_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectCountryFiscal_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_country_id_fiscal: event.target.value,
            opt_tras_province_id_fiscal:Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id_fiscal:""
        })); 
    };
    const handleBlurSelectCountryFiscal_Opt_Tras = (e) => {
        handleChangeSelectCountryFiscal_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectProvince_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_province_id :event.target.value,
            opt_tras_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Opt_Tras = (e) => {
        handleChangeSelectProvince_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectProvinceFiscal_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_province_id_fiscal :event.target.value,
            opt_tras_postalcode_id_fiscal:""
        }));
    };
    const handleBlurSelectProvinceFiscal_Opt_Tras = (e) => {
        handleChangeSelectProvinceFiscal_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const handleChangeSelectPostalCode_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Opt_Tras = (e) => {
        handleChangeSelectPostalCode_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectPostalCodeFiscal_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_postalcode_id_fiscal:event.target.value
        }));
    };
    const handleBlurSelectPostalCodeFiscal_Opt_Tras = (e) => {
        handleChangeSelectPostalCodeFiscal_Opt_Tras(e);
        setErrorsForm(validateFormContract()); 
    };

    const handleChangeSelectCommunity_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Opt_Tras = (e) => {
        handleChangeSelectCommunity_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeSelectCommunityFiscal_Opt_Tras = (event) => {
        setFormContract(formContract => ({
            ...formContract,
            opt_tras_community_id_fiscal : event.target.value   
        }));
    };
    const handleBlurSelectCommunityFiscal_Opt_Tras = (e) => {
        handleChangeSelectCommunityFiscal_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };

    const handleChangeDataEditContract_Opt_Tras = (e) => {
        const { name, value } = e.target;
        setFormContract(formContract => ({
          ...formContract,
          [name]: value,
        }));
    };
    const handleBlurDataEditContract_Opt_Tras = (e) => {
        handleChangeDataEditContract_Opt_Tras(e);
        setErrorsForm(validateFormContract());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setPriority(1);
        setTetxtSearch("Opt_Tras");
        setShowModalSearchStoresAddress(true);
    };
 
    const searchFormStoreAddressFiscal = (event) => {
        setPriority(-1);
        setTetxtSearch("Opt_Tras_Fiscal")
        setShowModalSearchStoresAddress(true);
    };
 

    const deleteDateOptTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            opt_tras_store_id:0,
            opt_tras_documnettype_id: "",
            opt_tras_documentid:"",
            opt_tras_tradename: "",
            opt_tras_name: "",
            opt_tras_contactname: "",
            opt_tras_nima: "",
            opt_tras_inscription: "",
            opt_tras_grnp:"",
            opt_tras_opt: 0,
            opt_tras_phone: "",
            opt_tras_email: "",
            opt_tras_roadtype_id: 0,
            opt_tras_street: "",
            opt_tras_country_id: Number(DEFAULTCOUNTRY),
            opt_tras_province_id: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id: "",
            opt_tras_community_id: Number(DEFAULTCOMMUNITY),
            opt_tras_roadtype_id_fiscal: 0,
            opt_tras_street_fiscal: "",
            opt_tras_country_id_fiscal: Number(DEFAULTCOUNTRY),
            opt_tras_province_id_fiscal: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id_fiscal: "",
            opt_tras_community_id_fiscal: Number(DEFAULTCOMMUNITY),
        })); 
        setErrorsForm(validateFormContract());
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            opt_tras_store_id:"",
            opt_tras_documnettype_id: "",
            opt_tras_tradename: "",
            opt_tras_name: "",
            opt_tras_contactname: "",
            opt_tras_nima: "",
            opt_tras_inscription: "",
            opt_tras_grnp:"",
            opt_tras_opt: "",
            opt_tras_phone: "",
            opt_tras_email: "",
            opt_tras_roadtype_id: "",
            opt_tras_street: "",
            opt_tras_country_id: "",
            opt_tras_province_id: "",
            opt_tras_postalcode_id: "",
            opt_tras_community_id: "",
            opt_tras_roadtype_id_fiscal: "",
            opt_tras_street_fiscal: "",
            opt_tras_country_id_fiscal: "",
            opt_tras_province_id_fiscal: "",
            opt_tras_postalcode_id_fiscal: "",
            opt_tras_community_id_fiscal: "" 
        }));
        setErrorsForm(errorsForm => ({
            ...errorsForm,
            opt_tras_documentid: 'Text_FieldDateOptTrasDocumentIDRequired'
        })); 
    };

    const deleteDataStreetOptTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            opt_tras_roadtype_id: 0,
            opt_tras_street: "",
            opt_tras_country_id: Number(DEFAULTCOUNTRY),
            opt_tras_province_id: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id: "",
            opt_tras_community_id: Number(DEFAULTCOMMUNITY),
            opt_tras_nima: "",
            opt_tras_inscription: "",
            opt_tras_grnp:"",
            opt_tras_opt: 0
        }));
        setErrorsForm(validateFormContract());
    };

    const deleteDataStreetFiscalOptTras = (event) => {
        setFormContract(formContract => ({
          ...formContract,
            opt_tras_roadtype_id_fiscal: 0,
            opt_tras_street_fiscal: "",
            opt_tras_country_id_fiscal: Number(DEFAULTCOUNTRY),
            opt_tras_province_id_fiscal: Number(DEFAULTPROVINCE),
            opt_tras_postalcode_id_fiscal: "",
            opt_tras_community_id_fiscal: Number(DEFAULTCOMMUNITY)
        }));
        setErrorsForm(validateFormContract());
    }; 

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`contract/getexiststorebynumberdocument/`,token,formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, ""));
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formContract.opt_tras_store_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getcontractstorebynumberdocument/`,token,formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, ""));
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    if (getData!==undefined && getData!==null && getData!==""){
                        setFormContract(formContract => ({
                            ...formContract,
                            opt_tras_store_id: getData.store_id,
                            opt_tras_documnettype_id: getData.documnettype_id,
                            opt_tras_tradename: getData.tradename,
                            opt_tras_name: getData.name,
                            opt_tras_contactname: getData.contacname,
                            opt_tras_nima: getData.nima_opt_tras,
                            opt_tras_inscription: getData.pygr_opt_tras,
                            opt_tras_opt: getData.opt_opt_tras,
                            opt_tras_grnp:getData.grnp_opt_tras,
                            opt_tras_phone: getData.phone,
                            opt_tras_email: getData.email,
                            opt_tras_roadtype_id: (getData.roadtype_id===undefined 
                                                    || getData.roadtype_id===null  
                                                    || getData.roadtype_id===""
                                                    || getData.roadtype_id==="0"
                                                    || getData.roadtype_id===0)?
                                                    0:getData.roadtype_id,
                            opt_tras_street: getData.street,
                            opt_tras_country_id: (getData.country_id===undefined 
                                                    || getData.country_id===null  
                                                    || getData.country_id===""
                                                    || getData.country_id==="0"
                                                    || getData.country_id===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id,
                            opt_tras_province_id: (getData.province_id===undefined 
                                                    || getData.province_id===null  
                                                    || getData.province_id===""
                                                    || getData.province_id==="0"
                                                    || getData.province_id===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id,
                            opt_tras_postalcode_id: (getData.postalcode_id===undefined 
                                                        || getData.postalcode_id===null  
                                                        || getData.postalcode_id===""
                                                        || getData.postalcode_id==="0"
                                                        || getData.postalcode_id===0)?
                                                        "":getData.postalcode_id,
                            opt_tras_community_id: (getData.community_id===undefined 
                                                        || getData.community_id===null  
                                                        || getData.community_id===""
                                                        || getData.community_id==="0"
                                                        || getData.community_id===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id,
                            opt_tras_roadtype_id_fiscal: (getData.roadtype_id_fiscal===undefined 
                                                        || getData.roadtype_id_fiscal===null  
                                                        || getData.roadtype_id_fiscal===""
                                                        || getData.roadtype_id_fiscal==="0"
                                                        || getData.roadtype_id_fiscal===0)?
                                                        0:getData.roadtype_id_fiscal,          
                            opt_tras_street_fiscal: getData.street_fiscal,
                            opt_tras_country_id_fiscal: (getData.country_id_fiscal===undefined 
                                                    || getData.country_id_fiscal===null  
                                                    || getData.country_id_fiscal===""
                                                    || getData.country_id_fiscal==="0"
                                                    || getData.country_id_fiscal===0)?
                                                    Number(DEFAULTCOUNTRY):getData.country_id_fiscal,
                            opt_tras_province_id_fiscal: (getData.province_id_fiscal===undefined 
                                                    || getData.province_id_fiscal===null  
                                                    || getData.province_id_fiscal===""
                                                    || getData.province_id_fiscal==="0"
                                                    || getData.province_id_fiscal===0)?
                                                    Number(DEFAULTPROVINCE):getData.province_id_fiscal,
                            opt_tras_postalcode_id_fiscal: (getData.postalcode_id_fiscal===undefined 
                                                        || getData.postalcode_id_fiscal===null  
                                                        || getData.postalcode_id_fiscal===""
                                                        || getData.postalcode_id_fiscal==="0"
                                                        || getData.postalcode_id_fiscal===0)?
                                                        "":getData.postalcode_id_fiscal,
                            opt_tras_community_id_fiscal: (getData.community_id_fiscal===undefined 
                                                        || getData.community_id_fiscal===null  
                                                        || getData.community_id_fiscal===""
                                                        || getData.community_id_fiscal==="0"
                                                        || getData.community_id_fiscal===0)?
                                                        Number(DEFAULTCOMMUNITY):getData.community_id_fiscal,
                        }));
                        setErrorsForm(validateFormContract());
                    }  
                }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            if (formContract.opt_tras_documentid && formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }
        }
        return () => { isMounted = false };
    }, [t,token,dispatch,expanded,formContract.opt_tras_documentid,errorsForm.opt_tras_documentid,setIsLoadingErrorData,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,setFormContract,formContract.opt_tras_store_id,setErrorsForm,validateFormContract,DEFAULTPROVINCE]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formContract.opt_tras_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formContract.opt_tras_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Opt_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [t,token,dispatch,i18n,showModalContractSettings,formContract.opt_tras_country_id,expanded,DEFAULTPROVINCE]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formContract.opt_tras_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formContract.opt_tras_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formContract.opt_tras_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Opt_Tras(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [t,token,dispatch,i18n,showModalContractSettings,formContract.opt_tras_country_id,formContract.opt_tras_province_id,expanded]);
 
    useEffect(() => {
        let isMounted = true; 
        const fetchDataDocumentIdOpt = async () => {
          try {
            const getData = await helpHttp().get2(ENDPOINT,`contract/getlistdocumentidcontracts/`,token,
              (formContract.opt_tras_documentid!==undefined && formContract.opt_tras_documentid!==null && formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
              setDocumentIdOptToSelect(getData); 
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };   
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            if (formContract.opt_tras_documentid && formContract.opt_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataDocumentIdOpt();
            }
        } else{
            setDocumentIdOptToSelect(initialDocumentIdToSelect);
        } 
        return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,formContract.opt_tras_documentid,expanded]);
    
    useEffect(() => {
        let isMounted = true; 
        const fetchDataTradenameOpt = async () => {
            try {
                const getData = await helpHttp().get2(ENDPOINT,`contract/getlisttradenamecontracts/`,token,
                    (formContract.opt_tras_tradename!==undefined && formContract.opt_tras_tradename!==null && formContract.opt_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formContract.opt_tras_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
                if (getData.err) {
                    dispatch(warningErrorAction(t('Text_InvalidCredentials')));
                } else if (isMounted) {
                    setTradenameOptToSelect(getData);
                }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };  
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1'){
            if (formContract.opt_tras_tradename && formContract.opt_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
                fetchDataTradenameOpt();
            }
        } else{
            setTradenameOptToSelect(initialDocumentIdToSelect);
        }  
        return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,formContract.opt_tras_tradename,expanded]);
    

    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel1') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="opt_tras_documnettype_id"
                    name="opt_tras_documnettype_id"
                    value={ (formContract.opt_tras_documnettype_id === undefined || formContract.opt_tras_documnettype_id ===null || formContract.opt_tras_documnettype_id ===""
                        || formContract.opt_tras_documnettype_id ==="0" || formContract.opt_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formContract.opt_tras_documnettype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Opt_Tras}
                    onChange={handleChangeSelectDocumentType_Opt_Tras}
                    helperText={errorsForm.opt_tras_documnettype_id!=null && errorsForm.opt_tras_documnettype_id!==""?t(errorsForm.opt_tras_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formContract.opt_tras_documnettype_id === undefined || formContract.opt_tras_documnettype_id ===null || formContract.opt_tras_documnettype_id ===""
                            || formContract.opt_tras_documnettype_id ==="0" || formContract.opt_tras_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formContract.opt_tras_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18}}>{t('Text_CONTRACT_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;  
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="opt_tras_documentid"
                        name="opt_tras_documentid"
                        value={formContract.opt_tras_documentid}
                        onChange={handleSearchDocumentIdOpt}
                        onKeyDown={handleKeyDownDocumentIdOpt}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.opt_tras_documentid!=null?t(errorsForm.opt_tras_documentid):t('PlaceHolder_CONTRACT_CIF')}
                        placeholder={t('PlaceHolder_CONTRACT_CIF')}
                        variant="standard"
                        inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    />
                    {/* Desplegable dinámico */}
                    {filteredDocumentIdOpt.length > 0 && (
                        <div
                            className="dropdown-class"
                            style={{
                            position: "absolute",
                            left:'0px',
                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                            borderRadius: "4px",
                            marginTop: "4px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            width: "350%",
                            fontSize: '18px',
                            backgroundColor: "#f8f9fa", // Fondo gris claro
                            zIndex: 1000,
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                        >
                            {filteredDocumentIdOpt.map((store, index) => (
                            <MenuItem
                                key={store.id}
                                onClick={() => handleSelectDocumentIdOpt(store)}
                                style={{
                                    cursor: "pointer",
                                    padding: "8px",
                                    borderBottom: "1px solid #eee",
                                    fontSize: '18px',
                                    backgroundColor:
                                    index === highlightedIndexDocumentIdOpt ? "#4e73df" : "transparent",
                                    color: index === highlightedIndexDocumentIdOpt ? "white" : "black",
                                }}
                            >
                                {store.documentId} - {store.tradename}
                            </MenuItem>
                            ))}
                        </div>
                    )}
                </span>  
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDateOptTras}>
                    <DeleteIcon style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 18}} onClick={searchFormStore}>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_CONTACT')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="opt_tras_contactname" 
                    name="opt_tras_contactname"
                    value={formContract.opt_tras_contactname}
                    inputProps={{ style: {width: i18n.language==='es'?447:532,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.opt_tras_contactname!=null && errorsForm.opt_tras_contactname!==""?t(errorsForm.opt_tras_contactname):t('PlaceHolder_CONTRACT_CONTACT')}
                    placeholder={t('PlaceHolder_CONTRACT_CONTACT')}
                    variant="standard"
                    onBlur={handleBlurDataEditContract_Opt_Tras}
                    onChange={handleChangeDataEditContract_Opt_Tras}
                />
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_TRADENAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                    <TextField
                        id="opt_tras_tradename"
                        name="opt_tras_tradename"
                        value={formContract.opt_tras_tradename}
                        onChange={handleSearchTradenameOpt}
                        onKeyDown={handleKeyDownTradenameOpt}
                        onBlur={(event) => {
                            // Solo cerrar si el foco se pierde fuera del desplegable
                            if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                              handleBlur();
                            }
                        }}
                        label={errorsForm.opt_tras_tradename!=null?t(errorsForm.opt_tras_tradename):t('PlaceHolder_CONTRACT_TRADENAME')}
                        placeholder={t('PlaceHolder_CONTRACT_TRADENAME')}
                        variant="standard"
                        inputProps={{ style: {width: 590,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    />
                    {/* Desplegable dinámico */}
                    {filteredTradenamedOpt.length > 0 && (
                        <div
                            className="dropdown-class"
                            style={{
                            position: "absolute",
                            left:'0px',
                            border: "2px solid #4e73df", // Borde azul (#4e73df)
                            borderRadius: "4px",
                            marginTop: "4px",
                            maxHeight: "200px",
                            overflowY: "auto",
                            width: "150%",
                            fontSize: '18px',
                            backgroundColor: "#f8f9fa", // Fondo gris claro
                            zIndex: 1000,
                            }}
                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                        >
                            {filteredTradenamedOpt.map((store, index) => (
                            <MenuItem
                                key={store.id}
                                onClick={() => handleSelectTradeNameOpt(store)}
                                style={{
                                    cursor: "pointer",
                                    padding: "8px",
                                    borderBottom: "1px solid #eee",
                                    fontSize: '18px',
                                    backgroundColor:
                                    index === highlightedIndexTradenamedOpt ? "#4e73df" : "transparent",
                                    color: index === highlightedIndexTradenamedOpt ? "white" : "black",
                                }}
                            >
                                {store.tradename} - {store.documentId}
                            </MenuItem>
                            ))}
                        </div>
                    )}
                </span> 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="opt_tras_name" 
                    name="opt_tras_name"
                    value={formContract.opt_tras_name}
                    inputProps={{ style: {width: i18n.language==='es'?594:615,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                    label={errorsForm.opt_tras_name!=null && errorsForm.opt_tras_name!==""?t(errorsForm.opt_tras_name):t('PlaceHolder_CONTRACT_NAME')}
                    placeholder={t('PlaceHolder_CONTRACT_NAME')}
                    variant="standard"
                    onBlur={handleBlurDataEditContract_Opt_Tras}
                    onChange={handleChangeDataEditContract_Opt_Tras}
                />
                </div>
            </div>
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_roadtype_id"
                        name="opt_tras_roadtype_id"
                        value={ (formContract.opt_tras_roadtype_id === undefined || formContract.opt_tras_roadtype_id ===null || formContract.opt_tras_roadtype_id ===""
                            || formContract.opt_tras_roadtype_id ==="0" || formContract.opt_tras_roadtype_id ===0)?"":formContract.opt_tras_roadtype_id}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurSelectRoadType_Opt_Tras}
                        onChange={handleChangeSelectRoadType_Opt_Tras}
                        helperText={errorsForm.opt_tras_roadtype_id!=null && errorsForm.opt_tras_roadtype_id!==""?t(errorsForm.opt_tras_roadtype_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_roadtype_id === undefined || formContract.opt_tras_roadtype_id ===null || formContract.opt_tras_roadtype_id ===""
                                || formContract.opt_tras_roadtype_id ==="0" || formContract.opt_tras_roadtype_id ===0)?"": formContract.opt_tras_roadtype_id
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_ADDRESS')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_street" 
                        name="opt_tras_street"
                        value={formContract.opt_tras_street}
                        inputProps={{ style: {width: i18n.language==='es'?497:540, fontSize: '18px'}, maxLength: 250, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.opt_tras_street!=null && errorsForm.opt_tras_street!==""?t(errorsForm.opt_tras_street):t('PlaceHolder_CONTRACT_ADDRESS')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras}
                    />
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataStreetOptTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores')) && formContract.opt_tras_documentid!==undefined && formContract.opt_tras_documentid!=="")?
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.opt_tras_store_id===undefined
                            || formContract.opt_tras_store_id===""
                            || formContract.opt_tras_store_id==="0"
                            || formContract.opt_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                            disabled={formContract.opt_tras_store_id===undefined
                                    || formContract.opt_tras_store_id===""
                                    || formContract.opt_tras_store_id==="0"
                                    || formContract.opt_tras_store_id===0 } onClick={searchFormStoreAddress}>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_community_id"
                        name="opt_tras_community_id"
                        value={ (formContract.opt_tras_community_id === undefined || formContract.opt_tras_community_id ===null || formContract.opt_tras_community_id ===""
                            || formContract.opt_tras_community_id ==="0" || formContract.opt_tras_community_id ===0)?Number(DEFAULTCOMMUNITY):formContract.opt_tras_community_id}
                        inputProps={{ style: {width: 250, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCommunity_Opt_Tras}
                        onChange={handleChangeSelectCommunity_Opt_Tras}
                        helperText={errorsForm.opt_tras_community_id!=null  && errorsForm.opt_tras_community_id!==""?t(errorsForm.opt_tras_community_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_community_id === undefined || formContract.opt_tras_community_id ===null || formContract.opt_tras_community_id ===""
                                || formContract.opt_tras_community_id ==="0" || formContract.opt_tras_community_id ===0)?Number(DEFAULTCOMMUNITY): formContract.opt_tras_community_id
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_country_id"
                        name="opt_tras_country_id"
                        value={ (formContract.opt_tras_country_id === undefined || formContract.opt_tras_country_id ===null || formContract.opt_tras_country_id ===""
                                || formContract.opt_tras_country_id ==="0" || formContract.opt_tras_country_id ===0)?Number(DEFAULTCOUNTRY):formContract.opt_tras_country_id}
                        inputProps={{ style: {width: i18n.language==='es'?310:335, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountry_Opt_Tras}
                        onChange={handleChangeSelectCountry_Opt_Tras}
                        helperText={errorsForm.opt_tras_country_id!=null && errorsForm.opt_tras_country_id!==""?t(errorsForm.opt_tras_country_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_country_id === undefined || formContract.opt_tras_country_id ===null || formContract.opt_tras_country_id ===""
                                || formContract.opt_tras_country_id ==="0" || formContract.opt_tras_country_id ===0)?Number(DEFAULTCOUNTRY): formContract.opt_tras_country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_province_id"
                        name="opt_tras_province_id"
                        value={ (formContract.opt_tras_province_id === undefined || formContract.opt_tras_province_id ===null || formContract.opt_tras_province_id ===""
                            || formContract.opt_tras_province_id ==="0" || formContract.opt_tras_province_id ===0)?Number(DEFAULTPROVINCE):formContract.opt_tras_province_id}
                        inputProps={{ style: {width: 310, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectProvince_Opt_Tras}
                        onChange={handleChangeSelectProvince_Opt_Tras}
                        helperText={errorsForm.opt_tras_province_id!=null && errorsForm.opt_tras_province_id!==""?t(errorsForm.opt_tras_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_province_id === undefined || formContract.opt_tras_province_id ===null || formContract.opt_tras_province_id ===""
                                || formContract.opt_tras_province_id ==="0" || formContract.opt_tras_province_id ===0)?Number(DEFAULTPROVINCE): formContract.opt_tras_province_id
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Opt_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_postalcode_id"
                        name="opt_tras_postalcode_id"
                        value={ (formContract.opt_tras_postalcode_id === undefined || formContract.opt_tras_postalcode_id ===null || formContract.opt_tras_postalcode_id ===""
                            || formContract.opt_tras_postalcode_id ==="0" || formContract.opt_tras_postalcode_id ===0)?"":formContract.opt_tras_postalcode_id}
                        inputProps={{ style: {width: i18n.language==='es'?399:365, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCode_Opt_Tras}
                        onChange={handleChangeSelectPostalCode_Opt_Tras}
                        helperText={errorsForm.opt_tras_postalcode_id!=null && errorsForm.opt_tras_postalcode_id!==""?t(errorsForm.opt_tras_postalcode_id):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.opt_tras_postalcode_id === undefined || formContract.opt_tras_postalcode_id ===null || formContract.opt_tras_postalcode_id ===""
                            || formContract.opt_tras_postalcode_id ==="0" || formContract.opt_tras_postalcode_id ===0)?"": formContract.opt_tras_postalcode_id
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Opt_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                </div>
            </div>
            <Typography style={{color:"#067330", fontSize: 16}} >  
                {t('Text_CONTRACT_ADDRESS_FISCAL')}
            </Typography>
            <hr className="sidebar-divider d-none d-md-block"/>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_RoadType')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_roadtype_id_fiscal"
                        name="opt_tras_roadtype_id_fiscal"
                        value={ (formContract.opt_tras_roadtype_id_fiscal === undefined || formContract.opt_tras_roadtype_id_fiscal ===null || formContract.opt_tras_roadtype_id_fiscal ===""
                            || formContract.opt_tras_roadtype_id_fiscal ==="0" || formContract.opt_tras_roadtype_id_fiscal ===0)?"":formContract.opt_tras_roadtype_id_fiscal}
                        inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurSelectRoadType_Fiscal_Opt_Tras}
                        onChange={handleChangeSelectRoadTypeFiscal_Opt_Tras}
                        helperText={errorsForm.opt_tras_roadtype_id_fiscal!=null && errorsForm.opt_tras_roadtype_id_fiscal!==""?t(errorsForm.opt_tras_roadtype_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_roadtype_id_fiscal === undefined || formContract.opt_tras_roadtype_id_fiscal ===null || formContract.opt_tras_roadtype_id_fiscal ===""
                                || formContract.opt_tras_roadtype_id_fiscal ==="0" || formContract.opt_tras_roadtype_id_fiscal ===0)?"": formContract.opt_tras_roadtype_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {roadTypesToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_ADDRESS_FISCAL')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_street_fiscal" 
                        name="opt_tras_street_fiscal"
                        value={formContract.opt_tras_street_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?533:547, fontSize: '18px'}, maxLength: 250, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.opt_tras_street_fiscal!=null && errorsForm.opt_tras_street_fiscal!==""?t(errorsForm.opt_tras_street_fiscal):t('PlaceHolder_CONTRACT_ADDRESS_FISCAL')}
                        placeholder={t('PlaceHolder_CONTRACT_ADDRESS_FISCAL')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras}
                    />
                    
                    &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 18}} onClick={deleteDataStreetFiscalOptTras}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores')) && formContract.opt_tras_documentid!==undefined && formContract.opt_tras_documentid!=="") ?
                        <IconButton  size="small"  style={{
                            color:
                            (formContract.opt_tras_store_id===undefined
                            || formContract.opt_tras_store_id===""
                            || formContract.opt_tras_store_id==="0"
                            || formContract.opt_tras_store_id===0)?"#858796":"#4e73df", fontSize: 18}} 
                            disabled={formContract.opt_tras_store_id===undefined
                                    || formContract.opt_tras_store_id===""
                                    || formContract.opt_tras_store_id==="0"
                                    || formContract.opt_tras_store_id===0 } onClick={searchFormStoreAddressFiscal}>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>:
                            <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Community')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_community_id_fiscal"
                        name="opt_tras_community_id_fiscal"
                        value={ (formContract.opt_tras_community_id_fiscal === undefined || formContract.opt_tras_community_id_fiscal ===null || formContract.opt_tras_community_id_fiscal ===""
                            || formContract.opt_tras_community_id_fiscal ==="0" || formContract.opt_tras_community_id_fiscal ===0)?Number(DEFAULTCOMMUNITY):formContract.opt_tras_community_id_fiscal}
                        inputProps={{ style: {width: 250, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCommunityFiscal_Opt_Tras}
                        onChange={handleChangeSelectCommunityFiscal_Opt_Tras}
                        helperText={errorsForm.opt_tras_community_id_fiscal!=null  && errorsForm.opt_tras_community_id_fiscal!==""?t(errorsForm.opt_tras_community_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_community_id_fiscal === undefined || formContract.opt_tras_community_id_fiscal ===null || formContract.opt_tras_community_id_fiscal ===""
                                || formContract.opt_tras_community_id_fiscal ==="0" || formContract.opt_tras_community_id_fiscal ===0)?Number(DEFAULTCOMMUNITY): formContract.opt_tras_community_id_fiscal
                        }}>
                        {communityToSelect.map((option) => (
                            <option key={option.id} value={option.id}>
                                {i18n.language==="es"?option.namees:option.nameen}
                            </option>
                        ))}
                    </TextField>  
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_country_id_fiscal"
                        name="opt_tras_country_id_fiscal"
                        value={ (formContract.opt_tras_country_id_fiscal === undefined || formContract.opt_tras_country_id_fiscal ===null || formContract.opt_tras_country_id_fiscal ===""
                                || formContract.opt_tras_country_id_fiscal ==="0" || formContract.opt_tras_country_id_fiscal ===0)?Number(DEFAULTCOUNTRY):formContract.opt_tras_country_id_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?310:335, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        variant="outlined"
                        onBlur={handleBlurSelectCountryFiscal_Opt_Tras}
                        onChange={handleChangeSelectCountryFiscal_Opt_Tras}
                        helperText={errorsForm.opt_tras_country_id_fiscal!=null && errorsForm.opt_tras_country_id_fiscal!==""?t(errorsForm.opt_tras_country_id_fiscal):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_country_id_fiscal === undefined || formContract.opt_tras_country_id_fiscal ===null || formContract.opt_tras_country_id_fiscal ===""
                                || formContract.opt_tras_country_id_fiscal ==="0" || formContract.opt_tras_country_id_fiscal ===0)?Number(DEFAULTCOUNTRY): formContract.opt_tras_country_id_fiscal
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_province_id_fiscal"
                        name="opt_tras_province_id_fiscal"
                        value={ (formContract.opt_tras_province_id_fiscal === undefined || formContract.opt_tras_province_id_fiscal ===null || formContract.opt_tras_province_id_fiscal ===""
                            || formContract.opt_tras_province_id_fiscal ==="0" || formContract.opt_tras_province_id_fiscal ===0)?Number(DEFAULTPROVINCE):formContract.opt_tras_province_id_fiscal}
                        inputProps={{ style: {width: 310, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectProvinceFiscal_Opt_Tras}
                        onChange={handleChangeSelectProvinceFiscal_Opt_Tras}
                        helperText={errorsForm.opt_tras_province_id_fiscal!=null && errorsForm.opt_tras_province_id!==""?t(errorsForm.opt_tras_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_province_id_fiscal === undefined || formContract.opt_tras_province_id_fiscal ===null || formContract.opt_tras_province_id_fiscal ===""
                                || formContract.opt_tras_province_id_fiscal ==="0" || formContract.opt_tras_province_id_fiscal ===0)?Number(DEFAULTPROVINCE): formContract.opt_tras_province_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Opt_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_postalcode_id_fiscal"
                        name="opt_tras_postalcode_id_fiscal"
                        value={ (formContract.opt_tras_postalcode_id_fiscal === undefined || formContract.opt_tras_postalcode_id_fiscal ===null || formContract.opt_tras_postalcode_id_fiscal ===""
                            || formContract.opt_tras_postalcode_id_fiscal ==="0" || formContract.opt_tras_postalcode_id_fiscal ===0)?"":formContract.opt_tras_postalcode_id_fiscal}
                        inputProps={{ style: {width: i18n.language==='es'?399:365, fontSize: '18px'}, maxLength: 100, }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCodeFiscal_Opt_Tras}
                        onChange={handleChangeSelectPostalCodeFiscal_Opt_Tras}
                        helperText={errorsForm.opt_tras_postalcode_id_fiscal!=null && errorsForm.opt_tras_postalcode_id_fiscal!==""?t(errorsForm.opt_tras_postalcode_id_fiscal):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formContract.opt_tras_postalcode_id_fiscal === undefined || formContract.opt_tras_postalcode_id_fiscal ===null || formContract.opt_tras_postalcode_id_fiscal ===""
                            || formContract.opt_tras_postalcode_id_fiscal ==="0" || formContract.opt_tras_postalcode_id_fiscal ===0)?"": formContract.opt_tras_postalcode_id_fiscal
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Opt_Tras.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="opt_tras_phone" 
                        name="opt_tras_phone"
                        value={formContract.opt_tras_phone}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 30 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                        label={errorsForm.opt_tras_phone!=null && errorsForm.opt_tras_phone!==""?t(errorsForm.opt_tras_phone):t('PlaceHolder_CONTRACT_Phone')}
                        placeholder={t('PlaceHolder_CONTRACT_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_email" 
                        name="opt_tras_email"
                        value={formContract.opt_tras_email}
                        inputProps={{ style: {width: i18n.language==='es'?350:350, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        label={errorsForm.opt_tras_email!=null && errorsForm.opt_tras_email!==""?t(errorsForm.opt_tras_email):t('PlaceHolder_CONTRACT_Email')}
                        placeholder={t('PlaceHolder_CONTRACT_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_OPT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;  
                    <TextField
                        id="opt_tras_opt"
                        name="opt_tras_opt"
                        value={ (formContract.opt_tras_opt === undefined || formContract.opt_tras_opt ===null || formContract.opt_tras_opt ===""
                            || formContract.opt_tras_opt ==="0" || formContract.opt_tras_opt ===0)?0:formContract.opt_tras_opt}
                        inputProps={{ style: {width: i18n.language==='es'?530:571, fontSize: '18px'}, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}     
                        variant="outlined"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras} 
                        helperText={errorsForm.opt_tras_opt!=null?t(errorsForm.opt_tras_opt):""}
                         select
                        SelectProps={{
                            native: true,
                            value: (formContract.opt_tras_opt === undefined || formContract.opt_tras_opt ===null || formContract.opt_tras_opt ===""
                                || formContract.opt_tras_opt ==="0" || formContract.opt_tras_opt ===0)?0: formContract.opt_tras_opt
                        }}>
                        <option key={0} value=""/>
                        {optTypeToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.code} - {option.namees}
                        </option>
                        ))}
                    </TextField>   
                </div>
            </div>  
          
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_NIMA')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_nima" 
                        name="opt_tras_nima"
                        value={formContract.opt_tras_nima}
                        inputProps={{ style: {width: i18n.language==='es'?200:200, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.opt_tras_nima!=null && errorsForm.opt_tras_nima!==""?t(errorsForm.opt_tras_nima):t('PlaceHolder_CONTRACT_NIMA')}
                        placeholder={t('PlaceHolder_CONTRACT_NIMA')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_INSCRIPTION')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_inscription" 
                        name="opt_tras_inscription"
                        value={formContract.opt_tras_inscription}
                        inputProps={{ style: {width: i18n.language==='es'?300:300, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}      
                        label={errorsForm.opt_tras_inscription!=null && errorsForm.opt_tras_inscription!==""?t(errorsForm.opt_tras_inscription):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_CONTRACT_GESTOR')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="opt_tras_grnp" 
                        name="opt_tras_grnp"
                        value={formContract.opt_tras_grnp}
                        inputProps={{ style: {width: i18n.language==='es'?250:250, fontSize: '18px'}, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.opt_tras_grnp!=null && errorsForm.opt_tras_grnp!==""?t(errorsForm.opt_tras_grnp):t('PlaceHolder_CONTRACT_INSCRIPTION')}
                        placeholder={t('PlaceHolder_CONTRACT_GESTOR')}
                        variant="standard"
                        onBlur={handleBlurDataEditContract_Opt_Tras}
                        onChange={handleChangeDataEditContract_Opt_Tras} 
                    />
                   
                </div>
            </div> 
        
            <ContractSearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Opt_Tras"}
                setFormContract={setFormContract}
            />
            <ContractSearchStoresAddresses
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={tetxtSearch}
                setFormContract={setFormContract}
                titleTable={formContract.opt_tras_tradename}
                store_id={formContract.opt_tras_store_id}
                priority={priority}
            />
        </> 
    )
}

ContractCreateOrEditOptTras.propTypes = {
    formContract: PropTypes.object.isRequired,
    setFormContract: PropTypes.func.isRequired,
    showModalContractSettings: PropTypes.bool.isRequired,
    setIsLoadingErrorData: PropTypes.func.isRequired,
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateFormContract: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default ContractCreateOrEditOptTras;