import React, {  useEffect, useCallback} from 'react';  
import { useTranslation } from "react-i18next" 
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import Textarea from '@mui/joy/Textarea'; 
import "css/generalSettings.css";   
import PropTypes from 'prop-types';
  
const SendEmailEdit = ({ openEditRow,setOpenEditRow,rowEdit}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']); 
 
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && openEditRow) {
        setOpenEditRow(false);  
      }
    },
    [
      setOpenEditRow,  
      openEditRow
    ]
  );

  const closeModalSentEmailEdit = () => {
    setOpenEditRow(false);   
  }; 

  const toggleDrawerEmail = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown' ) {
      closeModalSentEmailEdit();
      return;
    }
  };
      
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
 
  return (
    <div> 
      {openEditRow && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerEmail(false)}
            onOpen={toggleDrawerEmail(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  <i className="fas fa-solid fa-envelope text-warning"></i> {t('Text_EMAIL_Email')} {rowEdit.emailto}</h1>
                  <div>
                    <button type="button" className="buttonBack" onClick={closeModalSentEmailEdit}>{t('Button_Back')}</button>
                  </div>
                </div>
                <br/> 
                <hr className="sidebar-divider d-none d-md-block"/>
                <div className="card shadow mb-4">     
                  
                  <div className="row p-4"> 
                    <label className="text-primary">{t('Text_SENDEMAIL_Subject')}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                      id="subjectEmail" 
                      name="subjectEmail"
                      value={ rowEdit.subjectEmail!== null ? rowEdit.subjectEmail:""}
                      inputProps={{ style: {width: i18n.language==="es"?1001:900,}, maxLength: 100 }}
                      label={t('Text_SENDEMAIL_Subject')}
                      placeholder={t('Text_SENDEMAIL_Subject')}
                      variant="standard"
                      disabled>
                    </TextField>
                  </div>
                  <div className="row p-4"> 
                    <label className="text-primary">{t('Text_SENDEMAIL_Content')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;   
                    <Textarea 
                      id="contentEmail" 
                      name="contentEmail"
                      value={rowEdit.contentEmail}
                      label={t('Text_SENDEMAIL_Content')}
                      placeholder={t('Text_SENDEMAIL_Content')}
                      sx={{width: i18n.language==="es"?970:800} }
                      variant="standard"
                      disabled
                      maxRows={8}
                    />  
                  </div>  
                </div> 
              </Box>
            </Container>
          </SwipeableDrawer>  
        </div>
      } 
    </div>
  )
}

SendEmailEdit.propTypes = {
  openEditRow: PropTypes.bool.isRequired,
  setOpenEditRow: PropTypes.func.isRequired 
};
  
export default SendEmailEdit;

