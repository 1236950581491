import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom'; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ENDPOINT } from "constants/variables";
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card'; 
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'; 
import CircularProgress from '@mui/material/CircularProgress'; 
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'; 
import IconButton from '@mui/material/IconButton'; 
import Grid  from '@mui/material/Grid';
import MenuItem  from "@mui/material/MenuItem"; 
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import SignatureCanvas from 'react-signature-canvas'; 
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';  
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { NumericFormat } from 'react-number-format';
import { experimentalStyled as styled } from '@mui/material/styles'; 
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css"; 
import PurchaseDeliverySearchStores from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchStores";
import PurchaseDeliverySearchVehicles from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchVehicles";
import PurchaseDeliverySearchStoresAddresses from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchStoresAddress";
import PurchaseDeliverySearchArticles from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchArticles";
import TableSortingPurchaseDeliveryArticleList from "pages/purchaseDelivery/editOrNewPurchaseDelivery/SortingTablesPurchaseDeliveryArticlesList";
import PurchaseDeliverySearchDI from "pages/purchaseDelivery/editOrNewPurchaseDelivery/PurchaseDeliverySearchDI";
import image1 from "components/photos/formdi/tosign.png"; 
import signFrancisco from "components/photos/signs/Francisco.png"; 
import signGonzalo from "components/photos/signs/Gonzalo.png"; 
import signGuillermo from "components/photos/signs/Guillermo.png"; 
import signJorge from "components/photos/signs/Jorge.png"; 

import PropTypes from 'prop-types';  

const initialStatePurchaseDelivery = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "purchasenote":0, 
  "numberticket":0, 
  "ownnumberpurchase":"", 
  "datepurchase": null, 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_nima": "",
  "rel_trans_pygr": "",
  "rel_trans_grnp":"",
  "rel_trans_opt": 0,
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_nima": "",
  "ori_tras_pygr": "",
  "ori_tras_grnp":"",
  "ori_tras_opt": 0,
  "ori_tras_workplace": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalpurchasedelivery": 0,
  "totaldiscountpurchasedelivery": 0,
  "totaltaxespurchasedelivery":0,
  "totalNetoPurchasedelivery":0,
  "enabled":true,
  "subownnumberpurchase":0,
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": "",
  "totalWeigthNeto":0,
  "signature_dispatched_by":"",
  "signature_client":"",

  "linespurchasedelivery":[]
};

const initialProvincesToSelect = [{
  "id" : "",
  "name" : "",
  "enabled" : true,
  "country_id":1
}];

const initialPaymentToSelect = [{
  "id": "",
  "code": "",
  "namees": "",
  "nameen": "",
}];

const initialPostalCodesToSelect = [{
  "id":"",
  "postalcode":"",
  "name":"",
  "postalcodename":"",
  "enabled":true,
  "province_id":30
}];

const initialArticlesToSelect = {
  "id": 0,
  "indexrowarticle":0, 
  "purchase_id":0,
  "article_id":0,
  "article_code": "",
  "article_namees": "",
  "article_nameen": "",
  "datepurchase": null,
  "estimatedprice": 0,
  "amount": 0,
  "discount":0,
  "tax":0,
  "taxincluded":false, 
  "total":0,
  "ticketweight":true,
  "formdi_id":0,
  "ownformdi_id":0
  
}; 

const initialVehicleToSelect = [{
  "id": "",
  "vehiclestore_licenseplate": "" 
}];

const initialWorkPlaceToSelect = [{
  "id": "",
  "workplace": "" 
}];

const initialDocumentIdToSelect = [{
  "id": "",
  "documentId": "",
  "tradename": ""
}]; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

const PurchaseDeliveryEditOrCreate = ({id, showModalPurchasesDeliverySettings,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token);  
  const roles = useSelector((state) => state.loginUser.roles);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaulttypesdi = useSelector((state) => state.loginUser.defaulttypesdi.formatnumber);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesize);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
  const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
  const articlesButtonsList = useSelector((state) => state.loginUser.defaultticketsformat.articlesButtonsList);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formPurchaseDelivery, setFormPurchaseDelivery] = useState(initialStatePurchaseDelivery);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorData, setIsLoadingErrorData] = useState(false);
  const [errorsForm, setErrorsForm] = useState({});
  const [tetxtSearch, setTetxtSearch] = useState("Rel_Trans");  
  const [idCodeDeletePurchaseDelivery, setIdCodeDeletePurchaseDelivery] = useState(DELETE_CODE);
  const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
  const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false);  
  const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false);  
  const [showModalSearchArticles, setShowModalSearchArticles] = useState(false); 
  const [openDeleteRowArticles,setOpenDeleteRowArticles]= useState(false);
  const [editRowArticles, setEditRowArticles] = useState(initialArticlesToSelect); 
  const [provinceToSelect,setProvinceToSelect]= useState(initialProvincesToSelect);
  const [postalCodeToSelect,setPostalCodeToSelect]= useState(initialPostalCodesToSelect);
  const [paymenetTypeSelect,setPaymenetTypeSelect]= useState(initialPaymentToSelect); 
  const [showModalSearchDI, setShowModalSearchDI] = useState(false);  
  const [openSignDispatched,setOpenSignDispatched]= useState(false); 
  const [openSignClient,setOpenSignClient]= useState(false);
  const [signDispatchedCanvas, setSignDispatchedCanvas]= useState(); 
  const [signClientCanvas, setSignClientCanvas]= useState(); 
  const [vehiclesToSelect,setVehiclesToSelect]= useState(initialVehicleToSelect); 
  const [filteredVehicle, setFilteredVehicle] = useState([]); // Opciones filtradas
  const [highlightedIndexVehicle, setHighlightedIndexVehicle] = useState(-1); // Índice de la opción resaltada 
  const [documentIdToSelect,setDocumentIdToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredDocumentId, setFilteredDocumentId] = useState([]); // Opciones filtradas
  const [highlightedIndexDocumentId, setHighlightedIndexDocumentId] = useState(-1); // Índice de la opción resaltada 
  const [tradenameToSelect,setTradenameToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredTradename, setFilteredTradename] = useState([]); // Opciones filtradas
  const [highlightedIndexTradename, setHighlightedIndexTradename] = useState(-1); // Índice de la opción resaltada 
  const [documentIdOriToSelect,setDocumentIdOriToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredDocumentIdOri, setFilteredDocumentIdOri] = useState([]); // Opciones filtradas
  const [highlightedIndexDocumentIdOri, setHighlightedIndexDocumentIdOri] = useState(-1); // Índice de la opción resaltada 
  const [tradenameOriToSelect,setTradenameOriToSelect]= useState(initialDocumentIdToSelect);  
  const [filteredTradenamedOri, setFilteredTradenamedOri] = useState([]); // Opciones filtradas
  const [highlightedIndexTradenamedOri, setHighlightedIndexTradenamedOri] = useState(-1); // Índice de la opción resaltada 
  const [workPlaceToSelect,setWorkPlaceToSelect]= useState(initialWorkPlaceToSelect);  
  const [filteredWorkPlace, setFilteredWorkPlace] = useState([]); // Opciones filtradas
  const [highlightedIndexWorkPlace, setHighlightedIndexWorkPlace] = useState(-1); // Índice de la opción resaltada 
    
  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesDeliverySettings) {
        setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          linespurchasedelivery: [],
        }));
        setFormPurchaseDelivery(initialStatePurchaseDelivery);
        dispatch(setLoading(false));
        setIsLoadingErrorData(false);
        setErrorsForm({});
        setTetxtSearch("Rel_Trans ");   
        setIdCodeDeletePurchaseDelivery(DELETE_CODE);
        setShowModalSearchStores(false);
        setShowModalSearchVehicles(false);
        setShowModalSearchStoresAddress(false);
        setShowModalSearchArticles(false); 
        setOpenSignDispatched(false);
        setOpenSignClient(false);
        setOpenDeleteRowArticles(false); 
        setShowModalSearchDI(false);
        setSignDispatchedCanvas();
        setSignClientCanvas();
        setEditRowArticles(initialArticlesToSelect); 
        setProvinceToSelect(initialProvincesToSelect);
        setPostalCodeToSelect(initialPostalCodesToSelect);
        setPaymenetTypeSelect(initialPaymentToSelect);
        setVehiclesToSelect(initialVehicleToSelect); 
        setDocumentIdToSelect(initialDocumentIdToSelect);
        setTradenameToSelect(initialDocumentIdToSelect); 
        setDocumentIdOriToSelect(initialDocumentIdToSelect); 
        setTradenameOriToSelect(initialDocumentIdToSelect); 
        setWorkPlaceToSelect(initialWorkPlaceToSelect);
        setFilteredVehicle([]);
        setHighlightedIndexVehicle(-1); 
        setFilteredDocumentId([]);
        setHighlightedIndexDocumentId(-1); 
        setFilteredTradename([]);
        setHighlightedIndexTradename(-1); 
        setFilteredDocumentIdOri([]);
        setHighlightedIndexDocumentIdOri(-1); 
        setFilteredTradenamedOri([]);
        setHighlightedIndexTradenamedOri(-1); 
        setFilteredWorkPlace([]);
        setHighlightedIndexWorkPlace(-1); 
        navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
      }
    },
    [
      setFormPurchaseDelivery, 
      setIsLoadingErrorData,
      setErrorsForm,
      setTetxtSearch,  
      setIdCodeDeletePurchaseDelivery,
      setShowModalSearchStores,
      setShowModalSearchVehicles,
      setShowModalSearchStoresAddress,
      setShowModalSearchArticles, 
      setOpenDeleteRowArticles,
      setEditRowArticles,   
      setShowModalSearchDI,
      setProvinceToSelect,
      setPostalCodeToSelect,
      setPaymenetTypeSelect,
      setOpenSignDispatched,
      setOpenSignClient,
      setSignDispatchedCanvas,
      setSignClientCanvas,
      setVehiclesToSelect, 
      setDocumentIdToSelect,
      setTradenameToSelect, 
      setDocumentIdOriToSelect, 
      setTradenameOriToSelect,
      setWorkPlaceToSelect,
      setFilteredVehicle,
      setHighlightedIndexVehicle,
      setFilteredDocumentId, 
      setHighlightedIndexDocumentId,
      setFilteredTradename, 
      setHighlightedIndexTradename,
      setFilteredDocumentIdOri,
      setHighlightedIndexDocumentIdOri,
      setFilteredTradenamedOri,
      setHighlightedIndexTradenamedOri,
      setFilteredWorkPlace,
      setHighlightedIndexWorkPlace,
      showModalPurchasesDeliverySettings,
      navigate,
      typeOfList,
      dispatch,
      DELETE_CODE]
  );
  
  const handleCloseDataEditPurchaseDelivery = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      linespurchasedelivery: [],
    }));
    setFormPurchaseDelivery(initialStatePurchaseDelivery);
    dispatch(setLoading(false));
    setIsLoadingErrorData(false);
    setErrorsForm({});
    setTetxtSearch("Rel_Trans ");   
    setIdCodeDeletePurchaseDelivery(DELETE_CODE);
    setShowModalSearchStores(false);
    setShowModalSearchVehicles(false);
    setShowModalSearchStoresAddress(false);
    setShowModalSearchArticles(false); 
    setOpenDeleteRowArticles(false); 
    setShowModalSearchDI(false);
    setOpenSignDispatched(false);
    setOpenSignClient(false);
    setSignDispatchedCanvas();
    setSignClientCanvas();
    setEditRowArticles(initialArticlesToSelect); 
    setProvinceToSelect(initialProvincesToSelect);
    setPostalCodeToSelect(initialPostalCodesToSelect);
    setPaymenetTypeSelect(initialPaymentToSelect);
    setVehiclesToSelect(initialVehicleToSelect); 
    setDocumentIdToSelect(initialDocumentIdToSelect);
    setTradenameToSelect(initialDocumentIdToSelect);
    setDocumentIdOriToSelect(initialDocumentIdToSelect); 
    setTradenameOriToSelect(initialDocumentIdToSelect); 
    setFilteredVehicle([]);
    setHighlightedIndexVehicle(-1); 
    setFilteredDocumentId([]);
    setHighlightedIndexDocumentId(-1); 
    setFilteredTradename([]);
    setHighlightedIndexTradename(-1); 
    setFilteredDocumentIdOri([]);
    setHighlightedIndexDocumentIdOri(-1); 
    setFilteredTradenamedOri([]);
    setHighlightedIndexTradenamedOri(-1); 
    setFilteredWorkPlace([]);
    setHighlightedIndexWorkPlace(-1); 
    navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
	};

  const selectModalSignature = (id) => {
    switch (id) {
      case 1:   
        setOpenSignDispatched(true);  
        break;
      case 2:    
        setOpenSignClient(true);
        break;
      default:
        break;
    } 
  };

  const deleteSignature = (id) => {
    switch (id) {
      case 1:   
      setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          signature_dispatched_by: "",
        }));
        break;
      case 2:    
      setFormPurchaseDelivery(formPurchaseDelivery => ({
          ...formPurchaseDelivery,
          signature_client: "",
        }));
        break;
      default:
        break;
    } 
  };
  
  const handleCancelSignDispatched = () => {
    signDispatchedCanvas.clear();
    setOpenSignDispatched(false);
  };

  const handleDeleteSignDispatched = () => {
    signDispatchedCanvas.clear();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: "",
    }));
    setOpenSignDispatched(false);
  };
   
  const handleSubmitSignDispatched = () => {
    const dataURL = signDispatchedCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by:dataURL,
    }));
    setOpenSignDispatched(false);
  };
  
  const handleSetSignatureFrancisco = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signFrancisco,
    }));
  };

  const handleSetSignatureGonzalo = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signGonzalo,
    }));
  };
  
  const handleSetSignatureGuillermo = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signGuillermo,
    }));
  };
  
  const handleSetSignatureJorge = () => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_dispatched_by: signJorge,
    }));
  };

  const handleCancelSignClient = () => {
    signClientCanvas.clear();
    setOpenSignClient(false);
  };

  const handleDeleteSignClient = () => {
    signClientCanvas.clear();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_client: "",
    }));
    setOpenSignClient(false);
  };
  
  const handleSubmitSignClient = () => {
    const dataURL = signClientCanvas.getTrimmedCanvas().toDataURL('image/png');
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      signature_client:dataURL,
    }));
    setOpenSignClient(false);
  };

  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchVehicleFrom = (event) => {
    const searchVehicle = event.target.value.toUpperCase();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      vehiclestore_licenseplate: searchVehicle,
    })); 
    if (searchVehicle.trim() === "") {
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
      return;
    }  
    const filtered = vehiclesToSelect.filter((vehicle) => {
      if (!vehicle || !vehicle.vehiclestore_licenseplate) return false;
      const searchNormailzedVehicled = searchVehicle.toUpperCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedVehicle = vehicle.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
      return normalizedVehicle.includes(searchNormailzedVehicled);
    }); 
    setFilteredVehicle(filtered);
    setHighlightedIndexVehicle(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectVehicleFrom = (vehicle) => {
    if (!vehicle) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      vehiclestore_licenseplate: (vehicle.vehiclestore_licenseplate || "") 
    }));
    setFilteredVehicle([]);
    setHighlightedIndexVehicle(-1);
  }; 
  const handleKeyDownVehicleFrom = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredVehicle.length === 0 && formPurchaseDelivery.vehiclestore_licenseplate.trim() === "") {
        setFilteredVehicle(vehiclesToSelect);
        setHighlightedIndexVehicle(0);
      } else {
        setHighlightedIndexVehicle((prevIndex) => {
          const nextIndex = prevIndex < filteredVehicle.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexVehicle((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexVehicle >= 0) {
      if (highlightedIndexVehicle >= 0 && highlightedIndexVehicle < filteredVehicle.length) {
        handleSelectVehicleFrom(filteredVehicle[highlightedIndexVehicle]);
      }
    } else if (event.key === "Escape") {
      const isValid = vehiclesToSelect.some((store) => {
        if (!store || !store.vehiclestore_licenseplate) return false; 
        const normalizedVehicle = store.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputVehicle = formPurchaseDelivery.vehiclestore_licenseplate.toUpperCase().replace(/[.-]/g, "");
        return normalizedVehicle === normalizedInputVehicle;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          vehiclestore_licenseplate: "",
        }));
      } 
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchDocumentId = (event) => {
    const searchDocumentId = event.target.value;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      rel_trans_documentid: searchDocumentId,
    })); 
    if (searchDocumentId.trim() === "") {
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
      return;
    } 
    const filtered = documentIdToSelect.filter((store) => {
      if (!store || !store.documentId) return false;
      const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
      return normalizedDocumentId.includes(searchNormailzedDocumentId);
    });
    setFilteredDocumentId(filtered);
    setHighlightedIndexDocumentId(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectDocumentId = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
      rel_trans_documentid: store.documentId || "",
    }));
    setFilteredDocumentId([]);
    setHighlightedIndexDocumentId(-1);
  }; 
  const handleKeyDownDocumentId = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredDocumentId.length === 0 && formPurchaseDelivery.rel_trans_documentid.trim() === "") {
        setFilteredDocumentId(documentIdToSelect);
        setHighlightedIndexDocumentId(0);
      } else {
        setHighlightedIndexDocumentId((prevIndex) => {
          const nextIndex = prevIndex < filteredDocumentId.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexDocumentId((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentId >= 0) {
      if (highlightedIndexDocumentId >= 0 && highlightedIndexDocumentId < filteredDocumentId.length) {
        handleSelectDocumentId(filteredDocumentId[highlightedIndexDocumentId]);
      }
    } else if (event.key === "Escape") {
      const isValid = documentIdToSelect.some((store) => {
        if (!store || !store.documentId) return false; 
        const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputId = formPurchaseDelivery.rel_trans_documentid.toUpperCase();
        return normalizedStoreId === normalizedInputId;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          rel_trans_documentid: "",
        }));
      } 
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTradename = (event) => {
    const searchTradename = event.target.value.toUpperCase();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      rel_trans_tradename: searchTradename,
    })); 
    if (searchTradename.trim() === "") {
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
      return;
    } 
    const filtered = tradenameToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      return normalizedTradeName.includes(searchTradename);
    });
    setFilteredTradename(filtered);
    setHighlightedIndexTradename(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTradeName = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
      rel_trans_tradename: store.tradename || "",
      rel_trans_documentid: store.documentId || formPurchaseDelivery.rel_trans_documentid,
      
    }));
    setFilteredTradename([]);
    setHighlightedIndexTradename(-1);
  }; 
  const handleKeyDownTradename = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTradename.length === 0 && formPurchaseDelivery.rel_trans_tradename.trim() === "") {
        setFilteredTradename(tradenameToSelect);
        setHighlightedIndexTradename(0);
      } else {
        setHighlightedIndexTradename((prevIndex) => {
          const nextIndex = prevIndex < filteredTradename.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTradename((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradename >= 0) {
      if (highlightedIndexTradename >= 0 && highlightedIndexTradename < filteredTradename.length) {
        handleSelectTradeName(filteredTradename[highlightedIndexTradename]);
      }
    } else if (event.key === "Escape") {
      const isValid = tradenameToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = formPurchaseDelivery.rel_trans_tradename.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          rel_trans_tradename: "",
        }));
      } 
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
    }
  }; 
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchDocumentIdOri = (event) => {
    const searchDocumentIdOri = event.target.value;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_documentid: searchDocumentIdOri,
    })); 
    if (searchDocumentIdOri.trim() === "") {
      setFilteredDocumentIdOri([]);
      setHighlightedIndexDocumentIdOri(-1);
      return;
    } 
    const filtered = documentIdOriToSelect.filter((store) => {
      if (!store || !store.documentId) return false;
      const searchNormailzedDocumentId = event.target.value.toUpperCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedDocumentId = store.documentId.toUpperCase().replace(/[.-]/g, "");
      return normalizedDocumentId.includes(searchNormailzedDocumentId);
    });
    setFilteredDocumentIdOri(filtered);
    setHighlightedIndexDocumentIdOri(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectDocumentIdOri = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
      ori_tras_documentid: store.documentId || "",
    }));
    setFilteredDocumentIdOri([]);
    setHighlightedIndexDocumentIdOri(-1);
  }; 
  const handleKeyDownDocumentIdOri = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredDocumentIdOri.length === 0 && formPurchaseDelivery.ori_tras_documentid.trim() === "") {
        setFilteredDocumentIdOri(documentIdOriToSelect);
        setHighlightedIndexDocumentIdOri(0);
      } else {
        setHighlightedIndexDocumentIdOri((prevIndex) => {
          const nextIndex = prevIndex < filteredDocumentIdOri.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexDocumentIdOri((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexDocumentIdOri >= 0) {
      if (highlightedIndexDocumentIdOri >= 0 && highlightedIndexDocumentIdOri < filteredDocumentIdOri.length) {
        handleSelectDocumentIdOri(filteredDocumentIdOri[highlightedIndexDocumentIdOri]);
      }
    } else if (event.key === "Escape") {
      const isValid = documentIdOriToSelect.some((store) => {
        if (!store || !store.documentId) return false; 
        const normalizedStoreId = store.documentId.toUpperCase().replace(/[.-]/g, "");
        const normalizedInputId = formPurchaseDelivery.ori_tras_documentid.toUpperCase();
        return normalizedStoreId === normalizedInputId;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          ori_tras_documentid: "",
        }));
      } 
      setFilteredTradenamedOri([]);
      setHighlightedIndexDocumentIdOri(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchTradenameOri = (event) => {
    const searchTradenameOri = event.target.value.toUpperCase();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_tradename: searchTradenameOri,
    })); 
    if (searchTradenameOri.trim() === "") {
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
      return;
    } 
    const filtered = tradenameOriToSelect.filter((store) => {
      if (!store || !store.tradename) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedTradeName = store.tradename.toUpperCase();
      return normalizedTradeName.includes(searchTradenameOri);
    });
    setFilteredTradenamedOri(filtered);
    setHighlightedIndexTradenamedOri(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectTradeNameOri = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
      ori_tras_tradename: store.tradename || "",
      ori_tras_documentid: store.documentId || formPurchaseDelivery.ori_tras_documentid,
      
    }));
    setFilteredTradenamedOri([]);
    setHighlightedIndexTradenamedOri(-1);
  }; 
  const handleKeyDownTradenameOri = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredTradenamedOri.length === 0 && formPurchaseDelivery.ori_tras_tradename.trim() === "") {
        setFilteredTradenamedOri(tradenameOriToSelect);
        setHighlightedIndexTradenamedOri(0);
      } else {
        setHighlightedIndexTradenamedOri((prevIndex) => {
          const nextIndex = prevIndex < filteredTradenamedOri.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexTradenamedOri((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexTradenamedOri >= 0) {
      if (highlightedIndexTradenamedOri >= 0 && highlightedIndexTradenamedOri < filteredTradenamedOri.length) {
        handleSelectTradeNameOri(filteredTradenamedOri[highlightedIndexTradenamedOri]);
      }
    } else if (event.key === "Escape") {
      const isValid = tradenameOriToSelect.some((store) => {
        if (!store || !store.tradename) return false; 
        const normalizedTradeName = store.tradename.toUpperCase();
        const normalizedInputTradeName = formPurchaseDelivery.ori_tras_tradename.toUpperCase();
        return normalizedTradeName === normalizedInputTradeName;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          ori_tras_tradename: "",
        }));
      } 
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
    }
  };
  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchWorkPlace = (event) => {
    const searchWorkPlace = event.target.value.toUpperCase();
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_workplace: searchWorkPlace,
    })); 
    if (searchWorkPlace.trim() === "") {
      setFilteredWorkPlace([]);
      setHighlightedIndexWorkPlace(-1);
      return;
    } 
    const filtered = workPlaceToSelect.filter((store) => {
      if (!store || !store.workplace) return false;
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedWorkPlace = store.workplace.toUpperCase();
      return normalizedWorkPlace.includes(searchWorkPlace);
    });
    setFilteredWorkPlace(filtered);
    setHighlightedIndexWorkPlace(0);
  };
  // Manejo de la selección de una tienda
  const handleSelectWorkPlace = (store) => {
    if (!store) return; // Validación adicional para evitar errores
    setFormPurchaseDelivery((formPurchaseDelivery) => ({
      ...formPurchaseDelivery,
      ori_tras_workplace: store.workplace || ""
      
    }));
    setFilteredWorkPlace([]);
    setHighlightedIndexWorkPlace(-1);
  }; 

  const handleKeyDownWorkPlace = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredWorkPlace.length === 0 && formPurchaseDelivery.ori_tras_workplace.trim() === "") {
        setFilteredWorkPlace(workPlaceToSelect);
        setHighlightedIndexWorkPlace(0);
      } else {
        setHighlightedIndexWorkPlace((prevIndex) => {
          const nextIndex = prevIndex < filteredWorkPlace.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexWorkPlace((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexWorkPlace >= 0) {
      if (highlightedIndexWorkPlace >= 0 && highlightedIndexWorkPlace < filteredWorkPlace.length) {
        handleSelectWorkPlace(filteredWorkPlace[highlightedIndexWorkPlace]);
      }
    } else if (event.key === "Escape") {
      const isValid = workPlaceToSelect.some((store) => {
        if (!store || !store.workplace) return false; 
        const normalizedWorkPlace = store.workplace.toUpperCase();
        const normalizedInputWorkPlace = formPurchaseDelivery.ori_tras_workplace.toUpperCase();
        return normalizedWorkPlace === normalizedInputWorkPlace;
      }); 
      if (!isValid) {
        setFormPurchaseDelivery((formPurchaseDelivery) => ({
          ...formPurchaseDelivery,
          ori_tras_workplace: "",
        }));
      } 
      setFilteredWorkPlace([]);
      setHighlightedIndexWorkPlace(-1);
    }
  };

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

 const handleBlur = useCallback((event) => {
     if (!event || !event.currentTarget) return;
     // Asegúrate de que no se cierra si haces clic en un elemento de la lista
     if (!event.currentTarget.contains(event.relatedTarget)) {
      setFilteredVehicle([]);
      setHighlightedIndexVehicle(-1);
      setFilteredDocumentId([]);
      setHighlightedIndexDocumentId(-1);
      setFilteredTradename([]);
      setHighlightedIndexTradename(-1);
      setFilteredDocumentIdOri([]);
      setHighlightedIndexDocumentIdOri(-1);
      setFilteredTradenamedOri([]);
      setHighlightedIndexTradenamedOri(-1);
      setFilteredWorkPlace([]);
      setHighlightedIndexWorkPlace(-1);
    }
  }, []);
  
  const handleChangeDataEditPurchaseDelivery = (e) => {
    const { name, value } = e.target;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      [name]: value,
    }));
  };

  const handleBlurDataEditPurchaseDelivery = (e) => {
    handleChangeDataEditPurchaseDelivery(e);
    setErrorsForm(validatePurchaseDelivery());
  }; 

  const handleBlurSelectDate = (e) => {
    handleChangeSelectDate(e);
    setErrorsForm(validatePurchaseDelivery());
  };

  const handleChangeSelectDate = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      datepurchase: event!==null?new Date(event):null
    }));
  };

  const handleBlurTotalWeightNeto = (value) => {
    handleChangeTotalWeightNeto(value);
  };

  const handleChangeTotalWeightNeto = (value) => {
    let newValue=value!==null?Number((value.toLocaleString()).replaceAll(",","").replaceAll(" Tn","").replaceAll("kg","").replaceAll("€","").replaceAll("%","")):0;
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalWeigthNeto: newValue
    }));
  };

  const searchStoreRelTrans = (event) => {
    setTetxtSearch("Rel_Trans")
    setShowModalSearchStores(true);
  };

  const deleteDataStoreAddressOriTras = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchStoreAddress = (event) => {
    setShowModalSearchStoresAddress(true);
  };

  const deleteDataStoreRelTrans = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      rel_trans_store_id: 0,
      rel_trans_documnettype_id: 0,
      rel_trans_documnettype_name: "",
      rel_trans_documentid: "",
      rel_trans_tradename: "",
      rel_trans_name: "",
      rel_trans_contacperson: "",
      rel_trans_nima: "",
      rel_trans_pygr: "",
      rel_trans_grnp:"",
      rel_trans_opt: 0,
      rel_trans_roadtype_id: 0,
      rel_trans_street: "",
      rel_trans_country_id: Number(DEFAULTCOUNTRY),
      rel_trans_province_id:  Number(DEFAULTPROVINCE),
      rel_trans_postalcode_id: 0,
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchVehicles = (event) => { 
    setShowModalSearchVehicles(true);
  };
 
  const deleteDataVehicles = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      vehiclestore_id:0,
      vehiclestore_licenseplate: "",
      vehiclestore_defaultdriver:""
    })); 
    setFilteredVehicle([]);
    setHighlightedIndexVehicle(-1); 
    setErrorsForm(validatePurchaseDelivery());
  };

  const deleteDataStoreOriTras = (event) => {
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      ori_tras_store_id: 0,
      ori_tras_documnettype_id: 0,
      ori_tras_documnettype_name: "",
      ori_tras_documentid: "",
      ori_tras_tradename: "",
      ori_tras_name: "",
      ori_tras_contacperson: "",
      ori_tras_nima: "",
      ori_tras_pygr: "",
      ori_tras_grnp:"",
      ori_tras_opt: 0,
      ori_tras_workplace: "",
      ori_tras_roadtype_id: 0,
      ori_tras_street: "",
      ori_tras_country_id: Number(DEFAULTCOUNTRY),
      ori_tras_province_id:  Number(DEFAULTPROVINCE),
      ori_tras_postalcode_id: 0,
      ori_tras_email: ""
    }));  
    setErrorsForm(validatePurchaseDelivery());
  };

  const searchStoreOriTras = (event) => {
    setTetxtSearch("Ori_Tras")
    setShowModalSearchStores(true);
  };

  const handleCancelDeleteRow = () => { 
    setEditRowArticles(initialArticlesToSelect); 
    dispatch(setLoading(false));  
    setIsLoadingErrorData(false);
    setIdCodeDeletePurchaseDelivery(DELETE_CODE);
    setOpenDeleteRowArticles(false);
  };

  const handleChangeDeleteRow = (e) => {
    const value  = e.target.value;
    setIdCodeDeletePurchaseDelivery(value);
  };

  const handleCloseDeleteRow = (e) => {
    e.preventDefault();
    if (idCodeDeletePurchaseDelivery!==DELETE_CODE){
      dispatch(warningErrorAction(t('Text_InvalidCode')));
    } else {
      
      let indexDelete=formPurchaseDelivery.linespurchasedelivery.findIndex(((item) => item.indexrowarticle === editRowArticles.indexrowarticle));
      if ( indexDelete !== -1 ) {
        formPurchaseDelivery.linespurchasedelivery.splice( indexDelete, 1 );
      }
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
      })) 
      let totalNeto=0; 
      formPurchaseDelivery.linespurchasedelivery.map((item)=>{
        return totalNeto+=item.total;
      });
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        totalNetoPurchasedelivery:totalNeto
       }));
      setIdCodeDeletePurchaseDelivery(DELETE_CODE);
      setOpenDeleteRowArticles(false);
    }
  };
   
  const selectArticle = (post) => {
    
    let articleToAdd={
      "id":"",
      "indexrowarticle":formPurchaseDelivery.linespurchasedelivery.length>0?formPurchaseDelivery.linespurchasedelivery[formPurchaseDelivery.linespurchasedelivery.length-1].indexrowarticle+1:1,
      "purchase_id":formPurchaseDelivery.id,
      "article_id":post.button_article_id,
      "article_code":post.button_article_code,
      "article_namees":post.button_article_namees,
      "article_nameen":post.button_article_nameen,
      "datepurchase":formPurchaseDelivery.datepurchase,
      "estimatedprice":post.button_article_estimatedprice,
      "amount":0,
      "discount":0,
      "tax":post.button_article_tax,
      "taxincluded": post.button_article_taxincluded, 
      "total": 0 ,
      "ticketweight":post.button_article_ticketweight,
      "formdi_id":0,
      "ownformdi_id":0
      
    }
    formPurchaseDelivery.linespurchasedelivery.push(articleToAdd);
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery,
      totalpurchasedelivery:0,
      totaldiscountpurchasedelivery:0,
      totaltaxespurchasedelivery:0 
    })); 
    setFormPurchaseDelivery(formPurchaseDelivery => ({
      ...formPurchaseDelivery
    }));
  };

  const withValueCap3 = (inputObj) => {
    const { value } = inputObj;
    if (value <= 10000000000) return true;
    return false;
  };
 
  const handleSubmitDataEditPurchaseEdit = () => {
    const errors = validatePurchaseDelivery();
    setErrorsForm(errors);
    if ((Object.keys(errors).length === 0) 
        && formPurchaseDelivery.linespurchasedelivery!==undefined  
        && formPurchaseDelivery.linespurchasedelivery.length>0 ) {
      
      handleCloseDataEditPurchaseDelivery();
      const fetchData = async () => {
        let isMounted = true;
        try {
          const getData = id !== "0"
          ? await helpHttp().put(ENDPOINT, `purchasedelivery/updatepurchasedelivery`, token, formPurchaseDelivery)
          : await helpHttp().post(ENDPOINT, `purchasedelivery/savepurchasedelivery`, token, formPurchaseDelivery);
          if (getData.status === "OK") { 
            dispatch(successErrorAction(t('Text_SaveData'))); 
          } else {
            if (isMounted ) {
              dispatch(warningErrorAction(t(getData.cause)));
            }
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
        return () => { isMounted = false;};
      };
      fetchData(); 
    } else {
      if (formPurchaseDelivery.linespurchasedelivery===undefined  
        || formPurchaseDelivery.linespurchasedelivery.length===0 ){
          dispatch(warningErrorAction(t('Text_FieldArticlesRequired')));
      }else{
        dispatch(warningErrorAction(t('Text_IncorrectData')));      
      }
    }
  };

  const validatePurchaseDelivery = () => {
    let errorsForm = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (regexInvalidCharacters.test(formPurchaseDelivery.ownnumberpurchase)) {
      errorsForm.ownnumberpurchase = 'Text_TextNoValid';
    }

    if (formPurchaseDelivery.subownnumberpurchase!=="" & !regexNumber.test(formPurchaseDelivery.subownnumberpurchase)) {
      formPurchaseDelivery.subownnumberpurchase = 'Text_TextNoNumber';
    }

    if (regexInvalidCharacters.test(formPurchaseDelivery.rel_trans_documentid)) {
      errorsForm.rel_trans_documentid = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formPurchaseDelivery.vehiclestore_licenseplate)) {
      errorsForm.vehiclestore_licenseplate = 'Text_TextNoValid';
    }
    if (regexInvalidCharacters.test(formPurchaseDelivery.rel_trans_tradename)) {
      errorsForm.rel_trans_tradename = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_nima)) {
      errorsForm.ori_tras_nima = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_pygr)) {
      errorsForm.ori_tras_pygr = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_grnp)) {
      errorsForm.ori_tras_grnp = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_workplace)) {
      errorsForm.ori_tras_workplace = 'Text_TextNoValid';
    }  
    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_street)) {
      errorsForm.ori_tras_street = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formPurchaseDelivery.ori_tras_email)) {
      errorsForm.ori_tras_email = 'Text_TextNoValid';
    } 

    if (regexInvalidCharacters.test(formPurchaseDelivery.observations)) {
      errorsForm.observations = 'Text_TextNoValid';
    } 
    return errorsForm;
  };
  
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentRelTrans = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getstorebynumberdocument/`,token,formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, ""));
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.rel_trans_documentid){
            fetchStoreNumberDocumentRelTrans();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchStoreNumberDocumentRelTrans = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`purchasedelivery/getstorebynumberdocumentpriority`,token,formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, ""),6);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                      ...formPurchaseDelivery,
                      rel_trans_store_id: getData.rel_trans_store_id,
                      rel_trans_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      rel_trans_documentid: getData.rel_trans_documentid,
                      rel_trans_documnettype_name: getData.rel_trans_documnettype_name,
                      rel_trans_tradename: getData.rel_trans_tradename,
                      rel_trans_name: getData.rel_trans_name,
                      rel_trans_contacperson: getData.rel_trans_contacperson,
                      rel_trans_nima: (formPurchaseDelivery.rel_trans_nima===undefined 
                        ||formPurchaseDelivery.rel_trans_nima===null
                        ||formPurchaseDelivery.rel_trans_nima==="")
                        ?getData.rel_trans_nima:formPurchaseDelivery.rel_trans_nima,
                      rel_trans_pygr: (formPurchaseDelivery.rel_trans_pygr===undefined 
                        ||formPurchaseDelivery.rel_trans_pygr===null
                        ||formPurchaseDelivery.rel_trans_pygr==="")
                        ?getData.rel_trans_pygr:formPurchaseDelivery.rel_trans_pygr,
                      rel_trans_grnp: (formPurchaseDelivery.rel_trans_grnp===undefined 
                          ||formPurchaseDelivery.rel_trans_grnp===null
                          ||formPurchaseDelivery.rel_trans_grnp==="")
                          ?getData.rel_trans_grnp:formPurchaseDelivery.rel_trans_grnp,
                      rel_trans_opt: (getData.rel_trans_opt===undefined 
                            || getData.rel_trans_opt===null  
                            || getData.rel_trans_opt===""
                            || getData.rel_trans_opt==="0"
                            || getData.rel_trans_opt===0)?
                            0:getData.rel_trans_opt, 
                      rel_trans_roadtype_id: (getData.rel_trans_roadtype_id===undefined 
                                            || getData.rel_trans_roadtype_id===null  
                                            || getData.rel_trans_roadtype_id===""
                                            || getData.rel_trans_roadtype_id==="0"
                                            || getData.rel_trans_roadtype_id===0)?
                                            0:getData.rel_trans_roadtype_id,
                      rel_trans_street: getData.rel_trans_street,
                      rel_trans_country_id: (getData.rel_trans_country_id===undefined 
                                            || getData.rel_trans_country_id===null  
                                            || getData.rel_trans_country_id===""
                                            || getData.rel_trans_country_id==="0"
                                            || getData.rel_trans_country_id===0)?
                                            Number(DEFAULTCOUNTRY):getData.rel_trans_country_id,
                      rel_trans_province_id: (getData.rel_trans_province_id===undefined 
                                            || getData.rel_trans_province_id===null  
                                            || getData.rel_trans_province_id===""
                                            || getData.rel_trans_province_id==="0"
                                            || getData.rel_trans_province_id===0)?
                                            Number(DEFAULTPROVINCE):getData.rel_trans_province_id,
                      rel_trans_postalcode_id: (getData.rel_trans_postalcode_id===undefined 
                                                || getData.rel_trans_postalcode_id===null  
                                                || getData.rel_trans_postalcode_id===""
                                                || getData.rel_trans_postalcode_id==="0"
                                                || getData.rel_trans_postalcode_id===0)?
                                                "":getData.rel_trans_postalcode_id 
                  }));
              }  
          }         
        } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
    };
    if (formPurchaseDelivery.rel_trans_documentid && formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
      fetchExistNumberDocumentRelTrans();
    } 
    return () => { isMounted = false };
  }, [t,token,dispatch,formPurchaseDelivery.rel_trans_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchExistLicensePlate = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getexistvehicleylicenseplate/`,token,formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, ""));
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.vehiclestore_licenseplate){
            fetchLicensePlate();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchLicensePlate = async () => {
      try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getvehiclebylicenseplate/`,token,formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, ""));
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!==""){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                  ...formPurchaseDelivery,
                  vehiclestore_id: getData.id,
                  vehiclestore_store_id: (getData.store_id===undefined 
                                        || getData.store_id===null  
                                        || getData.store_id===""
                                        || getData.store_id==="0"
                                        || getData.store_id===0)?
                                        0:getData.store_id 
              }));
              if (formPurchaseDelivery.rel_trans_documentid===undefined || formPurchaseDelivery.rel_trans_documentid===null || formPurchaseDelivery.rel_trans_documentid==="" ){
                if (formPurchaseDelivery.rel_trans_tradename===undefined || formPurchaseDelivery.rel_trans_tradename===null || formPurchaseDelivery.rel_trans_tradename===""){
                  setFormPurchaseDelivery(formPurchaseDelivery => ({
                    ...formPurchaseDelivery,
                    rel_trans_documentid: getData.store_documentid 
                  }));
                }
              }  
            }
          }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formPurchaseDelivery.vehiclestore_licenseplate && formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, "").trim()!==""){
      fetchExistLicensePlate();
    } 
    return () => { isMounted = false };
  }, [t,token,dispatch,formPurchaseDelivery.vehiclestore_licenseplate,formPurchaseDelivery.rel_trans_documentid,formPurchaseDelivery.rel_trans_tradename,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery]);
 
  useEffect(() => {
    let isMounted = true;
    const fetchExistNumberDocumentOriTras = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getstorebynumberdocument/`,token,formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, ""));
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          if (getData>0 && getData!==formPurchaseDelivery.ori_tras_documentid){
            fetchStoreNumberDocumentOriTras();
          }
        }
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    const fetchStoreNumberDocumentOriTras = async () => {
      try {
          const getData = await helpHttp().get5(ENDPOINT,`purchasedelivery/getstorebynumberdocumentpriority`,token,formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, ""),2);
          if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
              if (getData!==undefined && getData!==null && getData!=="" && getData!=="0" && getData!==0  && getData.rel_trans_store_id!==formPurchaseDelivery.ori_tras_store_id){
                setFormPurchaseDelivery(formPurchaseDelivery => ({
                      ...formPurchaseDelivery,
                      ori_tras_store_id: getData.rel_trans_store_id,
                      ori_tras_documnettype_id: (getData.rel_trans_documnettype_id===undefined 
                                              || getData.rel_trans_documnettype_id===null  
                                              || getData.rel_trans_documnettype_id===""
                                              || getData.rel_trans_documnettype_id==="0"
                                              || getData.rel_trans_documnettype_id===0)?
                                              0:getData.rel_trans_documnettype_id,
                      ori_tras_documnettype_name: getData.rel_trans_documnettype_name,
                      ori_tras_documentid: getData.rel_trans_documentid,
                      ori_tras_tradename: getData.rel_trans_tradename,
                      ori_tras_name: getData.rel_trans_name,
                      ori_tras_contacperson: getData.rel_trans_contacperson,
                      ori_tras_nima: getData.ori_tras_nima,
                      ori_tras_pygr: getData.ori_tras_pygr,
                      ori_tras_grnp: getData.ori_tras_grnp, 
                      ori_tras_opt: getData.ori_tras_opt,
                      ori_tras_email: getData.rel_trans_email      
            }));
          }  
        }         
      } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
    if (formPurchaseDelivery.ori_tras_documentid && formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
      fetchExistNumberDocumentOriTras();
    }  
    return () => { isMounted = false };
  }, [t,token,dispatch,formPurchaseDelivery.ori_tras_documentid,errorsForm,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormPurchaseDelivery,formPurchaseDelivery.ori_tras_store_id]);


  useEffect(() => {
    let isMounted = true;
    const fetchDataProvince = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/simpleprovincesbycountrybyname/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setProvinceToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalPurchasesDeliverySettings){
      fetchDataProvince();
    }

    return () => { isMounted = false };     
  }, [t,token,dispatch,i18n,showModalPurchasesDeliverySettings,DEFAULTCOUNTRY]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataPostalCode = async () => {
      try {
        const getData = formPurchaseDelivery.ori_tras_province_id!==""
            ? await helpHttp().get2(ENDPOINT,`purchasedelivery/simplepostalcodesbyprovincebyname/`,token,formPurchaseDelivery.ori_tras_province_id)
            : await helpHttp().get2(ENDPOINT,`purchasedelivery/simplepostalcodesbycountry/`,token,DEFAULTCOUNTRY);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPostalCodeToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
    if (showModalPurchasesDeliverySettings){
      fetchDataPostalCode(); 
    }
    if (formPurchaseDelivery.id === "" && formPurchaseDelivery.defaultStore_id===0) { 
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi,
        isfinished:false,
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",
        linespurchasedelivery: [] 
      }));
    }
    return () => { isMounted = false };  
  }, [t,token,dispatch,i18n,showModalPurchasesDeliverySettings,formPurchaseDelivery.ori_tras_province_id,DEFAULTCOUNTRY,DEFAULTPROVINCE,formPurchaseDelivery.id,defaultStore.id,useraccount_id,defaulttypesdi,formPurchaseDelivery.defaultStore_id]);

  useEffect(() => {
      let isMounted = true; 
      const fetchDataVehicles = async () => {
        try {
          const getData = await helpHttp().get8(ENDPOINT,`purchasedelivery/getlistvehiclespurchasedeliveryswithoutfilter`,token,
            (formPurchaseDelivery.vehiclestore_licenseplate!==undefined && formPurchaseDelivery.vehiclestore_licenseplate!==null && formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, "").trim():"-1",
            (formPurchaseDelivery.rel_trans_documentid!==undefined && formPurchaseDelivery.rel_trans_documentid!==null && formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setVehiclesToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
      
      if (showModalPurchasesDeliverySettings && ((formPurchaseDelivery.vehiclestore_licenseplate && formPurchaseDelivery.vehiclestore_licenseplate.replace(/[.\-/\\|]/g, "").trim()!=="") || (formPurchaseDelivery.rel_trans_documentid && formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!==""))) { 
        fetchDataVehicles();
      }else{
        setVehiclesToSelect(initialVehicleToSelect);
    }
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,formPurchaseDelivery.vehiclestore_licenseplate,showModalPurchasesDeliverySettings,formPurchaseDelivery.rel_trans_documentid]);
    
    useEffect(() => {
      let isMounted = true; 
      const fetchDataDocumentId = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getlistdocumentidpurchasedeliveryswithoutfilter/`,token,
            (formPurchaseDelivery.rel_trans_documentid!==undefined && formPurchaseDelivery.rel_trans_documentid!==null && formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setDocumentIdToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      }; 
      
      if (showModalPurchasesDeliverySettings && formPurchaseDelivery.rel_trans_documentid && formPurchaseDelivery.rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
        fetchDataDocumentId();
      }else{
        setDocumentIdToSelect(initialDocumentIdToSelect);
      } 
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings,formPurchaseDelivery.rel_trans_documentid]);
  
    useEffect(() => {
      let isMounted = true; 
      const fetchDataDocumentIdOri = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getlistdocumentidpurchasedeliveryswithoutfilter/`,token,
            (formPurchaseDelivery.ori_tras_documentid!==undefined && formPurchaseDelivery.ori_tras_documentid!==null && formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setDocumentIdOriToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
  
      if (showModalPurchasesDeliverySettings && formPurchaseDelivery.ori_tras_documentid && formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
        fetchDataDocumentIdOri();
      }else{
        setDocumentIdOriToSelect(initialDocumentIdToSelect);
      } 
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings,formPurchaseDelivery.ori_tras_documentid]);
  
    useEffect(() => {
      let isMounted = true; 
      const fetchDataTradename = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedeliveryswithoutfilter/`,token,
            (formPurchaseDelivery.rel_trans_tradename!==undefined && formPurchaseDelivery.rel_trans_tradename!==null && formPurchaseDelivery.rel_trans_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.rel_trans_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setTradenameToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
  
      if (showModalPurchasesDeliverySettings && formPurchaseDelivery.rel_trans_tradename && formPurchaseDelivery.rel_trans_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
        fetchDataTradename();
      }else{
        setTradenameToSelect(initialDocumentIdToSelect);
      } 
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings,formPurchaseDelivery.rel_trans_tradename]);
  
    useEffect(() => {
      let isMounted = true; 
      const fetchDataTradenameOri = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getlisttradenamepurchasedeliveryswithoutfilter/`,token,
            (formPurchaseDelivery.ori_tras_tradename!==undefined && formPurchaseDelivery.ori_tras_tradename!==null && formPurchaseDelivery.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setTradenameOriToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
      
      if (showModalPurchasesDeliverySettings && formPurchaseDelivery.ori_tras_tradename && formPurchaseDelivery.ori_tras_tradename.replace(/[.\-/\\|]/g, "").trim()!=="") { 
        fetchDataTradenameOri();
      }else{
        setTradenameOriToSelect(initialWorkPlaceToSelect);
      } 
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings,formPurchaseDelivery.ori_tras_tradename]);
  
    useEffect(() => {
      let isMounted = true; 
      const fetchDataWorkPlace = async () => {
        try {
          const getData = await helpHttp().get2(ENDPOINT,`purchasedelivery/getlistworplacepurchasedelivery/`,token,
            (formPurchaseDelivery.ori_tras_documentid!==undefined && formPurchaseDelivery.ori_tras_documentid!==null && formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="")?formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim():"-1"); 
          if (getData.err) {
            dispatch(warningErrorAction(t('Text_InvalidCredentials')));
          } else if (isMounted) {
            setWorkPlaceToSelect(getData);
          }
        } catch (error) {
          dispatch(warningErrorAction(t('Text_IncorrectData'))); 
        }
      };  
      
      if (showModalPurchasesDeliverySettings && formPurchaseDelivery.ori_tras_documentid && formPurchaseDelivery.ori_tras_documentid.replace(/[.\-/\\|]/g, "").trim()!=="") { 
        fetchDataWorkPlace();
      }else{
        setWorkPlaceToSelect(initialWorkPlaceToSelect);
      }
      return () => { isMounted = false };
    }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings,formPurchaseDelivery.ori_tras_street,formPurchaseDelivery.ori_tras_documentid]);
  
 
  useEffect(() => {
    let isMounted = true;
  
    const fetchDataPayment = async () => {
      try {
        const getData = i18n.language === "es"
        ? await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynamees`, token)
        : await helpHttp().get(ENDPOINT, `paymenttype/paymenttypesorderbynameen`, token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setPaymenetTypeSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
 
    if (showModalPurchasesDeliverySettings ) {  
      fetchDataPayment(); 
    }
    return () => { isMounted = false };

  }, [t,token,dispatch,i18n,roles,showModalPurchasesDeliverySettings]);
   
  useEffect(() => {
    let isMounted = true;
    const fetchDataPurchaseDelivery = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`purchasedelivery/getpurchasedeliverybyid/`,token,id);
        if (getData.err) {
          setFormPurchaseDelivery(initialStatePurchaseDelivery);
          setIsLoadingErrorData(true);
           dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormPurchaseDelivery(getData);
           dispatch(setLoading(false));
        }  
      } catch (error) {
         dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };

    if (id!=="0"){
      if (formPurchaseDelivery.id===""){
        fetchDataPurchaseDelivery();  
      }
    }else{
      setFormPurchaseDelivery(initialStatePurchaseDelivery);
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        defaultformatnumber:defaulttypesdi,
        isfinished:false,
        purchasenote:0,
        numberticket:"",
        ownnumberpurchase:"",
        datepurchase:null,
        vehiclestore_id: 0,
        vehiclestore_licenseplate: "",
        vehiclestore_defaultdriver: "",
        vehiclestore_store_id: 0,

        rel_trans_store_id:0,
        rel_trans_documnettype_id:0,
        rel_trans_documentid:"",
        rel_trans_documnettype_name:"",
        rel_trans_tradename:"",
        rel_trans_name:"",
        rel_trans_contacperson:"",
        rel_trans_nima: "",
        rel_trans_pygr: "",
        rel_trans_grnp:"",
        rel_trans_opt: 0,
        rel_trans_roadtype_id:0,
        rel_trans_street:"",
        rel_trans_country_id: Number(DEFAULTCOUNTRY),
        rel_trans_province_id:  Number(DEFAULTPROVINCE),
        rel_trans_postalcode_id: 0,

        ori_tras_store_id: 0,
        ori_tras_documnettype_id: 0,
        ori_tras_documnettype_name:"",
        ori_tras_documentid:"",
        ori_tras_tradename:"",
        ori_tras_name:"",
        ori_tras_contacperson:"",
        ori_tras_nima: "",
        ori_tras_pygr: "",
        ori_tras_opt: 0,
        ori_tras_workplace: "",
        ori_tras_roadtype_id: 0,
        ori_tras_street:"",
        ori_tras_country_id: Number(DEFAULTCOUNTRY),
        ori_tras_province_id: Number(DEFAULTPROVINCE),
        ori_tras_postalcode_id: 0,
        ori_tras_email:"",

        observations:"", 
        totalpurchasedelivery: 0,
        totaldiscountpurchasedelivery: 0,
        totaltaxespurchasedelivery: 0,
        totalNetoPurchasedelivery:0,
        enabled:true,
        subownnumberpurchase:0,
        payment_id:0, 
        payment_code: "",
        payment_namees: "",
        payment_nameen: "", 
        totalWeigthNeto:0,
        signature_dispatched_by:"",
        signature_client:"",
        linespurchasedelivery: [] 

      }));
      setFormPurchaseDelivery(formPurchaseDelivery => ({
        ...formPurchaseDelivery,
        linespurchasedelivery:[]
      })); 
    }
    return () => { isMounted = false };
  }, [dispatch,id,t,token,formPurchaseDelivery.id,DEFAULTCOUNTRY,DEFAULTPROVINCE,defaultStore.id,defaulttypesdi,useraccount_id]);  
  
 return (
      <>
        {showModalPurchasesDeliverySettings ? ( 
          <div className="container-fluid">
            <Box sx={{ bgcolor: '#ffffff', width: '1520px'}}>
              <form className="createform" onSubmit={handleCloseDataEditPurchaseDelivery}>  
                <div className="row"> 
                  <div className="col-xl-12 mb-4"> 
                    <div  className="card border-info shadow">
                      <div className="card-body"> 
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div id="myDIV">
                              <h1 className="h9NewStyle mb-2">
                                  {isLoadingErrorData?
                                  <i className="fas fa-solid fa-exclamation fa-2x text-danger"/>:
                                  (isLoadingData?
                                  <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <i className="fas fa-regular fa-clipboard text-warning"></i>)} 
                                  &nbsp;&nbsp;{id==="0"?t('TextPurchaseDeliver_New')+': '+formPurchaseDelivery.purchasenote:t('Text_PurchaseDeliver_Edit')+': '+formPurchaseDelivery.purchasenote}
                              </h1>
                              <div>
                              {isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                  <>
                                    <button type="button" className="buttonCreate" onClick={handleSubmitDataEditPurchaseEdit}>
                                      {id==="0"?t('Button_Create'):t('Button_Update')}
                                    </button><> </>
                                    <button type="button" className="buttonBack" onClick={handleCloseDataEditPurchaseDelivery}>{t('Button_Cancel')}</button>
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-danger" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_REF_UPPER').toUpperCase()} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ownnumberpurchase" 
                                  name="ownnumberpurchase"
                                  value={formPurchaseDelivery.ownnumberpurchase}
                                  format={i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy"} 
                                  inputProps={{ style: {width: i18n.language==='es'?309:271,  fontSize: '18px', }, maxLength: 20 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.ownnumberpurchase!=null?t(errorsForm.ownnumberpurchase):t('Text_PURCHASEDELIVERY_REF_UPPER')}
                                  placeholder={t('Text_PURCHASEDELIVERY_REF_UPPER')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                  <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_DATEPURCHAE').toUpperCase()} :</label>
                                  &nbsp;&nbsp;&nbsp;&nbsp; 
                                  <DesktopDatePicker
                                    id="datepurchase" 
                                    name="datepurchase"
                                    value={ formPurchaseDelivery.datepurchase!== null ? new Date(formPurchaseDelivery.datepurchase):null}
                                    format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                    inputProps={{ style: { width: 220 }, maxLength: 200 }}
                                    label={errorsForm.datepurchase!=null?t(errorsForm.datepurchase):t('Text_PurchaseDeliver_Date')}
                                    onBlur={handleBlurSelectDate}
                                    onChange={handleChangeSelectDate}
                                    slotProps={{
                                      textField: {
                                        placeholder: i18n.language === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy",
                                        sx: {
                                          maxLength: 220,
                                          width: 220,
                                          fontSize: '18px',  
                                        },
                                        InputProps: {
                                          style: {
                                            fontSize: '18px',
                                          }
                                        },
                                        InputLabelProps:{ 
                                          style: {
                                            fontSize: '18px', 
                                            background: '#FFFFFF'
                                          }
                                        },
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PurchaseDeliver_TOTAL')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <NumericFormat 
                                  thousandSeparator={','} 
                                  decimalSeparator={'.'} 
                                  decimalScale= {2}
                                  fixedDecimalScale= {true}
                                  allowNegative= {true}
                                  isAllowed={withValueCap3}
                                  id="total" 
                                  name="total"
                                  suffix={" €"}
                                  value={formPurchaseDelivery.totalNetoPurchasedelivery}
                                  style={{width:315, color:"#f5f5f5", background:"#bdbdbd",fontSize: 36, textAlign:'right', paddingRight:5}} 
                                  disabled
                                />  
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_CIF_TRANS').toUpperCase()} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="rel_trans_documentid"
                                    name="rel_trans_documentid"
                                    value={formPurchaseDelivery.rel_trans_documentid}
                                    onChange={handleSearchDocumentId}
                                    onKeyDown={handleKeyDownDocumentId}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.rel_trans_documentid!=null?t(errorsForm.rel_trans_documentid):t('Text_PURCHASEDELIVERY_CIF_TRANS')}
                                    placeholder={t('Text_PURCHASEDELIVERY_CIF_TRANS')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?161:161,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredDocumentId.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "350%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredDocumentId.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectDocumentId(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexDocumentId ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexDocumentId ? "white" : "black",
                                            }}
                                        >
                                          {store.documentId} - {store.tradename}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b"}} onClick={deleteDataStoreRelTrans}>
                                    <DeleteIcon style={{fontSize: 24}}/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df"}} onClick={searchStoreRelTrans}>
                                    <SearchIcon style={{fontSize: 24}}/>
                                  </IconButton>
                                }
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_LICENSEPLATE').toUpperCase()} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="vehiclestore_licenseplate"
                                    name="vehiclestore_licenseplate"
                                    value={formPurchaseDelivery.vehiclestore_licenseplate}
                                    onChange={handleSearchVehicleFrom}
                                    onKeyDown={handleKeyDownVehicleFrom}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.vehiclestore_licenseplateFrom!=null?t(errorsForm.vehiclestore_licenseplateFrom):t('Placeholder_PURCHASEDELIVERY_LICENSEPLATE')}
                                    placeholder={t('Placeholder_PURCHASEDELIVERY_LICENSEPLATE')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?210:210,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredVehicle.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredVehicle.map((vehicle, index) => (
                                        <MenuItem
                                            key={vehicle.id}
                                            onClick={() => handleSelectVehicleFrom(vehicle)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexVehicle ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexVehicle ? "white" : "black",
                                            }}
                                        >
                                          {vehicle.vehiclestore_licenseplate}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span> 
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b"}} onClick={deleteDataVehicles}>
                                    <DeleteIcon style={{fontSize: 24}}/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Purchases_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df",}} onClick={searchVehicles}>
                                    <SearchIcon style={{fontSize: 24}}/>
                                  </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_TRANS').toUpperCase()} :</label> 
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="rel_trans_tradename"
                                    name="rel_trans_tradename"
                                    value={formPurchaseDelivery.rel_trans_tradename}
                                    onChange={handleSearchTradename}
                                    onKeyDown={handleKeyDownTradename}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.rel_trans_tradename!=null?t(errorsForm.rel_trans_tradename):t('Placeholder_PURCHASEDELIVERY_NAME_TRANS')}
                                    placeholder={t('Placeholder_PURCHASEDELIVERY_NAME_TRANS')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?394:417,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredTradename.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "150%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredTradename.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectTradeName(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexTradename ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexTradename ? "white" : "black",
                                            }}
                                        >
                                          {store.tradename} - {store.documentId}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group">   
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_NIMA').toUpperCase()} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_nima" 
                                  name="rel_trans_nima"
                                  value={formPurchaseDelivery.rel_trans_nima}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px', }, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.rel_trans_nima!=null?t(errorsForm.rel_trans_nima):t('Placeholder_PURCHASEDELIVERY_TRADENAME_NIMA')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_NIMA')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_PYGR')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="rel_trans_grnp" 
                                  name="rel_trans_grnp"
                                  value={formPurchaseDelivery.rel_trans_grnp}
                                  inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px', }, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.rel_trans_grnp!=null?t(errorsForm.rel_trans_grnp):t('Placeholder_PURCHASEDELIVERY_TRADENAME_PYGR')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_PYGR')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary">{t('Text_PURCHASEDELIVERY_TRADENAME_DRIVER')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="vehiclestore_defaultdriver" 
                                  name="vehiclestore_defaultdriver"
                                  value={formPurchaseDelivery.vehiclestore_defaultdriver}
                                  inputProps={{ style: {width: i18n.language==='es'?738:782,  fontSize: '18px', }, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.vehiclestore_defaultdriver!=null?t(errorsForm.vehiclestore_defaultdriver):t('Placeholder_PURCHASEDELIVERY_TRADENAME_DRIVER')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_DRIVER')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                />
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_CIF_STORE').toUpperCase()} :</label>  
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="ori_tras_documentid"
                                    name="ori_tras_documentid"
                                    value={formPurchaseDelivery.ori_tras_documentid}
                                    onChange={handleSearchDocumentIdOri}
                                    onKeyDown={handleKeyDownDocumentIdOri}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.ori_tras_documentid!=null?t(errorsForm.ori_tras_documentid):t('Text_PURCHASEDELIVERY_CIF_STORE')}
                                    placeholder={t('Text_PURCHASEDELIVERY_CIF_STORE')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?161:161,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredDocumentIdOri.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "350%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredDocumentIdOri.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectDocumentIdOri(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexDocumentIdOri ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexDocumentIdOri ? "white" : "black",
                                            }}
                                        >
                                          {store.documentId} - {store.tradename}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span>    
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b"}} onClick={deleteDataStoreOriTras}>
                                  <DeleteIcon style={{fontSize: 24}}/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                  <IconButton  size="small"  style={{color:"#4e73df"}} onClick={searchStoreOriTras}>
                                    <SearchIcon style={{fontSize: 24}}/>
                                  </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_STORE').toUpperCase()} :</label>   
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="ori_tras_tradename"
                                    name="ori_tras_tradename"
                                    value={formPurchaseDelivery.ori_tras_tradename}
                                    onChange={handleSearchTradenameOri}
                                    onKeyDown={handleKeyDownTradenameOri}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.ori_tras_tradename!=null?t(errorsForm.ori_tras_tradename):t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE')}
                                    placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?550:532,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredTradenamedOri.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredTradenamedOri.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectTradeNameOri(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexTradenamedOri ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexTradenamedOri ? "white" : "black",
                                            }}
                                        >
                                          {store.tradename} - {store.documentId}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span>  
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_EMAIL_STORE').toUpperCase()} :</label>    
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_email" 
                                  name="ori_tras_email"
                                  value={formPurchaseDelivery.ori_tras_email}
                                  inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                  label={errorsForm.ori_tras_email!=null?t(errorsForm.ori_tras_email):t('Placeholder_PURCHASEDELIVERY_EMAIL_STORE')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_EMAIL_STORE')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />  
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_WORKPLACE').toUpperCase()} :</label>    
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="ori_tras_workplace"
                                    name="ori_tras_workplace"
                                    value={formPurchaseDelivery.ori_tras_workplace}
                                    onChange={handleSearchWorkPlace}
                                    onKeyDown={handleKeyDownWorkPlace}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsForm.ori_tras_workplace!=null?t(errorsForm.ori_tras_workplace):t('Placeholder_PURCHASEDELIVERY_TRADENAME_WORKPLACE')}
                                    placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_WORKPLACE')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?1407:1343,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredWorkPlace.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredWorkPlace.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectWorkPlace(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexWorkPlace ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexWorkPlace ? "white" : "black",
                                            }}
                                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                        >
                                          {store.workplace}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span> 
                              </div>
                            </div>
                            <div className="row p-2"> 
                              <div className="form-group"> 
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS').toUpperCase()} :</label>    
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_street" 
                                  name="ori_tras_street"
                                  value={formPurchaseDelivery.ori_tras_street}
                                  inputProps={{ style: {width: i18n.language==='es'?376:416,  fontSize: '18px', }, maxLength: 255 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  label={errorsForm.ori_tras_street!=null?t(errorsForm.ori_tras_street):t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS')}
                                  placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_ADDRESS')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery} 
                                />  
                                &nbsp;&nbsp;
                                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataStoreAddressOriTras}>
                                  <DeleteIcon style={{fontSize: 24}}/>
                                </IconButton>
                                &nbsp;&nbsp;
                                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Tickets_Store_Master'))&&
                                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchStoreAddress}>
                                      <SearchIcon style={{fontSize: 24}}/>
                                    </IconButton>
                                } 
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_PROVINCE').toUpperCase()} :</label>    
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="ori_tras_province_id"
                                  name="ori_tras_province_id"
                                  value={ (formPurchaseDelivery.ori_tras_province_id === undefined || formPurchaseDelivery.ori_tras_province_id ===null || formPurchaseDelivery.ori_tras_province_id ===""
                                      || formPurchaseDelivery.ori_tras_province_id ==="0" || formPurchaseDelivery.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE):formPurchaseDelivery.ori_tras_province_id}
                                  inputProps={{ style: {width: i18n.language==='es'?180:180,  fontSize: '18px', }, maxLength: 100 }}
                                  style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                  variant="outlined"
                                  onBlur={handleBlurDataEditPurchaseDelivery}
                                  onChange={handleChangeDataEditPurchaseDelivery}
                                  helperText={errorsForm.ori_tras_province_id!=null && errorsForm.ori_tras_province_id!==""?t(errorsForm.ori_tras_province_id):""}
                                  select
                                  SelectProps={{
                                      native: true,
                                      value: (formPurchaseDelivery.ori_tras_province_id === undefined || formPurchaseDelivery.ori_tras_province_id ===null || formPurchaseDelivery.ori_tras_province_id ===""
                                          || formPurchaseDelivery.ori_tras_province_id ==="0" || formPurchaseDelivery.ori_tras_province_id ===0)?Number(DEFAULTPROVINCE): formPurchaseDelivery.ori_tras_province_id
                                  }}>
                                  <option key={0} value=""/>
                                  {provinceToSelect.map((option) => (
                                  <option key={option.id} value={option.id}>
                                      {option.name}
                                  </option>
                                  ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_POSTALCODE').toUpperCase()} :</label>    
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="ori_tras_postalcode_id"
                                name="ori_tras_postalcode_id"
                                value={ (formPurchaseDelivery.ori_tras_postalcode_id === undefined || formPurchaseDelivery.ori_tras_postalcode_id ===null || formPurchaseDelivery.ori_tras_postalcode_id ===""
                                    || formPurchaseDelivery.ori_tras_postalcode_id ==="0" || formPurchaseDelivery.ori_tras_postalcode_id ===0)?"":formPurchaseDelivery.ori_tras_postalcode_id}
                                inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                variant="outlined"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                                helperText={errorsForm.ori_tras_postalcode_id!=null && errorsForm.ori_tras_postalcode_id!==""?t(errorsForm.ori_tras_postalcode_id):""}
                                select
                                SelectProps={{
                                native: true,
                                value: (formPurchaseDelivery.ori_tras_postalcode_id === undefined || formPurchaseDelivery.ori_tras_postalcode_id ===null || formPurchaseDelivery.ori_tras_postalcode_id ===""
                                    || formPurchaseDelivery.ori_tras_postalcode_id ==="0" || formPurchaseDelivery.ori_tras_postalcode_id ===0)?"": formPurchaseDelivery.ori_tras_postalcode_id
                                }}>
                                <option key={0} value=""/>
                                {postalCodeToSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.postalcodename}
                                </option>
                                ))}
                              </TextField>
                            </div>
                          </div>
                          <div className="row p-2"> 
                            <div className="form-group"> 
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PURCHASEDELIVERY_TRADENAME_STORE_OTHERS').toUpperCase()} :</label>    
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="observations" 
                                name="observations"
                                value={formPurchaseDelivery.observations}
                                inputProps={{ style: {width: i18n.language==='es'?1303:1319,  fontSize: '18px', }, maxLength: 255 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                placeholder={t('Placeholder_PURCHASEDELIVERY_TRADENAME_STORE_OTHERS')}
                                variant="standard"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                              />
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PurchaseDeliver_Payment').toUpperCase()} :</label>    
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <TextField
                                id="payment_id"
                                name="payment_id"
                                value={ (formPurchaseDelivery.payment_id === undefined || formPurchaseDelivery.payment_id ===null || formPurchaseDelivery.payment_id ===""
                                      || formPurchaseDelivery.payment_id ==="0" || formPurchaseDelivery.payment_id ===0)?2:formPurchaseDelivery.payment_id}
                                inputProps={{ style: {width: i18n.language==='es'?300:300,  fontSize: '18px', }, maxLength: 255 }}
                                style={{ color:"#4e73df", fontSize: 18, textAlign:'right'}} 
                                InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                                variant="outlined"
                                onBlur={handleBlurDataEditPurchaseDelivery}
                                onChange={handleChangeDataEditPurchaseDelivery}
                                select
                                SelectProps={{
                                  native: true,
                                  value: (formPurchaseDelivery.payment_id === undefined || formPurchaseDelivery.payment_id ===null || formPurchaseDelivery.payment_id ===""
                                      || formPurchaseDelivery.payment_id ==="0" || formPurchaseDelivery.payment_id ===0)?2: formPurchaseDelivery.payment_id
                                }}> 
                                {paymenetTypeSelect.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {i18n.language==="es"?option.namees:option.nameen}
                                </option>
                                ))}
                              </TextField>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_PurchaseDeliver_TOTAL_WEIGHT').toUpperCase()} :</label>    
                              &nbsp;&nbsp;&nbsp;&nbsp; 
                              <NumericFormat 
                                thousandSeparator={','} 
                                decimalSeparator={'.'} 
                                decimalScale= {2}
                                fixedDecimalScale= {true}
                                allowNegative= {false}
                                isAllowed={withValueCap3}
                                id="totalWeigthNeto" 
                                name="totalWeigthNeto"
                                suffix=' Tn'
                                value={formPurchaseDelivery.totalWeigthNeto}
                                style={{width:250, color:"#red", background:"#black",fontSize: 28, textAlign:'right', paddingRight:5}} 
                                onBlur={(e) => handleBlurTotalWeightNeto(e.target.value)}
                                onChange={(e) => handleChangeTotalWeightNeto(e.target.value)}
                              />   
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <TableSortingPurchaseDeliveryArticleList
                                dataArticles={formPurchaseDelivery.linespurchasedelivery} 
                                numTotRegArticles={formPurchaseDelivery.linespurchasedelivery.length}
                                setOpenDeleteRowArticles={setOpenDeleteRowArticles}
                                setEditRowArticles={setEditRowArticles}
                                formPurchaseDelivery={formPurchaseDelivery}                                  
                                setFormPurchaseDelivery={setFormPurchaseDelivery}
                                setShowModalSearchArticles={setShowModalSearchArticles}
                                LIMITPAGESIZE={LIMITPAGESIZE}
                                setShowModalSearchDI={setShowModalSearchDI}
                              />
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <Grid container spacing={8} justify="center">
                                <Grid item xs={2} sm={4} md={2} key={1}>
                                  <Card sx={{ width: 250 }}>
                                    <CardMedia
                                      sx={{ height: 150 }}
                                      image={(formPurchaseDelivery.signature_dispatched_by!==undefined && formPurchaseDelivery.signature_dispatched_by!=="")?formPurchaseDelivery.signature_dispatched_by:image1}
                                      title={t("Text_Signature_DispatchedBy")}
                                    />
                                    <CardContent>
                                      <Typography style={{color:"#067330", fontSize: 16, textAlign:'center'}}>
                                        {t( "Text_Signature_DispatchedBy")}
                                      </Typography>
                                    </CardContent>
                                    <CardActions>
                                      <Button size="small" onClick={(e) => selectModalSignature(1)}>{t('Button_Sign')}</Button>
                                      <Button size="small" onClick={(e) => deleteSignature(1)}>{t('Button_Delete')}</Button>
                                    </CardActions>
                                  </Card>
                                </Grid>
                                <Grid item xs={2} sm={3} md={2.1} key={2}>
                                  <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                                    <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <ButtonGroup
                                        orientation="vertical"
                                        aria-label="Vertical button group"
                                        variant="contained"
                                        sx={{ gap: 2 }}
                                      >
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureFrancisco} >
                                          {t("Text_SIGN_FRANCISCO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureGonzalo}>
                                          {t("Text_SIGN_GONZALO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureGuillermo}>
                                          {t("Text_SIGN_GUILLLERMO")}
                                        </Button>
                                        <Button size="large" variant="contained" style={{ color: "#ffffff", background: "#4e73df", fontSize: 16, padding: 5 }} onClick={handleSetSignatureJorge}>
                                          {t("Text_SIGN_JORGE")}
                                        </Button>
                                      </ButtonGroup>
                                    </CardContent>
                                  </Card>
                                </Grid> 
                                
                                <Grid item xs={2} sm={4} md={2} key={3}>
                                  <Card sx={{ width: 250 }}>
                                    <CardMedia
                                      sx={{ height: 150 }}
                                      image={(formPurchaseDelivery.signature_client!==undefined && formPurchaseDelivery.signature_client!=="")?formPurchaseDelivery.signature_client:image1}
                                      title={t("Text_Signature_client")}
                                    />
                                    <CardContent>
                                      <Typography style={{color:"#067330", fontSize: 16, textAlign:'center'}}>
                                        {t("Text_Signature_client")}
                                      </Typography>
                                    </CardContent>
                                    <CardActions>
                                      <Button size="small" onClick={(e) => selectModalSignature(2)}>{t('Button_Sign')}</Button>
                                      <Button size="small" onClick={(e) => deleteSignature(2)}>{t('Button_Delete')}</Button>
                                    </CardActions>
                                  </Card>
                                </Grid> 
                              </Grid>
                              <hr className="sidebar-divider d-none d-md-block"/>
                                <Box sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 2, md: 12 }}>
                                    {articlesButtonsList.map((post, index) => (
                                      <Grid item xs={2} sm={2} md={2} key={index}>
                                        <Item>
                                          <Button variant="contained" style={{color:"#ffffff", background:post.button_color,fontSize: 16}} onClick={()=>selectArticle(post)}>
                                            {i18n.language==='es'?post.button_namees:post.button_nameen}
                                          </Button>
                                        </Item>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Box>
                              </div>                              
                            </div>
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <LoadingNews/>
                }
              </form>
            </Box>
            <br/>
            
            <PurchaseDeliverySearchStores
              showModalSearchStores={showModalSearchStores}
              setShowModalSearchStores={setShowModalSearchStores}
              selectStores={tetxtSearch}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
            />
            <PurchaseDeliverySearchVehicles
              showModalSearchVehicles={showModalSearchVehicles}
              setShowModalSearchVehicles={setShowModalSearchVehicles}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
              formPurchaseDelivery={formPurchaseDelivery}
            /> 
            <PurchaseDeliverySearchStoresAddresses
              showModalSearchStoresAddress={showModalSearchStoresAddress}
              setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
              setFormPurchaseDelivery={setFormPurchaseDelivery}
              titleTable={formPurchaseDelivery.ori_tras_tradename}
              store_id={formPurchaseDelivery.ori_tras_store_id}
              priority={2}
            />
            <PurchaseDeliverySearchArticles
              showModalSearchArticles={showModalSearchArticles}
              setShowModalSearchArticles={setShowModalSearchArticles}
              formPurchaseDelivery={formPurchaseDelivery}                                  
              setFormPurchaseDelivery={setFormPurchaseDelivery} 
            /> 
            <PurchaseDeliverySearchDI
              showModalSearchDI={showModalSearchDI}
              setShowModalSearchDI={setShowModalSearchDI}
              editRowArticles={editRowArticles}
              setEditRowArticles={setEditRowArticles}
              formPurchaseDelivery={formPurchaseDelivery} 
              setFormPurchaseDelivery={setFormPurchaseDelivery} 
            /> 
            <Dialog open={openSignDispatched} onClose={handleCancelSignDispatched}>
              <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_DispatchedBy')} </DialogTitle>
                <DialogContent>
                  <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                      ref={dataOptTras=>setSignDispatchedCanvas(dataOptTras)}
                    />
                  </div>
                </DialogContent>
              <DialogActions>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignDispatched}>{t('Button_Cancel')}</Button>
                <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignDispatched}>{t('Button_Delete')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignDispatched}>{t('Button_Sign')}</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={openSignClient} onClose={handleCancelSignClient}>
              <DialogTitle style={{color:"#4e73df", textAlign:'center'}}>{t('Text_Signature_client')} </DialogTitle>
                <DialogContent>
                  <div  style={{border:'2px solid #4e73df', width: 250, height: 150 }}>
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ width: 250, height: 150, className: 'sigCanvas' }}
                      ref={dataDestTras=>setSignClientCanvas(dataDestTras)}
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleCancelSignClient}>{t('Button_Cancel')}</Button>
                  <Button style={{color:"#4e73df",fontSize: 16 }} onClick={handleDeleteSignClient}>{t('Button_Delete')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#4e73df",fontSize: 16}} onClick={handleSubmitSignClient}>{t('Button_Sign')}</Button>
                </DialogActions>
            </Dialog> 
            
            <Dialog open={openDeleteRowArticles} onClose={handleCancelDeleteRow}>
              <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {editRowArticles.indexrowarticle}  - {editRowArticles.article_code} - {i18n.language==='es'?editRowArticles.article_namees:editRowArticles.article_nameen} </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t('Text_Line1')}<br/>
                  {t('Text_Line2')}
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="idCode"
                  label={t('Text_Label')}
                  type="text"
                  fullWidth
                  variant="standard"
                  error
                  defaultValue={idCodeDeletePurchaseDelivery}
                  onChange={handleChangeDeleteRow}/>
              </DialogContent>
              <DialogActions>
                <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRow}>{t('Button_Cancel')}</Button>
                <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRow}>{t('Button_Delete')}</Button>
              </DialogActions>
            </Dialog>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

PurchaseDeliveryEditOrCreate.propTypes = {
  id: PropTypes.string.isRequired,
  showModalPurchasesDeliverySettings: PropTypes.bool.isRequired,
  typeOfList: PropTypes.string.isRequired
};

export default PurchaseDeliveryEditOrCreate;