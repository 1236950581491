import React, {  useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box';    
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';    
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import PropTypes from 'prop-types';
import TableSortingTicketsVehicles from 'pages/tickets/ticketsEdit/SortingTablesTicketsVehicles'; 

const initialFiltersVehicles= {
  "idFrom":"",
  "idTo":"",
  "documentIdFrom":"",
  "documentIdTo":"",
  "tradenameFrom":"",
  "tradenameTo":"",
  "nameFrom":"",
  "nameTo":"",
  "contacpersonFrom":"",
  "contacpersonTo":"",
  "vehicleStoreFrom":"",
  "vehicleStoreTo":""
};
 
const initialStateVehicles = [{
  "id":"",
  "licenseplate":0,
  "defaultvehicle":false,
  "tara":0,
  "pma":0,
  "checkpma":false,
  "defaultdriver":"",
  "store_id":0,
  "store_documnettype_id":0,
  "store_documnettype_name":"",
  "store_tradename":"",
  "store_name":"",
  "store_contacperson":"",
  "store_grnp_rel_trans":""

}];
  
const TicketsSearchVehicles = ({ showModalSearchVehicles, setShowModalSearchVehicles,setForm,form}) => {
  const {t,i18n} = useTranslation(['listDataTickets']);
  const state = useSelector((state) => state);
  const {token} = state.loginUser
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const [dataVehicle, setDataVehicle] = useState(initialStateVehicles);
  const [isLoadingErrorVehicle, setIsLoadingErrorVehicle] = useState(false);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const [inputTextVehicle, setInputTextVehicle] = useState("");
  const [applyFiltersVehicle, setApplyFiltersVehicle]= useState(initialFiltersVehicles);
  const [filtersVehicle, setFiltersVehicle]= useState(initialFiltersVehicles);
  const [openEditFilterVehicle,setOpenEditFilterVehicle]= useState(false);
  const [errorsFiltersVehicle, setErrorsFiltersVehicle] = useState({});
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegVehicle,setNumTotRegVehicle]= useState(0);
  const [activeOffsetVehicle, setActiveOffsetVehicle]= useState(false);
  const [currencyOffsetVehicle, setCurrencyOffsetVehicle] = useState(0);
  const [rangeOffsetVehicle, setRangeOffsetVehicle] = useState([{}]); 
  const dispatch = useDispatch(); 
   
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && setShowModalSearchVehicles) {
        setDataVehicle(initialStateVehicles);
        setIsLoadingErrorVehicle(false);
        dispatch(setLoading(false));
        setInputTextVehicle("");
        setApplyFiltersVehicle(initialFiltersVehicles);
        setFiltersVehicle(initialFiltersVehicles);
        setOpenEditFilterVehicle(false);
        setErrorsFiltersVehicle({});
        setNumTotRegFilter(0);
        setNumTotRegVehicle(0);
        setActiveOffsetVehicle(false);
        setCurrencyOffsetVehicle(0);
        setRangeOffsetVehicle([{}]);
        setShowModalSearchVehicles(false);
      }
    },
    [ setDataVehicle,
      setIsLoadingErrorVehicle, 
      setInputTextVehicle,
      setApplyFiltersVehicle,
      setFiltersVehicle,
      setOpenEditFilterVehicle,
      setErrorsFiltersVehicle,
      setNumTotRegFilter,
      setNumTotRegVehicle,
      setActiveOffsetVehicle,
      setCurrencyOffsetVehicle,
      setRangeOffsetVehicle,
      setShowModalSearchVehicles,
      dispatch
    ]
  );

  const handleCloseDataEditVehicle = () => {
    setDataVehicle(initialStateVehicles);
    setIsLoadingErrorVehicle(false);
    dispatch(setLoading(false));
    setInputTextVehicle("");
    setApplyFiltersVehicle(initialFiltersVehicles);
    setFiltersVehicle(initialFiltersVehicles);
    setOpenEditFilterVehicle(false);
    setErrorsFiltersVehicle({});
    setNumTotRegFilter(0);
    setNumTotRegVehicle(0);
    setActiveOffsetVehicle(false);
    setCurrencyOffsetVehicle(0);
    setRangeOffsetVehicle([{}]); 
    setShowModalSearchVehicles(false)
	};

  const toggleDrawerSearchFormVehicles  = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      handleCloseDataEditVehicle();
    }
  };

  let inputHandlerVehicle = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputTextVehicle(lowerCase);
  };

  const openEditFiltersVehicle = () => {
    setFiltersVehicle(applyFiltersVehicle);
    setOpenEditFilterVehicle(!openEditFilterVehicle);
  };

  const toggleDrawerFilterEditVehicle = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditVehicle();
    }
  };

  const handleCancelFiltersVehicle = () => {
    setErrorsFiltersVehicle({});
    setFiltersVehicle(initialFiltersVehicles);
    setOpenEditFilterVehicle(!openEditFilterVehicle);
  };

  const handleChangeFilterEditVehicle = (e) => {
    const { name, value } = e.target;
    setFiltersVehicle(filtersVehicle => ({
      ...filtersVehicle,
      [name]: value
    }));
  };

  const handleBlurFilterEditVehicle = (e) => {
    setErrorsFiltersVehicle(validateFormFilterVehicle());
    handleChangeFilterEditVehicle(e);
  };

  const handleSubmitFilterEditVehicle = () => {
    setErrorsFiltersVehicle(validateFormFilterVehicle());
    if (Object.keys(errorsFiltersVehicle).length === 0) {
      setApplyFiltersVehicle(filtersVehicle);
      setFiltersVehicle(initialFiltersVehicles);
      setErrorsFiltersVehicle({});
      setOpenEditFilterVehicle(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }
  const handleDeleteFiltersVehicle = () => {
    setErrorsFiltersVehicle({});
    setFiltersVehicle(initialFiltersVehicles);
    setApplyFiltersVehicle(initialFiltersVehicles);
    setOpenEditFilterVehicle(!openEditFilterVehicle);
  };

  const validateFormFilterVehicle = () => {

    let errorsFiltersVehicle = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;
    
    if (filtersVehicle.idFrom!=="" & !regexNumber.test(filtersVehicle.idFrom)) {
      errorsFiltersVehicle.idFrom = 'Text_TextNoNumber';
    }
    if (filtersVehicle.idTo!=="" & !regexNumber.test(filtersVehicle.idTo)) {
      errorsFiltersVehicle.idTo = 'Text_TextNoNumber';
    }
    if (filtersVehicle.idFrom!=="" & !errorsFiltersVehicle.idFrom!==undefined){
      if (filtersVehicle.idTo!=="" & !errorsFiltersVehicle.idTo!==undefined){
        if (parseInt(filtersVehicle.idTo)<parseInt(filtersVehicle.idFrom)){
          errorsFiltersVehicle.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (regexInvalidCharacters.test(filtersVehicle.documentIdFrom)) {
      errorsFiltersVehicle.documentIdFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.documentIdTo)) {
      errorsFiltersVehicle.documentIdTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.tradenameFrom)) {
      errorsFiltersVehicle.tradenameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.tradenameTo)) {
      errorsFiltersVehicle.tradenameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.nameFrom)) {
      errorsFiltersVehicle.nameFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.nameTo)) {
      errorsFiltersVehicle.nameTo = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.contacpersonFrom)) {
      errorsFiltersVehicle.contacpersonFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.contacpersonTo)) {
      errorsFiltersVehicle.contacpersonTo = 'Text_TextNoValid';
    } 
     
    if (regexInvalidCharacters.test(filtersVehicle.vehicleStoreFrom)) {
      errorsFiltersVehicle.vehicleStoreFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersVehicle.vehicleStoreTo)) {
      errorsFiltersVehicle.vehicleStoreTo = 'Text_TextNoValid';
    } 
     
    if (filtersVehicle.documentIdTo!=="" & filtersVehicle.documentIdTo<filtersVehicle.documentIdFrom){
      errorsFiltersVehicle.documentIdTo = 'Text_TextGreater_documentid';
    }
    if (filtersVehicle.tradenameTo!=="" & filtersVehicle.tradenameTo<filtersVehicle.tradenameFrom){
      errorsFiltersVehicle.tradenameTo = 'Text_TextGreater_tradename';
    }
    if (filtersVehicle.nameTo!=="" & filtersVehicle.nameTo<filtersVehicle.nameFrom){
      errorsFiltersVehicle.nameTo = 'Text_TextGreater_name';
    }
    if (filtersVehicle.contacpersonTo!=="" & filtersVehicle.contacpersonTo<filtersVehicle.contacpersonFrom){
      errorsFiltersVehicle.contacpersonTo = 'Text_TextGreater_contactName';
    }
    if (filtersVehicle.vehicleStoreTo!=="" & filtersVehicle.vehicleStoreTo<filtersVehicle.vehicleStoreFrom){
      errorsFiltersVehicle.vehicleStoreTo = 'Text_TextGreater_vehicles';
    }
    return errorsFiltersVehicle;
  }  

  const filteredDataVehicle = dataVehicle.filter((el) => {
    if (inputTextVehicle === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.licenseplate || '') +
        String(el.defaultdriver || '') +
        String(el.store_documentid || '') +
        String(el.store_tradename || '') +
        String(el.store_name || '') +
        String(el.store_contacperson || '');
      let result = dataTranslate.toLowerCase().includes(inputTextVehicle.toLowerCase());
      return result;
    }
  })
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
   
  useEffect(() => {
    let isMounted = true;

    const fetchnumTotRegVehicle = async () => {
      try {
        dispatch(setLoading(true));
        const getData = await helpHttp().get(ENDPOINT, `ticketsgetdata/getinformationtablevehicles`, token);
        if (getData.err) {
          setDataVehicle(initialStateVehicles);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegVehicle(getData);
           dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
         dispatch(setLoading(false));
      } 
    };
 
    const fetchnumTotRegFilter = async () => {
      dispatch(setLoading(true));
      try {
        const getData = (form.rel_trans_documentid!==undefined && form.rel_trans_documentid!==null && form.rel_trans_documentid!=="")
        ?await helpHttp().post2(ENDPOINT,`ticketsgetdata/getcountfiltervehiclesstore`,token,form.rel_trans_documentid,applyFiltersVehicle)
        :await await helpHttp().post(ENDPOINT,`ticketsgetdata/getcountfiltervehicles`,token,applyFiltersVehicle);
        if (getData.err || getData.message === "Load failed") {
          setDataVehicle(initialStateVehicles);
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setNumTotRegFilter(getData);
           dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
        dispatch(setLoading(false));
      }
    };

    const fetchDataVehicle = async () => {
      dispatch(setLoading(true));
      try {
        const getData = (form.rel_trans_documentid!==undefined && form.rel_trans_documentid!==null && form.rel_trans_documentid!=="")
        ?await helpHttp().get4(ENDPOINT,`ticketsgetdata/getvehiclesstore`,token,form.rel_trans_documentid,currencyOffsetVehicle,LIMITPAGESIZE,applyFiltersVehicle)
        :await helpHttp().get3(ENDPOINT,`ticketsgetdata/getvehicles`,token,currencyOffsetVehicle,LIMITPAGESIZE,applyFiltersVehicle);
        if (getData.err || getData.message === "Load failed") {
          setDataVehicle(initialStateVehicles);
           dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataVehicle(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
         dispatch(setLoading(false));
      } 
    };
    
    if (showModalSearchVehicles){
      fetchnumTotRegVehicle();
      fetchnumTotRegFilter();
      fetchDataVehicle();
    }
    return () => { isMounted = false };
  }, [t,i18n,token,dispatch,LIMITPAGESIZE,applyFiltersVehicle,currencyOffsetVehicle,showModalSearchVehicles,form.rel_trans_documentid]);
  
  return(
    <>
      {showModalSearchVehicles && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerSearchFormVehicles(false)}
            onOpen={toggleDrawerSearchFormVehicles(true)}>
            <Container >
              <Box sx={{ bgcolor: '#ffffff', width: '1150px'}}>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  {isLoadingErrorVehicle?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoading?<CircularProgress color={"primary"}/>:<i className="fas fa-address-card text-warning"></i>} {t('Text_TICKETS_Vehicles_Search')}</h1>
                    <div>
                      <button type="button" className="buttonCreate" onClick={handleCloseDataEditVehicle}>{t('Button_Exit')}</button><> </>
                    </div> 
                </div>  
                <br/>
                <div className="searchStyle">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={inputTextVehicle}
                    inputProps={{ style: {fontSize: '18px'} }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                    placeholder={(form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="")?t('Text_Vehicle_Search'):t('Text_Vehicle_Store_Search')} 
                    onChange={inputHandlerVehicle}
                    fullWidth
                    label={(form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="")?t('Text_Vehicle_Search'):t('Text_Vehicle_Store_Search')} >
                  </TextField>
                </div>
                <br/>
                <div id="myDIV">
                  <h1 className="h6NewStyleHigh mb-2">
                    {t('Text_Filter')}&nbsp;
                      <button type="button" className="buttonFilterHigh" onClick={openEditFiltersVehicle}><i className="fas fa-solid fa-circle" style={{fontSize: '14px',color:(applyFiltersVehicle.idFrom!=="" || applyFiltersVehicle.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                      <button type="button" className="buttonFilterHigh" onClick={openEditFiltersVehicle}><i className="fas fa-solid fa-circle" style={{fontSize: '14px',color:(applyFiltersVehicle.vehicleStoreFrom!=="" || applyFiltersVehicle.vehicleStoreTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_vehicles')}</button><> </> 
                      {(form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="")&&
                        <>          
                          <button type="button" className="buttonFilterHigh" onClick={openEditFiltersVehicle}><i className="fas fa-solid fa-circle" style={{fontSize: '14px',color:(applyFiltersVehicle.documentIdFrom!=="" || applyFiltersVehicle.documentIdTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_documentid')}</button><> </>
                          <button type="button" className="buttonFilterHigh" onClick={openEditFiltersVehicle}><i className="fas fa-solid fa-circle" style={{fontSize: '14px',color:(applyFiltersVehicle.tradenameFrom!=="" || applyFiltersVehicle.tradenameTo!=="" || applyFiltersVehicle.nameFrom!=="" || applyFiltersVehicle.nameTo!=="" || applyFiltersVehicle.contacpersonFrom!=="" || applyFiltersVehicle.contacpersonTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Others_Filters')}</button><> </>
                        </>
                      }
                  </h1>
                </div>
                <br/>
                {!isLoading ?
                  <>
                    <div className="card shadow mb-4">    
                      <div className="card-body">
                        <TableSortingTicketsVehicles
                          dataVehicle={filteredDataVehicle}
                          numTotRegFilter={ numTotRegFilter}
                          titleTable={(form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="")?t('Text_Vehicles_Table'):form.rel_trans_tradename}
                          numTotRegVehicle={numTotRegVehicle}
                          activeOffsetVehicle={activeOffsetVehicle}
                          rangeOffsetVehicle={rangeOffsetVehicle}
                          currencyOffsetVehicle={currencyOffsetVehicle}
                          setCurrencyOffsetVehicle={setCurrencyOffsetVehicle} 
                          setForm={setForm}
                          handleCloseDataEditVehicle={handleCloseDataEditVehicle}
                        >
                        </TableSortingTicketsVehicles>
                      </div>             
                    </div>
                    {openEditFilterVehicle &&
                      <div>
                      <SwipeableDrawer
                        anchor='right'
                        open={true}
                        onClose={toggleDrawerFilterEditVehicle(false)}
                        onOpen={toggleDrawerFilterEditVehicle(true)}>
                        <Container >
                          <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                            <br/>
                              <div id="myDIV">
                                <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                              </div>
                              <hr className="sidebar-divider d-none d-md-block"/>
                              <form className="newfilterformstore" onSubmit={handleCancelFiltersVehicle}>  
                                <div className="form-floating mb-3 px-4 primary">
                                  <div className="form-group">
                                     <TextField
                                        id="idFrom" 
                                        name="idFrom"
                                        value={filtersVehicle.idFrom}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                        label={errorsFiltersVehicle.idFrom!=null?t(errorsFiltersVehicle.idFrom):t('Text_Filter_Code_From')}
                                        placeholder={t('PlaceHolder_Filter_Code_From')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditVehicle}
                                        onChange={handleChangeFilterEditVehicle}/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                      <TextField
                                        id="idTo" 
                                        name="idTo"
                                        value={filtersVehicle.idTo}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                        label={errorsFiltersVehicle.idTo!=null?t(errorsFiltersVehicle.idTo):t('Text_Filter_Code_To')}
                                        placeholder={t('PlaceHolder_Filter_Code_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditVehicle}
                                        onChange={handleChangeFilterEditVehicle}/>
                                    </div>
                                    <div className="form-group">
                                      <TextField
                                        id="vehicleStoreFrom" 
                                        name="vehicleStoreFrom"
                                        value={filtersVehicle.vehicleStoreFrom}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 50 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                        label={errorsFiltersVehicle.vehicleStoreFrom!=null?t(errorsFiltersVehicle.vehicleStoreFrom):t('PlaceHolder_Filter_vehicles_From')}
                                        placeholder={t('PlaceHolder_Filter_vehicles_From')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditVehicle}
                                        onChange={handleChangeFilterEditVehicle}/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                      <TextField
                                        id="vehicleStoreTo" 
                                        name="vehicleStoreTo"
                                        value={filtersVehicle.vehicleStoreTo}
                                        inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 50 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                        label={errorsFiltersVehicle.vehicleStoreTo!=null?t(errorsFiltersVehicle.vehicleStoreTo):t('PlaceHolder_Filter_vehicles_to')}
                                        placeholder={t('PlaceHolder_Filter_vehicles_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditVehicle}
                                        onChange={handleChangeFilterEditVehicle}/>
                                    </div>
                                    {(form.rel_trans_documentid===undefined || form.rel_trans_documentid===null || form.rel_trans_documentid==="")&&
                                      <>
                                        <div className="form-group">
                                          <TextField
                                            id="documentIdFrom" 
                                            name="documentIdFrom"
                                            value={filtersVehicle.documentIdFrom}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}    
                                            label={errorsFiltersVehicle.documentIdFrom!=null?t(errorsFiltersVehicle.documentIdFrom):t('Text_Filter_documentId_From')}
                                            placeholder={t('PlaceHolder_Filter_documentid_From')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <TextField
                                            id="documentIdTo" 
                                            name="documentIdTo"
                                            value={filtersVehicle.documentIdTo}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.documentIdTo!=null?t(errorsFiltersVehicle.documentIdTo):t('Text_Filter_documentId_To')}
                                            placeholder={t('PlaceHolder_Filter_documentid_to')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                        </div>
                                        <div className="form-group">
                                          <TextField
                                            id="tradenameFrom" 
                                            name="tradenameFrom"
                                            value={filtersVehicle.tradenameFrom}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.tradenameFrom!=null?t(errorsFiltersVehicle.tradenameFrom):t('Text_Filter_tradename_From')}
                                            placeholder={t('PlaceHolder_Filter_tradename_From')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                          <TextField
                                            id="tradenameTo" 
                                            name="tradenameTo"
                                            value={filtersVehicle.tradenameTo}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.tradenameTo!=null?t(errorsFiltersVehicle.tradenameTo):t('Text_Filter_tradename_To')}
                                            placeholder={t('PlaceHolder_Filter_tradename_to')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                        </div>
                                        <div className="form-group">
                                          <TextField
                                            id="nameFrom" 
                                            name="nameFrom"
                                            value={filtersVehicle.nameFrom}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.nameFrom!=null?t(errorsFiltersVehicle.nameFrom):t('Text_Filter_name_From')}
                                            placeholder={t('PlaceHolder_Filter_name_From')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                          <TextField
                                            id="nameTo" 
                                            name="nameTo"
                                            value={filtersVehicle.nameTo}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.nameTo!=null?t(errorsFiltersVehicle.nameTo):t('Text_Filter_name_To')}
                                            placeholder={t('PlaceHolder_Filter_name_To')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                        </div>
                                        <div className="form-group">
                                          <TextField
                                            id="contacpersonFrom" 
                                            name="contacpersonFrom"
                                            value={filtersVehicle.contacpersonFrom}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.contacpersonFrom!=null?t(errorsFiltersVehicle.contacpersonFrom):t('Text_Filter_contactName_From')}
                                            placeholder={t('PlaceHolder_Filter_contactName_From')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <TextField
                                            id="contacpersonTo" 
                                            name="contacpersonTo"
                                            value={filtersVehicle.contacpersonTo}
                                            inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 100 }}
                                            InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                            label={errorsFiltersVehicle.contacpersonTo!=null?t(errorsFiltersVehicle.contacpersonTo):t('Text_Filter_contactName_To')}
                                            placeholder={t('PlaceHolder_Filter_contactName_To')}
                                            variant="outlined"
                                            onBlur={handleBlurFilterEditVehicle}
                                            onChange={handleChangeFilterEditVehicle}/>
                                      </div>
                                    </>
                                  }                 
                                </div>
                                  
                                <hr className="sidebar-divider d-none d-md-block"/>
                                <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                                  <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditVehicle}>{t('Button_Apply')}</button><> </>
                                  <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersVehicle}>{t('Button_Cancel_Filters')}</button>
                                </div>
                            </form>
                          </Box>
                        </Container>
                      </SwipeableDrawer>
                    </div>
                  }  
                  
                  {/* End Content Row */}
                  </>:
                    <LoadingNews/>
                }             
              </Box>
            </Container>
          </SwipeableDrawer>
        </div>
      }  
    </>
  )
}

TicketsSearchVehicles.propTypes = {
  showModalSearchVehicles: PropTypes.bool.isRequired,
  setShowModalSearchVehicles: PropTypes.func.isRequired, 
  setForm: PropTypes.func.isRequired
};

export default TicketsSearchVehicles;
