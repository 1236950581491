// LOGIN
export const READ_LOGIN = "READ_LOGIN";
export const LOAD_LOGIN = "LOAD_LOGIN";
export const RESET_LOGIN = "RESET_LOGIN";
export const AESKEY = "YjkKLKHnklk8696bK75HJHbJHbJKHbk7";
export const IV = "KJGbUbGsUKYHJWuy";

// ERROR
export const LOAD_SUCCESS_ERROR = "LOAD_SUCCESS_ERROR";
export const LOAD_WARNING_ERROR = "LOAD_WARNING_ERROR";
export const RESET_ERROR = "RESET_ERROR";

// FORMATDI VARIABLES
export const READ_FORMATDI_VARIABLES = "READ_FORMATDI_VARIABLES";
export const LOAD_FORMATDI_VARIABLES = "LOAD_FORMATDI_VARIABLES";
export const RESET_FORMATDI_VARIABLES = "RESET_FORMATDI_VARIABLES";

