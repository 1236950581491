import React, {  useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector } from "react-redux"; 
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next"  
import { useNavigate } from 'react-router-dom';  
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box'; 
import CircularProgress from '@mui/material/CircularProgress';  
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import { successErrorAction,warningErrorAction } from "actions/ErrorActions"; 
import "css/generalSettings.css";    
import MenuItem  from "@mui/material/MenuItem"; 
import SendIcon from '@mui/icons-material/Send'; 
import Textarea from '@mui/joy/Textarea';
import { TextField } from '@mui/material';
import ReportPurchaseSendEmail from "pages/purchaseDelivery/purchaseDeliverySendEmail/ReportPurchaseSendEmail";
import PropTypes from 'prop-types';  

const initialStatePurchaseSendEmail = {
  "id" : "",
  "defaultStore_id":0, 
  "tradename": "",
  "useraccount_id":0,
  "username": "",
  "defaultformatnumber":1,
  "isfinished":false, 
  "purchasenote":0, 
  "numberticket":0, 
  "ownnumberpurchase":"", 
  "datepurchase": null, 
  "vehiclestore_id": 0,
  "vehiclestore_licenseplate": "",
  "vehiclestore_defaultdriver": "",
  "vehiclestore_store_id": 0,
  "rel_trans_store_id": 0,
  "rel_trans_documnettype_id": 0,
  "rel_trans_documentid": "",
  "rel_trans_documnettype_name": "",
  "rel_trans_tradename": "",
  "rel_trans_name": "",
  "rel_trans_contacperson": "",
  "rel_trans_nima": "",
  "rel_trans_pygr": "",
  "rel_trans_grnp":"",
  "rel_trans_opt": 0,
  "rel_trans_roadtype_id": 0,
  "rel_trans_street": "",
  "rel_trans_country_id": 1,
  "rel_trans_province_id": 30,
  "rel_trans_postalcode_id": 0,
  "ori_tras_store_id": 0,
  "ori_tras_documnettype_id": 0,
  "ori_tras_documnettype_name": "",
  "ori_tras_documentid": "",
  "ori_tras_tradename": "",
  "ori_tras_name": "",
  "ori_tras_contacperson": "",
  "ori_tras_nima": "",
  "ori_tras_pygr": "",
  "ori_tras_grnp":"",
  "ori_tras_opt": 0,
  "ori_tras_workplace": "",
  "ori_tras_roadtype_id": 0,
  "ori_tras_street": "",
  "ori_tras_country_id": 1,
  "ori_tras_province_id": 30,
  "ori_tras_postalcode_id": 0,
  "ori_tras_email": "",

  "observations": "",
  "totalpurchasedelivery": 0,
  "totaldiscountpurchasedelivery": 0,
  "totaltaxespurchasedelivery":0,
  "totalNetoPurchasedelivery":0,
  "enabled":true,
  "subownnumberpurchase":0,
  "payment_id" : 0 ,
  "payment_code": "",
  "payment_namees": "",
  "payment_nameen": "",
  "totalWeigthNeto":0,
  "signature_dispatched_by":"",
  "signature_client":"",
  "linespurchasedelivery":[]
}; 

const initialStateEMAIL = {
  "id":"",
  "defaultStore_id":0, 
  "useraccount_id":0,
  "store_EmailTo":"",
  "subjectEmail":"",
  "contentEmail":"",
  "filetosend":[] 
}; 

const initialEmailToSelect = [{
  "id": "",
  "email": "" 
}];

const PurchaseDeliverySendEmail = ({id, showModalPurchasesSendEmail,typeOfList}) => {
  const {t,i18n} = useTranslation(['listDataPurchases']);
  const token = useSelector((state) => state.loginUser.token);   
  const defaultStore = useSelector((state) => state.loginUser.defaultStore)
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const footeremail = useSelector((state) => state.loginUser.defaultticketsformat.footeremail);
   const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const [formPurchaseSendEmail, setFormPurchaseSendEmail] = useState(initialStatePurchaseSendEmail);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [formEMAIL, setFormEMAIL] = useState(initialStateEMAIL);
  const [errorsEMAIL, setErrorsEMAIL] = useState({});
  const [pdfBlob, setPdfBlob] = useState(null); 
  const [emailToSelect,setEmailToSelect]= useState(initialEmailToSelect);  
  const [filteredEmail, setFilteredEmail] = useState([]); // Opciones filtradas
  const [highlightedIndexEmail, setHighlightedIndexEmail] = useState(-1); // Índice de la opción resaltada 
   
  const PdfViewer = useMemo(() => {
    return pdfBlob ? (
      <iframe
        src={URL.createObjectURL(pdfBlob)}
        title="PDF Preview"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    ) : null;
  }, [pdfBlob]);

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalPurchasesSendEmail) {
        setFormPurchaseSendEmail(formPurchaseSendEmail => ({
          ...formPurchaseSendEmail,
          linespurchasedelivery: [],
        }));
        setFormPurchaseSendEmail(initialStatePurchaseSendEmail);
        dispatch(setLoading(false));  
        setPdfBlob(null); 
        setFormEMAIL(initialStateEMAIL);
        setErrorsEMAIL({});
        setEmailToSelect(initialEmailToSelect);
        setFilteredEmail([]);
        setHighlightedIndexEmail(-1); 
      
        navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
      }
    },
    [
      setFormPurchaseSendEmail,  
      setPdfBlob,    
      showModalPurchasesSendEmail,
      setEmailToSelect,
      setFilteredEmail,
      setHighlightedIndexEmail,
      typeOfList,
      dispatch,
      navigate]
  );
  
  const handleCloseSendEmailPurchaseSignature = () => {
    setFormPurchaseSendEmail(formPurchaseSendEmail => ({
      ...formPurchaseSendEmail,
      linespurchasedelivery: [],
    }));
    setFormPurchaseSendEmail(initialStatePurchaseSendEmail);
    dispatch(setLoading(false));
    setPdfBlob(null);   
    setFormEMAIL(initialStateEMAIL);
    setErrorsEMAIL({});
    setEmailToSelect(initialEmailToSelect);
    setFilteredEmail([]);
    setHighlightedIndexEmail(-1); 
    navigate(typeOfList==="1"?"/purchasedelivery":typeOfList==="2"?"/purchasedeliveryended":"/purchasedeliverybegin");
	};


  // Manejo del texto de búsqueda y filtrado dinámico
  const handleSearchEmail = (event) => {
    const searchEmail = event.target.value.toLowerCase();
    setFormEMAIL(formEMAIL => ({
      ...formEMAIL,
      store_EmailTo: searchEmail,
    })); 
    if (searchEmail.trim() === "") {
      setFilteredEmail([]);
      setHighlightedIndexEmail(-1);
      return;
    }  
    const filtered = emailToSelect.filter((store) => {
      if (!store || !store.email) return false;
      const searchNormailzedStore = searchEmail.toLowerCase().replace(/[.-]/g, "");
      // Comparar después de eliminar puntos y guiones de ambos
      const normalizedEmail = store.email.toLowerCase().replace(/[.-]/g, "");
      return normalizedEmail.includes(searchNormailzedStore);
    }); 
    setFilteredEmail(filtered);
    setHighlightedIndexEmail(0);
  };

  // Manejo de la selección de una tienda
  const handleSelectEmail = (email) => {
    if (!email) return; // Validación adicional para evitar errores
    setFormEMAIL((formEMAIL) => ({
      ...formEMAIL,
      store_EmailTo: email.email || ""
      
    }));
    setFilteredEmail([]);
    setHighlightedIndexEmail(-1);
  }; 

  const handleKeyDownEmail = (event) => {
    if (event.key === "ArrowDown") {
      if (filteredEmail.length === 0 && formEMAIL.store_EmailTo.trim() === "") {
        setFilteredEmail(emailToSelect);
        setHighlightedIndexEmail(0);
      } else {
        setHighlightedIndexEmail((prevIndex) => {
          const nextIndex = prevIndex < filteredEmail.length - 1 ? prevIndex + 1 : prevIndex;
          // Desplaza al siguiente elemento
          scrollToElement(nextIndex);
          return nextIndex;
        });
      }
    } else if (event.key === "ArrowUp") {
      setHighlightedIndexEmail((prevIndex) => {
        const prevIndexValid = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        // Desplaza al elemento anterior
        scrollToElement(prevIndexValid);
        return prevIndexValid;
      });
    } else if ((event.key === "Enter" || event.key === "Tab") && highlightedIndexEmail >= 0) {
      if (highlightedIndexEmail >= 0 && highlightedIndexEmail < filteredEmail.length) {
        handleSelectEmail(filteredEmail[highlightedIndexEmail]);
      }
    } else if (event.key === "Escape") {
      const isValid = emailToSelect.some((email) => {
        if (!email || !email.workplace) return false; 
        const normalizedEmail = email.email.toLowerCase();
        const normalizedInputEmail = formEMAIL.store_EmailTo.toLowerCase();
        return normalizedEmail === normalizedInputEmail;
      }); 
      if (!isValid) {
        setFormEMAIL((formEMAIL) => ({
          ...formEMAIL,
          store_EmailTo: "",
        }));
      } 
      setFilteredEmail([]);
      setHighlightedIndexEmail(-1);
    }
  };

  // Función para desplazar el contenedor
  const scrollToElement = (index) => {
    const dropdown = document.querySelector(".dropdown-class"); // Cambia por tu clase/ID
    const item = dropdown?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  
   const handleBlur = useCallback((event) => {
       if (!event || !event.currentTarget) return;
       // Asegúrate de que no se cierra si haces clic en un elemento de la lista
       if (!event.currentTarget.contains(event.relatedTarget)) {
        setFilteredEmail([]);
        setHighlightedIndexEmail(-1);
      }
    }, []);
    

  const handleBlurDataEditEmail = (e) => {
    handleChangeDataEditEmail(e);
    setErrorsEMAIL(validateFormEmail());
  };

  const handleChangeDataEditEmail = (e) => {
    const { name, value } = e.target;
    setFormEMAIL(formEMAIL => ({
      ...formEMAIL,
      [name]: value,
    })); 
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); // ✅ Convierte el Blob a Base64
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // ✅ Remueve "data:application/pdf;base64,"
      reader.onerror = reject;
    });
  };

  const validateFormEmail = () => {
    let errorsEMAIL = {};
    //let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (!formEMAIL.store_EmailTo || formEMAIL.store_EmailTo==="") {
      errorsEMAIL.store_EmailTo = 'Text_FieldSendEmailToRequired'; 
    } else {
      if (regexInvalidCharacters.test(formEMAIL.store_EmailTo)) {
        errorsEMAIL.store_EmailTo = 'Text_TextNoValid';
      }
    }  

    if (regexInvalidCharacters.test(formEMAIL.subjectEmail)) {
      errorsEMAIL.subjectEmail = 'Text_TextNoValid';
    }

    if (regexInvalidCharacters.test(formEMAIL.contentEmail)) {
      errorsEMAIL.contentEmail = 'Text_TextNoValid';
    }  
    return errorsEMAIL;
  };

  const handleSubmitSendEmail =  async ()=>{
    const errors = validateFormEmail();
    setErrorsEMAIL(errors); 
    if (
      Object.keys(errors).length === 0 &&
      formEMAIL &&
      formEMAIL.store_EmailTo &&
      formEMAIL.store_EmailTo !== "" &&
      pdfBlob
    ) {  
      dispatch(setLoading(true));
      // ✅ Convertir Blob a Base64 sin detener la ejecución
      blobToBase64(pdfBlob)
      .then((base64File) => {
        const formData = {
          id: id,
          defaultStore_id: defaultStore.id,
          useraccount_id: useraccount_id,
          store_EmailTo: formEMAIL.store_EmailTo,
          subjectEmail: formEMAIL.subjectEmail,
          contentEmail: formEMAIL.contentEmail,
          filetosend: base64File, 
          fileName: `Alb_${id.toString().padStart(8, '0')}.pdf`,
          fileType: "application/pdf",
        }; 
        // ✅ Llamada asíncrona sin bloquear el flujo 
        fetchData(formData);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }); 
      const fetchData = async (formData) => {
        try {
          const getData = await helpHttp().postemail(ENDPOINT, `api/sendemailwithattachment`, token,formData);
            if (getData.status === "OK") {
                dispatch(setLoading(false));
                dispatch(successErrorAction(t('Text_SaveData')));
              } else { 
                dispatch(setLoading(false));
                dispatch(warningErrorAction(t(getData.cause))); 
              }
            } catch (error) {
              dispatch(setLoading(false));
              dispatch(warningErrorAction(t('Text_IncorrectData')));
            } finally { 
              dispatch(setLoading(false));
              handleCloseSendEmailPurchaseSignature();
            }
        return () => { };
      };
      //fetchData();
    } else { 
      if (errorsEMAIL.store_EmailTo) {
        dispatch(warningErrorAction(t(errorsEMAIL.store_EmailTo)));
      } else if (errorsEMAIL.subjectEmail) {
        dispatch(warningErrorAction(t(errorsEMAIL.subjectEmail)));
      } else if (errorsEMAIL.contentEmail) {
        dispatch(warningErrorAction(t(errorsEMAIL.contentEmail)));
      } else if (!pdfBlob) {
        dispatch(warningErrorAction(t('Text_FieldSendFileRequired')));
      } else {
        dispatch(warningErrorAction(t('Text_IncorrectData')));
      }
    }
  };  
 useEffect(() => {
    let isMounted = true; 
    const fetchDataEmail = async () => {
      try {
        const getData = await helpHttp().get2(ENDPOINT,`api/getlistemails/`,token,
          (formEMAIL.store_EmailTo!==undefined && formEMAIL.store_EmailTo!==null && formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim()!=="")?formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim():"-1"); 
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setEmailToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };  
    
    if (showModalPurchasesSendEmail && formEMAIL.store_EmailTo && formEMAIL.store_EmailTo.replace(/[.;\-/\\|]/g, "").trim()!=="") { 
      fetchDataEmail();
    }else{
      setEmailToSelect(initialEmailToSelect);
    }
    return () => { isMounted = false };
  }, [t,token,dispatch,i18n,showModalPurchasesSendEmail,formEMAIL.store_EmailTo]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    if (pdfBlob) {
      const formatted_id = id.toString().padStart(8, '0');
      const file = new File([pdfBlob], "Alb_"+formatted_id+".pdf", { type: "application/pdf" });
      setFormEMAIL((formEMAIL) => ({
        ...formEMAIL,
        filetosend: file // Se almacena directamente como un único archivo
      }));
    }
  }, [pdfBlob,id]);   

  useEffect(() => {
    let isMounted = true;
    const fetchDataPurchaseDelivery = async () => {
      dispatch(setLoading(true));
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`purchasesignature/getpurchasesignaturebyid/`,token,id);
        if (getData.err) {
          setFormPurchaseSendEmail(initialStatePurchaseSendEmail); 
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setFormPurchaseSendEmail(getData);
          setFormEMAIL(formEMAIL => ({
            ...formEMAIL,
            defaultStore_id:defaultStore.id,
            useraccount_id:useraccount_id,
            store_EmailTo: getData.ori_tras_email,
            subjectEmail:"LE REMITO EL ALBARÁN Nº: "+getData.purchasenote,
            contentEmail:footeremail
          })); 
          dispatch(setLoading(false));
        }  
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }  
    };
    if (id!=="0"){
      if (formPurchaseSendEmail.id===""){
        fetchDataPurchaseDelivery(); 
        dispatch(setLoading(false));
      }
    }else{
      setFormPurchaseSendEmail(initialStatePurchaseSendEmail);
      setFormPurchaseSendEmail(formPurchaseSendEmail => ({
        ...formPurchaseSendEmail,
        linespurchasedelivery:[]
      }));
      setFormEMAIL(formEMAIL => ({
        ...formEMAIL,
        defaultStore_id:defaultStore.id,
        useraccount_id:useraccount_id,
        contentEmail:footeremail
      })); 
      dispatch(setLoading(false));
    }
    return () => { isMounted = false };
  }, [dispatch,id,t,token,pdfBlob,formPurchaseSendEmail.id,defaultStore.id,useraccount_id,footeremail]);  

  return (
    <>
      {showModalPurchasesSendEmail ? ( 
        <div className="container-fluid">
          <Box sx={{ bgcolor: '#ffffff', width: '1200px'}}>
            <form onSubmit={handleCloseSendEmailPurchaseSignature}>  
              <div className="row"> 
                <div className="col-xl-12 mb-4"> 
                  <div  className="card border-info shadow">
                    <div className="card-body"> 
                      <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                          <div id="myDIV">
                            <h1 className="h2NewStyle mb-2">
                              {(isLoadingData?
                                <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <i className="fas fa-regular fa-envelope text-primary"></i>)} 
                                &nbsp;&nbsp;{t('Text_NumberPurchase_EMAIL')+': '+formPurchaseSendEmail.purchasenote}
                            </h1>
                          <div>
                            {isLoadingData?
                              <CircularProgress sx={{ color : "#f6c23e"}}/>:
                                <>
                                  <button type="button" className="buttonFinishPurchase" onClick={handleSubmitSendEmail} >{t('Button_SEND')}&nbsp;&nbsp;&nbsp;&nbsp;<SendIcon sx={{ fontSize: 28, color: "white" }} /></button><> </>
                                  <button type="button" className="buttonBack" onClick={handleCloseSendEmailPurchaseSignature}>{t('Button_Cancel')}</button>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!isLoadingData ?
                <>
                  <div className="row"> 
                    <div className="col-xl-12 mb-4"> 
                      <div  className="card border-info shadow">
                        <div className="card-body"> 
                          <div className="row no-gutters align-items-center">
                            <div className="row p-2"> 
                              <div className="form-group">
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_EmailsTo')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ position: "relative" }}  onBlur={handleBlur}>
                                  <TextField
                                    id="store_EmailTo"
                                    name="store_EmailTo"
                                    value={formEMAIL.store_EmailTo}
                                    onChange={handleSearchEmail}
                                    onKeyDown={handleKeyDownEmail}
                                    onBlur={(event) => {
                                      // Solo cerrar si el foco se pierde fuera del desplegable
                                      if (!event.relatedTarget || !event.relatedTarget.closest(".dropdown-class")) {
                                        handleBlur();
                                      }
                                    }}
                                    label={errorsEMAIL.store_EmailTo!=null?t(errorsEMAIL.store_EmailTo):t('PlaceHolder_SENDEMAIL_EmailsTo')}
                                    placeholder={t('PlaceHolder_SENDEMAIL_EmailsTo')}
                                    variant="standard"
                                    inputProps={{ style: {width: i18n.language==='es'?846:971,  fontSize: '18px', }, maxLength: 100 }}
                                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                  />
                                  {/* Desplegable dinámico */}
                                  {filteredEmail.length > 0 && (
                                    <div
                                      className="dropdown-class"
                                      style={{
                                        position: "absolute",
                                        left:'0px',
                                        border: "2px solid #4e73df", // Borde azul (#4e73df)
                                        borderRadius: "4px",
                                        marginTop: "4px",
                                        maxHeight: "200px",
                                        overflowY: "auto",
                                        width: "100%",
                                        fontSize: '18px',
                                        backgroundColor: "#f8f9fa", // Fondo gris claro
                                        zIndex: 1000,
                                      }}
                                      onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                    >
                                      {filteredEmail.map((store, index) => (
                                        <MenuItem
                                            key={store.id}
                                            onClick={() => handleSelectEmail(store)}
                                            style={{
                                                cursor: "pointer",
                                                padding: "8px",
                                                borderBottom: "1px solid #eee",
                                                fontSize: '18px',
                                                backgroundColor:
                                                index === highlightedIndexEmail ? "#4e73df" : "transparent",
                                                color: index === highlightedIndexEmail ? "white" : "black",
                                            }}
                                            onMouseDown={(e) => e.preventDefault()} // Evita que se cierre al hacer clic en la barra de scroll
                                        >
                                          {store.email}
                                        </MenuItem>
                                      ))}
                                    </div>
                                  )}
                                </span>   
                              </div>
                              <div className="form-group">
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_Subject')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp; 
                                <TextField
                                  id="subjectEmail" 
                                  name="subjectEmail"
                                  value={ formEMAIL.subjectEmail!== null ? formEMAIL.subjectEmail:""}
                                  inputProps={{ style: {width:  i18n.language==="es"?1035:1031,  fontSize: '18px', }, maxLength: 100 }}
                                  InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}   
                                  label={errorsEMAIL.subjectEmail!=null?t(errorsEMAIL.subjectEmail):t('PlaceHolder_SENDEMAIL_Subject')}
                                  placeholder={t('PlaceHolder_SENDEMAIL_Subject')}
                                  variant="standard"
                                  onBlur={handleBlurDataEditEmail}
                                  onChange={handleChangeDataEditEmail}>
                                </TextField>
                              </div> 
                              <div className="form-group">  
                                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_SENDEMAIL_Content')} :</label>
                                &nbsp;&nbsp;&nbsp;&nbsp;   
                                <Textarea 
                                  id="contentEmail" 
                                  name="contentEmail"
                                  value={formEMAIL.contentEmail}
                                  label={errorsEMAIL.contentEmail!=null?t(errorsEMAIL.contentEmail):t('PlaceHolder_SENDEMAIL_Content')}
                                  placeholder={t('PlaceHolder_SENDEMAIL_Content')}
                                  sx={{width: i18n.language==="es"?1120:1120,  fontSize: '18px'} }
                                  variant="standard"
                                  onBlur={handleBlurDataEditEmail}
                                  onChange={handleChangeDataEditEmail}
                                  maxRows={8}
                                />  
                              </div> 
                            </div>   
                          </div>   
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-12 mb-4">
                    <div className="rowpdf">
                      <div className="cardpdf border-info shadow">
                        <div className="cardpdf-body">
                          <div className="rowpdf no-gutters align-items-center">
                            <div className="formgrouppdf" style={{ height: '100%' }}>
                              {PdfViewer}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>:
                  <LoadingNews/>
                } 
              </form>
            </Box>
            <ReportPurchaseSendEmail 
              formPurchaseSendEmail={formPurchaseSendEmail}  
              setPdfBlob={setPdfBlob} 
            />
            <br/>
          </div>
        //* End Page Content *//
      ) :  null }
    </>
  )    
}

PurchaseDeliverySendEmail.propTypes = {
  id: PropTypes.string.isRequired,
  showModalPurchasesSendEmail: PropTypes.bool.isRequired
};

export default PurchaseDeliverySendEmail;