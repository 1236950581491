import React, {  useEffect, useCallback, useState} from 'react'; 
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { ENDPOINT } from "constants/variables";
import Box from '@mui/material/Box'; 
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import Textarea from '@mui/joy/Textarea'; 
import "css/generalSettings.css";  
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TableSortingLinesSenEmail from "pages/recievedemails/SortingTablesLinesRecievedEmail";
import PropTypes from 'prop-types';

const initialStateSendEmail = {
 "id":"",
  "defaultStore_id":0, 
  "tradename": "",
  "defaultStore_email": "",
  "defaultStore_documentId": "",
  "useraccount_id":0,
  "username": "",
  "useraccount_email": "",
  "createdate": null,
  "store_IdTo": "",
  "store_NameTo": "",
  "store_EmailTo": "",
  "store_DocumentIdTo":"",
  "subjectEmail":"",
  "contentEmail":"",
  "reason_id":0,
  "reason_namees":"",
  "reason_nameen":"",
  "status_id":0,
  "status_namees":"",
  "status_nameen":"",
  "linesemails":[],
  "updatedFiles":[]
};

const SendEmailEdit = ({ openEditRow,setOpenEditRow,rowEdit}) => {
  const {t,i18n} = useTranslation(['listDataGeneralSettings']);
  const token = useSelector((state) => state.loginUser.token); 
   const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const [formEmail, setFormEmail] = useState(initialStateSendEmail);
  const dispatch = useDispatch();

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && openEditRow) {
        setOpenEditRow(false); 
        setFormEmail(formEmail => ({
          ...formEmail,
          linesemails:[],
          updatedFiles:[]
        }));
      }
    },
    [
      setOpenEditRow, 
      setFormEmail, 
      openEditRow
    ]
  );

  const closeModalSendEmailEdit = () => {
    setOpenEditRow(false);  
    setFormEmail(formEmail => ({
      ...formEmail,
      linesemails:[],
      updatedFiles:[]
    }));
  }; 

  const toggleDrawerEmail = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown' ) {
      closeModalSendEmailEdit();
      return;
    }
  };
      
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    let isMounted = true;
    const fetchDataEdite = async () => { 
      try {
        const getData = await helpHttp().get2(ENDPOINT, `receivedemail/getreceivedemailbyid/`, token, rowEdit.id);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setFormEmail(formEmail => ({
              ...formEmail,
              linesemails:[],
              updatedFiles:[]
            }));
            dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailied' : 'Text_InvalidCredentials')));
            
          }
        } else if (isMounted) {
          setFormEmail(getData); 
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }   
    };
    if (rowEdit.id !==undefined && rowEdit.id !== null && rowEdit.id !== "" && rowEdit.id !== "0") {
        fetchDataEdite(); 
    } else {
      setFormEmail(initialStateSendEmail);
      setFormEmail(formEmail => ({
        ...formEmail,
        linesemails:[],
        updatedFiles:[]
      })); 
    }
    return () => { isMounted = false };

  }, [ dispatch, t, token, rowEdit.id, setFormEmail]);
 
  return (
    <div> 
      {openEditRow && 
        <div>
          <SwipeableDrawer
            anchor='right'
            open={true}
            onClose={toggleDrawerEmail(false)}
            onOpen={toggleDrawerEmail(true)}
          >
            <Container >
              <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '1100px' }}>
                <br/>
                <div id="myDIV">
                  <h1 className="h1NewStyle mb-2">
                  <i className="fas fa-solid fa-envelope text-warning"></i> {t('Text_SENDEMAIL_Send')} {rowEdit.store_EmailTo}</h1>
                  <div>
                    <button type="button" className="buttonBack" onClick={closeModalSendEmailEdit}>{t('Button_Back')}</button>
                  </div>
                </div>
                <br/> 
                <hr className="sidebar-divider d-none d-md-block"/>
                <div className="card shadow mb-4">     
                  
                  <div className="row p-4"> 
                    <label className="text-primary">{t('Text_SENDEMAIL_Subject')}</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                      id="subjectEmail" 
                      name="subjectEmail"
                      value={ formEmail.subjectEmail!== null ? formEmail.subjectEmail:""}
                      inputProps={{ style: {width: i18n.language==="es"?1001:900,}, maxLength: 100 }}
                      label={t('Text_SENDEMAIL_Subject')}
                      placeholder={t('Text_SENDEMAIL_Subject')}
                      variant="standard"
                      disabled>
                    </TextField>
                  </div>
                  <div className="row p-4"> 
                    <label className="text-primary">{t('Text_SENDEMAIL_Content')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;   
                    <Textarea 
                      id="contentEmail" 
                      name="contentEmail"
                      value={formEmail.contentEmail}
                      label={t('Text_SENDEMAIL_Content')}
                      placeholder={t('Text_SENDEMAIL_Content')}
                      sx={{width: i18n.language==="es"?970:800} }
                      variant="standard"
                      disabled
                      maxRows={8}
                    />  
                  </div> 
                  <div className="row p-4">  
                    <TableSortingLinesSenEmail 
                      dataLineSendEmails={formEmail.linesemails}
                      numTotRegLineSendEmails={formEmail.linesemails!==null?formEmail.linesemails.length:0}
                      LIMITPAGESIZE={LIMITPAGESIZE}
                    /> 
                </div> 
                </div>
           
              </Box>
            </Container>
          </SwipeableDrawer>  
        </div>
      } 
    </div>
  )
}

SendEmailEdit.propTypes = {
  openEditRow: PropTypes.bool.isRequired,
  setOpenEditRow: PropTypes.func.isRequired 
};
  
export default SendEmailEdit;

