// Valores estáticos para configuración de la app
export const ENDPOINT= "https://api-gestrescan.com";
//export const ENDPOINT= "http://localhost:8000";
export const DEFAULT_STORE = "1";
export const DEFAULT_ROLE = "3";
export const NON_ERASABLE_STATUSTYPE_ID = "10";//OK
export const NON_ERASABLE_REASONTYPE_ID = "24";//OK
export const NON_ERASABLE_STORE_ID = "3";//OK
export const NON_ERASABLE_USERACCOUNT_ID = "17";//ok
export const NON_ERASABLE_CODELER_ID= "834";//ok
export const NON_ERASABLE_OPTTYPE_ID="10";//ok
export const NON_ERASABLE_TREATMENTTYPE_ID= "85";//ok
export const NON_ERASABLE_PROCESSTYPE_ID= "29";//ok
export const NON_ERASABLE_DANGERROUS_ID= "15";//ok
export const NON_ERASABLE_COLOR_ID= "137";//ok
export const NON_ERASABLE_COMMUNITY_ID= "15";//ok
export const NON_ERASABLE_COUNTRY_ID= "247";//ok
export const NON_ERASABLE_DESCRIPTION_ID= "2";//ok
export const NON_ERASABLE_DOCUMENTTYPES_ID= "4";//ok
export const NON_ERASABLE_LANGUAGE_ID= "2";//ok
export const NON_ERASABLE_POSTALCODE_ID= "14617";//ok
export const NON_ERASABLE_PROVINCE_ID= "53";//ok
export const NON_ERASABLE_ROADTYPES_ID= "46";//ok
export const NON_ERASABLE_TYPESDI_ID= "4";//ok
export const NON_ERASABLE_ARTICLESBUTTON_ID= "35";//ok
export const NON_ERASABLE_TICKETSFORMAT_ID= "4";//ok
export const NON_ERASABLE_ROLER_ID= "13"; //ok
export const NON_ERASABLE_EMPLOYEE_ID= "2";//ok
export const NON_ERASABLE_TYPESOFTAXES_ID="10";//ok
export const NON_ERASABLE_ARTICLES_ID="863";//ok
export const NON_ERASABLE_PAYMEMTTYPE_ID="3";//ok 
export const DEFAULTSTORETYPESDI = "1";//ok
export const DEFAULTEMPLOYEE= "1";//ok
export const DEFAULTTYPEOFDOCUMENT= "4";//ok
export const DEFAULTLANGUAGE= "1";//ok
export const DEFAULTCOLOR= "2";//ok
export const DEFAULTCOLOREMPLOYEE= "50";//ok 
export const LIMIT_PAGE_SIZE_SLIDE= "1000";//ok 
export const DEFAULT_TYPESDI= "1";//ok 
