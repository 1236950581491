import React, {  useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"; 
import { useTranslation } from "react-i18next";
import { DEFAULTTYPEOFDOCUMENT, ENDPOINT } from "constants/variables";

import IconButton from '@mui/material/IconButton'; 
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import TypesDISearchStores from "./TypesDISearchStores";
import TypesDISearchStoresAddress from "./TypesDISearchStoresAddress";
import TypesDISearchVehicles from "./TypesDISearchVehicles";
import PropTypes from 'prop-types';

const initialProvincesToSelect = [{
    "id" : "",
    "name" : "", 
    "enabled" : true,
    "country_id":1
}];

const initialPostalCodesToSelect = [{
    "id":"",
    "postalcode":"",
    "name":"",
    "postalcodename":"",
    "enabled":true,
    "province_id":""
}];

const TypeDICreateOrEditRelTrans = ({formForm,setFormForm,openEditRow,errorsForm,setErrorsForm,validateForm,documentTypesToSelect,roadTypesToSelect,communityToSelect,countryToSelect,expanded,optTypeToSelect}) => {
    const {t,i18n} = useTranslation(['listDataForms']);
    const token = useSelector((state) => state.loginUser.token); 
    const roles = useSelector((state) => state.loginUser.roles);  
    const DEFAULTCOUNTRY = useSelector((state) => state.formatDIUser.default_country); 
    const DEFAULTPROVINCE = useSelector((state) => state.formatDIUser.default_province); 
    const DEFAULTCOMMUNITY = useSelector((state) => state.formatDIUser.default_community); 
    const [showModalSearchStores, setShowModalSearchStores] = useState(false); 
    const [showModalSearchStoresAddress, setShowModalSearchStoresAddress] = useState(false); 
    const [showModalSearchVehicles, setShowModalSearchVehicles] = useState(false); 
    const [provinceToSelect_Rel_Trans,setProvinceToSelect_Rel_Trans]= useState(initialProvincesToSelect);
    const [postalCodeToSelect_Rel_Trans,setPostalCodeToSelect_Rel_Trans]= useState(initialPostalCodesToSelect);
    const dispatch = useDispatch(); 

    const handleChangeSelectDocumentType_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_documnettype_id:event.target.value
        }));
    };
    const handleBlurSelectDocumentType_Rel_Trans = (e) => {
        handleChangeSelectDocumentType_Rel_Trans(e);
    };
    
    const handleChangeSelectRoadType_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_roadtype_id : event.target.value 
        }));
    };
    const handleBlurSelectRoadType_Rel_Trans = (e) => {
        handleChangeSelectRoadType_Rel_Trans(e);
        setErrorsForm(validateForm());
    };
    
    const handleChangeSelectCountry_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_country_id: event.target.value,
            default_rel_trans_province_id:"",
            default_rel_trans_postalcode_id:""
        })); 
    };
    const handleBlurSelectCountry_Rel_Trans = (e) => {
        handleChangeSelectCountry_Rel_Trans(e);
        setErrorsForm(validateForm());
    };
    
    const handleChangeSelectProvince_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_province_id :event.target.value,
            default_rel_trans_postalcode_id:""
        }));
    };
    const handleBlurSelectProvince_Rel_Trans = (e) => {
        handleChangeSelectProvince_Rel_Trans(e);
        setErrorsForm(validateForm());
    };
    
    const handleChangeSelectPostalCode_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_postalcode_id:event.target.value
        }));
    };
    const handleBlurSelectPostalCode_Rel_Trans = (e) => {
        handleChangeSelectPostalCode_Rel_Trans(e);
        setErrorsForm(validateForm());
    };

    const handleChangeSelectCommunity_Rel_Trans = (event) => {
        setFormForm(formForm => ({
            ...formForm,
            default_rel_trans_community_id : event.target.value   
        }));
    };
    const handleBlurSelectCommunity_Rel_Trans = (e) => {
        handleChangeSelectCommunity_Rel_Trans(e);
        setErrorsForm(validateForm());
    };

    const handleChangeDataEditForm_Rel_Trans = (e) => {
        const { name, value } = e.target;
        setFormForm(formForm => ({
          ...formForm,
          [name]: value,
        }));
    };
    const handleBlurDataEditForm_Rel_Trans = (e) => {
        handleChangeDataEditForm_Rel_Trans(e);
        setErrorsForm(validateForm());
    };
    
    const searchFormStore = (event) => {
        setShowModalSearchStores(true);
    };

    const searchFormStoreAddress = (event) => {
        setShowModalSearchStoresAddress(true);
    };

    const searchFormVehicles = (event) => {
        setShowModalSearchVehicles(true);
    };

    const deleteDataRelTrans = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            default_rel_trans_id:0,
            default_rel_trans_documnettype_id: "",
            default_rel_trans_documentid:"",
            default_rel_trans_tradename: "",
            default_rel_trans_name: "",
            default_rel_trans_contactname: "",
            default_rel_trans_roadtype_id: 0,
            default_rel_trans_street: "",
            default_rel_trans_country_id: Number(DEFAULTCOUNTRY),
            default_rel_trans_province_id: Number(DEFAULTPROVINCE),
            default_rel_trans_postalcode_id: "",
            default_rel_trans_community_id: Number(DEFAULTCOMMUNITY),
            default_rel_trans_phone: "",
            default_rel_trans_email: "",
            default_rel_trans_nima: "",
            default_rel_trans_cnae: "",
            default_rel_trans_inscription: "",
            default_rel_trans_opt: 0,
            default_rel_trans_licenseplate: "",
            default_rel_trans_economicactivity:""
        }));
        setErrorsForm(validateForm()); 
    };
    const deleteDataSteetRelTrans = (event) => {
        setFormForm(formForm => ({
          ...formForm,
            default_rel_trans_roadtype_id: 0,
            default_rel_trans_street: "",
            default_rel_trans_country_id: Number(DEFAULTCOUNTRY),
            default_rel_trans_province_id: Number(DEFAULTPROVINCE),
            default_rel_trans_postalcode_id: "",
            default_rel_trans_community_id: Number(DEFAULTCOMMUNITY),
            default_rel_trans_nima: "", 
            default_rel_trans_inscription: "",
            default_rel_trans_opt: 0
        }));
        setErrorsForm(validateForm());
    };

    const deleteDataVehicleRelTrans = (event) => {
        setFormForm(formForm => ({
          ...formForm,
          default_rel_trans_licenseplate: ""
        }));
        setErrorsForm(validateForm());
    };

    useEffect(() => {
        let isMounted = true;
        const fetchExistNumberDocument = async () => {
            try {
              const getData = await helpHttp().get2(ENDPOINT,`form/getexiststorebynumberdocument/`,token,formForm.default_rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim());
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData>0 && getData!==formForm.default_rel_trans_id){
                    fetchStoreNumberDocument();
                }
              }
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
 
        const fetchStoreNumberDocument = async () => {
            try {
              const getData = await helpHttp().get5(ENDPOINT,`form/getformstorebynumberdocument`,token,formForm.default_rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim(),6);
              if (getData.err) {
                dispatch(warningErrorAction(t('Text_InvalidCredentials')));
              } else if (isMounted) {
                if (getData!==undefined && getData!==null && getData!==""){
                    setFormForm(formForm => ({
                        ...formForm,
                        default_rel_trans_id: getData.store_id,
                        default_rel_trans_documnettype_id: getData.documnettype_id,
                        default_rel_trans_tradename: getData.tradename,
                        default_rel_trans_name: getData.name,
                        default_rel_trans_contactname: getData.contacperson,
                        default_rel_trans_roadtype_id: (getData.roadtype_id===undefined 
                                                || getData.roadtype_id===null  
                                                || getData.roadtype_id===""
                                                || getData.roadtype_id==="0"
                                                || getData.roadtype_id===0)?
                                                0:getData.roadtype_id,
                        default_rel_trans_street: getData.street,
                        default_rel_trans_country_id: (getData.country_id===undefined 
                                                || getData.country_id===null  
                                                || getData.country_id===""
                                                || getData.country_id==="0"
                                                || getData.country_id===0)?
                                                Number(DEFAULTCOUNTRY):getData.country_id,
                        default_rel_trans_province_id: (getData.province_id===undefined 
                                                || getData.province_id===null  
                                                || getData.province_id===""
                                                || getData.province_id==="0"
                                                || getData.province_id===0)?
                                                Number(DEFAULTPROVINCE):getData.province_id,
                        default_rel_trans_postalcode_id: (getData.postalcode_id===undefined 
                                                    || getData.postalcode_id===null  
                                                    || getData.postalcode_id===""
                                                    || getData.postalcode_id==="0"
                                                    || getData.postalcode_id===0)?
                                                    "":getData.postalcode_id,
                        default_rel_trans_community_id: (getData.community_id===undefined 
                                                    || getData.community_id===null  
                                                    || getData.community_id===""
                                                    || getData.community_id==="0"
                                                    || getData.community_id===0)?
                                                    Number(DEFAULTCOMMUNITY):getData.community_id,
                        default_rel_trans_phone: getData.phone,
                        default_rel_trans_email: getData.email,
                        default_rel_trans_nima: getData.nima_rel_trans,
                        default_rel_trans_cnae: getData.cnae,
                        default_rel_trans_inscription: getData.pygr_rel_trans,
                        default_rel_trans_opt: getData.opt_rel_trans,
                        default_rel_trans_licenseplate: getData.licenseplate,
                        default_rel_trans_economicactivity:getData.economicactivity
                    }));
                }  
            }         
            } catch (error) {
                dispatch(warningErrorAction(t('Text_IncorrectData'))); 
            }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel6'){
            if (formForm.default_rel_trans_documentid && formForm.default_rel_trans_documentid.replace(/[.\-/\\|]/g, "").trim()!==""){
                fetchExistNumberDocument();
            }   
        }
        return () => { isMounted = false };
    }, [t,token,dispatch,expanded,formForm.default_rel_trans_documentid,errorsForm.default_rel_trans_documentid,DEFAULTCOMMUNITY,DEFAULTCOUNTRY,DEFAULTPROVINCE,setFormForm,formForm.default_rel_trans_id]);
    
    useEffect(() => {
        let isMounted = true;
        const fetchDataProvince = async () => {
          try {
            const getData = formForm.default_rel_trans_country_id!==""
                ? await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,formForm.default_rel_trans_country_id)
                : await helpHttp().get2(ENDPOINT,`province/simpleprovincesbycountrybyname/`,token,"null");
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setProvinceToSelect_Rel_Trans(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel6'){
            fetchDataProvince();
        }      
        return () => { isMounted = false };     
    }, [t,token,dispatch,i18n,openEditRow,formForm.default_rel_trans_country_id,expanded]);
   
    useEffect(() => {
        let isMounted = true;
        const fetchDataPostalCode = async () => {
          try {
            const getData = formForm.default_rel_trans_province_id!==""
                ? await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbyprovincebyname/`,token,formForm.default_rel_trans_province_id)
                : await helpHttp().get2(ENDPOINT,`postalcode/simplepostalcodesbycountry/`,token,formForm.default_rel_trans_country_id);
            if (getData.err) {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } else if (isMounted) {
                setPostalCodeToSelect_Rel_Trans(getData);
            }
          } catch (error) {
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }
        };
        if (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel6'){
            fetchDataPostalCode();
        }      
        return () => { isMounted = false };  
       
    }, [t,token,dispatch,i18n,openEditRow,formForm.default_rel_trans_country_id,formForm.default_rel_trans_province_id,expanded]);
 
    return(
    (expanded!==undefined && expanded!==null && expanded!==false && expanded==='panel6') &&
        <>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_DocuentType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="default_rel_trans_documnettype_id"
                    name="default_rel_trans_documnettype_id"
                    value={ (formForm.default_rel_trans_documnettype_id === undefined || formForm.default_rel_trans_documnettype_id ===null || formForm.default_rel_trans_documnettype_id ===""
                        || formForm.default_rel_trans_documnettype_id ==="0" || formForm.default_rel_trans_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT):formForm.default_rel_trans_documnettype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                    variant="outlined"
                    onBlur={handleBlurSelectDocumentType_Rel_Trans}
                    onChange={handleChangeSelectDocumentType_Rel_Trans}
                    helperText={errorsForm.default_rel_trans_documnettype_id!=null?t(errorsForm.default_rel_trans_documnettype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formForm.default_rel_trans_documnettype_id === undefined || formForm.default_rel_trans_documnettype_id ===null || formForm.default_rel_trans_documnettype_id ===""
                            || formForm.default_rel_trans_documnettype_id ==="0" || formForm.default_rel_trans_documnettype_id ===0)?Number(DEFAULTTYPEOFDOCUMENT): formForm.default_rel_trans_documnettype_id
                    }}>
                    <option key={0} value=""/>
                    {documentTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </TextField>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CIF')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="default_rel_trans_documentid" 
                    name="default_rel_trans_documentid"
                    value={formForm.default_rel_trans_documentid}
                    inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.default_rel_trans_documentid!=null?t(errorsForm.default_rel_trans_documentid):t('PlaceHolder_FORM_CIF')}
                    placeholder={t('PlaceHolder_FORM_CIF')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Rel_Trans}
                    onChange={handleChangeDataEditForm_Rel_Trans}
                />
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataRelTrans}>
                    <DeleteIcon style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                    <IconButton  size="small"  style={{color:"#4e73df", fontSize: 12}} onClick={searchFormStore}>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CONTACT')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="default_rel_trans_contactname" 
                    name="default_rel_trans_contactname"
                    value={formForm.default_rel_trans_contactname}
                    inputProps={{ style: {width: i18n.language==='es'?447:532,  fontSize: '18px', }, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.default_rel_trans_contactname!=null?t(errorsForm.default_rel_trans_contactname):t('PlaceHolder_FORM_CONTACT')}
                    placeholder={t('PlaceHolder_FORM_CONTACT')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Rel_Trans}
                    onChange={handleChangeDataEditForm_Rel_Trans} 
                />
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group"> 
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_TRADENAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="default_rel_trans_tradename" 
                    name="default_rel_trans_tradename"
                    value={formForm.default_rel_trans_tradename}
                    inputProps={{ style: {width: i18n.language==='es'?592:603,  fontSize: '18px', }, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.default_rel_trans_tradename!=null?t(errorsForm.default_rel_trans_tradename):t('PlaceHolder_FORM_TRADENAME')}
                    placeholder={t('PlaceHolder_FORM_TRADENAME')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Rel_Trans}
                    onChange={handleChangeDataEditForm_Rel_Trans} 
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NAME')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TextField
                    id="default_rel_trans_name" 
                    name="default_rel_trans_name"
                    value={formForm.default_rel_trans_name}
                    inputProps={{ style: {width: i18n.language==='es'?592:602,  fontSize: '18px', }, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.default_rel_trans_name!=null?t(errorsForm.default_rel_trans_name):t('PlaceHolder_FORM_NAME')}
                    placeholder={t('PlaceHolder_FORM_NAME')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Rel_Trans}
                    onChange={handleChangeDataEditForm_Rel_Trans} 
                />
               
                </div>
            </div>  
            <div className="row p-2"> 
                <div className="form-group">
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_RoadType')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="rel_trans_roadtype"
                    name="rel_trans_roadtype"
                    value={ (formForm.default_rel_trans_roadtype_id === undefined || formForm.default_rel_trans_roadtype_id ===null || formForm.default_rel_trans_roadtype_id ===""
                        || formForm.default_rel_trans_roadtype_id ==="0" || formForm.default_rel_trans_roadtype_id ===0)?"":formForm.default_rel_trans_roadtype_id}
                    inputProps={{ style: {width: 100,  fontSize: '18px'}, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                    variant="outlined"
                    onBlur={handleBlurSelectRoadType_Rel_Trans}
                    onChange={handleChangeSelectRoadType_Rel_Trans} 
                    helperText={errorsForm.default_rel_trans_roadtype_id!=null?t(errorsForm.default_rel_trans_roadtype_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formForm.default_rel_trans_roadtype_id === undefined || formForm.default_rel_trans_roadtype_id ===null || formForm.default_rel_trans_roadtype_id ===""
                            || formForm.default_rel_trans_roadtype_id ==="0" || formForm.default_rel_trans_roadtype_id ===0)?"": formForm.default_rel_trans_roadtype_id
                    }}>
                    <option key={0} value=""/>
                    {roadTypesToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                    ))}
                </TextField>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Address')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="default_rel_trans_street" 
                    name="default_rel_trans_street"
                    value={formForm.default_rel_trans_street}
                    inputProps={{ style: {width: i18n.language==='es'?585:599,  fontSize: '18px', }, maxLength: 250 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    label={errorsForm.default_rel_trans_street!=null?t(errorsForm.default_rel_trans_street):t('PlaceHolder_FORM_Address')}
                    placeholder={t('PlaceHolder_FORM_Address')}
                    variant="standard"
                    onBlur={handleBlurDataEditForm_Rel_Trans}
                    onChange={handleChangeDataEditForm_Rel_Trans} 
                />
                &nbsp;&nbsp;
                <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataSteetRelTrans}>
                    <DeleteIcon style={{fontSize: 28}}/>
                </IconButton>
                &nbsp;&nbsp;
                {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores')) ?
                    <IconButton  size="small"  style={{
                                color:
                                (formForm.default_rel_trans_id===undefined
                                || formForm.default_rel_trans_id===""
                                || formForm.default_rel_trans_id==="0"
                                || formForm.default_rel_trans_id===0)?"#858796":"#4e73df", fontSize: 12}} 
                                disabled={formForm.default_rel_trans_id===undefined
                                        || formForm.default_rel_trans_id===""
                                        || formForm.default_rel_trans_id==="0"
                                        || formForm.default_rel_trans_id===0 } onClick={searchFormStoreAddress}>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>:
                    <IconButton  size="small" style={{ color:"#858796", fontSize: 18}} disabled>
                        <SearchIcon style={{fontSize: 28}}/>
                    </IconButton>
                }
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Community')} :</label>
                &nbsp;&nbsp;&nbsp;&nbsp; 
                <TextField
                    id="default_rel_trans_community_id"
                    name="default_rel_trans_community_id"
                    value={ (formForm.default_rel_trans_community_id === undefined || formForm.default_rel_trans_community_id ===null || formForm.default_rel_trans_community_id ===""
                        || formForm.default_rel_trans_community_id ==="0" || formForm.default_rel_trans_community_id ===0)?Number(DEFAULTCOMMUNITY):formForm.default_rel_trans_community_id}
                    inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 100 }}
                    InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                    variant="outlined"
                    onBlur={handleBlurSelectCommunity_Rel_Trans}
                    onChange={handleChangeSelectCommunity_Rel_Trans}
                    helperText={errorsForm.default_rel_trans_community_id!=null?t(errorsForm.default_rel_trans_community_id):""}
                    select
                    SelectProps={{
                        native: true,
                        value: (formForm.default_rel_trans_community_id === undefined || formForm.default_rel_trans_community_id ===null || formForm.default_rel_trans_community_id ===""
                            || formForm.default_rel_trans_community_id ==="0" || formForm.default_rel_trans_community_id ===0)?Number(DEFAULTCOMMUNITY): formForm.default_rel_trans_community_id
                    }}>
                    {communityToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                    ))}
                </TextField>
                </div>
            </div>
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Country')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_country_id"
                        name="default_rel_trans_country_id"
                        value={ (formForm.default_rel_trans_country_id === undefined || formForm.default_rel_trans_country_id ===null || formForm.default_rel_trans_country_id ===""
                                || formForm.default_rel_trans_country_id ==="0" || formForm.default_rel_trans_country_id ===0)?Number(DEFAULTCOUNTRY):formForm.default_rel_trans_country_id}
                        inputProps={{ style: {width: i18n.language==='es'?300:300,  fontSize: '18px', }, maxLength: 250 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleBlurSelectCountry_Rel_Trans}
                        onChange={handleChangeSelectCountry_Rel_Trans} 
                        helperText={errorsForm.default_rel_trans_country_id!=null?t(errorsForm.default_rel_trans_country_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formForm.default_rel_trans_country_id === undefined || formForm.default_rel_trans_country_id ===null || formForm.default_rel_trans_country_id ===""
                                || formForm.default_rel_trans_country_id ==="0" || formForm.default_rel_trans_country_id ===0)?Number(DEFAULTCOUNTRY): formForm.default_rel_trans_country_id
                        }}>
                        {countryToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {i18n.language==="es"?option.namees:option.nameen}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Province')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_province_id"
                        name="default_rel_trans_province_id"
                        value={ (formForm.default_rel_trans_province_id === undefined || formForm.default_rel_trans_province_id ===null || formForm.default_rel_trans_province_id ===""
                            || formForm.default_rel_trans_province_id ==="0" || formForm.default_rel_trans_province_id ===0)?Number(DEFAULTPROVINCE):formForm.default_rel_trans_province_id}
                        inputProps={{ style: {width: i18n.language==='es'?300:300,  fontSize: '18px', }, maxLength: 250 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleBlurSelectProvince_Rel_Trans}
                        onChange={handleChangeSelectProvince_Rel_Trans} 
                        helperText={errorsForm.default_rel_trans_province_id!=null?t(errorsForm.default_rel_trans_province_id):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formForm.default_rel_trans_province_id === undefined || formForm.default_rel_trans_province_id ===null || formForm.default_rel_trans_province_id ===""
                                || formForm.default_rel_trans_province_id ==="0" || formForm.default_rel_trans_province_id ===0)?Number(DEFAULTPROVINCE): formForm.default_rel_trans_province_id
                        }}>
                        <option key={0} value=""/>
                        {provinceToSelect_Rel_Trans.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.name}
                        </option>
                        ))}
                    </TextField>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_PostalCode')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="default_rel_trans_postalcode_id"
                        name="default_rel_trans_postalcode_id"
                        value={ (formForm.default_rel_trans_postalcode_id === undefined || formForm.default_rel_trans_postalcode_id ===null || formForm.default_rel_trans_postalcode_id ===""
                            || formForm.default_rel_trans_postalcode_id ==="0" || formForm.default_rel_trans_postalcode_id ===0)?"":formForm.default_rel_trans_postalcode_id}
                        inputProps={{ style: {width: i18n.language==='es'?419:410,  fontSize: '18px', }, maxLength: 250 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        variant="outlined"
                        onBlur={handleBlurSelectPostalCode_Rel_Trans}
                        onChange={handleChangeSelectPostalCode_Rel_Trans}
                        helperText={errorsForm.default_rel_trans_postalcode_id!=null?t(errorsForm.default_rel_trans_postalcode_id):""}
                        select
                        SelectProps={{
                        native: true,
                        value: (formForm.default_rel_trans_postalcode_id === undefined || formForm.default_rel_trans_postalcode_id ===null || formForm.default_rel_trans_postalcode_id ===""
                            || formForm.default_rel_trans_postalcode_id ==="0" || formForm.default_rel_trans_postalcode_id ===0)?"": formForm.default_rel_trans_postalcode_id
                        }}>
                        <option key={0} value=""/>
                        {postalCodeToSelect_Rel_Trans.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.postalcodename}
                        </option>
                        ))}
                    </TextField>
                               
                </div>
            </div>
            
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Phone')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="default_rel_trans_phone" 
                        name="default_rel_trans_phone"
                        value={formForm.default_rel_trans_phone}
                        inputProps={{ style: {width: i18n.language==='es'?200:200,  fontSize: '18px', }, maxLength: 30 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.default_rel_trans_phone!=null?t(errorsForm.default_rel_trans_phone):t('PlaceHolder_FORM_Phone')}
                        placeholder={t('PlaceHolder_FORM_Phone')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Email')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="default_rel_trans_email" 
                        name="default_rel_trans_email"
                        value={formForm.default_rel_trans_email}
                        inputProps={{ style: {width: i18n.language==='es'?300:300,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.default_rel_trans_email!=null?t(errorsForm.default_rel_trans_email):t('PlaceHolder_FORM_Email')}
                        placeholder={t('PlaceHolder_FORM_Email')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_CNAE')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_cnae" 
                        name="default_rel_trans_cnae"
                        value={formForm.default_rel_trans_cnae}
                        inputProps={{ style: {width: i18n.language==='es'?180:180,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}  
                        label={errorsForm.default_rel_trans_cnae!=null?t(errorsForm.default_rel_trans_cnae):t('PlaceHolder_FORM_CNAE')}
                        placeholder={t('PlaceHolder_FORM_CNAE')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Economicactivity')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_economicactivity" 
                        name="default_rel_trans_economicactivity"
                        value={formForm.default_rel_trans_economicactivity}
                        inputProps={{ style: {width: i18n.language==='es'?306:348,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.default_rel_trans_economicactivity!=null?t(errorsForm.default_rel_trans_economicactivity):t('PlaceHolder_FORM_Economicactivity')}
                        placeholder={t('PlaceHolder_FORM_Economicactivity')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    /> 
                </div>
            </div>  
             
            <div className="row p-2"> 
                <div className="form-group">
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_NIMA')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="default_rel_trans_nima" 
                        name="default_rel_trans_nima"
                        value={formForm.default_rel_trans_nima}
                        inputProps={{ style: {width: i18n.language==='es'?160:160,  fontSize: '18px', }, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                        label={errorsForm.default_rel_trans_nima!=null?t(errorsForm.default_rel_trans_nima):t('PlaceHolder_FORM_NIMA')}
                        placeholder={t('PlaceHolder_FORM_NIMA')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_INSCRIPTION')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <TextField
                        id="default_rel_trans_inscription" 
                        name="default_rel_trans_inscription"
                        value={formForm.default_rel_trans_inscription}
                        inputProps={{ style: {width: i18n.language==='es'?250:250,  fontSize: '18px', }, maxLength: 50 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.default_rel_trans_inscription!=null?t(errorsForm.default_rel_trans_inscription):t('PlaceHolder_FORM_INSCRIPTION')}
                        placeholder={t('PlaceHolder_FORM_INSCRIPTION')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_OPT')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_opt"
                        name="default_rel_trans_opt"
                        value={ (formForm.default_rel_trans_opt === undefined || formForm.default_rel_trans_opt ===null || formForm.default_rel_trans_opt ===""
                            || formForm.default_rel_trans_opt ==="0" || formForm.default_rel_trans_opt ===0)?0:formForm.default_rel_trans_opt}
                        inputProps={{ style: {width: i18n.language==='es'?192:180,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        variant="outlined"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                        helperText={errorsForm.default_rel_trans_opt!=null?t(errorsForm.default_rel_trans_opt):""}
                        select
                        SelectProps={{
                            native: true,
                            value: (formForm.default_rel_trans_opt === undefined || formForm.default_rel_trans_opt ===null || formForm.default_rel_trans_opt ===""
                                || formForm.default_rel_trans_opt ==="0" || formForm.default_rel_trans_opt ===0)?0: formForm.default_rel_trans_opt
                        }}>
                        <option key={0} value=""/>
                        {optTypeToSelect.map((option) => (
                        <option key={option.id} value={option.id}>
                            {option.code} - {option.namees}
                        </option>
                        ))}
                    </TextField>  
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <label className="text-primary" style={{ fontSize: 18 }}>{t('Text_FORM_Vehicles')} :</label>
                    &nbsp;&nbsp;&nbsp;&nbsp; 
                    <TextField
                        id="default_rel_trans_licenseplate" 
                        name="default_rel_trans_licenseplate"
                        value={formForm.default_rel_trans_licenseplate}
                        inputProps={{ style: {width: i18n.language==='es'?150:150,  fontSize: '18px', }, maxLength: 100 }}
                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}} 
                        label={errorsForm.default_rel_trans_licenseplate!=null?t(errorsForm.default_rel_trans_licenseplate):t('PlaceHolder_FORM_Vehicles')}
                        placeholder={t('PlaceHolder_FORM_Vehicles')}
                        variant="standard"
                        onBlur={handleBlurDataEditForm_Rel_Trans}
                        onChange={handleChangeDataEditForm_Rel_Trans} 
                    />
                     &nbsp;&nbsp;
                    <IconButton  size="small"  style={{color:"#e74a3b", fontSize: 12}} onClick={deleteDataVehicleRelTrans}>
                        <DeleteIcon style={{fontSize: 28}}/>
                    </IconButton>
                    &nbsp;&nbsp;
                    {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Form_View_Stores'))&&
                        <IconButton  size="small"  style={{
                                    color:
                                    (formForm.default_rel_trans_id===undefined
                                    || formForm.default_rel_trans_id===""
                                    || formForm.default_rel_trans_id==="0"
                                    || formForm.default_rel_trans_id===0)?"#858796":"#4e73df", fontSize: 12}} 
                                    disabled={formForm.default_rel_trans_id===undefined
                                            || formForm.default_rel_trans_id===""
                                            || formForm.default_rel_trans_id==="0"
                                            || formForm.default_rel_trans_id===0 } onClick={searchFormVehicles}>
                            <SearchIcon style={{fontSize: 28}}/>
                        </IconButton>
                    }
                </div>
            </div>
            <TypesDISearchStores
                showModalSearchStores={showModalSearchStores}
                setShowModalSearchStores={setShowModalSearchStores}
                selectStores={"Rel_Trans"}
                setFormForm={setFormForm}
            />
             <TypesDISearchStoresAddress
                showModalSearchStoresAddress={showModalSearchStoresAddress}
                setShowModalSearchStoresAddress={setShowModalSearchStoresAddress}
                selectStores={"Rel_Trans"}
                setFormForm={setFormForm}
                titleTable={formForm.default_rel_trans_tradename}
                store_id={formForm.default_rel_trans_id}
                priority={6}
            />
             <TypesDISearchVehicles
                showModalSearchVehicles={showModalSearchVehicles}
                setShowModalSearchVehicles={setShowModalSearchVehicles}
                selectStores={"Rel_Trans"}
                setFormForm={setFormForm}
                titleTable={formForm.default_rel_trans_tradename}
                store_id={formForm.default_rel_trans_id}
            />
        </> 
    )
}

TypeDICreateOrEditRelTrans.propTypes = {
    formForm: PropTypes.object.isRequired,
    setFormForm: PropTypes.func.isRequired,
    openEditRow: PropTypes.bool.isRequired, 
    errorsForm: PropTypes.object.isRequired,
    setErrorsForm: PropTypes.func.isRequired,
    validateForm: PropTypes.func.isRequired,
    documentTypesToSelect: PropTypes.array.isRequired,
    roadTypesToSelect: PropTypes.array.isRequired,
    communityToSelect: PropTypes.array.isRequired,
    countryToSelect: PropTypes.array.isRequired,
    optTypeToSelect: PropTypes.array.isRequired
  };
  
  export default TypeDICreateOrEditRelTrans;
