import React, {  Suspense,useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next"
import { setFilters, resetFilters} from 'reducers/filtersShippingsSlice';
import { resetPagination } from 'reducers/paginationShippingsSlice';
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { ENDPOINT } from "constants/variables";
import { es } from 'date-fns/locale';
import Box from '@mui/material/Box'; 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import SwipeableDrawer from '@mui/material/SwipeableDrawer'; 
import "css/generalSettings.css";
import { successErrorAction, warningErrorAction } from "actions/ErrorActions";
import { helpHttp } from "service/HelpHttp";
import { setLoading } from 'reducers/loadingSlice';
import { LoadingNews } from "components/utils/LoadingNews";
import TableSortingShipping from "pages/shipping/filterShipping/SortingTablesShipping";
import ShippingPDF from "pages/shipping/generatePDF/ShippingPDF";
import PropTypes from 'prop-types'; 

const initialFiltersShippings= {
  "idFrom":"",
  "idTo":"",
  "defaultStoreIdFrom":"",
  "defaultStoreIdTo":"",
  "useraccountIdFrom":"",
  "useraccountIdTo":"",
  "numberShippingFrom":"",
  "numberShippingTo":"",
  "dateShippingFrom":null,
  "dateShippingTo":null,
};
 
const initialStoresToSelect = [{
  "id": "",
  "documentid": "",
  "tradename": "",
  "name": ""
}];

const initialUserAccountToSelect = [{
  "id": "",
  "username":"",
  "firstName": "",
  "lastName1": "",
  "lastName2": "",
  "email": "",
  "aliasuser": ""
}];

const initialStateShipping = [{
  "id" : 0,
  "ownshipping_id":0,
  "defaultStore_id" : 0,
  "tradename" : "",
  "useraccount_id" : 0,
  "username" :"",
  "defaultformatnumber": 1,
  "number_shipping":"",
  "date_shipping" : "",
  "opt_tras_store_id" : 0,
  "opt_tras_tradename" : "",
  "dest_tras_store_id" : 0,
  "dest_tras_tradename" : "",
  "rel_trans_store_id" : 0,
  "rel_trans_tradename" : "",
  "vehiclestore_licenseplate" : "",
  "totalamount" : 0
}];
            
const ShippingFilter = ({ showModalShippingsSettings,setShowModalShippingsSettings}) => {
  const {t,i18n} = useTranslation(['listDataShipping']);
  const token = useSelector((state) => state.loginUser.token); 
  const roles = useSelector((state) => state.loginUser.roles);  
  const useraccount_id = useSelector((state) => state.loginUser.useraccount_id);  
  const defaultStore = useSelector((state) => state.loginUser.defaultStore);  
  const LIMITPAGESIZE = useSelector((state) => state.loginUser.defaultticketsformat.limitpagesizetickes);
  const DELETE_CODE = useSelector((state) => state.formatDIUser.delete_code); 
  const DELETE_CODE_SHIPPING = useSelector((state) => state.formatDIUser.delete_code_formdi); 
  const [dataShipping, setDataShipping] = useState(initialStateShipping); 
  const filtersShippingsDetails = useSelector((state) => state.filtersShippings);
  const [filtersShipping, setFiltersShipping]= useState(filtersShippingsDetails);
  const [numTotRegFilter,setNumTotRegFilter]= useState(0);
  const [numTotRegShipping,setNumTotRegShipping]= useState(0);
  const [errorsFiltersShipping, setErrorsFiltersShipping] = useState({});
  const [inputTextShipping, setInputTextShipping] = useState("");  
  const [openDeleteRowShipping,setOpenDeleteRowShipping]= useState(false); 
  const [openEditRowShipping,setOpenEditRowShipping]= useState(false);
  const [openEditFilterShipping,setOpenEditFilterShipping]= useState(false);
  const [rowEditShipping,setRowEditShipping]= useState(initialStateShipping[0]);
  const isLoadingData = useSelector((state) => state.loading.isLoading);
  const [isLoadingErrorShipping, setIsLoadingErrorShipping] = useState(false);
  const [idCodeDelete,setIdCodeDelete]=useState(DELETE_CODE);
  const [viewPDF,setViewPDF]=useState(false);  
  const dispatch = useDispatch();
  const [activeOffsetShipping, setActiveOffsetShipping]= useState(false);
  const [currencyOffsetShipping, setCurrencyOffsetShipping] = useState(0);
  const [rangeOffsetShipping, setRangeOffsetShipping] = useState([{}]);
  const [storesToSelect,setStoresToSelect]= useState(initialStoresToSelect);
  const [userAccountsToSelect,setUserAccountsToSelect]= useState(initialUserAccountToSelect); 
  const navigate = useNavigate(); 

  const keyPress = useCallback(
    e => {
      if (e.key === 'Escape' && showModalShippingsSettings) {
        setDataShipping(initialStateShipping); 
        setNumTotRegFilter(0);
        setNumTotRegShipping(0);
        setErrorsFiltersShipping({});
        setInputTextShipping(""); 
        setOpenDeleteRowShipping(false); 
        setOpenEditRowShipping(false);
        setOpenEditFilterShipping(false);
        setRowEditShipping(initialStateShipping[0]);
        setIsLoadingErrorShipping(false);
        setIdCodeDelete(DELETE_CODE);
        setViewPDF(false);
        setActiveOffsetShipping(false);
        setCurrencyOffsetShipping(0);
        setRangeOffsetShipping([{}]);
        setStoresToSelect(initialStoresToSelect);
        setUserAccountsToSelect(initialUserAccountToSelect); 
        setShowModalShippingsSettings(false);
        dispatch(setLoading(false));
        dispatch(resetPagination());
        dispatch(resetFilters());
        setFiltersShipping(initialFiltersShippings);
        navigate("/home");
        return () => {};
      }
    },
    [
      setDataShipping,  
      setFiltersShipping,
      setNumTotRegFilter,
      setNumTotRegShipping,
      setErrorsFiltersShipping,
      setInputTextShipping, 
      setOpenDeleteRowShipping, 
      setOpenEditRowShipping,
      setOpenEditFilterShipping,
      setRowEditShipping, 
      setIsLoadingErrorShipping,
      setIdCodeDelete,
      setViewPDF,
      setActiveOffsetShipping,
      setCurrencyOffsetShipping,
      setRangeOffsetShipping,
      setStoresToSelect,
      setUserAccountsToSelect, 
      setShowModalShippingsSettings,
      showModalShippingsSettings, 
      navigate,
      dispatch,
      DELETE_CODE]
  );

  const closeModalShipping = () => {    
    setDataShipping(initialStateShipping); 
    setNumTotRegFilter(0);
    setNumTotRegShipping(0);
    setErrorsFiltersShipping({});
    setInputTextShipping(""); 
    setOpenDeleteRowShipping(false); 
    setOpenEditRowShipping(false);
    setOpenEditFilterShipping(false);
    setRowEditShipping(initialStateShipping[0]);
    setIsLoadingErrorShipping(false);
    setIdCodeDelete(DELETE_CODE);
    setViewPDF(false);
    setActiveOffsetShipping(false);
    setCurrencyOffsetShipping(0);
    setRangeOffsetShipping([{}]);
    setStoresToSelect(initialStoresToSelect);
    setUserAccountsToSelect(initialUserAccountToSelect); 
    setShowModalShippingsSettings(false);
    dispatch(setLoading(false));
    dispatch(resetPagination());
    dispatch(resetFilters());
    setFiltersShipping(initialFiltersShippings); 
    navigate("/home");
    return () => {};
  };
 
  const openCreateOrEditDataShipping = () => {
    setInputTextShipping(""); 
    setOpenEditFilterShipping(!openEditFilterShipping);
    navigate("/shippingdetail/0");
  };

   
  const openEditFiltersShipping = () => { 
      setOpenEditFilterShipping(!openEditFilterShipping);
  };

  const handleSubmitFilterEditShipping = () => {
    setErrorsFiltersShipping(validateFormFilterShipping());
    if (Object.keys(errorsFiltersShipping).length === 0) {
      setErrorsFiltersShipping({}); 
      dispatch(resetPagination());
      dispatch(setFilters(filtersShipping)); 
      setOpenEditFilterShipping(false);
    }  else{
      dispatch(warningErrorAction(t('Text_Error_Filter')));
    }
  }

  const handleDeleteFiltersShipping = () => {
    setErrorsFiltersShipping({});
    dispatch(resetFilters());
    dispatch(resetPagination());
    setFiltersShipping(initialFiltersShippings);
    setOpenEditFilterShipping(!openEditFilterShipping);
  };

  const handleCancelFiltersShipping = () => {
    setErrorsFiltersShipping({});
    setOpenEditFilterShipping(!openEditFilterShipping);
  };
 
  const handleChangeSelectDateShipping = (event) => {
    setFiltersShipping(filtersShipping => ({
      ...filtersShipping,
      dateShippingFrom: event!==null?new Date(event):null,
      dateShippingTo:null
    }));
  };

  const handleBlurSelectDateShippingFrom = (e) => {
    handleChangeSelectDateShipping(e);
    setErrorsFiltersShipping(validateFormFilterShipping());
  };

  const handleChangeSelectDateShippingTo = (event) => {
    setFiltersShipping(filtersShipping => ({
      ...filtersShipping,
      dateShippingTo: event!==null?new Date(event):null
    }));
  };

  const handleBlurSelectDateShippingTo = (e) => {
    handleChangeSelectDateShippingTo(e);
    setErrorsFiltersShipping(validateFormFilterShipping());
  };
    
  const handleChangeFilterEditShipping = (e) => {
    const { name, value } = e.target;
    setFiltersShipping(filtersShipping => ({
      ...filtersShipping,
      [name]: value
    }));
  };

  const handleBlurFilterEditShipping = (e) => {
    setErrorsFiltersShipping(validateFormFilterShipping());
    handleChangeFilterEditShipping(e);
  };

  const toggleDrawerFilterEditShipping = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.type === 'keydown') {
      event.preventDefault();
      handleCloseDataEditShipping();
    }
  };

  const validateFormFilterShipping = () => {

    let errorsFiltersShipping = {};
    let regexNumber = /^[0-9]+$/;
    let regexInvalidCharacters = /[\\!\\$\\%\\&\\|\\<\\>\\?\\{\\}\\~\\"\\']+/;

    if (filtersShipping.idFrom!=="" & !regexNumber.test(filtersShipping.idFrom)) {
      errorsFiltersShipping.idFrom = 'Text_TextNoNumber';
    }
    if (filtersShipping.idTo!=="" & !regexNumber.test(filtersShipping.idTo)) {
      errorsFiltersShipping.idTo = 'Text_TextNoNumber';
    }
    if (filtersShipping.idFrom!=="" & !errorsFiltersShipping.idFrom!==undefined){
      if (filtersShipping.idTo!=="" & !errorsFiltersShipping.idTo!==undefined){
        if (parseInt(filtersShipping.idTo)<parseInt(filtersShipping.idFrom)){
          errorsFiltersShipping.idTo = 'Text_TextGreater_Id';
        }
      }
    }

    if (filtersShipping.defaultStoreIdTo!=="" & filtersShipping.defaultStoreIdTo<filtersShipping.defaultStoreIdFrom){
      errorsFiltersShipping.defaultStoreIdTo = 'Text_TextGreater_Store';
    }

    if (filtersShipping.useraccountIdTo!=="" & filtersShipping.useraccountIdTo<filtersShipping.useraccountIdFrom){
      errorsFiltersShipping.useraccountIdTo = 'Text_TextGreater_UserAccount';
    }
  

    if (regexInvalidCharacters.test(filtersShipping.numberShippingFrom)) {
      errorsFiltersShipping.numberShippingFrom = 'Text_TextNoValid';
    } 
    if (regexInvalidCharacters.test(filtersShipping.numberShippingTo)) {
      errorsFiltersShipping.numberShippingTo = 'Text_TextNoValid';
    }

    if (filtersShipping.numberShippingTo!=="" & filtersShipping.numberShippingTo<filtersShipping.numberShippingFrom){
      errorsFiltersShipping.numberShippingTo = 'Text_TextGreater_numberShippingTo';
    }

    if (filtersShipping.dateShippingTo!==null & filtersShipping.dateShippingTo<filtersShipping.dateShippingFrom){
      errorsFiltersShipping.dateShippingTo = 'Text_TextGreater_Date';
    }
    return errorsFiltersShipping;
  }  

  const filteredDataShipping = dataShipping.filter((el) => {
    if (inputTextShipping === '') {
        return el;
    }
    else {
      let dataTranslate = 
        String(el.id || '') +
        String(el.number_shipping || '')+
        String(el.opt_tras_tradename || '')+
        String(el.dest_tras_tradename || '')+
        String(el.rel_trans_tradename || '')+
        String(el.vehiclestore_licenseplate || '')+
        String(el.totalamount || '');
      let result = dataTranslate.toLowerCase().includes(inputTextShipping.toLowerCase());
      return result;
    }
  })

  let inputHandlerShipping = (e) => {
      var lowerCase = e.target.value.toLowerCase();
      setInputTextShipping(lowerCase);
  };

  const handleChangeDeleteRowShipping = (e) => {
    const value  = e.target.value;
    setIdCodeDelete(value);
  };

  const handleCancelDeleteRowShipping = () => {
    setRowEditShipping(initialStateShipping[0]);
    setIsLoadingErrorShipping(false);
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowShipping(false);
  };

  const handleCloseDeleteRowShipping = (e) => {
    if (idCodeDelete !== DELETE_CODE_SHIPPING) {
      dispatch(warningErrorAction(t('Text_InvalidCode')));
      return;
    }
    let isMounted = true;
    if (e.key !== 'Escape') {
      if (rowEditShipping.id!=='') {      
        const fetchData = async () => {
          dispatch(setLoading(true));
          try {
            const getData = await helpHttp().del(ENDPOINT,`shipping/deleteshipping`,token,rowEditShipping);
            if (getData.status === "OK") {
              if (isMounted) {
                dispatch(successErrorAction(t('Text_ValidCode')));
                dispatch(successErrorAction(t('Text_DeleteField'))); 
              }
            } else {
              if (isMounted) {
                dispatch(warningErrorAction(t(getData.cause)));
              }
            }
            dispatch(setLoading(false));
          } catch (error) {
            dispatch(setLoading(false));
            dispatch(warningErrorAction(t('Text_IncorrectData'))); 
          }  
        };
        fetchData(); 
      } else {
        dispatch(warningErrorAction(t('Text_InvalidId')));
      }
    }
    setIdCodeDelete(DELETE_CODE);
    setOpenDeleteRowShipping(false);
    setIsLoadingErrorShipping(false);
    handleCloseDataEditShipping(); 
  };
 
  const handleCloseDataEditShipping = () => { 
    setOpenEditRowShipping(false);
    setRowEditShipping(openEditRowShipping?rowEditShipping:initialStateShipping[0]);
    dispatch(setLoading(false));
    setIsLoadingErrorShipping(false);
    toggleDrawerFilterEditShipping(false);  
	};
  
  useEffect(() => {
    if (numTotRegShipping > Number(LIMITPAGESIZE)) {
      setActiveOffsetShipping(true);
      const newRangeOffset = [
        {
          value: 0,
          label: " 1 - " + Number(LIMITPAGESIZE).toLocaleString('de-DE'),
        }
      ];
      for (let i = Number(LIMITPAGESIZE); i < numTotRegShipping; i += Number(LIMITPAGESIZE)) {
        newRangeOffset.push({
          value: i,
          label: " " + (i + 1).toLocaleString('de-DE') + " - " + (i + Number(LIMITPAGESIZE)).toLocaleString('de-DE'),
        });
      }
      setRangeOffsetShipping(newRangeOffset);
    } else {
      setCurrencyOffsetShipping(0);
      setActiveOffsetShipping(false);
      setRangeOffsetShipping([{}]);
    }
  }, [numTotRegShipping, LIMITPAGESIZE]);
 
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event && typeof event === 'object' && event.key) {
        keyPress(event);
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [keyPress]);
  
  useEffect(() => {
    let isMounted = true;
    const fetchnumTotRegShipping = async () => {
      try {
        const getData  = (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master')))
        ? await helpHttp().get(ENDPOINT,`shipping/getinformationtableshipping`,token)
        : (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master'))?
            await helpHttp().get2(ENDPOINT,`shipping/getinformationtableshippingwithstore/`,token,defaultStore.id):
            await helpHttp().get8(ENDPOINT,`shipping/getinformationtableshippingwithstoreanduseraccount`,token,defaultStore.id,useraccount_id))
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataShipping(initialStateShipping);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            }  
          }
        } else if (isMounted) {
          setNumTotRegShipping(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }   
    };

    const fetchnumTotRegFilter = async () => { 
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master')))?
        await helpHttp().get3(ENDPOINT,`shipping/getcountfiltershipping`,token,"-1","-1",filtersShippingsDetails):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master')))?
        await helpHttp().get3(ENDPOINT,`shipping/getcountfiltershipping`,token,"-1",useraccount_id,filtersShippingsDetails):
        await helpHttp().get3(ENDPOINT,`shipping/getcountfiltershipping`,token,defaultStore.id,useraccount_id,filtersShippingsDetails);
        if (getData.err || getData.message === "Load failed") {
          if (isMounted) {
            setDataShipping(initialStateShipping);
            if (getData.message==="Load failed"){
              dispatch(warningErrorAction(t('Text_ConnectionFailied')));
            }else {
              dispatch(warningErrorAction(t('Text_InvalidCredentials')));
            } 
          }
        } else if (isMounted) {
          setNumTotRegFilter(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 
 
    const fetchDataStore = async () => {
      try {
        const getData = await helpHttp().get(ENDPOINT,`shipping/storesorderbytradename`,token);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setStoresToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };

    const fetchDataUserAccount = async () => {
      try {
        const getData =(roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master')))?
                        await helpHttp().get2(ENDPOINT,`shipping/useraccountsorderbyusername/`,token,"-1"):
                        await helpHttp().get2(ENDPOINT,`shipping/useraccountsorderbyusername/`,token,defaultStore.id);
        if (getData.err) {
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setUserAccountsToSelect(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    }; 

    const fetchDataShipping = async () => {
      dispatch(setLoading(true));
      try {
        const getData = 
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master')))?
        await helpHttp().post7(ENDPOINT,`shipping/getshipping`,token,currencyOffsetShipping,LIMITPAGESIZE,"-1","-1",filtersShippingsDetails):
        (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master')))?
        await helpHttp().post7(ENDPOINT,`shipping/getshipping`,token,currencyOffsetShipping,LIMITPAGESIZE,"-1",useraccount_id,filtersShippingsDetails):
        await helpHttp().post7(ENDPOINT,`shipping/getshipping`,token,defaultStore.id,useraccount_id,filtersShippingsDetails);
        if (getData.err) {
          dispatch(setLoading(false));
          dispatch(warningErrorAction(t('Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataShipping(getData);
          dispatch(setLoading(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      }
    };
 
    if (showModalShippingsSettings){
      fetchnumTotRegShipping();
      fetchnumTotRegFilter(); 
      fetchDataShipping();
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master'))){
        fetchDataStore();
      }
      if (roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master'))){
        fetchDataUserAccount();
      } 
    }  
    return () => { isMounted = false; };
  }, [t,roles,token,dispatch,i18n.language,LIMITPAGESIZE,filtersShippingsDetails,showModalShippingsSettings,currencyOffsetShipping,defaultStore.id,useraccount_id ,openDeleteRowShipping,setOpenDeleteRowShipping,setOpenEditRowShipping,openEditRowShipping,rowEditShipping,setRowEditShipping,defaultStore ]);
     
   return (
    <>
       {showModalShippingsSettings ? ( 
        <div className="container-fluid">
          {/* Search input */}
          <div id="myDIV">
            <h1 className={"hANewStyle mb-2"}>
            {isLoadingErrorShipping?<i className="fas fa-solid fa-exclamation fa-2x text-danger"></i>:isLoadingData?<CircularProgress color={openDeleteRowShipping?"warning":"primary"}/>:<i className="fas fa-truck text-warning"></i>} {t('Text_Shippings_Table')}</h1>
              {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_Create')) ?
                <div>
                  <button type="button" className="buttonCreate" onClick={openCreateOrEditDataShipping}>{t('Button_Create')}</button><> </>
                  <button type="button" className="buttonBack" onClick={closeModalShipping}>{t('Button_Back')}</button><> </>
                </div>:
                <div>
                  <button type="button" className="buttonBack" onClick={closeModalShipping}>{t('Button_Back')}</button><> </>
                </div>
              }
          </div>  
          <br/>
          <div className="searchStyle">
            <TextField
              id="outlined-basic"
              variant="outlined" 
              value={inputTextShipping}
              inputProps={{ style: {fontSize: '18px'} }}
              InputLabelProps={{ style: {fontSize: '18px', background: '#f8f9fc'}}} 
              placeholder={t('Text_SHIPPING_Search')} 
              onChange={inputHandlerShipping}
              fullWidth
              label={t('Text_SHIPPING_Search')}>
            </TextField>
          </div>
          <br/>
          <div id="myDIV">
            <h1 className="h6NewStyleHigh mb-2">
            {t('Text_Filter')}&nbsp;&nbsp;
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersShipping}><i className="fas fa-solid fa-circle" style={{color:(filtersShippingsDetails.idFrom!=="" || filtersShippingsDetails.idTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Code')}</button><> </>
                {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Shippings_Store_Master"))&&
                  <>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersShipping}><i className="fas fa-solid fa-circle" style={{color:(filtersShippingsDetails.defaultStoreIdFrom!=="" || filtersShippingsDetails.defaultStoreIdTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Store_Create')}</button><> </>
                  </>
                }
                 {roles[0].listmenus.find((key)=>(key.name==="SubMenu_Settings_Shippings_User_Master"))&&
                  <>
                    <button type="button" className="buttonFilterHigh" onClick={openEditFiltersShipping}><i className="fas fa-solid fa-circle" style={{color:(filtersShippingsDetails.useraccountIdFrom!=="" || filtersShippingsDetails.useraccountIdTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_UserAcount_Create')}</button><> </>
                  </>
                }
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersShipping}><i className="fas fa-solid fa-circle" style={{color:(filtersShippingsDetails.numberShippingFrom!=="" || filtersShippingsDetails.numberShippingTo!=="")?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Number')}</button><> </>
                <button type="button" className="buttonFilterHigh" onClick={openEditFiltersShipping}><i className="fas fa-solid fa-circle" style={{color:(filtersShippingsDetails.dateShippingFrom!==null|| filtersShippingsDetails.dateShippingTo!==null)?"#32CD32":"#FFFFFF"}}></i>&nbsp;&nbsp;{t('Text_Date')}</button><> </>                 
            </h1>
          </div>
          <br/>

          {!isLoadingData ?
            <>
              <div className="card shadow mb-4">    
                <div className="card-body">
                  <TableSortingShipping
                    dataShipping={filteredDataShipping} 
                    numTotRegFilter={ numTotRegFilter}
                    titleTable={t('Text_SHIPPING_Table')}
                    setOpenDeleteRowShipping={setOpenDeleteRowShipping}
                    setRowEditShipping={setRowEditShipping}
                    showEditButtonShipping={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_Edit'))?true:false}
                    showPrintButtonShipping={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_Print'))?true:false}
                    showDeleteButtonShipping={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Shipping_Delete'))?true:false}
                    showStoreShipping={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master'))?true:false}
                    showUserAccountShipping={roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master'))?true:false}
                    numTotRegShipping={numTotRegShipping}
                    activeOffsetShipping={activeOffsetShipping}
                    rangeOffsetShipping={rangeOffsetShipping}
                    currencyOffsetShipping={currencyOffsetShipping}
                    setCurrencyOffsetShipping={setCurrencyOffsetShipping} 
                    setViewPDF={setViewPDF} 
                  > 
                  </TableSortingShipping>
                </div>             
              </div>
              
              {openEditFilterShipping &&
               <div>
               <SwipeableDrawer
                 anchor='right'
                 open={true}
                 onClose={toggleDrawerFilterEditShipping(false)}
                 onOpen={toggleDrawerFilterEditShipping(true)}>
                 <Container >
                   <Box sx={{ bgcolor: '#ffffff',height:'100vh', width: '850' }}>
                     <br/>
                       <div id="myDIV">
                         <h1 className="h1NewStyle px-4 mb-2">{t('Text_Filter')}</h1>
                       </div>
                       <hr className="sidebar-divider d-none d-md-block"/>
                       <form className="createfiltershipping" onSubmit={handleCancelFiltersShipping}>  
                         <div className="form-floating mb-3 px-4 primary">
                           <div className="form-group">
                             <TextField
                              id="idFrom" 
                              name="idFrom"
                              value={filtersShipping.idFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersShipping.idFrom!=null?t(errorsFiltersShipping.idFrom):t('Text_Filter_Code_From')}
                              placeholder={t('PlaceHolder_Filter_Code_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditShipping}
                              onChange={handleChangeFilterEditShipping}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                              id="idTo" 
                              name="idTo"
                              value={filtersShipping.idTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersShipping.idTo!=null?t(errorsFiltersShipping.idTo):t('Text_Filter_Code_To')}
                              placeholder={t('PlaceHolder_Filter_Code_to')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditShipping}
                              onChange={handleChangeFilterEditShipping}/>
                           </div>
                           {roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_Store_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="defaultStoreIdFrom"
                                      name="defaultStoreIdFrom"
                                      value={filtersShipping.defaultStoreIdFrom}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersShipping.defaultStoreIdFrom!=null?t(errorsFiltersShipping.defaultStoreIdFrom):t('Text_Filter_Store_From')}
                                      placeholder={t('PlaceHolder_Filter_Store_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditShipping}
                                      onChange={handleChangeFilterEditShipping}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {storesToSelect.map((option) => (
                                          <option key={option.id} value={option.id}>
                                             {option.documentid+" - "+option.tradename}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="defaultStoreIdTo"
                                        name="defaultStoreIdTo"
                                        value={filtersShipping.defaultStoreIdTo} 
                                        inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        label={errorsFiltersShipping.defaultStoreIdTo!=null?t(errorsFiltersShipping.defaultStoreIdTo):t('Text_Filter_Store_To')}
                                        placeholder={t('PlaceHolder_Filter_Store_to')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditShipping}
                                        onChange={handleChangeFilterEditShipping}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {storesToSelect.filter(item=>(item.id>=filtersShipping.defaultStoreIdFrom)).map(option => (
                                          <option key={option.id} value={option.id}>
                                              {option.documentid+" - "+option.tradename}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           { roles[0].listmenus.find((key)=>(key.name==='SubMenu_Settings_Shippings_User_Master'))   &&
                              <>
                                <Suspense delayMs={500} fallback={<CircularProgress sx={{ color :"#f6c23e"}}/>}>
                                  <div className="form-group">
                                    <TextField
                                      id="useraccountIdFrom"
                                      name="useraccountIdFrom"
                                      value={filtersShipping.useraccountIdFrom}
                                      inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                      InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                      label={errorsFiltersShipping.useraccountIdFrom!=null?t(errorsFiltersShipping.useraccountIdFrom):t('Text_Filter_UserAccount_From')}
                                      placeholder={t('PlaceHolder_UserAccount_From')}
                                      variant="outlined"
                                      onBlur={handleBlurFilterEditShipping}
                                      onChange={handleChangeFilterEditShipping}
                                      select
                                      SelectProps={{
                                      native: true,
                                      }}>
                                      <option key={0} value=""/>
                                      {userAccountsToSelect.map((option) => (
                                          <option key={option.id} value={option.username}>
                                            {option.username+" - "+option.email}
                                          </option>
                                      ))}
                                    </TextField>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                    <TextField
                                        id="useraccountIdTo"
                                        name="useraccountIdTo"
                                        value={filtersShipping.useraccountIdTo}  
                                        inputProps={{ style: {width: 382,  fontSize: '18px', }, maxLength: 100 }}
                                        InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                                        label={errorsFiltersShipping.useraccountIdTo!=null?t(errorsFiltersShipping.useraccountIdTo):t('Text_Filter_UserAccount_To')}
                                        placeholder={t('PlaceHolder_UserAccount_To')}
                                        variant="outlined"
                                        onBlur={handleBlurFilterEditShipping}
                                        onChange={handleChangeFilterEditShipping}
                                        select
                                        SelectProps={{
                                        native: true,
                                        }}>
                                         <option key={0} value=""/>
                                        {userAccountsToSelect.filter(item=>(item.username>=filtersShipping.useraccountIdFrom)).map(option => (
                                          <option key={option.id} value={option.username}>
                                              {option.username+" - "+option.email}
                                          </option>
                                        ))}
                                      </TextField>
                                    </div>
                                </Suspense>
                            </>
                           }   
                           <div className="form-group">
                             <TextField
                              id="numberShippingFrom" 
                              name="numberShippingFrom"
                              value={filtersShipping.numberShippingFrom}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersShipping.numberShippingFrom!=null?t(errorsFiltersShipping.numberShippingFrom):t('Text_Filter_Number_From')}
                              placeholder={t('PlaceHolder_Filter_Number_From')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditShipping}
                              onChange={handleChangeFilterEditShipping}/>
                             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                             <TextField
                              id="numberShippingTo" 
                              name="numberShippingTo"
                              value={filtersShipping.numberShippingTo}
                              inputProps={{ style: {width: 400,  fontSize: '18px', }, maxLength: 15 }}
                              InputLabelProps={{ style: {fontSize: '18px', background: '#FFFFFF'}}}
                              label={errorsFiltersShipping.numberShippingTo!=null?t(errorsFiltersShipping.numberShippingTo):t('Text_Filter_Number_To')}
                              placeholder={t('PlaceHolder_Filter_Number_To')}
                              variant="outlined"
                              onBlur={handleBlurFilterEditShipping}
                              onChange={handleChangeFilterEditShipping}/>
                           </div>
                         
                          <div className="form-group">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="dateShippingFrom" 
                                  name="dateShippingFrom"
                                  value={ filtersShipping.dateShippingFrom!== null ? new Date(filtersShipping.dateShippingFrom): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersShipping.dateShippingFrom!=null?t(errorsFiltersShipping.dateShippingFrom):t('PlaceHolder_Filter_DateSheet_From')}
                                  onBlur={handleBlurSelectDateShippingFrom}
                                  onChange={handleChangeSelectDateShipping}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === "es" ? es : undefined}>
                                <DesktopDatePicker
                                  id="dateShippingTo" 
                                  name="dateShippingTo"
                                  minDate={filtersShipping.dateShippingFrom!== null ? new Date(filtersShipping.dateShippingFrom): null}
                                  value={ filtersShipping.dateShippingTo!== null ? new Date(filtersShipping.dateShippingTo): null}
                                  format={i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy"}
                                  label={errorsFiltersShipping.dateShippingTo!=null?t(errorsFiltersShipping.dateShippingTo):t('PlaceHolder_Filter_DateSheet_To')}
                                  onBlur={handleBlurSelectDateShippingTo}
                                  onChange={handleChangeSelectDateShippingTo}
                                  slotProps={{
                                    textField: {
                                      placeholder: i18n.language==="es"?"dd/MM/yyyy":"MM/dd/yyyy",
                                      sx: {
                                        color: '#1565c0',
                                        maxLength: '100',
                                        width: 430,  
                                        fontSize: '18px'
                                      },
                                      InputProps: {
                                        style: {
                                          fontSize: '18px',
                                        }
                                      },
                                      InputLabelProps:{ 
                                        style: {
                                          fontSize: '18px', 
                                          background: '#FFFFFF'
                                        }
                                      },
                                    },
                                  }}
                                />
                              </LocalizationProvider>
                          </div>                    
                        </div>
                        <hr className="sidebar-divider d-none d-md-block"/>
                        <div className="btn-group d-grid d-md-flex justify-content-md-end" role="group">
                          <button className="btn btn-primary btn-lg custom-btn" type="button" onClick={handleSubmitFilterEditShipping}>{t('Button_Apply')}</button><> </>
                          <button className="btn btn-outline-secondary btn-lg custom-btn" type="button" onClick={handleDeleteFiltersShipping}>{t('Button_Cancel_Filters')}</button>
                        </div>
                     </form>
                   </Box>
                 </Container>
               </SwipeableDrawer>
              </div>
              }  
               <ShippingPDF 
                rowEditShipping={rowEditShipping} 
                viewPDF={viewPDF} 
                setViewPDF={setViewPDF}
              />
             
              <Dialog open={openDeleteRowShipping} onClose={handleCancelDeleteRowShipping}>
                <DialogTitle style={{color:"#e74a3b"}}>{t('Text_Delete')} {rowEditShipping.id}  - {rowEditShipping.number_shipping} - {rowEditShipping.opt_tras_tradename} </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('Text_Line1')}<br/>
                      {t('Text_Line2')}
                    </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="idCode"
                        label={t('Text_Label')}
                        type="text"
                        fullWidth
                        variant="standard"
                        error
                        defaultValue={idCodeDelete}
                        onChange={handleChangeDeleteRowShipping}/>
                    </DialogContent>
                  <DialogActions>
                  <Button style={{color:"#e74a3b",fontSize: 16 }} onClick={handleCancelDeleteRowShipping}>{t('Button_Cancel')}</Button>
                  <Button variant="contained" style={{color:"#ffffff", background:"#e74a3b",fontSize: 16}} onClick={handleCloseDeleteRowShipping}>{t('Button_Delete')}</Button>
                </DialogActions>
              </Dialog> 
              {/* End Content Row */}
          </>:
            <LoadingNews/> 
          }
        </div>
        //* End Page Content *//
      ) :  null }
    </>
  )
}

ShippingFilter.propTypes = {
  showModalShippingsSettings: PropTypes.bool.isRequired,
  setShowModalShippingsSettings: PropTypes.func.isRequired
};

export default ShippingFilter;

