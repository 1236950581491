import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idFrom:"",
  idTo:"",
  defaultStoreIdFrom:"",
  defaultStoreIdTo:"",
  dateEmailFrom:null,
  dateEmailTo:null,
  store_IdToFrom:"",
  store_IdToTo:"", 
  reasonFromES:"",
  reasonToES:"",
  reasonFromEN:"",
  reasonToEN:"",
  statusFromES:"",
  statusToES:"",
  statusFromEN:"",
  statusToEN:"" 
};

const filtersSentEmailslice = createSlice({
  name: 'filtersSentEmails',
  initialState,
  reducers: {
    setFilters(state, action) {
      return { ...state, ...action.payload };
    },
    resetFilters() {
      return initialState;
    },
  },
});

export const { setFilters, resetFilters } = filtersSentEmailslice.actions;
export default filtersSentEmailslice.reducer;
