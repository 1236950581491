import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "css/generalSettings.css";
import { warningErrorAction } from "actions/ErrorActions";
import ReportTemplateShipping from 'pages/shipping/generatePDF/ReportTemplateShipping'; 
import { ENDPOINT } from "constants/variables";
import { helpHttp } from "service/HelpHttp";
import PropTypes from 'prop-types';

const initialStateShipping = {
  id: "",
  ownshipping_id: "",
  defaultStore_id:0, 
  useraccount_id:0,
  defaultformatnumber:1, 
  number_shipping:"",
  date_shipping:"",
  
  rel_trans_store_id:0, 
  rel_trans_documentid:"",
  rel_trans_tradename:"",
  rel_trans_street:"",

  vehiclestore_id:0, 
  vehiclestore_licenseplate:"", 
  vehiclestore_documentid:"",
  vehiclestore_defaultdriver:"",
  vehiclestore_trailer:"",
  
  opt_tras_store_id:0, 
  opt_tras_documentid:"",
  opt_tras_tradename:"",
  opt_tras_street:"",

  dest_tras_store_id:0, 
  dest_tras_documentid:"",
  dest_tras_tradename:"",
  dest_tras_street:"",
  
  weight:0, 
  price_weight:0, 
  service_1:"",
  price_service_1:0, 
  service_2:"",
  price_service_2:0, 
  service_3:"",
  price_service_3:0, 
  destine_street:"",
  tax:0, 
  taxincluded:false,
  observations:"",
  signature_driver:"",
  signature_charger:""
  
};
 
const ShippingPDF = ({ rowEditShipping, viewPDF, setViewPDF }) => {
  const { t, i18n } = useTranslation(['listDataShipping']);
  const token = useSelector((state) => state.loginUser.token);   
  const [dataShipping, setDataShipping] = useState(initialStateShipping);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let isMounted = true;
    const fetchDataShipping = async () => {
     
      try {
        const getData = await  helpHttp().get2(ENDPOINT,`shipping/getshippingbyid/`,token,rowEditShipping.id);
        if (getData.err) {
          setDataShipping(initialStateShipping);
          dispatch(warningErrorAction(t(getData.message === "Load failed" ? 'Text_ConnectionFailed' : 'Text_InvalidCredentials')));
        } else if (isMounted) {
          setDataShipping(getData);
        }
      } catch (error) {
        dispatch(warningErrorAction(t('Text_IncorrectData'))); 
      } 
    };
    if (rowEditShipping!==undefined && rowEditShipping.id!==null  && rowEditShipping.id!=="" && rowEditShipping.id!=="0" && rowEditShipping.id!==0) {
      fetchDataShipping();
    }
    
    return () => {isMounted = false;};
  }, [rowEditShipping, dispatch, t, token,i18n.language]);  

  return (
    <div id="pdf-content" style={{ padding: 10 }}>
      {(viewPDF && dataShipping!==undefined && dataShipping.id!==undefined  && dataShipping.id!==null  && dataShipping.id!=="" && dataShipping.id!=="0" && dataShipping.id!==0 ) &&
        <ReportTemplateShipping dataShipping={dataShipping}  initialStateShipping={initialStateShipping} setDataShipping={setDataShipping} setViewPDF={setViewPDF}/>
      }
    </div>
  );
};


ShippingPDF.propTypes = {
  viewPDF: PropTypes.bool.isRequired,
  setViewPDF: PropTypes.func.isRequired
};

export default ShippingPDF;
